(function() {
	angular.module("badge-counter", [])
	angular.module("badge-counter").directive('badgeCount', BadgeCount)

	// BadgeCount.$inject = [];

	function BadgeCount() {
		return {
			restrict: 'A',
			scope: {
				badgeCount: '=?'
			},
			// template: ''
			link: function(scope, element, attrs) {
				if (!scope.badgeCount) scope.badgeCount = 0;
				scope.badgeCount = Number(scope.badgeCount)
				if (!Number.isNaN(scope.badgeCount)) {
					var n = Math.abs(scope.badgeCount);
					if (n > 99) {
						n = '!';
					}
					else {
						n = 'a';
					}
					if (n) {
						element.attr('data-badge-count', n);
						element.toggleClass('badge-icon', true);

					}
				}
			}
		}
	}
}) ();