'use strict';

angular.module('schoolbushubApp')
  .config(function ($stateProvider) {
        $stateProvider.state('menu', {
            'abstract': true,
            data: {
                authorities: ['ROLE_PARENT', 'ROLE_ADMIN']
            },
            views: {
                'app-menu@': {
                    templateUrl: 'scripts/components/menu/menu.html',
                    controller: 'MenuController'
                },
                'left-menu@': {
                    templateUrl: 'scripts/components/menu/left-menu.html',
                    controller: 'MenuController'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ]
            }
        });
  });
