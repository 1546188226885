"use strict";

angular
    .module("schoolbushubApp")
    .controller(
        "MenuController",
        function (
            $scope,
            $state,
            Auth,
            Principal,
            $mdSidenav,
            $rootScope,
            $filter,
            Language,
            $translate,
            tmhDynamicLocale,
            $mdDialog,
            MainService,
            $mdToast
        ) {
            $scope.showAdministration = false;
            $scope.labelLanguage;
            $scope.isProcessing = true;

            $scope.toggleMenu = function (close) {
                close ? $mdSidenav("left").close() : $mdSidenav("left").toggle();
            };

            /**
             * Identification of authorizations and language
             */
            Principal.identity().then(function (account) {
                $scope.account = account;
                if ($scope.account) {
                    $rootScope.userName = $scope.account.firstName + " " + $scope.account.lastName;
                    var roles = _.filter($scope.account.authorities, function (rol) {
                        return rol === "ROLE_ADMIN" || rol === "ROLE_CUSTOMERADMIN" || rol === "ROLE_SEONADMIN";
                    });
                    if (roles.length > 0) {
                        $scope.showAdministration = true;
                    }
                    if (account.langKey === "en") {
                        $scope.labelLanguage = $filter("translate")("main.menu.spanish");
                    } else {
                        $scope.labelLanguage = $filter("translate")("main.menu.english");
                    }
                }
            });

            /**
             * Change Language
             *
             * @param languageKey
             */
            $scope.changeLanguage = function (languageKey) {
                if (languageKey) {
                    $translate.use(languageKey);
                    moment.locale(languageKey);
                    tmhDynamicLocale.set(languageKey);
                    Principal.identity().then(function (account) {
                        Language.updateUserLanguage(languageKey);
                        account.langKey = languageKey;
                    });
                } else {
                    Principal.identity().then(function (account) {
                        var languageKey;
                        if (account.langKey === "en") {
                            languageKey = "es";
                            $scope.labelLanguage = $filter("translate")("main.menu.english");
                        } else {
                            languageKey = "en";
                            $scope.labelLanguage = $filter("translate")("main.menu.spanish");
                        }
                        Language.updateUserLanguage(languageKey);
                        $translate.use(languageKey);
                        moment.locale(languageKey);
                        tmhDynamicLocale.set(languageKey);
                        account.langKey = languageKey;
                    });
                }
            };

            $scope.privacyPolicy = function () {
                window.open("https://www.safefleet.net/privacy-policy/");
            };

            /**
             * Edit profile
             */
            $scope.editProfile = function (e) {
                $mdDialog
                    .show({
                        controller: DialogController,
                        templateUrl: "edit-contact-information.html",
                        parent: angular.element(document.body),
                        targetEvent: e,
                        clickOutsideToClose: false,
                        fullscreen: true,
                        resolve: {
                            account: function () {
                                return $scope.account;
                            },
                        },
                    })
                    .then(function () {
                        var toast = $mdToast
                            .simple()
                            .textContent($filter("translate")("main.editProfile.saveSuccess"))
                            .position("bottom right")
                            .hideDelay(3000);
                        $mdToast.show(toast);
                    });
            };

            function DialogController($scope, $mdDialog, $filter, account) {
                $scope.isParent = account.authorities.length === 1 && account.authorities[0] === 'ROLE_PARENT';
                $scope.email = account.email;
                $scope.phone = account.mobileNumber;
                $scope.sendEmail = account.sendEmail;

                $scope.cancel = function () {
                    $mdDialog.cancel();
                };


                $scope.deleteAccountShown = false;

                $scope.toggleDeleteConfirm = function (show) {
                    $scope.deleteAccountShown = !!show;
                };

                $scope.confirmDelete = function (ev, pass) {
                    $scope.isProcessing = true;
                        var confirm = $mdDialog
                        .confirm()
                        .title($filter("translate")("main.menu.deleteConfirm"))
                        .textContent($filter("translate")("main.menu.deleteCantUndo"))
                        .ariaLabel("confirm delete")
                        .targetEvent(ev)
                        .multiple(true)
                        .ok($filter("translate")("main.addStudent.submit"))
                        .cancel($filter("translate")("main.addStudent.cancel"));

                    $mdDialog.show(confirm).then(
                      function () {
                        MainService.deleteCurrentUser(pass).then(
                            function () {
                                $mdDialog.hide();
                                Auth.logout();
                                $scope.isProcessing = false;
                                $state.go("login");
                            },
                            function (err) {
                                var msgKey =
                                    err.status === 404 ? "main.menu.invalidPassword" : "main.menu.deleteAccountError";
                                var errorAlert = $mdDialog
                                    .alert()
                                    .title($filter("translate")("main.menu.deleteAccountError"))
                                    .textContent($filter("translate")(msgKey))
                                    .multiple(true)
                                    .ok($filter("translate")("main.deleteStudent.ok"));

                                $scope.isProcessing = false;
                                $mdDialog.show(errorAlert);
                            }
                        );
                      },
                      function() {
                        $scope.isProcessing = false;
                      }
                  );
                };

                $scope.save = function (answer) {
                    MainService.updateContactInformation($scope.$$childTail.email, $scope.$$childTail.phone, $scope.$$childTail.sendEmail).then(
                        function () {
                            //update the current account object
                            account.email = $scope.$$childTail.email;
                            account.mobileNumber = $scope.$$childTail.phone;
                            account.sendEmail = $scope.$$childTail.sendEmail;
                            console.log("contact information updated!");
                            $mdDialog.hide(answer);
                        },
                        function (err) {
                            var msg = $filter("translate")("main.editProfile.saveError");
                            if (err.data.exception && err.data.exception.includes("DataIntegrityViolationException")) {
                                msg = $filter("translate")("main.editProfile.emailDuplicated");
                            }
                            var toast = $mdToast.simple().textContent(msg).position("bottom right").hideDelay(3000);
                            $mdToast.show(toast);
                            console.error(err);
                        }
                    );
                };
            }

            /**
             * Logout
             */
            $scope.logout = function () {
                Auth.logout();
                $state.go("login");
            };

            /**
             * Administration
             */
            $scope.administration = function () {
                $state.go("administration");
            };

            $scope.goToMain = function () {
                $state.go("home");
            };
            $scope.goToMaps = function () {
                $state.go("maps");
            };

            $scope.changeView = function () {
                if ($state.current.name === "home") $state.go("maps");

                if ($state.current.name === "maps") $state.go("home");
            };

            $scope.getCurrentStyle = function (str) {
                return $state.current.name === str ? "current-section" : "";
            };
        }
    );
