'use strict';

angular.module('schoolbushubApp')
    .controller('VehicleAuditController', function ($scope,  $filter, AlertService, $state, $window, $uibModal, DataUtils, VehicleAudit, ParseLinks) {

        $scope.vehicleAudits = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;

        $scope.fullView = $window.innerWidth > 1600;
        $scope.toggleView = function() {
            $scope.fullView = !$scope.fullView;
        }

        $scope.loadAll = function() {
            var pageable = {page: $scope.page, size: $scope.per_page, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            VehicleAudit.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.total = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.vehicleAudits.push(result[i]);
                }
            }, function(err) {
                AlertService.error($filter('translate')('filters.resultMessages.resultsError'));
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.vehicleAudits = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            if ($scope.links && page > $scope.links['last']) { return; };
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.fields = VehicleAudit.filterVars();
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.vehicleAudit = {
                zoneType: null,
                deviceId: null,
                tripOrderGuid: null,
                eventTimeStamp: null,
                plannedArrivalTime: null,
                latLong: null,
                rawMessage: null,
                isMsgOld: null,
                insideAllowedTime: null,
                allowedTimeStart: null,
                allowedTimeEnd: null,
                belongsToStop: null,
                stopHasStudents: null,
                processTime: null,
                runGuid: null,
                stopOrder: null,
                id: null
            };
        };

        $scope.abbreviate = DataUtils.abbreviate;

        $scope.byteSize = DataUtils.byteSize;

        $scope.viewVehicleAuditDetail = function(va) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudit-detail.html',
                controller: 'VehicleAuditDetailController',
                size: 'lg',
                resolve: {entity: function() { return va; } }
            });
        }

        $scope.formatDate = function(d) {
            if (!d) return '';
            return moment(d).format('MM/DD/YYYY HH:mm:ss');
        }
    });
