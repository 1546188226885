'use strict';

angular.module('schoolbushubApp')
  .controller('RegisterController', function ($scope, $translate, Auth, $location, Verify, Notifications, $filter, Principal, Parameters, SchoolCode, $anchorScroll, $http, $q) {

    $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();
    $scope.validationAlphanumeric = Parameters.getRegularExpressionValidationAlphanumeric();
    $scope.validationMobileNumber = Parameters.getRegularExpressionMobileNumber();

    $scope.verifying = false;
    $scope.success = null;
    $scope.error = null;
    $scope.doNotMatch = null;
    $scope.errorUserExists = null;
    $scope.registerAccount = {};
    $scope.schoolCode = SchoolCode.get();
    var today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    var scroll = function () {
      if ($location.hash() !== 'toastBounds') {
        $location.hash('toastBounds');
      } else {
        $anchorScroll();
      }
    }

    /**
     * Register User.
     */
    $scope.register = function () {
      if ($scope.registerAccount.password !== $scope.registerAccount.confirmPassword) {
        $scope.doNotMatch = 'ERROR';
      } else {
        $scope.registerAccount.langKey = $translate.use();
        $scope.doNotMatch = null;
        $scope.error = null;
        $scope.errorUserExists = null;
        $scope.errorEmailExists = null;
        $scope.registerAccount.verificationKey = $scope.verificationKey;

        Auth.createAccount($scope.registerAccount).then(function () {
          $scope.success = 'OK';
        }).catch(function (response) {
          Auth.logout();
          $scope.success = null;
          scroll();
          if (response.status === 400 && response.data.description === 'login already in use') {
            $scope.errorUserExists = 'ERROR';
            Notifications.showToastAccount('error', $filter('translate')('register.messages.error.userexists'));
          } else if (response.status === 400 && response.data.description === 'e-mail address already in use') {
            $scope.errorEmailExists = 'ERROR';
            Notifications.showToastAccount('error', $filter('translate')('register.messages.error.emailexists'));
          } else {
            $scope.error = 'ERROR';
            Notifications.showToastAccount('error', $filter('translate')('register.messages.error.fail'));
          }
        });
      }
    };


    /**
    * Verify Student Callcack
    * @param response
    */
    var verifyStudentCallback = function (response) {
      if (response.status === 'success') {
        $scope.verificationKey = response.verificationKey;
        $scope.verified = 'OK';
        $scope.success = null;
        Notifications.showToastAccount('success', $filter('translate')('register.verify.studentVerifiedSuccessfully'));
      } else {
        scroll();
        Notifications.showToastAccount('error', $filter('translate')('register.verify.studentNotVerified'));
      }
      $scope.verifying = false;
    }

    /**
     * Verify Student
     */
    $scope.verifyStudent = function () {
      if ($scope.validated()) {
        $scope.verifying = true;
        Verify.verifyStudent($scope.school.code, moment($scope.dateOfBirth,
          $filter('translate')('register.formatDateMoment')).toDate(), $scope.idStudent, $scope.organization.id,
           verifyStudentCallback);
      }
    }

    /**
     * Validation of the fields
     * @returns {boolean}
     */
    $scope.validated = function () {
      var validated = true;
      var dateByParts = $scope.dateOfBirth.split('/');
      if (dateByParts.length == 3) {
        _.each(dateByParts, function (num) { if (isNaN(num) || isNaN(parseFloat(num))) { validated = false; } });
      } else {
        validated = false;
      }
      if (validated) {
        if (!moment($scope.dateOfBirth, [$filter('translate')('register.formatDateMoment')]).isValid()) {
          validated = false;
          scroll();
          Notifications.showToastAccount('error', $filter('translate')('register.verify.dateBirthIsInvalid'));
        } else {
          var birth = moment($scope.dateOfBirth, $filter('translate')('register.formatDateMoment')).toDate();
          if (moment(birth).diff(today, 'hours') > 0) {
            validated = false;
            scroll();
            Notifications.showToastAccount('error', $filter('translate')('register.verify.dateBirthIsHigherCurrentDate'));
          }
        }
      } else {
        scroll();
        Notifications.showToastAccount('error', $filter('translate')('register.verify.dateBirthIsInvalid'));
      }
      return validated;
    };

    $scope.searchSchool = function (query) {
      var deferred = $q.defer();
      var params = {
        organizationId: $scope.organization.id,
        schoolName: query
      };
      $http.get('api/schools/codes', { params: params }).then(function (results) {
        for (var i = 0; i < results.data.length; i++) {
          results.data[i].displayName = results.data[i].code + ' - ' + results.data[i].name;
        }
        deferred.resolve(results.data);
      }, function (err) {
        deferred.resolve([]);
      });
      return deferred.promise;
    }

    $scope.searchOrganizations = function (query) {
      var deferred = $q.defer();
      if ($scope.school) {
        $scope.school = null;
      }
      $http.get('api/organizations/search?name=' + query).then(function (results) {
        deferred.resolve(results.data);
      }, function (err) {
        deferred.resolve([]);
      });
      return deferred.promise;
    }
  });
