angular.module('schoolbushubApp')
    .directive("limitTo", [function() {
        return {
            restrict: "A",
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                attrs.$set("ngTrim", "false");
                var limitLength = parseInt(attrs.limitTo, 10);
                scope.$watch(attrs.ngModel, function() {
                    if(ngModel.$viewValue && ngModel.$viewValue.length>limitLength){
                        ngModel.$setViewValue( ngModel.$viewValue.substring(0, limitLength ) );
                        ngModel.$render();
                    }
                });
            }
        };
    }]);
