'use strict';

var dojoConfig = {
    has: {
        "esri-featurelayer-webgl": 1
    }
};

angular.module('schoolbushubApp', ['LocalStorageModule', 'tmh.dynamicLocale', 'pascalprecht.translate',
    'ngResource', 'ngCookies', 'ngAria', 'ngCacheBuster', 'ngFileUpload', 'ngSanitize', 'ui.select', 'ui.slider',
    'seon.queryBuilder', // jhipster-needle-angularjs-add-module JHipster will add new module here
    'ui.bootstrap', 'ui.router',  'infinite-scroll', 'angular-loading-bar', 'ngMaterial', 'ngMessages', 'ngAnimate',
    'ui.mask', 'badge-counter','rzSlider'])

    .run(["$rootScope", "$mdMedia", "$location", "$window", "$http", "$state", "$translate", "$timeout", "Language", "Auth", "Principal", "ENV", "VERSION", function ($rootScope, $mdMedia, $location, $window, $http, $state, $translate, $timeout, Language, Auth, Principal, ENV, VERSION) {
        $rootScope.$state = $state;

        $rootScope.$mdMedia = $mdMedia;
        // update the window title using params in the following
        // precendence
        // 1. titleKey parameter
        // 2. $state.$current.data.pageTitle (current state page title)
        // 3. 'global.title'
        var updateTitle = function(titleKey) {
            // if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
            //     titleKey = $state.$current.data.pageTitle;
            // }
            // $translate(titleKey || 'global.title').then(function (title) {
            //     $window.document.title = title;
            // });
        };

        $rootScope.ENV = ENV;
        $rootScope.VERSION = VERSION;
        $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {
            $rootScope.toState = toState;
            $rootScope.toStateParams = toStateParams;

            if (Principal.isIdentityResolved()) {
                Auth.authorize();
            }


            // Update the language
            Language.getCurrent().then(function (language) {
                $translate.use(language);
                // console.log(language)
                moment.locale(language);
            });

        });

        $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
            var titleKey = 'global.title' ;
            toggleOverlay(false);

            // Remember previous state unless we've been redirected to login or we've just
            // reset the state memory after logout. If we're redirected to login, our
            // previousState is already set in the authExpiredInterceptor. If we're going
            // to login directly, we don't want to be sent to some previous state anyway
            if (toState.name != 'login' && $rootScope.previousStateName) {
              $rootScope.previousStateName = fromState.name;
              $rootScope.previousStateParams = fromParams;
            }

            // Set the page title key to the one configured in state or use default one
            if (toState.data.pageTitle) {
                titleKey = toState.data.pageTitle;
            }
            updateTitle(titleKey);

            if ($state.current.name == "home") {
                $rootScope.menuBgStyle = {'background-color': 'rgba(0,0,0,0.7)'}
                $rootScope.contentBgStyle = {'background-color': 'rgba(0,0,0,0.0)'}
            }
            else {
                $rootScope.menuBgStyle = {'background-color': 'black'}
                $rootScope.contentBgStyle = {'background-color': '#DFDFDF'}
            }
        });

        // if the current translation changes, update the window title
        $rootScope.$on('$translateChangeSuccess', function() {updateTitle(); });


        $rootScope.back = function() {
            // If previous state is 'activate' or do not exist go to 'home'
            if ($rootScope.previousStateName === 'activate' || $state.get($rootScope.previousStateName) === null) {
                $state.go('home');
            } else {
                $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
            }
        };


        //page size for admin templates to adjust automatically based on screen resolution
        function setAdminPageSize() {
            $rootScope.infiniteScrollPageSize = Math.ceil(($window.innerHeight - 240) / 37);
        }
        setAdminPageSize();
        angular.element($window).bind('resize', function () {
            setAdminPageSize();
        });


        //block ui overlay methods
        $rootScope.$on('hideSpinner', function(){ toggleOverlay(false); });

        $rootScope.$on('showSpinner', function(){
            toggleOverlay(true);
            $timeout(function() {
                if (angular.element('.spinner').hasClass('shown')) {
                    toggleOverlay(false);
                }
            }, 45000);
        });

        function toggleOverlay(show) {
            angular.element('.spinner').toggleClass('shown', show? true : false);
        }
    }])
    .config(["$stateProvider", "$urlRouterProvider", "$httpProvider", "$locationProvider", "$translateProvider", "tmhDynamicLocaleProvider", "httpRequestInterceptorCacheBusterProvider", "AlertServiceProvider", function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider, $translateProvider, tmhDynamicLocaleProvider,
                      httpRequestInterceptorCacheBusterProvider, AlertServiceProvider) {
        // uncomment below to make alerts look like toast
        //AlertServiceProvider.showAsToast(true);

        //old style hashbang url
        $locationProvider.hashPrefix('');

        //Cache everything except rest api requests
        httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/, /.*i18n.*/], true);

        moment.locale(['en', 'es']);

        $urlRouterProvider.otherwise('/');
        $stateProvider.state('site', {
            'abstract': true,
            views: {
                'navbar@': {
                    templateUrl: 'scripts/components/navbar/navbar.html',
                    controller: 'NavbarController'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        });

        $httpProvider.interceptors.push('errorHandlerInterceptor');
        $httpProvider.interceptors.push('authExpiredInterceptor');
        $httpProvider.interceptors.push('authInterceptor');
        $httpProvider.interceptors.push('notificationInterceptor');
        // jhipster-needle-angularjs-add-interceptor JHipster will add new application interceptor here

        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'
        });

        $translateProvider.preferredLanguage('en');
        $translateProvider.useCookieStorage();
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        tmhDynamicLocaleProvider.localeLocationPattern('bower_components/angular-i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.useCookieStorage();
        tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');

    }])
    // jhipster-needle-angularjs-add-config JHipster will add new application configuration here
    .config(['$urlMatcherFactoryProvider', function($urlMatcherFactory) {
        $urlMatcherFactory.type('boolean', {
            name : 'boolean',
            decode: function(val) { return val == true ? true : val == "true" ? true : false },
            encode: function(val) { return val ? 1 : 0; },
            equals: function(a, b) { return this.is(a) && a === b; },
            is: function(val) { return [true,false,0,1].indexOf(val) >= 0 },
            pattern: /bool|true|0|1/
        });
    }])
    .config(["$mdThemingProvider", function($mdThemingProvider) {
      $mdThemingProvider.definePalette('sbhpalette', {
        '50': '000000',
        '100': '000000',
        '200': '000000',
        '300': '000000',
        '400': '000000',
        '500': '000000',
        '600': '000000',
        '700': '000000',
        '800': '000000',
        '900': '000000',
        'A100': '000000',
        'A200': '000000',
        'A400': '000000',
        'A700': '000000',
        'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
                                            // on this palette should be dark or light
        'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
        '200'],
        'contrastLightColors': ['A700', 'A400']    // could also specify this if default was 'dark'
      });

      $mdThemingProvider.theme('altTheme').primaryPalette('sbhpalette');

      // $mdThemingProvider.alwaysWatchTheme(true);
  }])

.filter('propsFilter', function() {
    return function(items, props) {
        var out = [];

        if (angular.isArray(items)) {
            var keys = Object.keys(props);

            items.forEach(function(item) {
                var itemMatches = false;

                for (var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        }
        else {
            out = items;
        }

        return out;
    };
});

/* jshint quotmark: false */
"use strict";
// DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
angular.module("schoolbushubApp")

.constant("REMOTE_SERVER", "")

.constant("ENV", "dev")

.constant("VERSION", "3.12.1")

.constant("USER_ROLES", {
	"all": "*",
	"admin": "ROLE_ADMIN",
	"seonadmin": "ROLE_SEONADMIN",
	"customeradmin": "ROLE_CUSTOMERADMIN",
	"parent": "ROLE_PARENT"
})
.constant("EVENT_SOURCES", {
	'NAVIGATOR':'Navigator',
	'NON_NAVIGATOR':'Non Navigator'
})

;

'use strict';

angular.module('schoolbushubApp')
    .factory('Auth', ["$rootScope", "$state", "$q", "$translate", "Principal", "AuthServerProvider", "Account", "Register", "Activate", "Password", "PasswordResetInit", "PasswordResetFinish", "DataService", function Auth($rootScope, $state, $q, $translate, Principal, AuthServerProvider, Account,
    Register, Activate, Password, PasswordResetInit, PasswordResetFinish, DataService) {
        return {
            login: function (credentials, callback) {
                var cb = callback || angular.noop;
                var deferred = $q.defer();

                DataService.set('credentials', undefined);

                AuthServerProvider.login(credentials).then(function (data) {
                    // retrieve the logged account information
                    Principal.identity(true).then(function(account) {
                        // After the login the language will be changed to
                        // the language selected by the user during his registration
                        $translate.use(account.langKey).then(function(){
                            $translate.refresh();
                        });
                        deferred.resolve(data);
                    });
                    return cb();
                }).catch(function (err) {
                    this.logout();
                    deferred.reject(err);
                    return cb(err);
                }.bind(this));

                return deferred.promise;
            },

            logout: function () {
                AuthServerProvider.logout();
                Principal.authenticate(null);
                // Reset state memory
                $rootScope.previousStateName = undefined;
                $rootScope.previousStateNameParams = undefined;
                DataService.set('credentials', undefined);
            },

            authorize: function(force, expiredSession) {
                if(Principal.isAuthenticated() || 
                  ($rootScope.toState.name !== 'login' && 
                   $rootScope.toState.name !== 'register' && 
                   $rootScope.toState.name !== 'requestReset')) {
                    return Principal.identity(force)
                        .then(function() {
                            var isAuthenticated = Principal.isAuthenticated();

                            // an authenticated user can't access to login and register pages
                            if (isAuthenticated && $rootScope.toState.parent === 'account'
                            && ($rootScope.toState.name === 'login' || $rootScope.toState.name === 'register')) {
                                $state.go('home');
                            }

                            if ($rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0
                            && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
                                if (isAuthenticated) {
                                    // user is signed in but not authorized for desired state
                                    $state.go('accessdenied');
                                }
                                else {
                                    // user is not authenticated. stow the state they wanted before you
                                    // send them to the signin state, so you can return them when you're done
                                    $rootScope.previousStateName = $rootScope.toState;
                                    $rootScope.previousStateNameParams = $rootScope.toStateParams;

                                    // now, send them to the signin state so they can log in
                                    $state.go('login', {'expiredSession': (expiredSession != undefined
                                    && expiredSession == true ? true : false)});

                                }
                            }
                        });
                    }
            },
            createAccount: function (account, callback) {
                var cb = callback || angular.noop;

                return Register.save(account,
                    function () {
                        return cb(account);
                    },
                    function (err) {
                        this.logout();
                        return cb(err);
                    }.bind(this)).$promise;
            },

            updateAccount: function (account, callback) {
                var cb = callback || angular.noop;

                return Account.save(account,
                    function () {
                        return cb(account);
                    },
                    function (err) {
                        return cb(err);
                    }.bind(this)).$promise;
            },

            activateAccount: function (key, callback) {
                var cb = callback || angular.noop;

                return Activate.get(key,
                    function (response) {
                        return cb(response);
                    },
                    function (err) {
                        return cb(err);
                    }.bind(this)).$promise;
            },

            changePassword: function (newPassword, callback) {
                var cb = callback || angular.noop;

                return Password.save(newPassword, function () {
                    return cb();
                }, function (err) {
                    return cb(err);
                }).$promise;
            },

            resetPasswordInit: function (mail, langKey, callback) {
                var cb = callback || angular.noop;

                return PasswordResetInit.save({ langKey: langKey }, mail, function() {
                    return cb();
                }, function (err) {
                    return cb(err);
                }).$promise;
            },

            resetPasswordFinish: function(keyAndPassword, callback) {
                var cb = callback || angular.noop;

                return PasswordResetFinish.save(keyAndPassword, function () {
                    return cb();
                }, function (err) {
                    return cb(err);
                }).$promise;
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('Principal', ["$q", "Account", function Principal($q, Account) {
        var _identity,
            _authenticated = false;

        return {
            isIdentityResolved: function () {
                return angular.isDefined(_identity);
            },
            isAuthenticated: function () {
                return _authenticated;
            },
            hasAuthority: function (authority) {
                if (!_authenticated) {
                    return $q.when(false);
                }

                return this.identity().then(function(_id) {
                    return _id.authorities && _id.authorities.indexOf(authority) !== -1;
                }, function(err){
                    return false;
                });
            },
            hasAnyAuthority: function (authorities) {
                if (!_authenticated || !_identity || !_identity.authorities) {
                    return false;
                }

                for (var i = 0; i < authorities.length; i++) {
                    if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                        return true;
                    }
                }

                return false;
            },
            authenticate: function (identity) {
                _identity = identity;
                _authenticated = identity !== null;
            },
            identity: function (force) {
                var deferred = $q.defer();

                if (force === true) {
                    _identity = undefined;
                }

                // check and see if we have retrieved the identity data from the server.
                // if we have, reuse it by immediately resolving
                if (angular.isDefined(_identity)) {
                    deferred.resolve(_identity);

                    return deferred.promise;
                }

                // retrieve the identity data from the server, update the identity object, and then resolve.
                Account.get().$promise
                    .then(function (account) {
                        _identity = account.data;
                        _authenticated = true;
                        deferred.resolve(_identity);
                    })
                    .catch(function() {
                        _identity = null;
                        _authenticated = false;
                        deferred.resolve(_identity);
                    });
                return deferred.promise;
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .directive('hasAnyAuthority', ['Principal', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                        element.removeClass('hidden');
                    },
                    setHidden = function () {
                        element.addClass('hidden');
                    },
                    defineVisibility = function (reset) {
                        var result;
                        if (reset) {
                            setVisible();
                        }
                        authorities = attrs.hasAnyAuthority.replace(/\s+/g, '').split(',');
                        result = Principal.hasAnyAuthority(authorities);
                        if (result) {
                            setVisible();
                        } else {
                            setHidden();
                        }
                    },
                authorities = attrs.hasAnyAuthority.replace(/\s+/g, '').split(',');
                if (authorities.length > 0) {
                    defineVisibility(true);

                    scope.$watch(function(scope) {
                        return Principal.isAuthenticated();
                    }, function(newValue) {
                        defineVisibility(true);
                    });
                }
            }
        };
    }])
    .directive('hasAuthority', ['Principal', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                        element.removeClass('hidden');
                    },
                    setHidden = function () {
                        element.addClass('hidden');
                    },
                    defineVisibility = function (reset) {

                        if (reset) {
                            setVisible();
                        }
                        authority = attrs.hasAuthority.replace(/\s+/g, '');
                        Principal.hasAuthority(authority)
                            .then(function (result) {
                                if (result) {
                                    setVisible();
                                } else {
                                    setHidden();
                                }
                            });
                    },
                authority = attrs.hasAuthority.replace(/\s+/g, '');
                if (authority.length > 0) {
                    defineVisibility(true);

                    scope.$watch(function(scope) {
                        return Principal.isAuthenticated();
                    }, function(newValue) {
                        defineVisibility(true);
                    });
                }
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('Account', ["$resource", function Account($resource) {
        return $resource('api/account', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            }
        });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('Activate', ["$resource", function ($resource) {
        return $resource('api/activate', {}, {
            'get': { method: 'GET', params: {}, isArray: false}
        });
    }]);



'use strict';

angular.module('schoolbushubApp')
    .factory('Password', ["$resource", function ($resource) {
        return $resource('api/account/change_password', {}, {
        });
    }]);

angular.module('schoolbushubApp')
    .factory('PasswordResetInit', ["$resource", function ($resource) {
        return $resource('api/account/reset_password/init', {}, {
        })
    }]);

angular.module('schoolbushubApp')
    .factory('PasswordResetFinish', ["$resource", function ($resource) {
        return $resource('api/account/reset_password/finish', {}, {
        })
    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('Register', ["$resource", function ($resource) {
        return $resource('api/register', {}, {
        });
    }]);

/* globals $ */
"use strict";

angular.module("schoolbushubApp").directive("showValidation", function () {
    return {
        restrict: "A",
        require: "form",
        link: function (scope, element) {
            element.find(".form-group").each(function () {
                var $formGroup = $(this);
                var $inputs = $formGroup.find("input[ng-model],textarea[ng-model],select[ng-model]");

                if ($inputs.length > 0) {
                    $inputs.each(function () {
                        var $input = $(this);
                        scope.$watch(
                            function () {
                                console.log(
                                    "Watcher: ",
                                    $input.context.name,
                                    $input.context.value,
                                    $input.hasClass("ng-invalid"),
                                    $input.hasClass("ng-dirty")
                                );
                                return $input.hasClass("ng-invalid") && $input.hasClass("ng-dirty");
                            },
                            function (isInvalid) {
                                console.log("CLASE DE ERROR: ", isInvalid);
                                $formGroup.toggleClass("has-error", isInvalid);
                            }
                        );
                    });
                }
            });
        },
    };
});

/* globals $ */
'use strict';

angular.module('schoolbushubApp')
    .directive('maxbytes', ["$q", function ($q) {
        function endsWith(suffix, str) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }

        function paddingSize(base64String) {
            if (endsWith('==', base64String)) {
                return 2;
            }
            if (endsWith('=', base64String)) {
                return 1;
            }
            return 0;
        }

        function numberOfBytes(base64String) {
            return base64String.length / 4 * 3 - paddingSize(base64String);
        }

        return {
            restrict: 'A',
            require: '?ngModel',
            link: function (scope, element, attrs, ngModel) {
                if (!ngModel) return;

                ngModel.$validators.maxbytes = function (modelValue) {
                    return ngModel.$isEmpty(modelValue) || numberOfBytes(modelValue) <= attrs.maxbytes;
                };
            }
        };
    }]);

/* globals $ */
'use strict';

angular.module('schoolbushubApp')
    .directive('minbytes', ["$q", function ($q) {
        function endsWith(suffix, str) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }

        function paddingSize(base64String) {
            if (endsWith('==', base64String)) {
                return 2;
            }
            if (endsWith('=', base64String)) {
                return 1;
            }
            return 0;
        }

        function numberOfBytes(base64String) {
            return base64String.length / 4 * 3 - paddingSize(base64String);
        }

        return {
            restrict: 'A',
            require: '?ngModel',
            link: function (scope, element, attrs, ngModel) {
                if (!ngModel) return;

                ngModel.$validators.minbytes = function (modelValue) {
                    return ngModel.$isEmpty(modelValue) || numberOfBytes(modelValue) >= attrs.minbytes;
                };
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["uibPagerConfig", function (uibPagerConfig) {
        uibPagerConfig.itemsPerPage = 20;
        uibPagerConfig.previousText = '«';
        uibPagerConfig.nextText = '»';
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["uibPaginationConfig", function (uibPaginationConfig) {
        uibPaginationConfig.itemsPerPage = 20;
        uibPaginationConfig.maxSize = 5;
        uibPaginationConfig.boundaryLinks = true;
        uibPaginationConfig.firstText = '«';
        uibPaginationConfig.previousText = '‹';
        uibPaginationConfig.nextText = '›';
        uibPaginationConfig.lastText = '»';
    }]);

'use strict';

angular.module('schoolbushubApp').factory('AuditsService', ["$http", function($http) {
    return {
        findAll: function() {
            return $http.get('api/audits/all').then(function(response) {
                return response.data;
            });
        },
        findByFilters: function(page, perPage, fromDate, toDate, user, state, callback) {
            var params = {
                page: page,
                per_page: perPage,
                fromDate: moment(fromDate).format('YYYY-MM-DD 00:00:00Z'),
                toDate: moment(toDate).format('YYYY-MM-DD 23:59:59Z'),
                user: user,
                state: state
            };
            $http.get('api/audits/byFilters', { params: params }).then(
                function(response) {
                    callback(response);
                },
                function(error) {
                    callback({ error: error });
                }
            );
        }
    };
}]);

'use strict';

angular.module('schoolbushubApp')
    .factory('LogsService', ["$resource", function ($resource) {
        return $resource('api/logs', {}, {
            'findAll': { method: 'GET', isArray: true},
            'changeLevel': { method: 'PUT'}
        });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('ConfigurationService', ["$rootScope", "$filter", "$http", function ($rootScope, $filter, $http) {
        return {
            get: function() {
                return $http.get('configprops').then(function (response) {
                    var properties = [];
                    angular.forEach(response.data, function (data) {
                        properties.push(data);
                    });
                    var orderBy = $filter('orderBy');
                    return orderBy(properties, 'prefix');
                });
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('MonitoringService', ["$rootScope", "$http", function ($rootScope, $http) {
        return {
            getMetrics: function () {
                return $http.get('metrics/metrics').then(function (response) {
                    return response.data;
                });
            },

            checkHealth: function () {
                return $http.get('health').then(function (response) {
                    return response.data;
                });
            },

            threadDump: function () {
                return $http.get('dump').then(function (response) {
                    return response.data;
                });
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('authInterceptor', ["$rootScope", "$q", "$location", "localStorageService", function ($rootScope, $q, $location, localStorageService) {
        return {
            // Add authorization token to headers
            request: function (config) {
                config.headers = config.headers || {};
                var token = localStorageService.get('token');
                
                if (token && token.expires_at && token.expires_at > new Date().getTime()) {
                    config.headers.Authorization = 'Bearer ' + token.access_token;
                }
                
                return config;
            }
        };
    }])
    .factory('authExpiredInterceptor', ["$rootScope", "$q", "$injector", "localStorageService", function ($rootScope, $q, $injector, localStorageService) {
        return {
            responseError: function (response) {
                // token has expired
                if (response.status === 401 && (response.data.error == 'invalid_token' || response.data.error == 'Unauthorized')) {
                    var expiredSession = (localStorageService.get('token') == null? false:true);
                    localStorageService.remove('token');
                    var Principal = $injector.get('Principal');
                    if (Principal.isAuthenticated()) {
                        var Auth = $injector.get('Auth');
                        Auth.authorize(true, expiredSession);
                    }
                }
                return $q.reject(response);
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('errorHandlerInterceptor', ["$q", "$rootScope", function ($q, $rootScope) {
        return {
            'responseError': function (response) {
                if (!(response.status == 401 && response.data.path && response.data.path.indexOf("/api/account") == 0 )){
	                $rootScope.$emit('schoolbushubApp.httpError', response);
	            }
                return $q.reject(response);
            }
        };
    }]);

 'use strict';

angular.module('schoolbushubApp')
    .factory('notificationInterceptor', ["$q", "AlertService", function ($q, AlertService) {
        return {
            response: function(response) {
                var alertKey = response.headers('X-schoolbushubApp-alert');
                if (angular.isString(alertKey)) {
                    AlertService.success(alertKey, { param : response.headers('X-schoolbushubApp-params')});
                }
                return response;
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .directive('activeMenu', ["$translate", "$locale", "tmhDynamicLocale", function($translate, $locale, tmhDynamicLocale) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var language = attrs.activeMenu;

                scope.$watch(function() {
                    return $translate.use();
                }, function(selectedLanguage) {
                    if (language === selectedLanguage) {
                        tmhDynamicLocale.set(language);
                        element.addClass('active');
                    } else {
                        element.removeClass('active');
                    }
                });
            }
        };
    }])
    .directive('activeLink', ["location", function(location) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var clazz = attrs.activeLink;
                var path = attrs.href;
                path = path.substring(1); //hack because path does bot return including hashbang
                scope.location = location;
                scope.$watch('location.path()', function(newPath) {
                    if (path === newPath) {
                        element.addClass(clazz);
                    } else {
                        element.removeClass(clazz);
                    }
                });
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('NavbarController', ["$scope", "$location", "$state", "Auth", "Principal", "ENV", "HelpService", "$window", function ($scope, $location, $state, Auth, Principal, ENV, HelpService, $window) {
        $scope.isAuthenticated = Principal.isAuthenticated;
        $scope.$state = $state;
        $scope.inProduction = ENV === 'prod';

        $scope.logout = function () {
            Auth.logout();
            $state.go('login');
        };

        $scope.help = function () {
            HelpService.getHelpUrl(function (response) {
                if (response.status == 'success'){
                    console.log(response.helpUrl);
                    $window.open(response.helpUrl);
                }
            });
        }
    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('User', ["$resource", function ($resource) {
        return $resource('api/users/:login', {}, {
                'query': {method: 'GET'},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'update': { method:'PUT' }
            });
        }]);

'use strict';

angular.module('schoolbushubApp')
    .filter('characters', function () {
        return function (input, chars, breakOnWord) {
            if (isNaN(chars)) {
                return input;
            }
            if (chars <= 0) {
                return '';
            }
            if (input && input.length > chars) {
                input = input.substring(0, chars);

                if (!breakOnWord) {
                    var lastspace = input.lastIndexOf(' ');
                    // Get last space
                    if (lastspace !== -1) {
                        input = input.substr(0, lastspace);
                    }
                } else {
                    while (input.charAt(input.length-1) === ' ') {
                        input = input.substr(0, input.length - 1);
                    }
                }
                return input + '...';
            }
            return input;
        };
    })
    .filter('words', function () {
        return function (input, words) {
            if (isNaN(words)) {
                return input;
            }
            if (words <= 0) {
                return '';
            }
            if (input) {
                var inputWords = input.split(/\s+/);
                if (inputWords.length > words) {
                    input = inputWords.slice(0, words).join(' ') + '...';
                }
            }
            return input;
        };
    });

/*jshint bitwise: false*/
'use strict';

angular.module('schoolbushubApp')
    .service('Base64', function () {
        var keyStr = 'ABCDEFGHIJKLMNOP' +
            'QRSTUVWXYZabcdef' +
            'ghijklmnopqrstuv' +
            'wxyz0123456789+/' +
            '=';
        this.encode = function (input) {
            var output = '',
                chr1, chr2, chr3 = '',
                enc1, enc2, enc3, enc4 = '',
                i = 0;

            while (i < input.length) {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);

                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;

                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }

                output = output +
                    keyStr.charAt(enc1) +
                    keyStr.charAt(enc2) +
                    keyStr.charAt(enc3) +
                    keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = '';
                enc1 = enc2 = enc3 = enc4 = '';
            }

            return output;
        };

        this.decode = function (input) {
            var output = '',
                chr1, chr2, chr3 = '',
                enc1, enc2, enc3, enc4 = '',
                i = 0;

            // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

            while (i < input.length) {
                enc1 = keyStr.indexOf(input.charAt(i++));
                enc2 = keyStr.indexOf(input.charAt(i++));
                enc3 = keyStr.indexOf(input.charAt(i++));
                enc4 = keyStr.indexOf(input.charAt(i++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                output = output + String.fromCharCode(chr1);

                if (enc3 !== 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 !== 64) {
                    output = output + String.fromCharCode(chr3);
                }

                chr1 = chr2 = chr3 = '';
                enc1 = enc2 = enc3 = enc4 = '';
            }
        };
    })
    .factory('StorageService', ["$window", function ($window) {
        return {

            get: function (key) {
                return JSON.parse($window.localStorage.getItem(key));
            },

            save: function (key, data) {
                $window.localStorage.setItem(key, JSON.stringify(data));
            },

            remove: function (key) {
                $window.localStorage.removeItem(key);
            },

            clearAll : function () {
                $window.localStorage.clear();
            }
        };
    }]);


'use strict';

angular.module('schoolbushubApp')
    .filter('capitalize', function () {
        return function (input, scope) {
            if (input != null)
                input = input.toLowerCase();
            return input.substring(0, 1).toUpperCase() + input.substring(1);
        }
    });

'use strict';

angular.module('schoolbushubApp')
    .provider('AlertService', function () {
        this.toast = false;

        this.$get = ['$timeout', '$sce', '$translate', function($timeout, $sce,$translate) {

            var exports = {
                factory: factory,
                isToast: isToast,
                add: addAlert,
                closeAlert: closeAlert,
                closeAlertByIndex: closeAlertByIndex,
                clear: clear,
                get: get,
                success: success,
                error: error,
                info: info,
                warning : warning
            },
            
            toast = this.toast,
            alertId = 0, // unique id for each alert. Starts from 0.
            alerts = [],
            timeout = 5000; // default timeout

            function isToast() {
                return toast;
            }

            function clear() {
                alerts = [];
            }

            function get() {
                return alerts;
            }

            function success(msg, params, position) {
                return this.add({
                    type: "success",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function error(msg, params, position) {
                return this.add({
                    type: "danger",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function warning(msg, params, position) {
                return this.add({
                    type: "warning",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function info(msg, params, position) {
                return this.add({
                    type: "info",
                    msg: msg,
                    params: params,
                    timeout: timeout,
                    toast: toast,
                    position: position
                });
            }

            function factory(alertOptions) {
                var alert = {
                    type: alertOptions.type,
                    msg: $sce.trustAsHtml(alertOptions.msg),
                    id: alertOptions.alertId,
                    timeout: alertOptions.timeout,
                    toast: alertOptions.toast,
                    position: alertOptions.position ? alertOptions.position : 'top right',
                    scoped: alertOptions.scoped,
                    close: function (alerts) {
                        return exports.closeAlert(this.id, alerts);
                    }
                }
                if(!alert.scoped) {
                    alerts.push(alert);
                }
                return alert;
            }

            function addAlert(alertOptions, extAlerts) {
                alertOptions.alertId = alertId++;
                alertOptions.msg = $translate.instant(alertOptions.msg, alertOptions.params);
                var that = this;
                var alert = this.factory(alertOptions);
                if (alertOptions.timeout && alertOptions.timeout > 0) {
                    $timeout(function () {
                        that.closeAlert(alertOptions.alertId, extAlerts);
                    }, alertOptions.timeout);
                }
                return alert;
            }

            function closeAlert(id, extAlerts) {
                var thisAlerts = extAlerts ? extAlerts : alerts;
                return this.closeAlertByIndex(thisAlerts.map(function(e) { return e.id; }).indexOf(id), thisAlerts);
            }

            function closeAlertByIndex(index, thisAlerts) {
                return thisAlerts.splice(index, 1);
            }

            return exports;
        }];

        this.showAsToast = function(isToast) {
            this.toast = isToast;
        };

    });

'use strict';

angular.module('schoolbushubApp')
    .directive('jhAlert', ["AlertService", function(AlertService) {
        return {
            restrict: 'E',
            template: '<div class="alerts" ng-cloak="">' +
                            '<div uib-alert ng-repeat="alert in alerts" ng-class="\'alert-\' + (alert.type || \'warning\')" close="alert.close(alerts)">{{alert.msg}}</div>' +
                      '</div>',
            controller: ['$scope',
                function($scope) {
                    $scope.alerts = AlertService.get();
                    $scope.$on('$destroy', function () {
                        $scope.alerts = [];
                    });
                }
            ]
        }
    }])
    .directive('jhAlertError', ["AlertService", "$rootScope", "$translate", function(AlertService, $rootScope, $translate) {
        return {
            restrict: 'E',
            template: '<div class="alerts" ng-cloak="">' +
                            '<div uib-alert ng-repeat="alert in alerts" ng-class="\'alert-\' + (alert.type || \'warning\')" close="alert.close(alerts)">{{alert.msg}}</div>' +
                      '</div>',
            controller: ['$scope',
                function($scope) {

                    $scope.alerts = [];

                    var addErrorAlert = function (message, key, data) {
                        key = key && key != null ? key : message;
                        $scope.alerts.push(
                            AlertService.add(
                                {
                                    type: "danger",
                                    msg: key,
                                    params: data,
                                    timeout: 5000,
                                    toast: AlertService.isToast(),
                                    scoped: true
                                },
                                $scope.alerts
                            )
                        );
                    }

                    var cleanHttpErrorListener = $rootScope.$on('schoolbushubApp.httpError', function (event, httpResponse) {
                        var i;
                        event.stopPropagation();
                        switch (httpResponse.status) {
                            // connection refused, server not reachable
                            case 0:
                                addErrorAlert("Server not reachable",'error.server.not.reachable');
                                break;

                            case 400:
                                var errorHeader = httpResponse.headers('X-schoolbushubApp-error');
                                var entityKey = httpResponse.headers('X-schoolbushubApp-params');
                                if (errorHeader) {
                                    var entityName = $translate.instant('global.menu.entities.' + entityKey);
                                    addErrorAlert(errorHeader, errorHeader, {entityName: entityName});
                                } else if (httpResponse.data && httpResponse.data.fieldErrors) {
                                    for (i = 0; i < httpResponse.data.fieldErrors.length; i++) {
                                        var fieldError = httpResponse.data.fieldErrors[i];
                                        // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                                        var convertedField = fieldError.field.replace(/\[\d*\]/g, "[]");
                                        var fieldName = $translate.instant('schoolbushubApp.' + fieldError.objectName + '.' + convertedField);
                                        addErrorAlert('Field ' + fieldName + ' cannot be empty', 'error.' + fieldError.message, {fieldName: fieldName});
                                    }
                                } else if (httpResponse.data && httpResponse.data.message) {
                                    addErrorAlert(httpResponse.data.message, httpResponse.data.message, httpResponse.data);
                                } else {
                                    addErrorAlert(httpResponse.data);
                                }
                                break;

                            default:
                                if (httpResponse.data && httpResponse.data.message) {
                                    addErrorAlert(httpResponse.data.message);
                                } else if (httpResponse.data && httpResponse.data.error && httpResponse.data.entity) {
                                    var codeMessage = $translate.instant('schoolbushubApp.' + httpResponse.data.entity + '.' + httpResponse.data.error);
                                    addErrorAlert(codeMessage, codeMessage, httpResponse.data);
                                } 
                                else {
                                    addErrorAlert(JSON.stringify(httpResponse));
                                }
                        }
                    });

                    $scope.$on('$destroy', function () {
                        if(cleanHttpErrorListener !== undefined && cleanHttpErrorListener !== null){
                            cleanHttpErrorListener();
                            $scope.alerts = [];
                        }
                    });
                }
            ]
        }
    }]);

'use strict';

angular.module('schoolbushubApp')
    .service('ParseLinks', function () {
        this.parse = function (header) {
            if (header.length == 0) {
                throw new Error("input must not be of zero length");
            }

            // Split parts by comma
            var parts = header.split(',');
            var links = {};
            // Parse each part into a named link
            angular.forEach(parts, function (p) {
                var section = p.split(';');
                if (section.length != 2) {
                    throw new Error("section could not be split on ';'");
                }
                var url = section[0].replace(/<(.*)>/, '$1').trim();
                var queryString = {};
                url.replace(
                    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
                    function($0, $1, $2, $3) { queryString[$1] = $3; }
                );
                var page = queryString['page'];
                if( angular.isString(page) ) {
                    page = parseInt(page);
                }
                var name = section[1].replace(/rel="(.*)"/, '$1').trim();
                links[name] = page;
            });

            return links;
        }
    });

'use strict';

angular.module('schoolbushubApp')
    .service('DateUtils', ["$filter", function ($filter) {

    this.convertLocaleDateToServer = function(date) {
        if (date) {
            return $filter('date')(date, 'yyyy-MM-dd');
        } else {
            return null;
        }
    };

    this.convertLocaleDateFromServer = function(date) {
        if (date) {
            var dateString = date.split("-");
            return new Date(dateString[0], dateString[1] - 1, dateString[2]);
        }
        return null;
    };

    this.convertDateTimeFromServer = function(date) {
        if (date) {
            return new Date(date);
        } else {
            return null;
        }
    };

    // common date format for all date input fields
    this.dateformat = function() {
        return 'yyyy-MM-dd';
    };

    this.dateTimeFormat = function (d) {
        if (!d) return '--';
        return moment.utc(d).format('MM/DD/YYYY h:mm A');
    };

    this.timeFormat = function(d) {
        if (!d) return '--';
        return moment.utc(d).format('h:mm A');
    };

    this.timeFormatWithTz = function(d, tz) {
        if (!d || !tz) return '--';
        return moment.utc(d).tz(tz).format('h:mm A');
    }
}]);

'use strict';

angular.module('schoolbushubApp')
    .service('DataUtils', ["$filter", function ($filter) {
    this.byteSize = function (base64String) {
        if (!angular.isString(base64String)) {
            return '';
        }
        function endsWith(suffix, str) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }
        function paddingSize(base64String) {
            if (endsWith('==', base64String)) {
                return 2;
            }
            if (endsWith('=', base64String)) {
                return 1;
            }
            return 0;
        }
        function size(base64String) {
            return base64String.length / 4 * 3 - paddingSize(base64String);
        }
        function formatAsBytes(size) {
            return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bytes";
        }

        return formatAsBytes(size(base64String));
    };
    this.abbreviate = function (text) {
        if (!angular.isString(text)) {
            return '';
        }
        if (text.length < 30) {
            return text;
        }
        return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
    };
}]);

'use strict';

angular.module('schoolbushubApp')
    .directive('jhSort', function () {
        return {
            restrict: 'A',
            scope: {
                predicate: '=jhSort',
                ascending: '=',
                callback: '&'
            },
            controller: ['$scope', function ($scope) {
                this.sort = function (field) {
                    if (field !== $scope.predicate) {
                        $scope.ascending = true;
                    } else {
                        $scope.ascending = !$scope.ascending;
                    }
                    $scope.predicate = field;
                    $scope.$apply();
                    $scope.callback();
                }
                this.applyClass = function (element) {
                    var allThIcons = element.parent().find('span.glyphicon'),
                    sortIcon = 'glyphicon-sort',
                    sortAsc = 'glyphicon-sort-by-attributes',
                    sortDesc = 'glyphicon-sort-by-attributes-alt',
                    remove = sortIcon + ' ' + sortDesc,
                    add = sortAsc,
                    thisIcon = element.find('span.glyphicon');
                    if (!$scope.ascending) {
                        remove = sortIcon + ' ' + sortAsc;
                        add = sortDesc;
                    }
                    allThIcons.removeClass(sortAsc + ' ' + sortDesc);
                    allThIcons.addClass(sortIcon);
                    thisIcon.removeClass(remove);
                    thisIcon.addClass(add);
                }
            }]
        }
    }).directive('jhSortBy', function () {
        return {
            restrict: 'A',
            scope: false,
            require: '^jhSort',
            link: function (scope, element, attrs, parentCtrl) {
                element.bind('click', function () {
                    parentCtrl.sort(attrs.jhSortBy);
                    parentCtrl.applyClass(element);
                });
            }
        };
    });

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('account', {
                abstract: true,
                parent: 'site'
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
            $stateProvider
                .state('activate', {
                    parent: 'account',
                    isApp: true,
                    url: '/activate?key',
                    data: {
                        roles: [],
                        pageTitle: 'activate.title'
                    },
                    views: {
                        'app-content@': {
                            templateUrl: 'scripts/app/account/activate/activate.html',
                            controller: 'ActivationController'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('activate');
                            return $translate.refresh();
                        }]
                    }
                });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('ActivationController', ["$scope", "$stateParams", "Auth", function ($scope, $stateParams, Auth) {
        Auth.activateAccount({key: $stateParams.key}).then(function () {
            $scope.error = null;
            $scope.success = 'OK';
        }).catch(function () {
            $scope.success = null;
            $scope.error = 'ERROR';
        });
    }]);


'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('login', {
                parent: 'account',
                isApp: true,
                url: '/',
                data: {
                    authorities: [],
                    pageTitle: 'login.title'
                },
                views: {
                    'app-content@': {
                        templateUrl: 'scripts/app/account/login/login.html',
                        controller: 'LoginController'
                    }
                },
                params: {
                    'expiredSession': false
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sendActivationEmailSuccess',{
                            parent: 'account',
                            isApp: true,
                            url: '/sendActivationEmailSuccess',
                            data: {
                                roles: [],
                                pageTitle: 'login.title'
                            },
                            views: {
                                'app-content@': {
                                    templateUrl: 'scripts/app/account/login/sendActivationEmailSuccess.html',
                                    controller: 'LoginController'
                                }
                            },
                            resolve: {
                                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                    $translatePartialLoader.addPart('login');
                                    return $translate.refresh();
                                }]
                            }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('LoginController', ["$rootScope", "$scope", "$state", "$timeout", "Auth", "Principal", "Notifications", "$stateParams", "$location", "$filter", "$anchorScroll", "DataService", function ($rootScope, $scope, $state, $timeout, Auth, Principal, Notifications, $stateParams, $location, $filter, $anchorScroll, DataService) {

        var scroll = function(){
            if ($location.hash() !== 'toastBounds') {
                $location.hash('toastBounds');
            } else {
                $anchorScroll();
            }
        }

        if($stateParams.expiredSession){
            Notifications.showToastAccount('error', $filter('translate')('login.messages.error.theSessionHasExpired'));
        }

        $scope.user = {};
        $scope.errors = {};

        $scope.rememberMe = true;
        $timeout(function (){angular.element('[ng-model="username"]').focus();});
        $scope.login = function (event) {
            event.preventDefault();
            Auth.login({
                username: $scope.username,
                password: $scope.password,
                rememberMe: $scope.rememberMe
            }).then(function () {
                $scope.authenticationError = false;
                Principal.identity().then(function(account) {
                    if ($rootScope.previousStateName && $rootScope.previousStateName.parent != 'account') {
                        $state.go($rootScope.previousStateName.name, $rootScope.previousStateNameParams);
                    } else {
                        if(_.filter(account.authorities, function(rol){ return rol == 'ROLE_ADMIN' ||
                                    rol == 'ROLE_CUSTOMERADMIN' ||
                                    rol == 'ROLE_SEONADMIN'; }).length > 0){
                            $state.go('administration');
                        }else{
                            $state.go('home');
                        }
                    }
                });
            }).catch(function (err) {
                if(err.status == 401){
                    $scope.authenticationError = false;
                    $scope.emailNotVerified = true;
                    $scope.usernameToSendEmail = $scope.username;

                    DataService.set("username", $scope.username);
                    scroll();
                    Notifications.showCustomToast("SendActivationEmailController", "scripts/app/account/login/sendVerificationEmailTemplate.html");
                } else{
                    $scope.authenticationError = true;
                    $scope.emailNotVerified = false;
                    scroll();
                    Notifications.showToastAccount('error', $filter('translate')('login.messages.error.authentication'));
                }
            });
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('SendActivationEmailController', ["$scope", "$location", "Notifications", "$filter", "$http", "$mdToast", "DataService", "Language", function ($scope, $location, Notifications, $filter, $http, $mdToast, DataService, Language) {

    var langKey = '';
    Language.getCurrent().then(function (language) {
        langKey = language;
    })

    $scope.sendActivationEmail = function() {

        var username = DataService.get("username");

        var response = $http.get('/api/sendActivationEmail/' + username + '/' + langKey);

        response.then(function() {
            $location.path('/sendActivationEmailSuccess');

        });

        response.catch(function() {
            Notifications.showToastAccount('error', $filter('translate')('login.messages.error.errorSendActivationEmail'));
        });

    };

    $scope.closeToast = function() {
        $mdToast.hide();
    };

  }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('password', {
                parent: 'account',
                url: '/password',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CUSTOMERADMIN', 'ROLE_SEONADMIN'],
                    pageTitle: 'global.menu.account.password'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/account/password/password.html',
                        controller: 'PasswordController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('password');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('PasswordController', ["$scope", "Auth", "Principal", "Parameters", function ($scope, Auth, Principal, Parameters) {
        Principal.identity().then(function(account) {
            $scope.account = account;
        });

        $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();
        $scope.success = null;
        $scope.error = null;
        $scope.doNotMatch = null;
        $scope.changePassword = function () {
            if ($scope.password !== $scope.confirmPassword) {
                $scope.error = null;
                $scope.success = null;
                $scope.doNotMatch = 'ERROR';
            } else {
                $scope.doNotMatch = null;
                Auth.changePassword($scope.password).then(function () {
                    $scope.error = null;
                    $scope.success = 'OK';
                }).catch(function () {
                    $scope.success = null;
                    $scope.error = 'ERROR';
                });
            }
        };
    }]);

/* globals $ */
'use strict';

angular.module('schoolbushubApp')
    .directive('passwordStrengthBar', function () {
        return {
            replace: true,
            restrict: 'E',
            template: '<div id="strength">' +
            '<ul id="strengthBar">' +
            '<li class="point"></li><li class="point"></li><li class="point"></li><li class="point"></li><li class="point"></li>' +
            '</ul>' +
            '</div>',
            link: function (scope, iElement, attr) {
                var strength = {
                    colors: ['#F00', '#F90', '#FF0', '#9F0', '#0F0'],
                    mesureStrength: function (p) {

                        var _force = 0;
                        var _regex = /[$-/:-?{-~!"^_`\[\]]/g; // "

                        var _lowerLetters = /[a-z]+/.test(p);
                        var _upperLetters = /[A-Z]+/.test(p);
                        var _numbers = /[0-9]+/.test(p);
                        var _symbols = _regex.test(p);

                        var _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];
                        var _passedMatches = $.grep(_flags, function (el) {
                            return el === true;
                        }).length;

                        _force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
                        _force += _passedMatches * 10;

                        // penality (short password)
                        _force = (p.length <= 6) ? Math.min(_force, 10) : _force;

                        // penality (poor variety of characters)
                        _force = (_passedMatches === 1) ? Math.min(_force, 10) : _force;
                        _force = (_passedMatches === 2) ? Math.min(_force, 20) : _force;
                        _force = (_passedMatches === 3) ? Math.min(_force, 40) : _force;

                        return _force;

                    },
                    getColor: function (s) {

                        var idx = 0;
                        if (s <= 10) {
                            idx = 0;
                        }
                        else if (s <= 20) {
                            idx = 1;
                        }
                        else if (s <= 30) {
                            idx = 2;
                        }
                        else if (s <= 40) {
                            idx = 3;
                        }
                        else {
                            idx = 4;
                        }

                        return { idx: idx + 1, col: this.colors[idx] };
                    }
                };
                scope.$watch(attr.passwordToCheck, function (password) {
                    if (password) {
                        var c = strength.getColor(strength.mesureStrength(password));
                        iElement.removeClass('ng-hide');
                        iElement.find('ul').children('li')
                            .css({ 'background': '#DDD' })
                            .slice(0, c.idx)
                            .css({ 'background': c.col });
                    }
                    else{
                        iElement.addClass('ng-hide');
                    }
                });
            }
        };
    }).directive('compareTo',function (){
        return {
            require: "ngModel",
            scope: {
                otherModelValue: "=compareTo"
            },
            link: function(scope, element, attributes, ngModel) {

                ngModel.$validators.compareTo = function(modelValue) {
                    if(angular.isDefined(scope.otherModelValue)){
                        /* scope.isEqual = (modelValue == scope.otherModelValue); */
                        ngModel.$setValidity('isEqual', modelValue == scope.otherModelValue, 'RegisterController');
                        return modelValue == scope.otherModelValue;
                    }
                };

                scope.$watch("otherModelValue", function() {
                    ngModel.$validate();
                });
            }
        };
    });;

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('register', {
                parent: 'account',
                isApp: true,
                url: '/register',
                data: {
                    roles: [],
                    pageTitle: 'register.title'
                },
                views: {
                    'app-content@': {
                        templateUrl: 'scripts/app/account/register/register.html',
                        controller: 'RegisterController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('register');
                        return $translate.refresh();
                    }]
                }
            }).state('registerWithSchoolCode', {
                parent: 'account',
                isApp: true,
                url: '/register/:schoolCode',
                data: {
                    roles: [],
                    pageTitle: 'register.title'
                },
                views: {
                    'app-content@': {
                        controller: 'SchoolCodeController'
                    }
                }
            });
}]);

'use strict';

angular.module('schoolbushubApp')
  .controller('RegisterController', ["$scope", "$translate", "Auth", "$location", "Verify", "Notifications", "$filter", "Principal", "Parameters", "SchoolCode", "$anchorScroll", "$http", "$q", function ($scope, $translate, Auth, $location, Verify, Notifications, $filter, Principal, Parameters, SchoolCode, $anchorScroll, $http, $q) {

    $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();
    $scope.validationAlphanumeric = Parameters.getRegularExpressionValidationAlphanumeric();
    $scope.validationMobileNumber = Parameters.getRegularExpressionMobileNumber();

    $scope.verifying = false;
    $scope.success = null;
    $scope.error = null;
    $scope.doNotMatch = null;
    $scope.errorUserExists = null;
    $scope.registerAccount = {};
    $scope.schoolCode = SchoolCode.get();
    var today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    var scroll = function () {
      if ($location.hash() !== 'toastBounds') {
        $location.hash('toastBounds');
      } else {
        $anchorScroll();
      }
    }

    /**
     * Register User.
     */
    $scope.register = function () {
      if ($scope.registerAccount.password !== $scope.registerAccount.confirmPassword) {
        $scope.doNotMatch = 'ERROR';
      } else {
        $scope.registerAccount.langKey = $translate.use();
        $scope.doNotMatch = null;
        $scope.error = null;
        $scope.errorUserExists = null;
        $scope.errorEmailExists = null;
        $scope.registerAccount.verificationKey = $scope.verificationKey;

        Auth.createAccount($scope.registerAccount).then(function () {
          $scope.success = 'OK';
        }).catch(function (response) {
          Auth.logout();
          $scope.success = null;
          scroll();
          if (response.status === 400 && response.data.description === 'login already in use') {
            $scope.errorUserExists = 'ERROR';
            Notifications.showToastAccount('error', $filter('translate')('register.messages.error.userexists'));
          } else if (response.status === 400 && response.data.description === 'e-mail address already in use') {
            $scope.errorEmailExists = 'ERROR';
            Notifications.showToastAccount('error', $filter('translate')('register.messages.error.emailexists'));
          } else {
            $scope.error = 'ERROR';
            Notifications.showToastAccount('error', $filter('translate')('register.messages.error.fail'));
          }
        });
      }
    };


    /**
    * Verify Student Callcack
    * @param response
    */
    var verifyStudentCallback = function (response) {
      if (response.status === 'success') {
        $scope.verificationKey = response.verificationKey;
        $scope.verified = 'OK';
        $scope.success = null;
        Notifications.showToastAccount('success', $filter('translate')('register.verify.studentVerifiedSuccessfully'));
      } else {
        scroll();
        Notifications.showToastAccount('error', $filter('translate')('register.verify.studentNotVerified'));
      }
      $scope.verifying = false;
    }

    /**
     * Verify Student
     */
    $scope.verifyStudent = function () {
      if ($scope.validated()) {
        $scope.verifying = true;
        Verify.verifyStudent($scope.school.code, moment($scope.dateOfBirth,
          $filter('translate')('register.formatDateMoment')).toDate(), $scope.idStudent, $scope.organization.id,
           verifyStudentCallback);
      }
    }

    /**
     * Validation of the fields
     * @returns {boolean}
     */
    $scope.validated = function () {
      var validated = true;
      var dateByParts = $scope.dateOfBirth.split('/');
      if (dateByParts.length == 3) {
        _.each(dateByParts, function (num) { if (isNaN(num) || isNaN(parseFloat(num))) { validated = false; } });
      } else {
        validated = false;
      }
      if (validated) {
        if (!moment($scope.dateOfBirth, [$filter('translate')('register.formatDateMoment')]).isValid()) {
          validated = false;
          scroll();
          Notifications.showToastAccount('error', $filter('translate')('register.verify.dateBirthIsInvalid'));
        } else {
          var birth = moment($scope.dateOfBirth, $filter('translate')('register.formatDateMoment')).toDate();
          if (moment(birth).diff(today, 'hours') > 0) {
            validated = false;
            scroll();
            Notifications.showToastAccount('error', $filter('translate')('register.verify.dateBirthIsHigherCurrentDate'));
          }
        }
      } else {
        scroll();
        Notifications.showToastAccount('error', $filter('translate')('register.verify.dateBirthIsInvalid'));
      }
      return validated;
    };

    $scope.searchSchool = function (query) {
      var deferred = $q.defer();
      var params = {
        organizationId: $scope.organization.id,
        schoolName: query
      };
      $http.get('api/schools/codes', { params: params }).then(function (results) {
        for (var i = 0; i < results.data.length; i++) {
          results.data[i].displayName = results.data[i].code + ' - ' + results.data[i].name;
        }
        deferred.resolve(results.data);
      }, function (err) {
        deferred.resolve([]);
      });
      return deferred.promise;
    }

    $scope.searchOrganizations = function (query) {
      var deferred = $q.defer();
      if ($scope.school) {
        $scope.school = null;
      }
      $http.get('api/organizations/search?name=' + query).then(function (results) {
        deferred.resolve(results.data);
      }, function (err) {
        deferred.resolve([]);
      });
      return deferred.promise;
    }
  }]);

/*
* created by wmontalvo on 09/24/2015
**/
'use strict';
/* Directives */
angular.module('schoolbushubApp').directive('passwordStrength', function  () {
       return {
          restrict: 'A',
          link: function(scope, element, attrs){
              scope.$watch(attrs.passwordStrength, function(value) {
                  console.log(value);
                  if(angular.isDefined(value)){
                      var validator = isSatisfied(value && value.length >= 8) +
                      isSatisfied(value && /[A-z]/.test(value)) +
                      isSatisfied(value && /(?=.*\W)/.test(value)) +
                      isSatisfied(value && /\d/.test(value));
                      if(validator){
                          if (value.length > 8) {
                              scope.strength = 'strong';
                          } else if (value.length > 3) {
                              scope.strength = 'medium';
                          } else {
                              scope.strength = 'weak';
                          }
                      }
                  }
                  function isSatisfied(criteria) {
                      return criteria ? 1 : 0;
                  }
              });
          }
      };
  }).directive('patternValidator',function (){
     return {
         require: 'ngModel',
         restrict: 'A',
         link: function(scope, elem, attrs, ctrl) {
           ctrl.$parsers.unshift(function(viewValue) {

             var patt = new RegExp(attrs.patternValidator);

             var isValid = patt.test(viewValue);

             ctrl.$setValidity('passwordPattern', isValid);

             // angular does this with all validators -> return isValid ? viewValue : undefined;
             // But it means that the ng-model will have a value of undefined
             // So just return viewValue!
             return viewValue;

           });
         }
       };
  }).directive('compareTo',function (){
     return {
         require: "ngModel",
         scope: {
            otherModelValue: "=compareTo"
         },
         link: function(scope, element, attributes, ngModel) {

            ngModel.$validators.compareTo = function(modelValue) {
                return modelValue == scope.otherModelValue;
            };

            scope.$watch("otherModelValue", function() {
                ngModel.$validate();
            });
         }
     };
});

'use strict';

angular.module('schoolbushubApp')
    .service('SchoolCode', function () {

        var schoolCode;

        var get = function(){
            var sc = schoolCode;
            schoolCode = undefined;
            return sc;
        }

        var set = function(sc){
            schoolCode = sc;
        }

        return{
            get:get,
            set:set
        }

    });


'use strict';

angular.module('schoolbushubApp')
    .controller('SchoolCodeController', ["SchoolCode", "$stateParams", "$state", function (SchoolCode, $stateParams, $state) {
        SchoolCode.set($stateParams.schoolCode);
        $state.transitionTo('register');
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('settings', {
                parent: 'account',
                url: '/settings',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_CUSTOMERADMIN', 'ROLE_SEONADMIN'],
                    pageTitle: 'global.menu.account.settings'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/account/settings/settings.html',
                        controller: 'SettingsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('SettingsController', ["$scope", "Principal", "Auth", "Language", "$translate", function ($scope, Principal, Auth, Language, $translate) {
        $scope.success = null;
        $scope.error = null;

         /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            }
        }

        Principal.identity().then(function(account) {
            $scope.settingsAccount = copyAccount(account);
        });

        $scope.save = function () {
            Auth.updateAccount($scope.settingsAccount).then(function() {
                $scope.error = null;
                $scope.success = 'OK';
                Principal.identity(true).then(function(account) {
                    $scope.settingsAccount = copyAccount(account);
                });
                Language.getCurrent().then(function(current) {
                    if ($scope.settingsAccount.langKey !== current) {
                        $translate.use($scope.settingsAccount.langKey);
                    }
                });
            }).catch(function() {
                $scope.success = null;
                $scope.error = 'ERROR';
            });
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('ResetFinishController', ["$scope", "$stateParams", "$timeout", "Auth", "Parameters", "$filter", "Notifications", function ($scope, $stateParams, $timeout, Auth, Parameters, $filter, Notifications) {

        $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();

        Auth.logout();

        $scope.keyMissing = $stateParams.key === undefined;

        if($scope.keyMissing){
            Notifications.showToastAccount('error', $filter('translate')('reset.finish.messages.keymissing'));
        }


        $scope.resetAccount = {};
        $timeout(function (){angular.element('[ng-model="resetAccount.password"]').focus();});

        $scope.finishReset = function() {
            if ($scope.resetAccount.password !== $scope.confirmPassword) {
                Notifications.showToastAccount('error', $filter('translate')('global.messages.error.dontmatch'));
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: $scope.resetAccount.password}).then(function () {
                    $scope.success = 'OK';
                }).catch(function (response) {
                    $scope.success = null;
                    Notifications.showToastAccount('error', $filter('translate')('reset.finish.messages.error'));
                });
            }

        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('finishReset', {
                parent: 'account',
                isApp: true,
                url: '/reset/finish?key',
                data: {
                    roles: []
                },
                views: {
                    'app-content@': {
                        templateUrl: 'scripts/app/account/reset/finish/reset.finish.html',
                        controller: 'ResetFinishController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reset');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('RequestResetController', ["$rootScope", "$scope", "$state", "$timeout", "Auth", "Notifications", "$filter", "Language", function ($rootScope, $scope, $state, $timeout, Auth, Notifications, $filter, Language) {

        $scope.error = null;
        $scope.errorEmailNotExists = null;
        $scope.resetAccount = {};
        $timeout(function (){angular.element('[ng-model="resetAccount.email"]').focus();});
        Language.getCurrent().then(function (language) {
            $scope.langKey = language;
        });

        $scope.requestReset = function () {

            $scope.error = null;

            Auth.resetPasswordInit($scope.resetAccount.email, $scope.langKey).then(function () {
                Notifications.showToastAccount('success', $filter('translate')('reset.request.messages.success'));
            }).catch(function (response) {
                if (response.status === 400 && response.data === 'e-mail address not registered') {
                    Notifications.showToastAccount('error', $filter('translate')('reset.request.messages.notfound'));
                } else {
                    $scope.error = 'ERROR';
                }
            });
        }

    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('requestReset', {
                parent: 'account',
                isApp: true,
                url: '/reset/request',
                data: {
                    roles: []
                },
                views: {
                    'app-content@': {
                        templateUrl: 'scripts/app/account/reset/request/reset.request.html',
                        controller: 'RequestResetController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reset');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('admin', {
                abstract: true,
                parent: 'site'
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('audits', {
                parent: 'admin',
                url: '/audits',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'audits.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/audits/audits.html',
                        controller: 'AuditsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('audits');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('AuditsController', ["$scope", "$filter", "AuditsService", "ParseLinks", function ($scope, $filter, AuditsService, ParseLinks) {
        $scope.audits = [];
        $scope.page = 1;
        $scope.per_page = 30;
        $scope.lastPage = 1;
        $scope.maxSize = 5;
        $scope.busy = false;
        $scope.optionsPerPage = [5,10,20,50,100];
        $scope.user = '';
        $scope.state = '';
        $scope.fromDate = new Date();
        $scope.toDate = new Date();

        //calendar stuff
        $scope.altInputFormats = ['M!/d!/yyyy'];
        $scope.format = 'MM/dd/yyyy';
        $scope.fromDateCalendarOpened = false;
        $scope.toDateCalendarOpened = false;
        $scope.fromDatepickerOpts = {
            maxDate: new Date()
        };
        $scope.toDatepickerOpts = {
            maxDate: new Date()
        };

        $scope.toggleCalendar = function(cal) {
            if (cal === 'from') {
                $scope.fromDateCalendarOpened = !$scope.fromDateCalendarOpened;
            } else if (cal === 'to') {
                $scope.toDateCalendarOpened = !$scope.toDateCalendarOpened;
            }
        }
        $scope.calendarChanged = function() {
            $scope.fromDatepickerOpts.maxDate = $scope.toDate || new Date();
            $scope.toDatepickerOpts.minDate = $scope.fromDate;
        }

        $scope.loadResults = function () {
            $scope.fromDate.setHours(0,0,0,0);
            $scope.toDate.setHours(23,59,59,999);
            $scope.page = 1;
            $scope.lastPage = 1;
            $scope.audits = [];
            $scope.loadPage();
        };

        $scope.loadPage = function () {
            if ($scope.page < 1 || $scope.page > $scope.lastPage) {
                return;
            };
            
            $scope.busy = true;
            AuditsService.findByFilters($scope.page, $scope.per_page, $scope.fromDate, $scope.toDate, $scope.user, $scope.state, function(response) {
                $scope.totalItems = response.data.totalElements;
                $scope.lastPage = Math.ceil($scope.totalItems / $scope.per_page);
                $scope.audits = $scope.audits.concat(response.data.content);
                $scope.busy = false;
            });
            $scope.page++;
        };


        $scope.formatDateTime = function(dt) {
            if (!dt) return '--';
            return moment(dt).format('MM/DD/YYYY HH:mm:ss');
        };

        $scope.calendarChanged();

        $scope.loadResults();
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('configuration', {
                parent: 'admin',
                url: '/configuration',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'configuration.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/configuration/configuration.html',
                        controller: 'ConfigurationController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('configuration');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('ConfigurationController', ["$scope", "ConfigurationService", function ($scope, ConfigurationService) {
        ConfigurationService.get().then(function(configuration) {
            $scope.configuration = configuration;
        });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('health', {
                parent: 'admin',
                url: '/health',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'health.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/health/health.html',
                        controller: 'HealthController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('health');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('HealthController', ["$scope", "MonitoringService", "$uibModal", function ($scope, MonitoringService, $uibModal) {
        $scope.updatingHealth = true;
        $scope.separator = '.';

        $scope.refresh = function () {
            $scope.updatingHealth = true;
            MonitoringService.checkHealth().then(function (response) {
                $scope.healthData = $scope.transformHealthData(response);
                $scope.updatingHealth = false;
            }, function (response) {
                $scope.healthData =  $scope.transformHealthData(response.data);
                $scope.updatingHealth = false;
            });
        };

        $scope.refresh();

        $scope.getLabelClass = function (statusState) {
            if (statusState === 'UP') {
                return 'label-success';
            } else {
                return 'label-danger';
            }
        };

        $scope.transformHealthData = function (data) {
            var response = [];
            $scope.flattenHealthData(response, null, data);
            return response;
        };

        $scope.flattenHealthData = function (result, path, data) {
            angular.forEach(data, function (value, key) {
                if ($scope.isHealthObject(value)) {
                    if ($scope.hasSubSystem(value)) {
                        $scope.addHealthObject(result, false, value, $scope.getModuleName(path, key));
                        $scope.flattenHealthData(result, $scope.getModuleName(path, key), value);
                    } else {
                        $scope.addHealthObject(result, true, value, $scope.getModuleName(path, key));
                    }
                }
            });
            return result;
        };

        $scope.getModuleName = function (path, name) {
            var result;
            if (path && name) {
                result = path + $scope.separator + name;
            }  else if (path) {
                result = path;
            } else if (name) {
                result = name;
            } else {
                result = '';
            }
            return result;
        };


        $scope.showHealth = function(health) {
            var modalInstance = $uibModal.open({
                templateUrl: 'scripts/app/admin/health/health.modal.html',
                controller: 'HealthModalController',
                size: 'lg',
                resolve: {
                    currentHealth: function() {
                        return health;
                    },
                    baseName: function() {
                        return $scope.baseName;
                    },
                    subSystemName: function() {
                        return $scope.subSystemName;
                    }

                }
            });
        };

        $scope.addHealthObject = function (result, isLeaf, healthObject, name) {

            var healthData = {
                'name': name
            };
            var details = {};
            var hasDetails = false;

            angular.forEach(healthObject, function (value, key) {
                if (key === 'status' || key === 'error') {
                    healthData[key] = value;
                } else {
                    if (!$scope.isHealthObject(value)) {
                        details[key] = value;
                        hasDetails = true;
                    }
                }
            });

            // Add the of the details
            if (hasDetails) {
                angular.extend(healthData, { 'details': details});
            }

            // Only add nodes if they provide additional information
            if (isLeaf || hasDetails || healthData.error) {
                result.push(healthData);
            }
            return healthData;
        };

        $scope.hasSubSystem = function (healthObject) {
            var result = false;
            angular.forEach(healthObject, function (value) {
                if (value && value.status) {
                    result = true;
                }
            });
            return result;
        };

        $scope.isHealthObject = function (healthObject) {
            var result = false;
            angular.forEach(healthObject, function (value, key) {
                if (key === 'status') {
                    result = true;
                }
            });
            return result;
        };

        $scope.baseName = function (name) {
            if (name) {
              var split = name.split('.');
              return split[0];
            }
        };

        $scope.subSystemName = function (name) {
            if (name) {
              var split = name.split('.');
              split.splice(0, 1);
              var remainder = split.join('.');
              return remainder ? ' - ' + remainder : '';
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('HealthModalController', ["$scope", "$uibModalInstance", "currentHealth", "baseName", "subSystemName", function($scope, $uibModalInstance, currentHealth, baseName, subSystemName) {

        $scope.currentHealth = currentHealth;
        $scope.baseName = baseName, $scope.subSystemName = subSystemName;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('logs', {
                parent: 'admin',
                url: '/logs',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'logs.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/logs/logs.html',
                        controller: 'LogsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('logs');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('notifications', {
                parent: 'admin',
                url: '/notifications',
                data: {
                    authorities: ['ROLE_SEONADMIN', 'ROLE_ADMIN', 'ROLE_CUSTOMERADMIN'],
                    pageTitle: 'audits.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/notifications/notifications.html',
                        controller: 'notificationsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('notifications');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('notificationsController', ["$scope", "$filter", "$http", "Organization", "RouteModification", "FiltersRouteSvc", "RestService", "AlertService", function ($scope, $filter, $http, Organization, RouteModification, FiltersRouteSvc, RestService, AlertService) {
        var scp = $scope;
        scp.organizations = [];
        scp.selectedRoutes = [];
        scp.isCustomerAdmin = false;
        scp.disableSendMessage = true;
        scp.notifyAll = "true";
        scp.searchParams = {
            url: 'findRoutes'
        };

        scp.botchTranslate = function(str) {
            return $filter('translate')(str);
        }

        Organization.getRoleOrganizations().then(function (result) {
            scp.organizations = result.organizations;
            scp.isCustomerAdmin = result.isCustomerAdmin;
            scp.organization = result.organization;
        })

        scp.search = function ($event) {
            scp.selectAll = false;
            scp.searchResults = undefined;
            scp.selectedRoutes = [];
            var searchPars = {fleetId: scp.organization.code};
            searchPars.active = true;
            if (scp.searchParams.route) {
                searchPars.runId = scp.searchParams.route.id;
            }
            if (scp.searchParams.vehicle) {
                searchPars.vehicleId = scp.searchParams.vehicle.id;
            }
            if (scp.searchParams.school) {
               searchPars.schoolId = scp.searchParams.school.guid;
            }

             searchPars.page = 0;
             searchPars.size = 5000;

            RouteModification.query(searchPars, function (data, headers) {
                scp.searchResults = [];
                angular.forEach(data, function (r) {
                    scp.searchResults.push(r);
                });
                $event.preventDefault();
                $event.stopPropagation();
                scp.searchResultsOpen = true;
                scp.resultsParams = {
                    name: scp.searchParams.name,
                    organizationId: scp.organization.id,
                };
                scp.verifySendMessage();
                if(scp.searchResults.length === 0){
                    AlertService.info($filter('translate')('notifications.filter.notfoundroutes'));
                }
            }, function (err) {
                AlertService.error($filter('translate')('notifications.filter.error'));
            });
        };

        scp.selectRoute = function(route){
            if(route.__selected) {
                scp.selectedRoutes.push(route);
            } else {
                scp.selectedRoutes.shift(route)
            }
            scp.verifySendMessage();
        }

        scp.selectAllRoutes = function(){
            if(scp.selectAll){
                scp.selectedRoutes = scp.searchResults;
            } else {
                scp.selectedRoutes = [];
            }
            scp.searchResults.forEach(function(r){
                r.__selected = scp.selectAll;
            });
            scp.verifySendMessage();
        }

        scp.updateMessage = function(){
            scp.customMessage = scp.messageType;
            scp.verifySendMessage();
        }

        scp.sendMessage = function(){
            var requestBody = {
                message: scp.customMessage,
                organizationId: scp.organization.id
            };
            if(scp.notifyAll === 'false') {
               requestBody.routes = scp.selectedRoutes
            }
            RestService.post({url: 'sendMassiveNotification'}, requestBody, function(){
                AlertService.info($filter('translate')('notifications.messageSended'));
            }, function(err){
            if(err.status === 404){
             AlertService.error($filter('translate')('notifications.studentsOrParentsNotFound'));
            } else {
             AlertService.error($filter('translate')('notifications.errorSendingMessage'));
            }
                console.log(err);
            });
        }

      scp.getRoutesByName = function(query) {
          if (!scp.organization || !query) {
            return undefined;
          }
          FiltersRouteSvc.getRoutesByName(scp.organization, query, function(data) {
            scp.routesAsync = data;
          }, function(err){
            scp.routesAsync = [];
          });
      };

      scp.getVehiclesByName = function(query) {
          if (!scp.organization || !query) {
              return undefined;
          }
          FiltersRouteSvc.getVehiclesByName(scp.organization, query, function(data) {
            scp.vehiclesAsync = [];
            for(var i = 0; i < data.length; i++) {
                var r = data[i];
                scp.vehiclesAsync.push({ id: r.id, name: r.id });
            }
          }, function(err){
            scp.vehiclesAsync = [];
          });
      };

      scp.getSchoolsByName = function(query) {
          if (!scp.organization || !query) {
              return undefined;
          }
          FiltersRouteSvc.getSchoolsByName(scp.organization, query, function(data) {
            scp.schoolsAsync = data;
          }, function(err){
            scp.schoolsAsync = [];
          });
      };

      scp.verifySendMessage = function() {
        scp.disableSendMessage =  !scp.customMessage || (scp.notifyAll === 'false' && scp.selectedRoutes.length === 0);
      }

     scp.cleanFilters = function () {
       $scope.searchParams.route = undefined;
       $scope.searchParams.vehicle = undefined;
       $scope.searchParams.school = undefined;
     }

    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('LogsController', ["$scope", "LogsService", function ($scope, LogsService) {
        $scope.loggers = LogsService.findAll();

        $scope.changeLevel = function (name, level) {
            LogsService.changeLevel({name: name, level: level}, function () {
                $scope.loggers = LogsService.findAll();
            });
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('metrics', {
                parent: 'admin',
                url: '/metrics',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'metrics.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/metrics/metrics.html',
                        controller: 'MetricsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('metrics');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('MetricsController', ["$scope", "MonitoringService", "$uibModal", function ($scope, MonitoringService, $uibModal) {
        $scope.metrics = {};
        $scope.updatingMetrics = true;

        $scope.refresh = function () {
            $scope.updatingMetrics = true;
            MonitoringService.getMetrics().then(function (promise) {
                $scope.metrics = promise;
                $scope.updatingMetrics = false;
            }, function (promise) {
                $scope.metrics = promise.data;
                $scope.updatingMetrics = false;
            });
        };

        $scope.$watch('metrics', function (newValue) {
            $scope.servicesStats = {};
            $scope.cachesStats = {};
            angular.forEach(newValue.timers, function (value, key) {
                if (key.indexOf('web.rest') !== -1 || key.indexOf('service') !== -1) {
                    $scope.servicesStats[key] = value;
                }
                if (key.indexOf('net.sf.ehcache.Cache') !== -1) {
                    // remove gets or puts
                    var index = key.lastIndexOf('.');
                    var newKey = key.substr(0, index);

                    // Keep the name of the domain
                    index = newKey.lastIndexOf('.');
                    $scope.cachesStats[newKey] = {
                        'name': newKey.substr(index + 1),
                        'value': value
                    };
                }
            });
        });

        $scope.refresh();

        $scope.refreshThreadDumpData = function() {
            MonitoringService.threadDump().then(function(data) {

                var modalInstance = $uibModal.open({
                    templateUrl: 'scripts/app/admin/metrics/metrics.modal.html',
                    controller: 'MetricsModalController',
                    size: 'lg',
                    resolve: {
                        threadDump: function() {
                            return data.content;
                        }

                    }
                });
            });
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('MetricsModalController', ["$scope", "$uibModalInstance", "threadDump", function($scope, $uibModalInstance, threadDump) {

        $scope.threadDump = threadDump;
        $scope.threadDumpRunnable = 0;
        $scope.threadDumpWaiting = 0;
        $scope.threadDumpTimedWaiting = 0;
        $scope.threadDumpBlocked = 0;

        angular.forEach(threadDump, function(value) {
            if (value.threadState === 'RUNNABLE') {
                $scope.threadDumpRunnable += 1;
            } else if (value.threadState === 'WAITING') {
                $scope.threadDumpWaiting += 1;
            } else if (value.threadState === 'TIMED_WAITING') {
                $scope.threadDumpTimedWaiting += 1;
            } else if (value.threadState === 'BLOCKED') {
                $scope.threadDumpBlocked += 1;
            }
        });

        $scope.threadDumpAll = $scope.threadDumpRunnable + $scope.threadDumpWaiting +
            $scope.threadDumpTimedWaiting + $scope.threadDumpBlocked;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.getLabelClass = function (threadState) {
            if (threadState === 'RUNNABLE') {
                return 'label-success';
            } else if (threadState === 'WAITING') {
                return 'label-info';
            } else if (threadState === 'TIMED_WAITING') {
                return 'label-warning';
            } else if (threadState === 'BLOCKED') {
                return 'label-danger';
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
.config(["$stateProvider", function ($stateProvider) {
    $stateProvider
        .state('users', {
            parent: 'admin',
            url: '/users',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CUSTOMERADMIN', 'ROLE_SEONADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/admin/user/users.html',
                    controller: 'UserController'
                }
            },
            resolve: {
            	mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('user');
                    return $translate.refresh();
                }]
            }
        });
}]);

'use strict';

angular.module('schoolbushubApp')
    .controller('UserController', ["$scope", "$timeout", "$uibModal", "User", "USER_ROLES", "Principal", "$translate", "Auth", "ParseLinks", "Organization", "Parameters", function($scope, $timeout, $uibModal, User, USER_ROLES, Principal, $translate, Auth, ParseLinks, Organization, Parameters) {
        var scp = $scope;

        $scope.users = [];
        $scope.currentPage = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.maxSize = 5;
        $scope.sorts = ["login,asc"];
        $scope.validationMobileNumber = Parameters.getRegularExpressionMobileNumber();
        $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();

        $scope.user = {
            login: null,
            email: null,
            authoritiesUser: []
        };

        Principal.identity().then(function(account) {
            $scope.account = account;
            if (jQuery.inArray("ROLE_CUSTOMERADMIN", $scope.account.roles) != -1 || $scope.account.authorities == "ROLE_CUSTOMERADMIN") {
                $scope.organizations = [$scope.account.organization];
            } else {
                $scope.organizations = Organization.resource.filterQuery( {"pageable":{"page":0,"size":1000,"sort":["id,asc","id"]}});
            }
        });


        /**
         * Set the roles for the user.
         * @author jmontoya
         */
        $scope.setRol = function(users) {
            users.forEach(function(user) {
                if (user.authorities) {
                    user.authorities.forEach(function(role) {
                        var roleName = $translate.instant('schoolbushubApp.user.appRoles.' + role.name);
                        user.roles = user.roles ? user.roles + ', ' + roleName : roleName;
                    });
                }
                $scope.users.push(user);
            });
        };

        $scope.loadPage = function() {
            if ($scope.currentPage < 0 || $scope.links && $scope.currentPage > $scope.links['last']) {
                return;
            };
            User.query({
                page: $scope.currentPage,
                size: $scope.per_page,
                sort: $scope.sorts
            }, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = result.totalElements;
                $scope.setRol(result.content);

                // The second page is forced to load to prevent the scroll from disappearing sometimes
                // when the notification message is displayed and the first page occupies the entire size
                // but when the message disappears it makes the scroll disappear. (SBH-1881)
                if($scope.currentPage - 1 === 0) {
                    $scope.$emit('list:users');
                }
            });
            $scope.currentPage++;
        };

        /**
         * show edit modal.
         * @author wmontalvo
         */
        $scope.update = function(login) {
            $scope.requiredClass = '';
            $scope.user = User.get({
                login: login
            }, function(user) {
                user.authoritiesUser = [];
                user.confirmEmail = user.email;

                var roleAdmin = {
                    has: _.where(user.authorities, {
                        name: USER_ROLES.admin
                    })[0] ? true : false,
                    name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.admin),
                    rol: USER_ROLES.admin,
                    disable: false
                }

                var roleCustomerAdmin = {
                    has: _.where(user.authorities, {
                        name: USER_ROLES.customeradmin
                    })[0] ? true : false,
                    name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.customeradmin),
                    rol: USER_ROLES.customeradmin,
                    disable: false
                }

                var roleParent = {
                    has: _.where(user.authorities, {
                        name: USER_ROLES.parent
                    })[0] ? true : false,
                    name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.parent),
                    rol: USER_ROLES.parent,
                    disable: false
                }

                var roleSeonAdmin = {
                    has: _.where(user.authorities, {
                        name: USER_ROLES.seonadmin
                    })[0] ? true : false,
                    name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.seonadmin),
                    rol: USER_ROLES.seonadmin
                }

                if (_.indexOf($scope.account.authorities, "ROLE_ADMIN") != -1){
                    user.authoritiesUser.push(roleAdmin);
                    user.authoritiesUser.push(roleCustomerAdmin);
                    user.authoritiesUser.push(roleParent);
                    user.authoritiesUser.push(roleSeonAdmin);
                }else

                if (_.indexOf($scope.account.authorities, "ROLE_SEONADMIN") != -1){
                    user.authoritiesUser.push(roleSeonAdmin);
                    user.authoritiesUser.push(roleCustomerAdmin);
                    user.authoritiesUser.push(roleParent);
                }else

                if (_.indexOf($scope.account.authorities, "ROLE_CUSTOMERADMIN") != -1){
                    user.authoritiesUser.push(roleParent);
                    user.authoritiesUser.push(roleCustomerAdmin);
                }
            });
            $scope.isEdit = true;
            $('#editUserModal').modal('show');
            $scope.showUser();
        };


        /**
         * create user.
         * @author jmontoya
         */
        $scope.createUser = function() {
            $scope.isEdit = false;
            $scope.requiredClass = 'bold';
            $scope.createRoleList();
            $('#strengthBar li').each(function() {
                $(this).css("background-color", "#DDD");
            });
            $scope.showUser();
        };

        /**
         * create roles list.
         * @author jmontoya
         */
        $scope.createRoleList = function() {
            $scope.user = {
                login: null,
                email: null,
                authoritiesUser: []
            };

            var roleAdmin = {
                has: false,
                name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.admin),
                rol: USER_ROLES.admin,
                disable: false
            }

            var roleCustomerAdmin = {
                has: false,
                name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.customeradmin),
                rol: USER_ROLES.customeradmin,
                disable: false
            }

            var roleParent = {
                has: false,
                name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.parent),
                rol: USER_ROLES.parent,
                disable: false
            }

            var roleSeonAdmin = {
                has: false,
                name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.seonadmin),
                rol: USER_ROLES.seonadmin,
                disable: false
            }

            if (_.indexOf($scope.account.authorities, "ROLE_ADMIN") != -1){
                $scope.user.authoritiesUser.push(roleAdmin);
                $scope.user.authoritiesUser.push(roleCustomerAdmin);
                $scope.user.authoritiesUser.push(roleParent);
                $scope.user.authoritiesUser.push(roleSeonAdmin);
            }else

            if (_.indexOf($scope.account.authorities, "ROLE_SEONADMIN") != -1){
                $scope.user.authoritiesUser.push(roleSeonAdmin);
                $scope.user.authoritiesUser.push(roleCustomerAdmin);
                $scope.user.authoritiesUser.push(roleParent);
            }else

            if (_.indexOf($scope.account.authorities, "ROLE_CUSTOMERADMIN") != -1){
                $scope.user.authoritiesUser.push(roleCustomerAdmin);
                $scope.user.authoritiesUser.push(roleParent);
            }
        };


        $scope.currentPageChanged = function(){
            $scope.currentPage = 0;
            $scope.loadPage();
        }

        $scope.loadPage();

        $scope.deleteModal = function(user) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/admin/user/_deleteUserModal.html',
                controller: 'DelUserModalCtrl',
                resolve: {
                    user: function () {
                        return user;
                    }
                }
            });

            modalInstance.result.then(function(status) {
                $scope.reloadPages();
            }, function(reason) {
                if (reason == 'error') {
                    bootbox.alert($translate.instant("global.messages.error.generalError"));
                }
            });
        };

        $scope.showUser = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/admin/user/_userModal.html',
                controller: 'UserModalCtrl',
                backdrop: 'static',
                resolve: {
                    info: function () {
                        return {
                            user:scp.user,
                            isEdit: scp.isEdit,
                            requiredClass:scp.requiredClass,
                            organizations:scp.organizations,
                            account:scp.account
                        };
                    }
                }
            });
            scp.user = {};

            modalInstance.result.then(function(status) {
                $scope.reloadPages();
            }, function (reason) {
                if (reason == 'error') {
                    bootbox.alert($translate.instant("global.messages.error.generalError"));
                }
            });
        };

        $scope.reloadPages = function () {
            $scope.users = [];
            $scope.currentPage = 0;
            $scope.loadPage();
        }
    }]);

angular.module('schoolbushubApp').controller('DelUserModalCtrl', ["$scope", "$uibModalInstance", "user", "User", "$translate", function ($scope, $uibModalInstance, user, User, $translate) {
    $scope.user = user;

    $scope.delete = function(login) {
        User.delete({
            login: login
        }, function() {
           $uibModalInstance.close('ok');
        }, function(response) {
            bootbox.alert($translate.instant("global.messages.error.generalError"));
        });
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
}]);

angular.module('schoolbushubApp').controller('UserModalCtrl', ["$scope", "$uibModalInstance", "info", "User", "$translate", "USER_ROLES", "Parameters", function ($scope, $uibModalInstance, info, User, $translate, USER_ROLES, Parameters) {
    $scope.user = info.user;
    $scope.isEdit = info.isEdit;
    $scope.requiredClass = info.requiredClass;
    $scope.organizations = info.organizations;
    $scope.account = info.account;
    $scope.ROLE_ADM = USER_ROLES.admin
    $scope.validationMobileNumber = Parameters.getRegularExpressionMobileNumber();
    $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();
    $scope.customerAdminSelected = function(){
        var authUser = _.find($scope.user.authoritiesUser, function(aut){ return aut.rol == "ROLE_CUSTOMERADMIN";});
        if (authUser && authUser.has){
            return true;
        }
        return false;
    }


    $scope.save = function() {
        if ($scope.password !== $scope.confirmPassword && $scope.password !== '' && $scope.confirmPassword !== '') {
            $scope.doNotMatch = true;
        }
        else {
            $scope.doNotMatch = null;

            var method = $scope.isEdit? 'update' : 'save';
            if (!$scope.user.langKey) { $scope.user.langKey = 'en'; }
            $scope.user.authorities = [];
            _.each($scope.user.authoritiesUser, function(auth) {
                if (auth.has) {
                    var auth = {
                        name: auth.rol
                    };
                    $scope.user.authorities.push(auth);
                };
            });

            User[method]({
                    user: $scope.user,
                    password: $scope.confirmPassword
                },
                function() {
                    $uibModalInstance.close('ok');
                    window.location.reload();
                },
                function(response) {
                    if (response.status === 400 && response.data.error && response.data.error === 'e-mail address already in use') {
                        bootbox.alert($translate.instant("schoolbushubApp.user.messages.emailAlreadyUse"));
                    }
                    else if (response.status === 400 && response.data.error && response.data.error === 'username already in use') {
                        bootbox.alert($translate.instant("schoolbushubApp.user.messages.loginAlreadyUse"));
                    }
                    else {
                        bootbox.alert($translate.instant("global.messages.error.generalError"));
                    }
                }
            );
        }
    }

    $scope.checkCustomerAdmin = function() {
         if ($scope.hasAnyAdminRole($scope.account.authorities)) {
            if (!$scope.customerAdminSelected()) {
                $scope.user.organization = null;
            }
         }
         else if ($scope.account.authorities.includes("ROLE_CUSTOMERADMIN")) {
             if ($scope.customerAdminSelected()) {
                 $scope.user.organization = $scope.account.organization;
             }
             else {
                 $scope.user.organization = null;
             }
         }

        return !($scope.hasAnyAdminRole($scope.account.authorities) && $scope.customerAdminSelected());
    }

    $scope.hasAnyAdminRole = function(authorities) {
        return authorities.includes("ROLE_ADMIN") ||
            authorities.includes("ROLE_SEONADMIN");
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.restrictRoles = function (checkObj) {
        if (checkObj.rol === "ROLE_ADMIN") {
          _.each($scope.user.authoritiesUser, function(role) {
            if (role !== checkObj) {
              if (checkObj.has) {
                role.has = !checkObj.has;
              }
              role.disable = checkObj.has;
              }
          });
        }
    }


    try {
        $scope.user.$promise.then(function () {
          $scope.restrictRoles($scope.user.authoritiesUser[0]);
        });
    } catch (error) {

    }
}]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('monitoring', {
                parent: 'admin',
                url: '/monitoring',
                data: {
                    authorities: ['ROLE_SEONADMIN', 'ROLE_ADMIN', 'ROLE_CUSTOMERADMIN'],
                    pageTitle: 'School Bus Hub'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/monitoring/monitoring.html',
                        controller: 'MonitoringCtrl'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('monitoring');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

"use strict";

angular.module("schoolbushubApp").controller("MonitoringCtrl", MonitoringCtrl);
MonitoringCtrl.$inject = [
  "$scope",
  "$location",
  "$anchorScroll",
  "$http",
  "$filter",
  "RestService",
  "MonitoringSvc",
  "Organization",
  "EVENT_SOURCES",
];

function MonitoringCtrl(
  $scope,
  $location,
  $anchorScroll,
  $http,
  $filter,
  RestService,
  MonitoringSvc,
  Organization,
  EVENT_SOURCES
) {
  var scp = $scope;

  scp.disablePlanned = false;
  scp.disableHistoric = false;
  scp.organizations = [];
  scp.routesAsync = [];
  scp.vehiclesAsync = [];
  scp.runOrVehicle = {};
  var dateFormat = "YYYY-MM-DD";

  scp.isCustomerAdmin = false;
  Organization.getRoleOrganizations().then(function (result) {
    scp.organizations = result.organizations;
    scp.isCustomerAdmin = result.isCustomerAdmin;
    scp.organization = result.organization;
  });

  var baseSizes = {
    selStop: { h: 35, w: 65, o: 30 },
    stop: { h: 94.626, w: 50 },
    pulse: { h: 32, w: 32 },
    line: { w: 7 },
  };

  var distanceIcons = ["location_on", "arrow_upward", "arrow_downward", "arrow_downward"];

  var stopVarsToCopy = [
    "distanceInsideAllowedTime",
    "distanceMsgNotReceived",
    "distanceMsgOld",
    "timeInsideAllowedTime",
    "timeMsgNotReceived",
    "timeNotificationSent",
    "expectedEventTime",
    "realEventTime",
    "realArrivalTime",
    "stopDuration",
    "sbhTimeArrival",
    "sbhDistanceArrival"
  ];
  var studentVarsToCopy = ["registered", "distanceEmail", "timeEmail", "pickUp", "dropOff", "arrivalTime"];

  require([
    "esri/config",
    "esri/map",
    "esri/layers/FeatureLayer",
    "esri/geometry/Point",
    "esri/symbols/PictureMarkerSymbol",
    "esri/graphic",
    "esri/symbols/SimpleLineSymbol",
    "esri/Color",
    "esri/renderers/SimpleRenderer",
    "esri/renderers/UniqueValueRenderer",
    "esri/geometry/Extent",
    "esri/layers/GraphicsLayer",
    "esri/SpatialReference",
  ], function (
    esriConfig,
    Map,
    FeatureLayer,
    Point,
    PictureMarkerSymbol,
    Graphic,
    SimpleLineSymbol,
    Color,
    SimpleRenderer,
    UniqueValueRenderer,
    Extent,
    GraphicsLayer,
    SpatialReference
  ) {
    esriConfig.defaults.io.corsEnabledServers.push("arcgisstaging.vmaxlive.net");
    esriConfig.defaults.io.corsDetection = false;

    scp.map = new Map("monitoringMap", {
      center: [-107.4919934, 49.7952768],
      zoom: 4,
      basemap: "streets",
      force3DTransforms: true,
    });

    scp.map.on("load", function (ev) {
      setStopIconsSizes(ev.map.getZoom());
    });
    scp.map.on("zoom-end", function (ev) {
      setStopIconsSizes(ev.level);
    });

    var selectedStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-selected.svg", 20, 20);
    scp.selectedMarker = new Graphic(undefined, selectedStopSymbol);

    var historicSymbol = new PictureMarkerSymbol();
    historicSymbol.setUrl("../assets/images/arrow2.svg");

    var historicRenderer = new SimpleRenderer(historicSymbol);
    historicRenderer.setRotationInfo({
      field: function (gr) {
        return parseInt(gr.attributes.heading);
      },
    });

    var routeSymbol = new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, Color("#337AB7"), 4);
    var routeRenderer = new SimpleRenderer(routeSymbol);

    var blueStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-blue.svg", 20, 20);
    var grayStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-gray.svg", 20, 20);
    var greenStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-green.svg", 20, 20);
    var yellowStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-yellow.svg", 20, 20);
    var redStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-red.svg", 20, 20);
    var depotSymbol = new PictureMarkerSymbol("../assets/images/monitoring/depot-black.svg", 20, 20);
    var schoolSymbol = new PictureMarkerSymbol("../assets/images/monitoring/school-blue.svg", 20, 20);

    function setStopIconsSizes(lvl) {
      var factor = lvl * lvl * 0.004;
      historicSymbol.setHeight(baseSizes.pulse.h * factor);
      historicSymbol.setWidth(baseSizes.pulse.w * factor);
      routeSymbol.setWidth(baseSizes.line.w * factor);
      selectedStopSymbol.setWidth(baseSizes.selStop.w * factor);
      selectedStopSymbol.setHeight(baseSizes.selStop.h * factor);
      selectedStopSymbol.setOffset(0, baseSizes.selStop.o * factor);

      angular.forEach(
        [blueStopSymbol, grayStopSymbol, greenStopSymbol, yellowStopSymbol, redStopSymbol, depotSymbol, schoolSymbol],
        function (sym) {
          sym.setWidth(baseSizes.stop.w * factor);
          sym.setHeight(baseSizes.stop.h * factor);
        }
      );
    }

    var plannedRenderer = new UniqueValueRenderer(grayStopSymbol, function (stop) {
      if (stop.attributes.type.toLowerCase() === "stop") {
        if (
          stop.attributes.students.length === 0 ||
          stop.attributes.difference === undefined ||
          stop.attributes.noEvents
        )
          return "gray";
        if (Math.abs(stop.attributes.difference) <= 1) return "green";
        if (Math.abs(stop.attributes.difference) <= 5) return "yellow";
        return "red";
      } else if (stop.attributes.type.toLowerCase() === "school") {
        return "school";
      }
      return "depot";
    });
    plannedRenderer.addValue("gray", grayStopSymbol);
    plannedRenderer.addValue("green", greenStopSymbol);
    plannedRenderer.addValue("yellow", yellowStopSymbol);
    plannedRenderer.addValue("red", redStopSymbol);
    plannedRenderer.addValue("blue", blueStopSymbol);
    plannedRenderer.addValue("depot", depotSymbol);
    plannedRenderer.addValue("school", schoolSymbol);

    scp.clearLayers = function () {
      scp.map.graphics.remove(scp.selectedMarker);
      if (scp.plannedRouteLayer) {
        scp.map.removeLayer(scp.plannedRouteLayer);
        scp.plannedRouteLayer = undefined;
      }
      if (scp.historicRouteLayer) {
        scp.map.removeLayer(scp.historicRouteLayer);
        scp.historicRouteLayer = undefined;
      }
      if (scp.routeLayer) {
        scp.map.removeLayer(scp.routeLayer);
        scp.routeLayer = undefined;
      }
    };

    scp.addPlannedLayers = function () {
      if (!scp.routesData.route) {
        return;
      }

      //route polygon layer
      var routeLayer = _.find(scp.routesData.route.directions.layers, function (l) {
        return l.layerDefinition.name === "RouteInfo";
      });
      if (routeLayer) {
        scp.routeLayer = new FeatureLayer(routeLayer);
        scp.routeLayer.setRenderer(routeRenderer);
        scp.routeLayer.setVisibility(!scp.disablePlanned);
        scp.map.addLayer(scp.routeLayer);
        scp.map.reorderLayer(scp.routeLayer, 0);
      }

      // planned stops
      if (scp.routesData.route.routeInfo.stops.length > 0) {
        var stops = scp.routesData.route.routeInfo.stops;

        scp.plannedRouteLayer = new GraphicsLayer();
        scp.plannedRouteLayer.setRenderer(plannedRenderer);
        scp.plannedRouteLayer.setVisibility(!scp.disablePlanned);

        var min = { x: stops[0].geometry.x, y: stops[0].geometry.y };
        var max = { x: stops[0].geometry.x, y: stops[0].geometry.y };
        for (var i = 0; i < stops.length; i++) {
          stops[i].isSelected = false;
          var p = stops[i].geometry;
          if (p.x < min.x) {
            min.x = p.x;
          }
          if (p.x > max.x) {
            max.x = p.x;
          }
          if (p.y < min.y) {
            min.y = p.y;
          }
          if (p.y > max.y) {
            max.y = p.y;
          }

          var point = new Point(stops[i].geometry);
          scp.plannedRouteLayer.add(new Graphic(point, undefined, stops[i]));
        }
        // set initial extent
        var ext = new Extent(min.x, min.y, max.x, max.y, new SpatialReference(stops[0].geometry.spatialReference));
        scp.map.setExtent(ext, true);

        scp.map.addLayer(scp.plannedRouteLayer);
        scp.map.reorderLayer(scp.plannedRouteLayer, 1);
        scp.plannedRouteLayer.on("click", function (ev) {
          scp.highlightStop(ev.graphic.attributes, true);
        });
      }
    };
    scp.addHistoricLayer = function () {
      var info = scp.routesData.historicRoute;
      if (info) {
        scp.historicRouteLayer = new FeatureLayer(info, {
          mode: FeatureLayer.MODE_SNAPSHOT,
        });
        scp.historicRouteLayer.setRenderer(historicRenderer);
        scp.historicRouteLayer.setVisibility(!scp.disableHistoric);
        scp.map.addLayer(scp.historicRouteLayer);

        scp.historicRouteLayer.on("mouse-move", function (evt) {
          var content =
            "<b>" +
            $filter("translate")("monitoring.map.time") +
            ": </b>" +
            scp.formatLocalTimeSeconds(evt.graphic.attributes.eventTimeStamp) +
            "<br>" +
            "<b>" +
            $filter("translate")("monitoring.map.speed") +
            ": </b>" +
            evt.graphic.attributes.speed +
            " " +
            evt.graphic.attributes.distanceUnits +
            "h<br>";
          scp.map.infoWindow.setContent(content);
          scp.map.infoWindow.show(evt.screenPoint, scp.map.getInfoWindowAnchor(evt.screenPoint));
        });
        scp.historicRouteLayer.on("mouse-out", function (evt) {
          scp.map.infoWindow.hide();
        });
      }
    };
  });

  scp.searchResultsOpen = false;
  scp.searchParams = { url: "searchRoutes" };

  scp.datePickerOptions = {
    maxDate: moment(),
  };

  scp.botchTranslate = function (str) {
    return $filter("translate")(str);
  };

  scp.$watch("date", function (newVal, oldVal) {
    if (newVal) {
      scp.searchParams.date = moment.utc(newVal).format(dateFormat);
    } else {
      scp.searchParams.date = "";
    }
    scp.invalidDate = newVal && moment.utc(newVal) > moment.utc();
  });

  scp.toggleResults = function ($event) {
    $event.preventDefault();
    $event.stopPropagation();
    scp.searchResultsOpen = !scp.searchResultsOpen;
    scp.calendarOpened = false;
  };

  scp.searchKeyDown = function (evt) {
    if (evt.keyCode === 13) {
      scp.search(evt);
    }
  };

  scp.search = function ($event) {
    $event.preventDefault();
    $event.stopPropagation();
    scp.searchResults = undefined;
    scp.searchParams.organizationId = scp.organization.id;
    scp.searchParams.isNavigator = scp.organization.eventSource === 'Navigator';
    scp.searchParams.name = undefined;
    scp.searchParams.displayName = undefined;
    scp.searchParams.vehicleId = undefined;
    if (scp.runOrVehicle.name) {
      if (scp.runOrVehicle.name.type == "run") {
        var eventSource = (scp.organization.eventSource || '').toLowerCase();
        var runNameOrId = eventSource === 'navigator'
            ? scp.runOrVehicle.name.name : scp.runOrVehicle.name.id;
        scp.searchParams.name = runNameOrId;
        scp.searchParams.displayName = scp.runOrVehicle.name.text;
      }
      if (scp.runOrVehicle.name.type == "vehicle") {
        scp.searchParams.vehicleId = scp.runOrVehicle.name.id;
      }
    }
    RestService.getArr(
      scp.searchParams,
      function (data) {
        scp.searchResults = [];
        angular.forEach(data, function (r) {
          scp.searchResults.push(r);
        });
        scp.searchResultsOpen = true;
        scp.resultsParams = {
          name: scp.searchParams.displayName,
          date: scp.searchParams.date,
          organizationId: scp.organization.id,
          timezone: scp.organization.fullTimezone,
        };
      },
      function (err) {
        console.console.error(err);
      }
    );
  };

  function addOptions(type, values) {
    _.forEach(values, function (value) {
        value.text = type === "run" ? value.name : value.id;
        scp.optionsAsync.push(Object.assign(value, { type: type }));
    });
  }

  scp.vehicleAndRunGroups = function(item) {
    if (item.type === "run") {
      return $filter("translate")("monitoring.route");
    }

    if (item.type === "vehicle") {
      return $filter("translate")("monitoring.vehicle");
    }
  }

  scp.getOptionsByName = function (query) {
    if (!$scope.organization || !query) {
      return undefined;
    }
    scp.optionsAsync = [];
    Promise.all([
      MonitoringSvc.queryRuns(scp.organization.code, query, addOptions.bind(this, "run")),
      MonitoringSvc.queryVehicles(scp.organization.code, query, addOptions.bind(this, "vehicle")),
    ]);
  };

  scp.selectBus = function (bus) {
    scp.clearLayers();
    scp.bus = bus;
    scp.orgIsNavigator = scp.searchParams.isNavigator;
    scp.routesData = {};
    scp.slider = {};
    scp.searchResultsOpen = false;
    scp.loadingPlanned = true;
    scp.loadingHistoric = true;
    loadRoute(bus);
  };

  function loadRoute(bus) {
    var params = { url: "stopsFromThePlannedRoute", routeId: bus.runGuid, organizationId: bus.organization.code };

    RestService.get(
      params,
      function (data) {
        scp.loadingPlanned = false;
        if (data && data.routeInfo && data.routeInfo.stops) {
          data.routeInfo.stops = _.sortBy(data.routeInfo.stops, "order");
          var searchDate = moment.utc(bus.date);
          data.routeInfo.startTime = moment
            .tz(data.routeInfo.startTime, scp.resultsParams.timezone)
            .year(searchDate.year())
            .month(searchDate.month())
            .date(searchDate.date());
          data.routeInfo.endTime = moment
            .tz(data.routeInfo.endTime, scp.resultsParams.timezone)
            .year(searchDate.year())
            .month(searchDate.month())
            .date(searchDate.date());
          if (data.routeInfo.startTime.isAfter(data.routeInfo.endTime)) {
            data.routeInfo.endTime.add(1, "days");
          }
          setSliderValues(data);
          loadTelemetry(data.routeInfo.startTime, data.routeInfo.endTime, bus);
        } else {
          scp.loadingHistoric = false;
        }
        scp.routesData.route = data;
        loadRouteEvents(bus);
      },
      function (err) {
        scp.loadingPlanned = false;
      }
    );
  }

  function loadTelemetry(startTime, endTime, bus) {
    var params = {
      url: "historicRoute",
      startDate: moment.tz(startTime, scp.resultsParams.timezone).format("YYYY-MM-DD HH:mm"),
      endDate: moment.tz(endTime, scp.resultsParams.timezone).format("YYYY-MM-DD HH:mm"),
      organizationId: bus.organization.code,
      deviceId: bus.deviceId,
    };

    RestService.get(
      params,
      function (data) {
        scp.loadingHistoric = false;
        scp.routesData.historicRoute = data;
        scp.addHistoricLayer();
        scp.slider.options.disabled = false;
      },
      function (err) {
        scp.loadingHistoric = false;
        scp.slider.options.disabled = false;
      }
    );
  }

  function loadRouteEvents(bus) {
    var params = {
      url: "routeEvents",
      date: scp.searchParams.date,
      deviceId: bus.deviceId,
      routeId: bus.runGuid,
      organizationId: scp.resultsParams.organizationId,
    };
    if (!scp.resultsParams.date) {
      params.date = moment.utc(bus.date).format(dateFormat);
    }

    RestService.get(
      params,
      function (data) {
        if (scp.routesData.route.routeInfo) {
          mergeRouteInfoAndEvents(scp.routesData.route.routeInfo, data);
          setStopVariables(scp.routesData.route.routeInfo.stops);
          scp.addPlannedLayers();
        }
      },
      function (err) {
        scp.loadingHistoric = false;
      }
    );
  }

  function setStopVariables(stops) {
    angular.forEach(stops, function (stop) {
      var arrival = stop.actualArrivalTime || stop.realArrivalTime || undefined;
      if (arrival !== undefined && stop.arrivalTime !== undefined) {
        arrival = moment.tz(scp.resultsParams.timezone).startOf("day").format(dateFormat) + arrival.substr(10, 9);
        var newArr = moment.tz(arrival, scp.resultsParams.timezone).valueOf();
        stop.differenceMs = newArr - stop.arrivalTime;
        stop.difference = Math.floor(stop.differenceMs / 60000);
      }
      stop.failedDistance = 0;
      stop.failedTime = 0;
      stop.failedStudent = 0;
      angular.forEach(stop.students, function (student) {
        if (scp.orgIsNavigator) {
          !student.pickUp && !student.dropOff && stop.failedStudent++;
        } else {
          !student.distanceEmail && stop.failedDistance++;
        }
        !student.timeEmail && stop.failedTime++;
      });
      if (stop.students.length === 0) {
        stop.noEvents = true;
      }
    });
  }

  function mergeRouteInfoAndEvents(routeInfo, events) {
    if (!routeInfo || !routeInfo.stops || !events || !events.stops) {
      return;
    }
    for (var i = 0; i < routeInfo.stops.length; i++) {
      var stop = routeInfo.stops[i];
      var eventStop =
        _.find(events.stops, function (s) {
          return s.order === stop.order;
        }) || {};
      stop.noEvents = !eventStop.order;
      _.each(stopVarsToCopy, function (v) {
        stop[v] = eventStop[v];
      });
      _.each(stop.students, function (student) {
        var eventStudent =
          _.find(eventStop.students, function (evtSt) {
            return evtSt.id === student.id;
          }) || {};
        _.each(studentVarsToCopy, function (v) {
          student[v] = eventStudent[v];
        });
        if (scp.orgIsNavigator) {
          student.icon = distanceIcons[~~student.pickUp + (~~student.dropOff << 1)];
        } else {
          student.icon = distanceIcons[0];
        }
      });
    }
  }

  scp.timeDifferenceClass = function (stop) {
    if ((stop.noEvents && stop.students.length === 0) || stop.difference === undefined) return "unavailable";
    if (stop.difference >= -1 && stop.difference <= 1) return "success";
    if (stop.difference >= -5 && stop.difference <= 5) return "warning";
    return "danger";
  };

  scp.timeDifferenceTooltip = function (diff, noEvents) {
    if (noEvents || noEvents === undefined) return $filter("translate")("monitoring.stopsList.noEvents");
    if (diff === 0) return $filter("translate")("monitoring.stopsList.busOnTime");
    if (diff < 0)
      return $filter("translate")("monitoring.stopsList.busEarly", {
        num: -diff,
      });
    if (diff > 0)
      return $filter("translate")("monitoring.stopsList.busLate", {
        num: diff,
      });
    if (scp.orgIsNavigator)
      return $filter("translate")("monitoring.stopsList.noPickUpDropOff");

    return $filter("translate")("monitoring.stopsList.busUnavailable");
  };

  scp.notificationsClass = function (stop) {
    if (stop.students.length > 0) {
      var errors = stop.failedDistance + stop.failedTime + stop.failedStudent;
      return errors > 0 ? "danger" : "";
    }
    return "unavailable";
  };

  scp.notificationsTooltip = function (t, students) {
    if (students.length === 0) return $filter("translate")("monitoring.stopsList.stopWithoutStudents");
    if (t == 0) return $filter("translate")("monitoring.stopsList.okNotifications");
    return $filter("translate")("monitoring.stopsList.failedNotifications", {
      num: t,
    });
  };

  scp.studentTooltip = function (arrivalTime) {
    if (arrivalTime) {
      var time = scp.formatTime(arrivalTime);
      return $filter("translate")("monitoring.stopsList.studentTooltip", {
        time: time,
      });
    }
  };

  scp.togglePlanned = function () {
    if (scp.plannedRouteLayer) {
      scp.plannedRouteLayer.setVisibility(scp.disablePlanned);
    }
    if (scp.routeLayer) {
      scp.routeLayer.setVisibility(scp.disablePlanned);
    }
    if (scp.selectedMarker) {
      scp.disablePlanned ? scp.selectedMarker.show() : scp.selectedMarker.hide();
    }
    scp.disablePlanned = !scp.disablePlanned;
  };

  scp.toggleHistoric = function () {
    if (scp.historicRouteLayer) {
      scp.historicRouteLayer.setVisibility(scp.disableHistoric);
    }
    scp.disableHistoric = !scp.disableHistoric;
  };

  scp.expandStop = function (s, evt) {
    s.isExpanded = s.isExpanded !== true;
  };

  scp.selectStop = function (s, evt) {
    evt.stopPropagation();
    scp.highlightStop(s);
  };

  scp.highlightStop = function (stop, force) {
    if (scp.routesData) {
      angular.forEach(scp.routesData.route.routeInfo.stops, function (s) {
        s.isSelected = s === stop;
        if (s.isSelected) {
          s.isExpanded = true;
          if (force) {
            if ($location.hash() === "listStop" + s.order) {
              $anchorScroll();
            } else {
              $location.hash("listStop" + s.order);
            }
          }
        }
      });
    }

    if (scp.plannedRouteLayer) {
      var mapStop = _.find(scp.plannedRouteLayer.graphics, function (s) {
        return s.attributes === stop;
      });

      if (mapStop) {
        if (scp.map.getZoom() < 14) {
          scp.map.setZoom(14);
        }
        scp.map.centerAt(mapStop.geometry);
        scp.map.graphics.remove(scp.selectedMarker);
        scp.selectedMarker.setGeometry(mapStop.geometry);
        scp.map.graphics.add(scp.selectedMarker);
      }
    }
    if (force) {
      scp.$apply();
    }
  };

  scp.getRoutesByName = function (query) {
    if (!$scope.organization || !query) {
      return undefined;
    }
    return $http
      .get("api/runs/match?organization=" + $scope.organization.code + "&name=" + query + "&active=true")
      .then(
        function (results) {
          $scope.routesAsync = results.data;
        },
        function (err) {
          $scope.routesAsync = [];
        }
      );
  };
  scp.getVehiclesByName = function (query) {
    if (!$scope.organization || !query) {
      return undefined;
    }
    $http.get("api/vehicles/match?organization=" + $scope.organization.code + "&id=" + query + "&active=true").then(
      function (results) {
        $scope.vehiclesAsync = [];
        for (var i = 0; i < results.data.length; i++) {
          var r = results.data[i];
          $scope.vehiclesAsync.push({ id: r.id, name: r.id });
        }
      },
      function (err) {
        $scope.vehiclesAsync = [];
      }
    );
  };

  scp.formatArrivalDiff = function (diff) {
    if (isNaN(diff)) return "";
    var v = Math.abs(diff);
    if (v > 60) return "!!";
    return v;
  };

  scp.formatFailedCount = function (tCount, dCount) {
    var c = tCount + dCount;
    if (c > 99) c = "!!";
    return c;
  };

  scp.formatDateTime = function (d) {
    if (!d) return "--";
    return moment.utc(d).format("MM/DD/YYYY HH:mm:ss");
  };

  scp.formatTime = function (d) {
    if (!d) return "--";
    return moment.utc(d).format("HH:mm");
  };

  scp.formatLocalTime = function (d) {
    if (!d) return "--";
    return moment.tz(d, scp.resultsParams.timezone).format("HH:mm");
  };

  scp.formatTimeSeconds = function (d) {
    if (!d) return "--";
    return moment.utc(d).format("HH:mm:ss");
  };

  scp.formatLocalTimeSeconds = function (d) {
    if (!d) return "--";
    return moment.tz(d, scp.resultsParams.timezone).format("HH:mm:ss");
  };

  scp.formatDate = function (d) {
    if (!d) return "--";
    return moment.utc(d).format("MM/DD/YYYY");
  };

  scp.dateDiff = function (d1, d2) {
    if (!d1 || !d2) return "--";
    return Math.floor(moment(d1).diff(d2) / 60000);
  };

  scp.$on("$destroy", function () {
    scp.map.destroy();
  });

  scp.getStudentsInStop = function (stop) {
    if (!stop.students || stop.students.length === 0) {
      MonitoringSvc.getStudentsInStop(
        stop.tripOrderGuid.replace("-PU", "").replace("-DO", ""),
        scp.bus.deviceId,
        scp.bus.organization.code,
        function (data) {
          stop.students = data;
        },
        function () {
          stop.students = [];
        }
      );
    }
  };

  scp.onEndSlider = function () {
    scp.map.removeLayer(scp.historicRouteLayer);
    scp.historicRouteLayer = undefined;
    scp.slider.options.disabled = true;
    loadTelemetry(scp.slider.minValue, scp.slider.maxValue, scp.bus);
  };

  function setSliderValues(data) {
    var minValue = data.routeInfo.startTime.valueOf() - 900000;
    var maxValue = data.routeInfo.endTime.valueOf() + 900000;
    var oneHourInMilli = 3600000;
    scp.slider = {
      minValue: minValue,
      maxValue: maxValue,
      options: {
        floor: minValue - oneHourInMilli,
        ceil: maxValue + oneHourInMilli,
        translate: scp.formatLocalTime,
        onEnd: scp.onEndSlider,
        hideLimitLabels: true,
      },
    };
  }
}

"use strict";

angular.module("schoolbushubApp").factory("MonitoringSvc", MonitoringSvc);
MonitoringSvc.$inject = ["$http", "$resource"];

function MonitoringSvc($http, $resource) {
  return {
    getRoutesInfo: function (deviceId, runGuid, organizationId, date, successCallback, errorCallback) {
      var url =
        "/api/monitoring?deviceId=" +
        deviceId +
        "&runGuid=" +
        runGuid +
        "&organizationId=" +
        organizationId +
        "&date=" +
        date;
      return $http.get(url).then(
        function (resp) {
          successCallback(resp.data);
        },
        function (error) {
          errorCallback(error.data);
        }
      );
    },
    searchRoutes: function (name, date, successCallback, errorCallback) {
      var url = "api/searchRoutes";
      return $http.get(url).then(
        function (resp) {
          successCallback(resp.data);
        },
        function (error) {
          errorCallback(error.data);
        }
      );
    },
    getStudentsInStop: function (tripOrderGuid, deviceId, organizationId, successCallback, errorCallback) {
      var url =
        "api/studentsInStop?tripOrderGuid=" +
        tripOrderGuid +
        "&deviceId=" +
        deviceId +
        "&organizationId=" +
        organizationId;
      return $http.get(url).then(
        function (resp) {
          successCallback(resp.data);
        },
        function (error) {
          console.log(error);
          errorCallback();
        }
      );
    },
    queryRuns: function (orgId, query, callback) {
      return $http.get("api/runs/match?organization=" + orgId + "&name=" + query + "&active=true").then(
        function (results) {
          callback(results.data);
        },
        function (err) {
          callback([]);
        }
      );
    },
    queryVehicles: function (orgId, query, callback) {
      return $http.get("api/vehicles/match?organization=" + orgId + "&id=" + query + "&active=true").then(
        function (results) {
          callback(results.data);
        },
        function (err) {
          callback([]);
        }
      );
    },
  };
}

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('entity', {
                abstract: true,
                parent: 'site'
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('error', {
                parent: 'site',
                url: '/error',
                data: {
                    authorities: [],
                    pageTitle: 'error.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/error/error.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('error');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accessdenied', {
                parent: 'site',
                url: '/accessdenied',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/error/accessdenied.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('error');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('administration', {
                parent: 'site',
                url: '/administration',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN'],
                    pageTitle: 'School Bus Hub'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/administration/administration.html',
                        controller: 'AdministrationController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('administration');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('AdministrationController', ["$scope", "Principal", function ($scope, Principal) {
        Principal.identity().then(function(account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });
    }]);

MessageFormat.locale.en=function(n){return n===1?"one":"other"}

MessageFormat.locale.fr=function(n){return n===0||n==1?"one":"other"}

MessageFormat.locale.es=function(n){return n===1?"one":"other"}

'use strict';

angular.module('schoolbushubApp')
    .factory('Language', ["$q", "$http", "$translate", "LANGUAGES", function ($q, $http, $translate, LANGUAGES) {
        return {
            getCurrent: function () {
                var deferred = $q.defer();
                var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

                if (angular.isUndefined(language)) {
                    language = 'en';
                }

                deferred.resolve(language);
                return deferred.promise;
            },
            getAll: function () {
                var deferred = $q.defer();
                deferred.resolve(LANGUAGES);
                return deferred.promise;
            },
            updateUserLanguage: function(langKey){
                $http.post('/api/updateUserLanguage?&langKey='+langKey,{});
            }
        };
    }])

/*
 Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 They are written in English to avoid character encoding issues (not a perfect solution)
 */
    .constant('LANGUAGES', [
        'en', 'es'
        // jhipster-needle-006 - JHipster will add new languages here
    ]
);

'use strict';

angular.module('schoolbushubApp')
    .controller('LanguageController', ["$scope", "$translate", "Language", "tmhDynamicLocale", "Principal", function ($scope, $translate, Language, tmhDynamicLocale, Principal) {
        $scope.changeLanguage = function (languageKey) {
            $translate.use(languageKey);
            moment.locale(languageKey);
            tmhDynamicLocale.set(languageKey);
            Principal.identity().then(function(account) {
                Language.updateUserLanguage(languageKey);
                account.langKey = languageKey;
            })
        };

        Language.getAll().then(function (languages) {
            $scope.languages = languages;
        });
    }])
    .filter('findLanguageFromKey', function () {
        return function (lang) {
            return {
                "ca": "Català",
                "da": "Dansk",
                "de": "Deutsch",
                "en": "English",
                "es": "Español",
                "fr": "Français",
                "gl": "Galego",
                "hu": "Magyar",
                "it": "Italiano",
                "ja": "日本語",
                "ko": "한국어",
                "nl": "Nederlands",
                "pl": "Polski",
                "pt-br": "Português (Brasil)",
                "pt-pt": "Português",
                "ro": "Română",
                "ru": "Русский",
                "sv": "Svenska",
                "ta": "தமிழ்",
                "tr": "Türkçe",
                "zh-cn": "中文（简体）",
                "zh-tw": "繁體中文"
            }[lang];
        }
    });

'use strict';

angular.module('schoolbushubApp')
    .factory('AuthServerProvider', ["$http", "localStorageService", "Base64", function loginService($http, localStorageService, Base64) {
        return {
            login: function(credentials) {
                var data = "username=" +  encodeURIComponent(credentials.username) + "&password="
                    + encodeURIComponent(credentials.password) + "&grant_type=password&scope=read%20write&" +
                    "client_secret=mySecretOAuthSecret&client_id=schoolbushubapp";
                return $http.post('oauth/token', data, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept": "application/json",
                        "Authorization": "Basic " + Base64.encode("schoolbushubapp" + ':' + "mySecretOAuthSecret")
                    }
                }).then(function (response) {
                    var expiredAt = new Date();
                    expiredAt.setSeconds(expiredAt.getSeconds() + response.data.expires_in);
                    response.data.expires_at = expiredAt.getTime();
                    localStorageService.set('token', response.data);
                    return response.data;
                });
            },
            logout: function() {
                // logout from the server
                $http.post('api/logout').then(function() {
                    localStorageService.clearAll();
                });
            },
            getToken: function () {
                return localStorageService.get('token');
            },
            hasValidToken: function () {
                var token = this.getToken();
                return token && token.expires_at && token.expires_at > new Date().getTime();
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('organization', {
                parent: 'entity',
                url: '/organizations',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN'],
                    pageTitle: 'schoolbushubApp.organization.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/organization/organizations.html',
                        controller: 'OrganizationController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('organization');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('organization.new', {
                parent: 'organization',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/organization/organization-dialog.html',
                        controller: 'OrganizationDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    apiUser: null,
                                    apiPassword: null,
                                    timezone: null,
                                    organizationGuid: null,
                                    arrivalTimeWindow: null,
                                    id: null,
                                    applicationCode: null
                                };
                            },
                            isNew:true
                        }
                    }).result.then(function(result) {
                        $state.go('organization', null, { reload: true });
                    }, function() {
                        $state.go('organization');
                    })
                }]
            })
            .state('organization.edit', {
                parent: 'organization',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/organization/organization-dialog.html',
                        controller: 'OrganizationDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['Organization', function(Organization) {
                                return Organization.resource.get({id : $stateParams.id});
                            }],
                            isNew:false
                        }
                    }).result.then(function(result) {
                        $state.go('organization', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('organization.delete', {
                parent: 'organization',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/organization/organization-delete-dialog.html',
                        controller: 'OrganizationDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Organization', function(Organization) {
                                return Organization.resource.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('organization', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('OrganizationController', ["$scope", "$state", "$uibModal", "Organization", "ParseLinks", "$filter", "AlertService", function ($scope, $state, $uibModal, Organization, ParseLinks, $filter, AlertService) {

        const HTTP_STATUS = { "OK": 200, "UNAUTHORIZED":401, "NOT_FOUND": 404 };
        $scope.organizations = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.loadAll = function() {
            var pageable = {page: $scope.page, size: $scope.per_page, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            Organization.resource.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.total = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    result[i].sourceApplication = result[i].applicationCode === 'CMP' ? 'Compass' : 'Path';
                    $scope.organizations.push(result[i]);
                }
            }, function(err) {
                AlertService.error($filter('translate')('filters.resultMessages.resultsError'));
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.organizations = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            if ($scope.links && page > $scope.links['last']) { return; };
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.fields = Organization.resource.filterVars();
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.organization = {
                name: null,
                apiUser: null,
                apiPassword: null,
                timezone: null,
                organizationGuid: null,
                arrivalTimeWindow: null,
                id: null
            };
        };

        var syncSchoolsCallback = function(response, organization){
            if (response.data == HTTP_STATUS.OK) {
                AlertService.success($filter('translate')('schoolbushubApp.organization.synchronizedSchools', {
                    organization: organization.code
                }));
            } else if (response.data == HTTP_STATUS.NOT_FOUND) {
                AlertService.error($filter('translate')('schoolbushubApp.organization.organizationHasNoSchools', {
                    organization: organization.code
                }));
            } else if (response.data == HTTP_STATUS.UNAUTHORIZED) {
                AlertService.error($filter('translate')('schoolbushubApp.organization.organizationNotFound', {
                    organization: organization.code
                }));
            } else {
                AlertService.error($filter('translate')('schoolbushubApp.organization.errorSynchronizingSchools', {
                    organization: organization.code
                }));
            }
            $scope.disabledSyncSchools = false;
        }

        $scope.syncSchools = function(organization){
            $scope.disabledSyncSchools = true;
            Organization.synchronizeSchools(organization, syncSchoolsCallback);
        }

        $scope.clearNotifications = function(organization) {
            Organization.clearNotifications(organization, function(response, organization){
                if(response.status == 'success'){
                    AlertService.success($filter('translate')('schoolbushubApp.organization.clearedNotifications', { organization: organization.code }));
                }else{
                    AlertService.error($filter('translate')('schoolbushubApp.organization.errorClearNotifications', { organization: organization.code }));
                }
            });
        }

        $scope.viewOrganizationDetail = function(org) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/entities/organization/organization-detail.html',
                controller: 'OrganizationDetailController',
                size: 'lg',
                resolve: {entity: function() { return org; } }
            });
        }
    }]);

(function () {
  "use strict";

  angular.module("schoolbushubApp").controller("OrganizationDialogController", OrganizationDialogController);

  OrganizationDialogController.$inject = [
    "$scope",
    "$stateParams",
    "$uibModalInstance",
    "$q",
    "$http",
    "entity",
    "Organization",
    "User",
    "School",
    "isNew"
  ];
  function OrganizationDialogController($scope, $stateParams, $uibModalInstance, $q, $http, entity, Organization, User, School, isNew) {
    if (entity.id === null) {
      entity.arrivalTimeWindow = 1200;
    }
    $scope.isNew = isNew;
    $scope.disableRouteNot = false;
    $scope.trueFalseOptions = [true, false];
    Organization.getEventSource(function(resp){
      $scope.eventSourceData=resp.data;
      $scope.nonNavigator = resp.data[1];
      $scope.organization.eventSource= entity.id === null ?  resp.data[0]:$scope.organization.eventSource;
    });
    $scope.organization = entity;

    $scope.users = User.query();
    $scope.schools = School.query();

    // LOCATION
    $scope.existingLocation = null;
    $scope.locationsAsync = [];

    var arcgisUrl = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/';
    var geocodeParams = '?f=json&maxSuggestions=10&category=City%2CSubregion&sourceCountry=CA%2CUSA';
    var candidateParams = '?f=json&outSR=%5Bobject+Object%5D&outFields=City%2CCounty%2CRegion%2CRegionAbbr%2CSubregion%2CCountry%2CMatch_addr&maxLocations=6';


    if ($scope.organization && $scope.organization.$promise) {

      $scope.organization.$promise.then(function () {
        var storedLocation = { text:  $scope.organization.locationName };
        $scope.locationsAsync = [storedLocation];
        $scope.location = storedLocation;
        $scope.orgCode = $scope.organization.code;
      });
    }


    // seon master data load
    $scope.loadingMaster = false;
    $scope.masterLoadError = false;

    var masterProperties = ["endpoint", "endpointUser", "endpointPassword", "timezone", "applicationCode", "streamingUrl"];

    $scope.load = function (id) {
      Organization.resource.get({ id: id }, function (result) {
        $scope.organization = result;
      });
    };

    $scope.timezones = [];

    _.forEach(moment.tz.names(), function (timezone) {
      $scope.timezones.push({
        name: timezone,
        zone: moment.tz(timezone).format("Z")
      });
    });

    $scope.getTimezoneOffset = function (timezone) {
      return moment.tz(timezone).format("Z");
    };

    var onSaveSuccess = function (result) {
      $scope.$emit("schoolbushubApp:organizationUpdate", result);
      $uibModalInstance.close(result);
      $scope.isSaving = false;
    };

    var onSaveError = function (result) {
      $scope.isSaving = false;
    };

    $scope.save = function () {
      $scope.isSaving = true;
      if ($scope.organization.id != null) {
        Organization.resource.update($scope.organization, onSaveSuccess, onSaveError);
      } else {
        Organization.resource.save($scope.organization, onSaveSuccess, onSaveError);
      }
    };

    $scope.loadOrg = function (code, alias, applicationCode) {
      $scope.loadingMaster = true;
      Organization.getOrganizationData(code, alias, applicationCode, function (resp) {
        $scope.loadingMaster = false;
        $scope.masterLoadError = !_.isUndefined(resp.error);
        if (resp.data) {
          $scope.orgCode = $scope.organization.code;
          _.each(masterProperties, function (prop) {
            $scope.organization[prop] = resp.data[prop];
          });
        }
      });
    };

    $scope.clear = function () {
      $uibModalInstance.dismiss("cancel");
    };


    $scope.getLocations = function (query) {
      var deferred = $q.defer();
      if (!query) {
        deferred.resolve();
        return deferred.promise;
      }

      var req = new XMLHttpRequest();
      req.open('GET', arcgisUrl + 'suggest' + geocodeParams + '&text=' + query, true);
      req.onreadystatechange = function () {

        if (req.readyState === 4) {
          if (req.status === 200) {
            $scope.locationsAsync = JSON.parse(req.responseText).suggestions;
          } else {
            $scope.locationsAsync = [];
          }
          $scope.$apply();
          deferred.resolve();
        }
      }
      req.send(null);

      return deferred.promise;
    };


    $scope.locationSelected = function (item) {
      if (!item) {
        $scope.organization.location = undefined;
        $scope.organization.locationName = undefined;
        return;
      }

      $scope.existingLocation = null;

      var req = new XMLHttpRequest();
      req.open('GET', arcgisUrl + 'findAddressCandidates' + candidateParams + '&magicKey=' + item.magicKey, true);
      req.onreadystatechange = function () {

        if (req.readyState === 4) {
          if (req.status === 200) {
            var result = JSON.parse(req.responseText);
            var match = result && result.candidates && result.candidates[0] || {};
            var sr = result && result.spatialReference;
            $scope.organization.state = match.attributes.Region;
            $scope.organization.city = match.attributes.City;
            $scope.organization.county = match.attributes.Subregion;
            $scope.organization.locationName = match.address;

            if (match.extent && match.location) {
              match.location.spatialReference = sr;
              match.extent.spatialReference = sr;
            }

            $scope.organization.extent = JSON.stringify(match.extent);
            $scope.organization.location = JSON.stringify(match.location);

          } else {
            $scope.organization.state = undefined;
            $scope.organization.city = undefined;
            $scope.organization.county = undefined;
            $scope.organization.locationName = undefined;
            $scope.organization.extent = undefined;
            $scope.organization.location = undefined;

            $scope.location = undefined;
          }
          $scope.$apply();
        }
      }
      req.send(null);
    };

    $scope.cleanOrganizationCode = function () {
      $scope.orgCode = undefined;
      $scope.organization.code= '';
    }
  }

})();

'use strict';

angular.module('schoolbushubApp')
	.controller('OrganizationDeleteController', ["$scope", "$uibModalInstance", "entity", "Organization", "$filter", "AlertService", function($scope, $uibModalInstance, entity, Organization, $filter, AlertService) {

        $scope.organization = entity;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Organization.removeOrganization(id, function(response, error){
                if(response.status == 'success'){
                    $uibModalInstance.close(true);
                    $scope.clear();
                }else{
                    $uibModalInstance.dismiss('cancel');
                    if(error.numberOfStudents){
                        AlertService.error($filter('translate')('schoolbushubApp.organization.organizationPartnerStudents', { number: error.numberOfStudents }));
                    }else{
                        if(error.numberOfSchools){
                            AlertService.error($filter('translate')('schoolbushubApp.organization.organizationPartnerSchools', { number: error.numberOfSchools }));
                        }else{
                            if(error.numberOfUsers){
                                AlertService.error($filter('translate')('schoolbushubApp.organization.organizationPartnerUsers', { number: error.numberOfUsers }));
                            }else{
                                AlertService.error($filter('translate')('schoolbushubApp.organization.errorDelete'));
                            }
                        }
                    }
                }
            });
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('OrganizationDetailController', ["$scope", "$rootScope", "$uibModalInstance", "entity", "Organization", function ($scope, $rootScope, $uibModalInstance, entity, Organization) {
        $scope.organization = entity;
        $scope.sourceApplication = $scope.organization.applicationCode === 'CMP' ? 'Compass' : 'Path';
        $scope.load = function (id) {
            Organization.resource.get({id: id}, function(result) {
                $scope.organization = result;
            });
        };

        var unsubscribe = $rootScope.$on('schoolbushubApp:organizationUpdate', function(event, result) {
            $scope.organization = result;
        });

        $scope.close = function() {
            unsubscribe();
            $uibModalInstance.dismiss('ok');
        };

        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('Organization', ["$resource", "$http", "Principal", "AlertService", function ($resource, $http, Principal, AlertService) {

        var synchronizeSchools = function (organization, callback) {
            var url = 'api/organizations/synchronizeSchools';
            $http.post(url, organization.id).then(function success(response) {
                callback({
                    data: response.status
                }, organization);
            }, function error(response) {
                callback({
                    data: response.status
                }, organization);
            });
        }

        var removeOrganization = function (organizationId, callback) {
            var url = 'api/organizations/remove';
            $http.post(url, organizationId)
                .then(function () {
                    callback({
                        status: 'success'
                    });
                }).catch(function (error) {
                    callback({
                        status: 'error'
                    }, error);
                });
        }

        var clearNotifications = function (organization, callback) {
            var url = 'api/organizations/clearNotifications';
            $http.post(url, organization)
                .then(function () {
                    callback({
                        status: 'success'
                    }, organization);
                }).catch(function () {
                    callback({
                        status: 'error'
                    }, organization);
                });
        }

        var getOrganizationData = function (code, alias, applicationCode, callback) {
            var url = 'api/organizations/verify/' + code;
            var params = {'alias':alias, 'applicationCode':applicationCode};
            $http.get(url, {params:params}).then(function success(response) {
                callback({ data: response.data });
            }, function error(response) {
                callback({ error: response.data });
            });
        }

        var getEventSource = function (callback) {
            var url = 'api/organizations/eventSource';
            $http.get(url).then(function success(response) {
                callback({ data: response.data });
            }, function error(response) {
                callback({ error: response.data });
            });
        }

        var resource = $resource('api/organizations/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'filterQuery': {
                method: 'POST',
                url: 'api/organizations/query',
                isArray: true
            },
            'filterVars': {
                method: 'GET',
                url: 'api/organizations/info',
                isArray: true
            },
            'update': {
                method: 'PUT'
            }
        });

        var getRoleOrganizations = function () {
            return new Promise(function (resolve) {
                Principal.identity().then(function (account) {
                    if (account) {
                        var roles = _.filter(account.authorities, function (rol) {
                            return rol == 'ROLE_CUSTOMERADMIN';
                        });
                        if (roles.length == 0) {
                            resource.filterQuery( {"pageable":{"page":0,"size":1000,"sort":["id,asc","id"]}},
                                function (result) {
                                    return resolve({
                                        isCustomerAdmin: false,
                                        organizations: result.length > 0 ? _.map(result, function (o) {
                                            return o;
                                        }) : []
                                    })
                                },
                                function (err) {
                                    AlertService.error('Error getting organizations');
                                }
                            );
                        } else {
                            return resolve({
                                isCustomerAdmin: true,
                                organization: account.organization,
                                organizations: []
                            });
                        }
                    }
                });
            });
        }

        return {
            resource: resource,
            synchronizeSchools: synchronizeSchools,
            removeOrganization: removeOrganization,
            clearNotifications: clearNotifications,
            getOrganizationData: getOrganizationData,
            getRoleOrganizations: getRoleOrganizations,
            getEventSource: getEventSource
        }
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('school', {
                parent: 'entity',
                url: '/schools',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN'],
                    pageTitle: 'schoolbushubApp.school.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/school/schools.html',
                        controller: 'SchoolController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('school');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('organization');
                        return $translate.refresh();
                    }]
                }
            })
            // .state('school.detail', {
            //     parent: 'entity',
            //     url: '/school/{id}',
            //     data: {
            //         authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN'],
            //         pageTitle: 'schoolbushubApp.school.detail.title'
            //     },
            //     views: {
            //         'content@': {
            //             templateUrl: 'scripts/app/entities/school/school-detail.html',
            //             controller: 'SchoolDetailController'
            //         }
            //     },
            //     resolve: {
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('school');
            //             return $translate.refresh();
            //         }],
            //         entity: ['$stateParams', 'School', function($stateParams, School) {
            //             return School.get({id : $stateParams.id});
            //         }]
            //     }
            // })
            .state('school.new', {
                parent: 'school',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/school/school-dialog.html',
                        controller: 'SchoolDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    code: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('school', null, { reload: true });
                    }, function() {
                        $state.go('school');
                    })
                }]
            })
            .state('school.edit', {
                parent: 'school',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/school/school-dialog.html',
                        controller: 'SchoolDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['School', function(School) {
                                return School.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('school', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('school.delete', {
                parent: 'school',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/school/school-delete-dialog.html',
                        controller: 'SchoolDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['School', function(School) {
                                return School.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('school', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('SchoolController', ["$scope", "$filter", "AlertService", "$state", "$uibModal", "School", "ParseLinks", function ($scope,  $filter, AlertService, $state, $uibModal, School, ParseLinks) {

        $scope.schools = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.loadAll = function() {
            var pageable = {page: $scope.page, size: $scope.per_page, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            School.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.total = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.schools.push(result[i]);
                }
            }, function(err) {
                AlertService.error($filter('translate')('filters.resultMessages.resultsError'));
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.schools = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            if ($scope.links && page > $scope.links['last']) { return; };
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.fields = School.filterVars();
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.school = {
                name: null,
                code: null,
                id: null
            };
        };

        $scope.viewOrganizationDetail = function(org) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/entities/organization/organization-detail.html',
                controller: 'OrganizationDetailController',
                size: 'lg',
                resolve: {entity: function() { return org; } }
            });
        }

        $scope.viewSchoolDetail = function(schl) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/entities/school/school-detail.html',
                controller: 'SchoolDetailController',
                size: 'lg',
                resolve: {entity: function() { return schl; } }
            });
        }
    }]);

'use strict';

angular.module('schoolbushubApp').controller('SchoolDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'School', 'Organization',
        function($scope, $stateParams, $uibModalInstance, entity, School, Organization) {

        $scope.school = entity;
        $scope.organizations = Organization.resource.query();
        $scope.load = function(id) {
            School.get({id : id}, function(result) {
                $scope.school = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('schoolbushubApp:schoolUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.school.id != null) {
                School.update($scope.school, onSaveSuccess, onSaveError);
            } else {
                School.save($scope.school, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}]);

'use strict';

angular.module('schoolbushubApp')
	.controller('SchoolDeleteController', ["$scope", "$uibModalInstance", "entity", "School", function($scope, $uibModalInstance, entity, School) {

        $scope.school = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            School.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('SchoolDetailController', ["$scope", "$rootScope", "$stateParams", "$uibModalInstance", "entity", "School", "Organization", function ($scope, $rootScope, $stateParams, $uibModalInstance, entity, School, Organization) {
        $scope.school = entity;
        $scope.load = function (id) {
            School.get({id: id}, function(result) {
                $scope.school = result;
            });
        };

        var unsubscribe = $rootScope.$on('schoolbushubApp:schoolUpdate', function(event, result) {
            $scope.school = result;
        });

        $scope.close = function() {
            unsubscribe();
            $uibModalInstance.dismiss('ok');
        };

        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('School', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/schools/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'filterQuery': { method: 'POST', url: 'api/schools/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/schools/info', isArray:true },
            'update': { method:'PUT' }
        });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('student', {
                parent: 'entity',
                url: '/students',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN'],
                    pageTitle: 'schoolbushubApp.student.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/student/students.html',
                        controller: 'StudentController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('student');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('student.new', {
                parent: 'student',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/student/student-dialog.html',
                        controller: 'StudentDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    firstName: null,
                                    middleName: null,
                                    lastName: null,
                                    schoolName: null,
                                    studentId: null,
                                    avatar: null,
                                    schoolCode: null,
                                    dateOfBirth: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('student', null, { reload: true });
                    }, function() {
                        $state.go('student');
                    })
                }]
            })
            .state('student.edit', {
                parent: 'student',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/student/student-dialog.html',
                        controller: 'StudentDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['Student', function(Student) {
                                return Student.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('student', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('student.delete', {
                parent: 'student',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/student/student-delete-dialog.html',
                        controller: 'StudentDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Student', function(Student) {
                                return Student.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('student', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('StudentController', ["$scope", "$filter", "AlertService", "$state", "$uibModal", "Student", "ParseLinks", function ($scope,  $filter, AlertService, $state, $uibModal, Student, ParseLinks) {

        $scope.students = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.loadAll = function() {
            var pageable = {page: $scope.page, size: $scope.per_page, sort: [$scope.predicate + ','
            + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            Student.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.total = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.students.push(result[i]);
                }
            }, function(err) {
                AlertService.error($filter('translate')('filters.resultMessages.resultsError'));
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.students = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            if ($scope.links && page > $scope.links['last']) { return; };
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.fields = Student.filterVars();
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.student = {
                firstName: null,
                middleName: null,
                lastName: null,
                schoolName: null,
                studentId: null,
                avatar: null,
                schoolCode: null,
                dateOfBirth: null,
                id: null
            };
        };

        $scope.viewStudentDetail = function(stdt) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/entities/student/student-detail.html',
                controller: 'StudentDetailController',
                size: 'lg',
                resolve: {entity: function() { return stdt; } }
            });
        }

        $scope.formatDoB = function(d) {
            return moment.utc(d).format('MM/DD/YYYY');
        }
    }]);

'use strict';

angular.module('schoolbushubApp').controller('StudentDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Student', 'User', '$filter',
        function($scope, $stateParams, $uibModalInstance, entity, Student, User, $filter) {

        $scope.student = entity;
        $scope.users = User.query();
        $scope.load = function(id) {
            Student.get({id : id}, function(result) {
                $scope.student = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('schoolbushubApp:studentUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function () {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            $scope.student.dateOfBirth =  $filter('date')($scope.student.dateOfBirth, "yyyy-MM-dd");
            if ($scope.student.id != null) {
                Student.update($scope.student, onSaveSuccess, onSaveError);
            } else {
                Student.save($scope.student, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.datePickerForDateOfBirth = {};

        $scope.datePickerForDateOfBirth.status = {
            opened: false
        };

        $scope.datePickerForDateOfBirthOpen = function($event) {
            $scope.datePickerForDateOfBirth.status.opened = true;
        };
}]);

'use strict';

angular.module('schoolbushubApp')
	.controller('StudentDeleteController', ["$scope", "$uibModalInstance", "$translate", "entity", "Student", function($scope, $uibModalInstance, $translate, entity, Student) {

       $scope.student = entity;

        entity.$promise.then(function(stu)  {
          var studentName = [stu.firstName, stu.middleName, stu.lastName].join(' ');
          $scope.dialogText = $translate.instant("schoolbushubApp.student.delete.question", { fullName: studentName });
        });
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Student.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('StudentDetailController', ["$scope", "$rootScope", "$stateParams", "$uibModalInstance", "entity", "Student", "User", function ($scope, $rootScope, $stateParams, $uibModalInstance, entity, Student, User) {
        $scope.student = entity;
        $scope.load = function (id) {
            Student.get({id: id}, function(result) {
                $scope.student = result;
            });
        };

        var unsubscribe = $rootScope.$on('schoolbushubApp:studentUpdate', function(event, result) {
            $scope.student = result;
        });

        $scope.close = function() {
            unsubscribe();
            $uibModalInstance.dismiss('ok');
        };

        $scope.$on('$destroy', unsubscribe);

        $scope.formatDoB = function(d) {
            return moment.utc(d).format('MM/DD/YYYY');
        }

    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('Student', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/students/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateOfBirth = DateUtils.convertLocaleDateFromServer(data.dateOfBirth);
                    return data;
                }
            },
            'filterQuery': { method: 'POST', url: 'api/students/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/students/info', isArray:true },
            'update': { method:'PUT' }
        });
    }]);

'use strict';

angular.module('schoolbushubApp')
.config(["$stateProvider", function ($stateProvider) {
    $stateProvider
    .state('notificationAudit', {
        parent:'reports',
        url: '/notificationAudits',
        data: {
            authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
        },
        views: {
            'content@': {
                templateUrl: 'scripts/app/reports/notificationAudit/notificationAudits.html',
                controller: 'NotificationAuditController'
            }
        },
        resolve: {
            mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                $translatePartialLoader.addPart('reports');
                return $translate.refresh();
            }]
        }
    })
}]);


'use strict';

angular.module('schoolbushubApp')
    .controller('NotificationAuditController', ["$scope", "$state", "$filter", "$sce", "$uibModal", "$translate", "AlertService", "ReportsService", "NotificationAudit", "ParseLinks", "Principal", "Organization", "DateUtils", function ($scope, $state, $filter, $sce, $uibModal, $translate,
        AlertService, ReportsService, NotificationAudit, ParseLinks, Principal, Organization, DateUtils) {

        /**
        * Generate Report Callback
        *
        * @param response
        */
        var loadPageCallback = function (response) {
            if (response.status == 'success') {
                $scope.loadPage(response.auditResponse);
            } else {
                AlertService.error($filter('translate')('reports.notificationAudit.errorToGenerateTheReport'));
            }
            $scope.busy = false;
        };

        /**
         * Generate Report Callback
         *
         * @param response
         */
        var generateReportCallback = function (response) {
            if (response.status == 'success') {
                $scope.loadPage(response.auditResponse);

                if ($scope.notificationAudits.length > 0) {
                    AlertService.success($filter('translate')('reports.notificationAudit.reportGeneratedSuccessfully'));
                } else {
                    $scope.lastPage = true;
                    AlertService.warning($filter('translate')('reports.notificationAudit.noResultsWereFound'));
                }
            } else {
                AlertService.error($filter('translate')('reports.notificationAudit.errorToGenerateTheReport'));
            }
            $scope.busy = false;
        };

        $scope.formatDate = $filter('translate')('reports.formatDate');
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.lastPage = false;
        $scope.busy = false;

        $scope.organizations = [];

        $scope.messageTypes = [
            { value: 'SYSTEM', translateStr: 'reports.notificationAudit.system' },
            { value: 'DISTANCE', translateStr: 'reports.notificationAudit.stop' },
            { value: 'TIME', translateStr: 'reports.notificationAudit.time' },
            { value: 'BULK', translateStr: 'reports.notificationAudit.massive' },
            { value: 'PICK_UP', translateStr: 'reports.notificationAudit.pickUp' },
            { value: 'DROP_OFF', translateStr: 'reports.notificationAudit.dropOff' }
        ];

        $scope.notificationTypes = [
            { value: 'EMAIL', translateStr: 'reports.notificationAudit.email' },
            { value: 'PUSH', translateStr: 'reports.notificationAudit.push' }
        ];

        $scope.isCustomerAdmin = false;
        Organization.getRoleOrganizations().then(function (result) {
            $scope.organizations = result.organizations;
            $scope.isCustomerAdmin = result.isCustomerAdmin;
            $scope.organization = result.organization;
        });

        //calendar stuff
        $scope.altInputFormats = ['M!/d!/yyyy'];
        $scope.format = 'MM/dd/yyyy';
        $scope.startDateCalendarOpened = false;
        $scope.endDateCalendarOpened = false;
        $scope.startDatepickerOpts = {
            maxDate: new Date()
        };
        $scope.endDatepickerOpts = {
            maxDate: new Date()
        };

        $scope.toggleCalendar = function (cal) {
            if (cal === 'start') {
                $scope.startDateCalendarOpened = !$scope.startDateCalendarOpened;
            } else if (cal === 'end') {
                $scope.endDateCalendarOpened = !$scope.endDateCalendarOpened;
            }
        }
        $scope.calendarChanged = function () {
            $scope.startDatepickerOpts.maxDate = $scope.endDateCtrl || new Date();
            $scope.endDatepickerOpts.minDate = $scope.startDateCtrl;

            $scope.notificationAuditRequest.startDate = undefined;
            if ($scope.startDateCtrl) {
                $scope.notificationAuditRequest.startDate = moment($scope.startDateCtrl).format("YYYY-MM-DD[T00:00:00Z]");
            }

            $scope.notificationAuditRequest.endDate = undefined;
            if ($scope.endDateCtrl) {
                $scope.notificationAuditRequest.endDate = moment($scope.endDateCtrl).format("YYYY-MM-DD[T23:59:59Z]");
            }
        }

        //actions stuff
        $scope.formIsEmpty = function () {
            return (
                !$scope.notificationAuditRequest.username &&
                !$scope.notificationAuditRequest.recipient &&
                !$scope.notificationAuditRequest.studentId &&
                !$scope.notificationAuditRequest.startDate &&
                !$scope.notificationAuditRequest.endDate &&
                !$scope.notificationAuditRequest.messageTypes &&
                !$scope.notificationAuditRequest.types
            );
        }

        $scope.clean = function () {
            $scope.notificationAudits = [];
            $scope.startDateCtrl = undefined;
            $scope.endDateCtrl = undefined;
            $scope.notificationAuditRequest = {
                username: "",
                studentId: "",
                messageType: "",
                type: "",
                recipient: "",
                browserTimezone: moment.tz.guess()
            };
        }

        $scope.clean();


        /**
        * Generate Report
        */
        $scope.generateReport = function () {
            $scope.notificationAudits = [];
            $scope.page = 1;
            $scope.lastPage = false;
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.busy = true;
            ReportsService.getNotificationsReport($scope.notificationAuditRequest, $scope.organization, $scope.page, $scope.per_page, generateReportCallback);
            $scope.page++;
        };

        /**
        * Generate Report
        */
        $scope.loadPageScroll = function () {
            if ($scope.page < 1 || $scope.lastPage) {
                return;
            };

            $scope.busy = true;
            ReportsService.getNotificationsReport($scope.notificationAuditRequest, $scope.organization, $scope.page, $scope.per_page, loadPageCallback);
            $scope.page++;
        };

        $scope.loadPage = function(page) {
            if (page && page.length > 0) {
                $scope.notificationAudits = $scope.notificationAudits.concat(page);
                if (page.length < $scope.per_page) {
                    $scope.lastPage = true;
                }
            } else {
                $scope.lastPage = true;
            }
        }

        /**
         * convert To UTC
         *
         * @param dt
         * @returns {Date}
         */
        $scope.convertToUTC = function (dt) {
            var localDate = new Date(dt);
            var localTime = localDate.getTime();
            var localOffset = localDate.getTimezoneOffset() * 60000;
            return new Date(localTime + localOffset);
        };

        $scope.showBody = function (audit, timezone) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/reports/notificationAudit/_mailBodyModal.html',
                controller: 'MailBodyModalCtrl',
                size: 'lg',
                resolve: {
                    audit: function () {
                        return audit;
                    },
                    timezone: function () {
                       return timezone;
                    }
                }
            });
        };

        $scope.showVehicleAudit = function (audit) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/reports/notificationAudit/_vehicleAuditModal.html',
                controller: 'VehicleAuditModalCtrl',
                size: 'lg',
                resolve: {
                    info: function () {
                        return audit;
                    }
                }
            });
        };

        $scope.showErrorDetails = function (audit) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/reports/notificationAudit/_errorsDetailModal.html',
                controller: 'ErrorsDetailModalCtrl',
                size: 'lg',
                resolve: {
                    audit: function () {
                        return audit;
                    }
                }
            });
        };

        $scope.formatTime = function (d) {
            return DateUtils.dateTimeFormat(d);
        }
    }]);

angular.module('schoolbushubApp').controller('MailBodyModalCtrl', ["$scope", "$uibModalInstance", "audit", "timezone", "$sce", function ($scope, $uibModalInstance, audit, timezone, $sce) {
    $scope.audit = audit;
    $scope.html = $sce.trustAsHtml(audit.messageBody);
    $scope.date = moment.utc($scope.audit.date).tz(timezone).format('LLLL');

    $scope.clear = function () {
        $uibModalInstance.dismiss('ok');
    };
}]);

angular.module('schoolbushubApp').controller('VehicleAuditModalCtrl', ["$scope", "$uibModalInstance", "info", "$sce", function ($scope, $uibModalInstance, info, $sce) {
    $scope.vehicleAudit = info;
    $scope.vehicleAudit.rawMessage = JSON.parse($scope.vehicleAudit.rawMessage);
    $scope.vehicleAudit.rawMessage = JSON.stringify($scope.vehicleAudit.rawMessage, undefined, 2);

    $scope.clear = function () {
        $uibModalInstance.dismiss('ok');
    };
}]);

angular.module('schoolbushubApp').controller('ErrorsDetailModalCtrl', ["$scope", "$uibModalInstance", "audit", "$sce", function ($scope, $uibModalInstance, audit, $sce) {
    $scope.audit = audit;
    $scope.date = moment($scope.audit.date).format('LLLL');

    $scope.clear = function () {
        $uibModalInstance.dismiss('ok');
    };
}]);

'use strict';

angular.module('schoolbushubApp').controller('NotificationAuditDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'NotificationAudit', 'VehicleAudit',
        function($scope, $stateParams, $uibModalInstance, entity, NotificationAudit, VehicleAudit) {

        $scope.notificationAudit = entity;
        $scope.vehicleaudits = VehicleAudit.query();
        $scope.load = function(id) {
            NotificationAudit.get({id : id}, function(result) {
                $scope.notificationAudit = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('schoolbushubApp:notificationAuditUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.notificationAudit.id != null) {
                NotificationAudit.update($scope.notificationAudit, onSaveSuccess, onSaveError);
            } else {
                NotificationAudit.save($scope.notificationAudit, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.datePickerForDate = {};

        $scope.datePickerForDate.status = {
            opened: false
        };

        $scope.datePickerForDateOpen = function($event) {
            $scope.datePickerForDate.status.opened = true;
        };
}]);

'use strict';

angular.module('schoolbushubApp')
	.controller('NotificationAuditDeleteController', ["$scope", "$uibModalInstance", "entity", "NotificationAudit", function($scope, $uibModalInstance, entity, NotificationAudit) {

        $scope.notificationAudit = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            NotificationAudit.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('NotificationAuditDetailController', ["$scope", "$rootScope", "$stateParams", "entity", "NotificationAudit", "VehicleAudit", function ($scope, $rootScope, $stateParams, entity, NotificationAudit, VehicleAudit) {
        $scope.notificationAudit = entity;
        $scope.load = function (id) {
            NotificationAudit.get({id: id}, function(result) {
                $scope.notificationAudit = result;
            });
        };
        var unsubscribe = $rootScope.$on('schoolbushubApp:notificationAuditUpdate', function(event, result) {
            $scope.notificationAudit = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('NotificationAudit', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/notificationAudits/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.date = DateUtils.convertDateTimeFromServer(data.date);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }]);

(function () {
    'use strict';

    angular.module('schoolbushubApp').config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('routeModification', {
                parent: 'reports',
                url: '/routeModification',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/routeModification/routeModification.html',
                        controller: 'RouteModificationController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        $translatePartialLoader.addPart('routeModification');
                        return $translate.refresh();
                    }]
                }
            })
            .state('routeModification.cancel', {
                parent: 'routeModification',
                url: '/{fleetId}/{id}/cancel',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/admin/routeModification/cancelRouteModal.html',
                        controller: 'CancelRouteController',
                        size: 'md',
                        resolve: {
                            entity: ['RouteModification', function (RouteModification) {
                                return RouteModification.get({ runId: $stateParams.id, fleetId: $stateParams.fleetId});
                            }],
                            cancelled: function() { return false }
                        }
                    }).result.then(function (result) {
                        $state.go('routeModification');
                    }, function (err) {
                        $state.go('^');
                    })
                }]
            })
            .state('routeModification.restore', {
                parent: 'routeModification',
                url: '/{fleetId}/{id}/restore',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/admin/routeModification/cancelRouteModal.html',
                        controller: 'CancelRouteController',
                        size: 'md',
                        resolve: {
                            entity: ['RouteModification', function (RouteModification) {
                                return RouteModification.get({ runId: $stateParams.id, fleetId: $stateParams.fleetId });
                            }],
                            cancelled: function() { return true }
                        }
                    }).result.then(function (result) {
                        $state.go('routeModification');
                    }, function (err) {
                        $state.go('^');
                    })
                }]
            })
            .state('routeModification.edit', {
                parent: 'routeModification',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/admin/routeModification/editRouteModal.html',
                        controller: 'EditRouteController',
                        size: 'md',
                        resolve: {
                            entity: ['RouteModification', function (RouteModification) {
                                return RouteModification.get({ id: $stateParams.id });
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('routeModification', null, { reload: true });
                    }, function (err) {
                        $state.go('^');
                    })
                }]
            });
    }]);

})();

(function () {
    'use strict';

    angular.module('schoolbushubApp').controller('RouteModificationController', RouteModificationController);

    RouteModificationController.$inject = [
        "$scope", "$filter", "AlertService", "RouteModification", "DateUtils", "Organization", "$http", "ParseLinks"
    ];


    function RouteModificationController($scope, $filter, AlertService, RouteModification, DateUtils, Organization, $http, ParseLinks) {
        $scope.routes = [];
        $scope.total = 0;

        // pagination
        $scope.page = 0;
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.per_page = $scope.infiniteScrollPageSize + 4;

        $scope.date = null;
        $scope.dateCtrlOpened = false;
        $scope.startDatepickerOpts = {
            minDate: new Date()
        };
        $scope.altInputFormats = ['M!/d!/yyyy'];
        $scope.format = 'MM/dd/yyyy';

        // filters options
        $scope.routesAsync = [];
        $scope.vehiclesAsync = [];
        $scope.schoolsAsync = [];


        $scope.searchParams = {
            route: undefined,
            vehicle: undefined,
            school: undefined,
            date: new Date()
        };

        $scope.allRoutesSelected = false;

        Organization.getRoleOrganizations().then(function (result) {
            $scope.organizations = result.organizations;
            $scope.isCustomerAdmin = result.isCustomerAdmin;
            $scope.organization = result.organization;
        });



        $scope.predicate = 'id';

        $scope.executeSearch = function (page) {
            if (!$scope.organization || (!$scope.searchParams.vehicle && !$scope.searchParams.route && !$scope.searchParams.school)) {
              return;
            }
            if ($scope.links && page > $scope.links['last']) {
              return;
            };

            if (page === 0) {
              $scope.routes.length = 0;
              $scope.per_page = $scope.infiniteScrollPageSize + 4;
            }
            $scope.page = page;

            var searchPars = {fleetId: $scope.organization.code};
            searchPars.active = true;
            if ($scope.searchParams.route) {
                searchPars.runId = $scope.searchParams.route.id;
            }
            if ($scope.searchParams.vehicle) {
                searchPars.vehicleId = $scope.searchParams.vehicle.id;
            }
            if ($scope.searchParams.school) {
                searchPars.schoolId = $scope.searchParams.school.guid;
            }
            if ($scope.searchParams.date) {
                searchPars.date = $scope.searchParams.date.valueOf();
            }

            searchPars.page = $scope.page;
            searchPars.size = $scope.per_page;


            RouteModification.query(searchPars, function (result, headers) {
                if (result.length < 1) {
                    $scope.total = 0;
                    AlertService.warning($filter('translate')('reports.notificationAudit.noResultsWereFound'));
                    return;
                }
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.total = headers('x-total-count');
                 for (var i = 0; i < result.length; i++) {
                    var r = result[i];
                    r.status = calculateRouteStatus(r);
                    r.cancellable = !r.cancelled && !r.inProgress && !r.completed && r.upcoming;
                    $scope.routes.push(r);
                }
                $scope.date = new Date();
                $scope.selectedOrg = $scope.organization;
            }, function (err) {
                AlertService.error($filter('translate')('filters.resultMessages.resultsError'));
            });
        };

        function calculateRouteStatus(route) {
            if (route.inProgress) return 'inProgress';
            if (route.completed) return 'completed';
            if (route.cancelled) return 'cancelled';
            return 'upcoming';
        }


        $scope.reset = function sortBy() {
            $scope.routes = [];
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.page = 0;
            $scope.total = 0;
        };

        $scope.timeFormat = function (time) {
            return DateUtils.timeFormatWithTz(time, $scope.selectedOrg.fullTimezone);
        };

        $scope.clean = function() {
            $scope.selectedRoute = undefined;
            $scope.vehicle = undefined;
            $scope.routes = [];
            $scope.total = 0;
            clearSearchParameters();
        };

        function clearSearchParameters(){
            $scope.searchParams = {
                route: undefined,
                vehicle: undefined,
                school: undefined,
                date: new Date()
            };
        }

         $scope.clearFilters = function() {
            clearSearchParameters();
            $scope.schoolsAsync = [];
            $scope.vehiclesAsync = [];
            $scope.routesAsync = [];
        };

        // filter queries
        $scope.getRoutesByName = function(query) {
            if (!$scope.organization || !query) {
                return undefined;
            }
            return $http.get('api/runs/match?organization=' + $scope.organization.code + '&name=' + query
            + '&active=true').then(function (results) {
                $scope.routesAsync = results.data;
            }, function (err) {
                $scope.routesAsync = [];
            });
        };
        $scope.getVehiclesByName = function(query) {
            if (!$scope.organization || !query) {
                return undefined;
            }
            $http.get('api/vehicles/match?organization=' + $scope.organization.code + '&id=' + query
            + '&active=true').then(function (results) {
                $scope.vehiclesAsync = [];
                for(var i = 0; i < results.data.length; i++) {
                    var r = results.data[i];
                    $scope.vehiclesAsync.push({ id: r.id, name: r.id });
                }
            }, function (err) {
                $scope.vehiclesAsync = [];
            });
        };
        $scope.getSchoolsByName = function(query) {
            if (!$scope.organization || !query) {
                return undefined;
            }
            $http.get('api/schools/match?organization=' + $scope.organization.id + '&schoolName=' + query)
            .then(function (results) {
                $scope.schoolsAsync = results.data;
            }, function (err) {
                $scope.schoolsAsync = [];
            });
        };
    }
})();

(function() {
    'use strict';
    'use strict';

    angular.module('schoolbushubApp').controller('CancelRouteController', CancelRouteController);

    CancelRouteController.$inject = [
        "$scope", "$uibModalInstance", "$stateParams", "entity", "cancelled", "RouteModification", "AlertService", "$filter"
    ];

    function CancelRouteController($scope, $uibModalInstance, $stateParams, entity, cancelled, RouteModification, AlertService, $filter) {
        $scope.route = entity;
        $scope.cancelled = cancelled;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.submitDisabled = true;

        entity.$promise.then(function() {
            $scope.submitDisabled = false;
        });

        $scope.confirmAction = function () {
            delete $scope.route.selected;
            var method, trans;

            if ($scope.route.cancelled) {
                method = RouteModification.restore;
                trans = 'routeRestoredOk'
            } else {
                method = RouteModification.cancel;
                trans = 'routeCancelledOk';
            }

            method({fleetId: $stateParams.fleetId, uvid: $scope.route.uvid, routeId: $scope.route.id},[$scope.route],
                function () {
                    AlertService.success($filter('translate')('routeModification.messages.' + trans, { id: $scope.route.name}));
                    $uibModalInstance.close(true);
                });
        };
    }
})();

(function () {
    'use strict';

    angular.module('schoolbushubApp').controller('EditRouteController', EditRouteController);

    EditRouteController.$inject = [
        "$scope", "$state", "$uibModalInstance", "entity", "RouteModification", "DateUtils", "AlertService"
    ];

    function EditRouteController($scope, $state, $uibModalInstance, entity, RouteModification, DateUtils, AlertService) {

        $scope.route = entity;

        entity.$promise.then(function(a) {
            a.shiftTime = a.shiftTime || 0;
            $scope.shiftUpdated();
        });


        $scope.shiftUpdated = function() {
            $scope.newStartTime = $scope.route.startTime + $scope.route.shiftTime * 60000;
            $scope.newEndTime = $scope.route.endTime + $scope.route.shiftTime * 60000;
        }

        $scope.timeFormat = function (time) {
            return DateUtils.timeFormat(time);
        };

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmEdit = function (id) {
            delete $scope.route.selected;
            RouteModification.update($scope.route,
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();

(function () {
    'use strict';

    angular.module('schoolbushubApp').factory('FiltersRouteSvc',
    ["$http", "$resource", function ($http, $resource) {
    	return {
    		// filter queries
        getRoutesByName: function(organization, query, successCallback, errorCallback) {
            return $http.get('api/runs/match?organization=' + organization.code + '&name=' + query
            + '&active=true').then(function (results) {
                successCallback(results.data);
            }, function (err) {
                errorCallback(err.data);
            });
        },
        getVehiclesByName: function(organization, query, successCallback, errorCallback) {
            $http.get('api/vehicles/match?organization=' + organization.code + '&id=' + query
            + '&active=true').then(function (results) {
                successCallback(results.data);
            }, function (err) {
                errorCallback(err.data);
            });
        },
        getSchoolsByName: function(organization, query, successCallback, errorCallback) {
            $http.get('api/schools/match?organization=' + organization.id + '&schoolName=' + query)
            .then(function (results) {
                successCallback(results.data);
            }, function (err) {
                errorCallback(err.data);
            });
        }
    	}
    }]);
})();


(function () {
    'use strict';

    angular.module('schoolbushubApp').factory('RouteModification',
        ["$resource", function ($resource) {
            return $resource('api/editRuns/:fleetId', {uvid: '@uvid'}, {
                'query': { method: 'GET', isArray: true },
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data[0];
                    }
                },
                'cancel': {
                    method: 'PUT',
                    url: '/api/editRuns/canceled/:fleetId'
                },
                'restore': {
                    method: 'DELETE',
                    url: '/api/editRuns/canceled/:fleetId'
                }
            });
        }]);
})();

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('vehicleAudit', {
                parent: 'entity',
                url: '/vehicleAudits',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN'],
                    pageTitle: 'schoolbushubApp.vehicleAudit.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudits.html',
                        controller: 'VehicleAuditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('vehicleAudit');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            // .state('vehicleAudit.detail', {
            //     parent: 'entity',
            //     url: '/vehicleAudit/{id}',
            //     data: {
            //         authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN'],
            //         pageTitle: 'schoolbushubApp.vehicleAudit.detail.title'
            //     },
            //     views: {
            //         'content@': {
            //             templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudit-detail.html',
            //             controller: 'VehicleAuditDetailController'
            //         }
            //     },
            //     resolve: {
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('vehicleAudit');
            //             return $translate.refresh();
            //         }],
            //         entity: ['$stateParams', 'VehicleAudit', function($stateParams, VehicleAudit) {
            //             return VehicleAudit.get({id : $stateParams.id});
            //         }]
            //     }
            // })
            .state('vehicleAudit.new', {
                parent: 'vehicleAudit',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudit-dialog.html',
                        controller: 'VehicleAuditDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    zoneType: null,
                                    deviceId: null,
                                    tripOrderGuid: null,
                                    eventTimeStamp: null,
                                    plannedArrivalTime: null,
                                    latLong: null,
                                    rawMessage: null,
                                    isMsgOld: null,
                                    insideAllowedTime: null,
                                    allowedTimeStart: null,
                                    allowedTimeEnd: null,
                                    belongsToStop: null,
                                    stopHasStudents: null,
                                    processTime: null,
                                    runGuid: null,
                                    stopOrder: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('vehicleAudit', null, { reload: true });
                    }, function() {
                        $state.go('vehicleAudit');
                    })
                }]
            })
            .state('vehicleAudit.edit', {
                parent: 'vehicleAudit',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudit-dialog.html',
                        controller: 'VehicleAuditDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['VehicleAudit', function(VehicleAudit) {
                                return VehicleAudit.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('vehicleAudit', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('vehicleAudit.delete', {
                parent: 'vehicleAudit',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudit-delete-dialog.html',
                        controller: 'VehicleAuditDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['VehicleAudit', function(VehicleAudit) {
                                return VehicleAudit.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('vehicleAudit', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('VehicleAuditController', ["$scope", "$filter", "AlertService", "$state", "$window", "$uibModal", "DataUtils", "VehicleAudit", "ParseLinks", function ($scope,  $filter, AlertService, $state, $window, $uibModal, DataUtils, VehicleAudit, ParseLinks) {

        $scope.vehicleAudits = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;

        $scope.fullView = $window.innerWidth > 1600;
        $scope.toggleView = function() {
            $scope.fullView = !$scope.fullView;
        }

        $scope.loadAll = function() {
            var pageable = {page: $scope.page, size: $scope.per_page, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            VehicleAudit.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.total = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.vehicleAudits.push(result[i]);
                }
            }, function(err) {
                AlertService.error($filter('translate')('filters.resultMessages.resultsError'));
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.vehicleAudits = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            if ($scope.links && page > $scope.links['last']) { return; };
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.fields = VehicleAudit.filterVars();
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.vehicleAudit = {
                zoneType: null,
                deviceId: null,
                tripOrderGuid: null,
                eventTimeStamp: null,
                plannedArrivalTime: null,
                latLong: null,
                rawMessage: null,
                isMsgOld: null,
                insideAllowedTime: null,
                allowedTimeStart: null,
                allowedTimeEnd: null,
                belongsToStop: null,
                stopHasStudents: null,
                processTime: null,
                runGuid: null,
                stopOrder: null,
                id: null
            };
        };

        $scope.abbreviate = DataUtils.abbreviate;

        $scope.byteSize = DataUtils.byteSize;

        $scope.viewVehicleAuditDetail = function(va) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudit-detail.html',
                controller: 'VehicleAuditDetailController',
                size: 'lg',
                resolve: {entity: function() { return va; } }
            });
        }

        $scope.formatDate = function(d) {
            if (!d) return '';
            return moment(d).format('MM/DD/YYYY HH:mm:ss');
        }
    }]);

'use strict';

angular.module('schoolbushubApp').controller('VehicleAuditDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'VehicleAudit', 'NotificationAudit',
        function($scope, $stateParams, $uibModalInstance, DataUtils, entity, VehicleAudit, NotificationAudit) {

        $scope.vehicleAudit = entity;
        $scope.notificationaudits = NotificationAudit.query();
        $scope.load = function(id) {
            VehicleAudit.get({id : id}, function(result) {
                $scope.vehicleAudit = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('schoolbushubApp:vehicleAuditUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.vehicleAudit.id != null) {
                VehicleAudit.update($scope.vehicleAudit, onSaveSuccess, onSaveError);
            } else {
                VehicleAudit.save($scope.vehicleAudit, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.abbreviate = DataUtils.abbreviate;

        $scope.byteSize = DataUtils.byteSize;
        $scope.datePickerForEventTimeStamp = {};

        $scope.datePickerForEventTimeStamp.status = {
            opened: false
        };

        $scope.datePickerForEventTimeStampOpen = function($event) {
            $scope.datePickerForEventTimeStamp.status.opened = true;
        };
        $scope.datePickerForPlannedArrivalTime = {};

        $scope.datePickerForPlannedArrivalTime.status = {
            opened: false
        };

        $scope.datePickerForPlannedArrivalTimeOpen = function($event) {
            $scope.datePickerForPlannedArrivalTime.status.opened = true;
        };
        $scope.datePickerForAllowedTimeStart = {};

        $scope.datePickerForAllowedTimeStart.status = {
            opened: false
        };

        $scope.datePickerForAllowedTimeStartOpen = function($event) {
            $scope.datePickerForAllowedTimeStart.status.opened = true;
        };
        $scope.datePickerForAllowedTimeEnd = {};

        $scope.datePickerForAllowedTimeEnd.status = {
            opened: false
        };

        $scope.datePickerForAllowedTimeEndOpen = function($event) {
            $scope.datePickerForAllowedTimeEnd.status.opened = true;
        };
        $scope.datePickerForProcessTime = {};

        $scope.datePickerForProcessTime.status = {
            opened: false
        };

        $scope.datePickerForProcessTimeOpen = function($event) {
            $scope.datePickerForProcessTime.status.opened = true;
        };
}]);

'use strict';

angular.module('schoolbushubApp')
	.controller('VehicleAuditDeleteController', ["$scope", "$uibModalInstance", "entity", "VehicleAudit", function($scope, $uibModalInstance, entity, VehicleAudit) {

        $scope.vehicleAudit = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            VehicleAudit.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('VehicleAuditDetailController', ["$scope", "$rootScope", "$stateParams", "$uibModalInstance", "DataUtils", "entity", "VehicleAudit", "NotificationAudit", function ($scope, $rootScope, $stateParams, $uibModalInstance, DataUtils, entity, VehicleAudit, NotificationAudit) {
        $scope.vehicleAudit = entity;
        $scope.load = function (id) {
            VehicleAudit.get({id: id}, function(result) {
                $scope.vehicleAudit = result;
            });
        };

        var unsubscribe = $rootScope.$on('schoolbushubApp:vehicleAuditUpdate', function(event, result) {
            $scope.vehicleAudit = result;
        });

        $scope.close = function() {
            unsubscribe();
            $uibModalInstance.dismiss('ok');
        };

        $scope.$on('$destroy', unsubscribe);

        $scope.byteSize = DataUtils.byteSize;

        $scope.formatDate = function(d) {
            if (!d) return '';
            return moment(d).format('MM/DD/YYYY HH:mm:ss');
        }
    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('VehicleAudit', ["$resource", "DateUtils", function ($resource, DateUtils) {
        return $resource('api/vehicleAudits/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.eventTimeStamp = DateUtils.convertDateTimeFromServer(data.eventTimeStamp);
                    data.plannedArrivalTime = DateUtils.convertDateTimeFromServer(data.plannedArrivalTime);
                    data.allowedTimeStart = DateUtils.convertDateTimeFromServer(data.allowedTimeStart);
                    data.allowedTimeEnd = DateUtils.convertDateTimeFromServer(data.allowedTimeEnd);
                    data.processTime = DateUtils.convertDateTimeFromServer(data.processTime);
                    return data;
                }
            },
            'filterQuery': { method: 'POST', url: 'api/vehicleaudits/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/vehicleaudits/info', isArray:true },
            'update': { method:'PUT' }
        });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .service('Parameters', function () {

        var REGULAR_EXPRESSION_VALIDATION_PASSWORDS = '((?=.*\\d)(?=.*[A-Za-z])(?=.*\\W).{8,32})';

        var REGULAR_EXPRESSION_VALIDATION_ALPHANUMERIC = '[A-Za-z0-9]*';

        var REGULAR_EXPRESSION_MOBILE_NUMBER = '(\\(([0-9]{3})\\) [0-9]{3} - [0-9]{4}){1,16}';

        var getRegularExpressionValidationPassword = function(){
            return REGULAR_EXPRESSION_VALIDATION_PASSWORDS;
        }

        var getRegularExpressionValidationAlphanumeric = function(){
            return REGULAR_EXPRESSION_VALIDATION_ALPHANUMERIC;
        }

        var getRegularExpressionMobileNumber = function(){
            return REGULAR_EXPRESSION_MOBILE_NUMBER;
        }

        return{
            getRegularExpressionValidationPassword:getRegularExpressionValidationPassword,
            getRegularExpressionValidationAlphanumeric:getRegularExpressionValidationAlphanumeric,
            getRegularExpressionMobileNumber:getRegularExpressionMobileNumber
        }

    });

'use strict';

angular.module('schoolbushubApp')
    .directive('confirmVerify', function() {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, model) {
                if (!attrs.confirmVerify) {
                    console.error('confirmVerify expects a model as an argument!');
                    return;
                }
                scope.$watch(attrs.confirmVerify, function (value) {
                    if (model.$viewValue !== undefined && model.$viewValue !== '') {
                        model.$setValidity('confirmVerify', value === model.$viewValue);
                    }
                });
                scope.$watch(attrs.ngModel, function (value) {
                    var isValid = value === scope.$eval(attrs.confirmVerify);
                    model.$setValidity('confirmVerify', isValid);
                    return isValid ? value : undefined;
                });
            }
        };
});

angular.module('schoolbushubApp')
    .directive('formattingMobileNumber', function() {
        var length = 0;
        var patt = new RegExp('(\\(([0-9]{3})\\) [0-9]{3} - [0-9]{4}){1,16}');
        return {
            scope: {
                onchange: '&watchChange'
            },
            require: 'ngModel',
            link: function(scope, element, attrs, model) {
                element.on('input', function() {
                    scope.$apply(function () {
                        if (element.context.value.length > length) {
                            switch (element.context.value.length) {
                                case 1:
                                    if(element.context.value != '(') {
                                        element.context.value = '(' + element.context.value;
                                    }
                                    break;
                                case 4:
                                    element.context.value = element.context.value + ') '
                                    break;
                                case 9:
                                    element.context.value = element.context.value + ' - ';
                                    break;
                                default:
                            }
                        }else{
                            switch (element.context.value.length) {
                                case 1:
                                    if(element.context.value != '(') {
                                        element.context.value = '(' + element.context.value;
                                    }
                                    break;
                                case 5:
                                    element.context.value = element.context.value.substring(0, 3);
                                    break;
                                case 11:
                                    element.context.value = element.context.value.substring(0, 8);
                                    break;
                                default:
                            }
                        }
                        var isValid = patt.test(element.context.value);
                        model.$setValidity('numberFormat', isValid);
                        length = element.context.value.length;
                    });
                });
                model.$parsers.push(function (value) {
                    var isValid = patt.test(value);
                    model.$setValidity('numberFormat', isValid);
                    return isValid ? value : undefined;
                });
            }
        };
    });

angular.module('schoolbushubApp')
    .directive("limitTo", [function() {
        return {
            restrict: "A",
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                attrs.$set("ngTrim", "false");
                var limitLength = parseInt(attrs.limitTo, 10);
                scope.$watch(attrs.ngModel, function() {
                    if(ngModel.$viewValue && ngModel.$viewValue.length>limitLength){
                        ngModel.$setViewValue( ngModel.$viewValue.substring(0, limitLength ) );
                        ngModel.$render();
                    }
                });
            }
        };
    }]);

'use strict';
angular.module('schoolbushubApp').factory('RestService', RestService);
RestService.$inject = ['$resource'];

function RestService ($resource) {
    return $resource('api/:url/:id', {url: '@url'}, {
        get: { method: 'GET', params: {id: '@id'}},
        getArr: { method: 'GET', isArray: true},
        post: { method: 'POST' },
        postArr: { method: 'POST', isArray: true},
        put: { method: 'PUT', params: {id: '@id'}},
        'delete': { method: 'DELETE', params: {id: '@id'}},
        'deleteArr': { method: 'DELETE', params: {id: '@id'}, isArray: true}
    });
}

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('reports', {
                abstract: true,
                parent: 'site'
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('AccountAuditController', ["$scope", "ReportsService", "AlertService", "$filter", "Parameters", "$uibModal", "Principal", "Organization", function ($scope, ReportsService, AlertService, $filter, Parameters, $uibModal, Principal, Organization) {

        $scope.accounts = [];
        $scope.formatDate = $filter('translate')('reports.formatDate');
        $scope.validationMobileNumber = Parameters.getRegularExpressionMobileNumber();
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.lastPage = true;
        $scope.accountAuditRequest = {
            schoolCodes:[],
            userLastName:"",
            userEmailAddress:"",
            userMobileNumber:"",
            emailAddressVerified:"",
            accountDeactivated:""
        };

        $scope.organizations = [];
        $scope.isCustomerAdmin = false;
        Principal.identity().then(function(account) {
            if(account){
               var roles = _.filter(account.authorities, function(rol){ return rol == 'ROLE_CUSTOMERADMIN'; });
                if(roles.length == 0){
                    Organization.resource.filterQuery( {"pageable":{"page":0,"size":1000,"sort":["id,asc","id"]}},
                        function(result, headers) {
                            for (var i = 0; i < result.length; i++) {
                                $scope.organizations.push(result[i]);
                                }
                        }, function(err) {
                            AlertService.error('Error getting organizations');
                        }
                    );
                } else {
                    $scope.isCustomerAdmin = true;
                    $scope.organization = account.organization;
                    $scope.getSchools($scope.organization);
                }
            }
        });

        $scope.getSchools = function(organization) {
            $scope.schools = [];
            ReportsService.getSchools(organization, function(response) {
                if (response.status == 'success') {
                    $scope.schools = response.schools;
                }
                else {
                    AlertService.error($filter('translate')('reports.accountAudit.errorGettingSchools'));
                }
            });
        }

         /**
         * Generate Report Callback
         *
         * @param response
         */
        var generateReportCallback = function(response){
            if(response.status == 'success'){
                if(response.accountAuditResponse.length > 0) {
                    $scope.accounts = response.accountAuditResponse;
                    if(response.accountAuditResponse.length < $scope.per_page){
                        $scope.lastPage = true;
                    }else{
                        $scope.lastPage = false;
                    }
                }else{
                    $scope.lastPage = true;
                }
                if($scope.accounts.length > 0){
                    AlertService.success($filter('translate')('reports.accountAudit.reportGeneratedSuccessfully'));
                }else{
                    AlertService.warning($filter('translate')('reports.accountAudit.noResultsWereFound'));
                }
            }else{
                AlertService.error($filter('translate')('reports.accountAudit.errorToGenerateTheReport'));
            }
        };

         /**
         * Generate Report Callback
         *
         * @param response
         */
        var loadPageCallback = function(response){
            if(response.status == 'success'){
                if(response.accountAuditResponse.length > 0) {
                    for (var i = 0; i < response.accountAuditResponse.length; i++) {
                        $scope.accounts.push(response.accountAuditResponse[i]);
                    }
                    if(response.accountAuditResponse.length < $scope.per_page){
                        $scope.lastPage = true;
                    }
                }else{
                    $scope.lastPage = true;
                }
            }else{
                AlertService.error($filter('translate')('reports.accountAudit.errorToGenerateTheReport'));
            }
        };

        /**
         * Generate Report
         */
        $scope.generateReport = function(){
            $scope.accounts = [];
            $scope.page = 1;
            $scope.per_page = $scope.infiniteScrollPageSize;
            ReportsService.getReport($scope.accountAuditRequest, $scope.organization, $scope.page, $scope.per_page, generateReportCallback);
        };

        /**
         * Generate Report
         */
        $scope.loadPage = function(page){
            // if ($scope.links && page > $scope.links['last']) { return; };
            $scope.page = page;
            if(!$scope.lastPage) {
                ReportsService.getReport($scope.accountAuditRequest, $scope.organization, $scope.page, $scope.per_page, loadPageCallback);
            }
        };

        /**
         * Clean  Account Audit Request
         */
        $scope.clean = function(){
            $scope.accounts = [];
            $scope.accountAuditRequest = {
                schoolCodes:[],
                userLastName:"",
                userEmailAddress:"",
                userMobileNumber:"",
                emailAddressVerified:"",
                accountDeactivated:""
            };
        };

        /**
         * convert To UTC
         *
         * @param dt
         * @returns {Date}
         */
        $scope.formatDateTime = function(dt) {
             if (!dt) return '--';
             return moment.utc(dt).format('MM/DD/YYYY HH:mm:ss');
        };

        /**
         * Open Modal Detail Students
         */
        $scope.openDetailStudents = function (account) {
            ReportsService.getDetailStudents(account.emailAddress, $scope.accountAuditRequest.schoolCodes, function(response){
                if(response.status == 'success'){
                    $scope.modalInstance = $uibModal.open({
                        templateUrl: 'scripts/app/reports/accountaudit/detail-students.html',
                        controller: 'DetailStudentsController',
                        backdrop: 'static',
                        resolve: {
                            students: function () {
                                return response.students;
                            },
                            userName: function() {
                                return account.firstName + ' ' + account.lastName;
                            },
                            mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                $translatePartialLoader.addPart('reports');
                                return $translate.refresh();
                            }]
                        }
                    });
                }else{
                    AlertService.error($filter('translate')('reports.accountAudit.failedQueryDetailStudents'));
                }
            });
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('accountAudit', {
                parent:'reports',
                url: '/accountAudit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/reports/accountaudit/account-audit.html',
                        controller: 'AccountAuditController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        return $translate.refresh();
                    }]
                }
            })
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('DetailStudentsController', ["$scope", "students", "userName", "$uibModalInstance", "$filter", function ($scope, students, userName, $uibModalInstance, $filter) {
        $scope.students = students;
        $scope.userName = userName;
        $scope.formatDate = $filter('translate')('reports.formatDate');

        /**
         * Close modal
         */
        $scope.ok = function () {
            $uibModalInstance.dismiss('cancel');
        };

        /**
         * convert To UTC
         *
         * @param dt
         * @returns {Date}
         */
        $scope.convertToUTC = function(dt) {
            var localDate = new Date(dt);
            var localTime = localDate.getTime();
            var localOffset = localDate.getTimezoneOffset() * 60000;
            return new Date(localTime + localOffset);
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('StudentAuditController', ["$scope", "ReportsService", "AlertService", "$filter", function ($scope, ReportsService, AlertService, $filter) {

        /**
         * Generate Report Callback
         *
         * @param response
         */
        var generateReportCallback = function(response){
            if(response.status == 'success'){
                $scope.students = response.studentAuditResponse;
                if($scope.students.length > 0){
                    $scope.studentName = $scope.students[0].studentName;
                    $scope.studentDateOfBirth = $scope.students[0].studentDateOfBirth;
                    $scope.studentSchoolCodeId = $scope.students[0].studentSchoolCodeId;
                    AlertService.success($filter('translate')('reports.studentAudit.reportGeneratedSuccessfully'));
                }else{
                    AlertService.warning($filter('translate')('reports.studentAudit.noResultsWereFound'));
                }
            }else{
                AlertService.error($filter('translate')('reports.studentAudit.errorToGenerateTheReport'));
            }
        };

        $scope.students = [];
        $scope.studentId;
        $scope.studentName;
        $scope.studentDateOfBirth;
        $scope.studentSchoolCodeId;
        $scope.formatDate = $filter('translate')('reports.formatDate');

        /**
         * Generate Report
         */
        $scope.generateReport = function(){
            $scope.studentName = '';
            $scope.studentDateOfBirth = '';
            $scope.studentSchoolCodeId = '';
            ReportsService.getStudentReport($scope.studentId, generateReportCallback);
        };

        /**
         * Clean Report
         */
        $scope.clean = function(){
            $scope.students = [];
            $scope.studentId = '';
            $scope.studentName = '';
            $scope.studentDateOfBirth = '';
            $scope.studentSchoolCodeId = '';
        };

        /**
         * Convert DateTime To UTC
         *
         * @param dt
         * @returns {Date}
         */
        $scope.formatDateTime = function(dt) {
             if (!dt) return '--';
             return moment.utc(dt).format('MM/DD/YYYY HH:mm:ss');
        };

        /**
         * Convert Date To UTC
         *
         * @param dt
         * @returns {Date}
         */
        $scope.formatDate = function(dt) {
             if (!dt) return '--';
             return moment.utc(dt).format('MM/DD/YYYY');
        };

    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('studentAudit', {
                parent:'reports',
                url: '/studentAudit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/reports/studentaudit/student-audit.html',
                        controller: 'StudentAuditController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        return $translate.refresh();
                    }]
                }
            })
    }]);

'use strict';

angular.module('schoolbushubApp')
    .service('ReportsService', ["$http", function ($http) {
        /**
         * Get Schools list
         */
        var getSchools = function(organization, callback) {
            var headers = {'X-Requested-With':'XMLHttpRequest', 'Accept':'application/json, text/plain, */*'};
            $http.get('api/schools/list?organizationId='+organization.id, undefined, headers)
            .then(function(response) {
                callback({status:'success', schools:response.data});
            })
            .catch(function() {
                callback({status:'error'});
            });
        }

        /**
         * Get Report Account Audit
         *
         * @param accountAuditRequest
         * @param callback
         */
        var getReport = function(accountAuditRequest, organization, page,  per_page, callback){
            var url = 'api/accountsAudit?page='+page+'&per_page='+per_page+'&organizationId='+organization.id;
            var headers = {'X-Requested-With':'XMLHttpRequest', 'Accept':'application/json, text/plain, */*'};
            $http.post(url, accountAuditRequest, headers)
            .then(function(response) {
                callback({status:'success',accountAuditResponse:response.data});
            }).catch(function(){
                callback({status:'error'});
            });
        };

        /**
         * Get Report Student Audit
         *
         * @param id student
         * @param callback
         */
        var getStudentReport = function(studentId, callback){
            var url = 'api/studentAudit?studentId='+studentId;
            var headers = {'X-Requested-With':'XMLHttpRequest', 'Accept':'application/json, text/plain, */*'};
            $http.post(url, [], headers)
                .then(function(response) {
                    callback({status:'success',studentAuditResponse:response.data});
                }).catch(function(){
                    callback({status:'error'});
                });
        };

        /**
         * Get Report Student Audit
         *
         * @param id student
         * @param callback
         */
        var getDetailStudents = function(emailAddress, schoolCodes, callback){
            var url = 'api/detailStudents';
            var headers = {'X-Requested-With':'XMLHttpRequest', 'Accept':'application/json, text/plain, */*'};
            var params = {'schoolCodes[]':schoolCodes, 'emailAddress':emailAddress};
            $http.get(url, {headers:headers,params:params})
                .then(function(response) {
                    callback({status:'success',students:response.data});
                }).catch(function(){
                    callback({status:'error'});
                });
        };

        /**
         * Get Report Account Audit
         *
         * @param auditRequest
         * @param callback
         */
        var getNotificationsReport = function(auditRequest, organization, page, per_page, callback){
            var url = 'api/notificationAudits/report?organizationId='+((organization)? organization.id:'')+'&page='+page+'&per_page='+per_page;
            var headers = {'X-Requested-With':'XMLHttpRequest', 'Accept':'application/json, text/plain, */*'};
            $http.post(url, auditRequest, headers)
            .then(function(response) {
                callback({status:'success',auditResponse:response.data});
            }).catch(function(){
                callback({status:'error'});
            });
        };

        return {
            getSchools:getSchools,
            getReport:getReport,
            getStudentReport:getStudentReport,
            getDetailStudents:getDetailStudents,
            getNotificationsReport:getNotificationsReport
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .factory('HelpService', ["$http", function ($http) {
        return {
            getHelpUrl: function (callback) {
                $http.get('api/helpUrl').then(function(response) {
                    callback({status:'success',helpUrl:response.data});
                }).catch(function(){
                    callback({status:'error'});
                });
            }
        };
    }]);


'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('home', {
                parent: 'menu',
                url: '/dashboard',
                isHome: true,
                isApp: true,
                data: {
                    authorities: ['ROLE_PARENT', 'ROLE_ADMIN'],
                    pageTitle: 'School Bus Hub'
                },
                views: {
                    'app-home@': {
                        templateUrl: 'scripts/app/main/main.html',
                        controller: 'MainController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('main');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('MainController',
        ["$scope", "$filter", "$mdDialog", "Principal", "MainService", "Notifications", "$translate", "$location", "$mdMedia", "$timeout", "$window", "$rootScope", "tmhDynamicLocale", "$anchorScroll", "SharedService", "DataService", "$http", "$q", function ($scope, $filter, $mdDialog, Principal, MainService, Notifications, $translate, $location, $mdMedia,
                  $timeout, $window, $rootScope, tmhDynamicLocale, $anchorScroll, SharedService, DataService, $http, $q) {

        $scope.verifyData;
        $scope.verifying = false;
        $scope.showOne = false;
        $scope.student1 = true;
        $scope.verify = false;
        $scope.idStudent;
        $scope.dateOfBirth;
        $scope.direction = 'left';
        $scope.currentIndex = 0;
        $scope.transition = true;
        var today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);


        var scp = $scope;
        scp.listFlexGtSm = 100;
        scp.listFlexSm = 100;
        scp.listColsGtSm = 4;
        scp.listColsSm = 4;

        function fetchStudents(force) {
            SharedService.getStudents(force).then(function(students) {
                scp.students = students;

                scp.listFlexGtSm = students.length * 25;
                scp.listFlexGtSm = (scp.listFlexGtSm > 100? 100 : scp.listFlexGtSm);
                scp.listFlexSm = students.length * 50;
                scp.listFlexSm = (scp.listFlexSm > 100? 100 : scp.listFlexSm);

                scp.listColsGtSm = students.length > 4? 4 : students.length;
                scp.listColsSm = students.length > 2? 2 : students.length;

                var currentIndex = scp.currentIndex +1;
                if(scp.students.length > 0 && currentIndex > scp.students.length){
                    scp.currentIndex = scp.students.length-1;
                }
            });
        }
        fetchStudents(true);

        scp.selectChild = function(student) {
             if (scp.selectedStudent == student) {
                scp.selectedStudent = undefined;
             }
             else {
                scp.selectedStudent = student;
                $location.hash('student' + student.id);
                setTimeout(function() {
                    $anchorScroll();
                    $location.url($location.path());
                }, 300);
             }
         }


        $scope.isCurrentSlideIndex = function (index) {
            return $scope.currentIndex === index;
        };

        $scope.prevSlide = function () {
            $scope.transition = true;
            $scope.direction = 'right';
            $scope.currentIndex = ($scope.currentIndex > 0) ? --$scope.currentIndex : $scope.currentIndex;
        };

        $scope.nextSlide = function () {
            $scope.transition = true;
            $scope.direction = 'left';
            $scope.currentIndex = ($scope.currentIndex < $scope.students.length - 1)
            ? ++$scope.currentIndex : $scope.currentIndex;
        };

        $scope.showAdvanced = function(ev) {
            $mdDialog.show({
                controller: 'AddStudentController',
                templateUrl: 'scripts/app/main/add-student.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader',
                    function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('main');
                        return $translate.refresh();
                    }]
                }
            }).then(function() {
                fetchStudents(true);
            }, function() {
              $scope.status = 'You cancelled the dialog.';
            });
        };

          // deleteStudent callback
          var deleteStudentCallback = function(response) {
            if (response.status == 'Ok') {
                fetchStudents(true);
            }
            else{
                var alert = $mdDialog.alert()
                    .title($filter('translate')('main.modalError.title'))
                    .content($filter('translate')('main.modalError.errorDeleteStudent'))
                    .ok($filter('translate')('main.modalError.close'));
                $mdDialog.show(alert);
            }
        };

        // Deletes and student (The relationship between student and tutor).
        $scope.deleteStudent = function(studentId) {
            var confirm = $mdDialog.confirm()
              .title($filter('translate')('main.deleteStudent.confirmTitle'))
              .content($filter('translate')('main.deleteStudent.confirmContent'))
              .ok($filter('translate')('main.deleteStudent.ok'))
              .cancel($filter('translate')('main.deleteStudent.cancel'));
            $mdDialog.show(confirm).then(function() {
                MainService.deleteStudent(studentId, deleteStudentCallback);
            });
        };

         /**
         * Verify Student Callback
         * @param response
         */
        var verifyStudentCallback = function(response) {
            if (response.status.data == 'added') {
                fetchStudents(true);
                $scope.verify=false;
                $scope.cleanVerify();
            } else if (response.status.data == 'duplicate') {
                Notifications.showToastDashboard('error', $filter('translate')('main.addStudent.duplicateStudent'));
            } else {
                Notifications.showToastDashboard('error', $filter('translate')('main.addStudent.studentNotFound'));
            }
            $scope.verifying = false;
        };

        /**
         * Verify Student
         */
        $scope.verifyStudent = function() {
            if($scope.validated()) {
                $scope.verifying = true;
                MainService.verifyAndAddStudent($scope.school.code, moment($scope.dateOfBirth,
                $filter('translate')('main.formatDateMoment')).toDate(), $scope.idStudent, $scope.organization.id,
                 verifyStudentCallback);
            }
        };

        /**
         * Validation of the fields
         * @returns {boolean}
         */
        $scope.validated = function(){
            var validated = true;
            var dateByParts = $scope.dateOfBirth.split('/');
            if(dateByParts.length == 3){
                _.each(dateByParts, function (num) { if (isNaN(num) || isNaN(parseFloat(num))) {validated = false;}});
            }else{
                validated = false;
            }
            if (validated) {
                if (!moment($scope.dateOfBirth, [$filter('translate')('main.formatDateMoment')]).isValid()) {
                    validated = false;
                    Notifications.showToastDashboard('error',
                    $filter('translate')('main.addStudent.dateBirthIsInvalid'));
                } else {
                    var birth = moment($scope.dateOfBirth, $filter('translate')('main.formatDateMoment')).toDate();
                    if (moment(birth).diff(today, 'hours') > 0) {
                        validated = false;
                        Notifications.showToastDashboard('error',
                        $filter('translate')('main.addStudent.dateBirthIsHigherCurrentDate'));
                    }
                }
            } else {
                Notifications.showToastDashboard('error', $filter('translate')('main.addStudent.dateBirthIsInvalid'));
            }
            return validated;
        }

        /**
         * Clean Verify
         */
        $scope.cleanVerify = function(){
            $scope.organization = undefined;
            $scope.school = undefined;
            $scope.idStudent = angular.copy($scope.verifyData);
            $scope.dateOfBirth = angular.copy($scope.verifyData);
            $scope.verifyStudentForm.$setPristine();
            $scope.verifyStudentForm.$setUntouched();
            $location.hash('ContainerModal');
            $anchorScroll();
            $location.url($location.path());
        }

        $scope.changeView = function(index){
            if(window.innerWidth < 1200) {
                $scope.transition = false;
                $scope.currentIndex = index;
                $scope.showOne = true;
            }
        }

        $scope.setGridMinH = function() {
        	$timeout(function(){
        		$scope.$apply();
        		var winH = $window.innerHeight;
        		var offset = 66;
        		if ($mdMedia('gt-sm'))
        		    offset = offset + 140;

        		$scope.gridMinH = {"min-height": (winH - offset)};
        	})
        }

        angular.element($window).bind('resize', function () {
        	$timeout(function(){ $scope.$apply();});
        	$scope.setGridMinH();
        	$location.hash('sbh-top-menu');
        	$anchorScroll();
        	$location.url($location.path());
        });

        $scope.setGridMinH();

        $scope.searchSchool = function (query) {
          var deferred = $q.defer();
          var params = {
            organizationId: $scope.organization.id,
            schoolName: query
          };
          $http.get('api/schools/codes', { params: params }).then(function (results) {
            for (var i = 0; i < results.data.length; i++) {
              results.data[i].displayName = results.data[i].code + ' - ' + results.data[i].name;
            }
            deferred.resolve(results.data);
          }, function (err) {
            deferred.resolve([]);
          });
          return deferred.promise;
        }

        $scope.searchOrganizations = function (query) {
          var deferred = $q.defer();
          if ($scope.school) {
            $scope.school = null;
          }
          $http.get('api/organizations/search?name=' + query).then(function (results) {
            deferred.resolve(results.data);
          }, function (err) {
            deferred.resolve([]);
          });
          return deferred.promise;
        }

    }]);

'use strict';

angular.module('schoolbushubApp')
    .config(["$stateProvider", function ($stateProvider) {
        $stateProvider
            .state('maps', {
                parent: 'menu',
                url: '/maps/:studentId?',
                isHome: true,
                isApp: true,
                data: {
                    authorities: ['ROLE_PARENT', 'ROLE_ADMIN'],
                    pageTitle: 'School Bus Hub'
                },
                views: {
                    'app-home@': {
                        templateUrl: 'scripts/app/maps/mapsview.html',
                        controller: 'MapsController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('main');
                        $translatePartialLoader.addPart('maps');
                        return $translate.refresh();
                    }]
                }
            });
    }]);

'use strict';

angular.module('schoolbushubApp').controller('MapsController', MapsController);
MapsController.$inject = ['$scope', '$timeout', '$location', '$stateParams', '$anchorScroll', '$window', '$document',
  '$interval', '$mdToast', '$mdMedia', '$state', '$filter', '$mdDialog', 'SharedService', 'DataService'];

function MapsController($scope, $timeout, $location, $stateParams, $anchorScroll, $window, $document, $interval,
  $mdToast, $mdMedia, $state, $filter, $mdDialog, SharedService, DataService) {
  var scp = $scope;
  scp.mapH = { height: '90vh' };

  var busRefreshInt;

  var baseSizes = {
    stop: { w: 50, h: 95 },
    bus: { w: 70.5, h: 60 },
    route: { w: 8 },
  };

  var rotationMap = {
    N: 0,
    NNE: 22.5,
    NE: 45,
    ENE: 67.5,
    E: 90,
    ESE: 112.5,
    SE: 135,
    SSE: 157.5,
    S: 180,
    SSW: 202.5,
    SW: 225,
    WSW: 247.5,
    W: 270,
    WNW: 292.5,
    NW: 315,
    NNW: 337.5
  };

  var angleArr = [
    "0", "15", "30", "45", "60", "75",
    "90", "105", "120", "135", "150", "165",
    "180", "195", "210", "225", "240", "255",
    "270", "285", "300", "315", "330", "345",
  ];

  require([
    "esri/map", "esri/geometry/Point", "esri/geometry/Extent", "esri/layers/StreamLayer", "esri/layers/FeatureLayer",
    "esri/layers/GraphicsLayer", "esri/Color", "esri/renderers/SimpleRenderer",'esri/renderers/UniqueValueRenderer',
    "esri/geometry/Multipoint", "esri/symbols/SimpleMarkerSymbol", "esri/symbols/PictureMarkerSymbol",
    "esri/symbols/SimpleLineSymbol", "esri/graphic", "esri/config", "esri/request", 'dojo/request/xhr', 'dojo/aspect'],
    function (Map, Point, Extent, StreamLayer, FeatureLayer, GraphicsLayer, Color, SimpleRenderer, UniqueValueRenderer, Multipoint,
      SimpleMarkerSymbol, PictureMarkerSymbol, SimpleLineSymbol, Graphic, esriConfig, esriRequest, XHR, Aspect) {

      esriConfig.defaults.io.corsDetection = false;

      esriRequest.setRequestPreCallback(function (ioArgs) {
        var hostName = scp.orgUrls && scp.orgUrls.streamDomain;
        if (hostName && ioArgs && ioArgs.url && ioArgs.url.indexOf(hostName) > -1) {
          ioArgs.headers = ioArgs.headers || {};
          ioArgs.headers.Authorization = scp.orgUrls.ssoToken;
        }
        return ioArgs;
      });

      Aspect.before(dojo, "xhr", function(httpMethod, args)  {
        if (scp.orgUrls && scp.orgUrls.ssoToken) {
          args.headers = args.headers || {};
          args.headers.Authorization = scp.orgUrls.ssoToken;
        }
      });

      scp.map = new Map("mapDiv", {
        center: [-107.4919934, 49.7952768],
        zoom: 4,
        basemap: "streets",
        force3DTransforms: true
      });
      scp.map.on("load", function (ev) {
        setStopIconsSizes(ev.map.getZoom());
        init();
      });
      scp.map.on('zoom-end', function (ev) {
        setStopIconsSizes(ev.level);
      });

      var stopSym = new PictureMarkerSymbol("../assets/images/maps/stop.svg");
      var schoolSym = new PictureMarkerSymbol("../assets/images/maps/school.svg");
      var busSVGs = _.map(angleArr, function (name) {
        return new PictureMarkerSymbol("../assets/images/maps/bus/" + name + ".svg");
      });
      var routeSymbol = new SimpleLineSymbol(esri.symbol.SimpleLineSymbol.STYLE_SOLID,
        new Color([118, 158, 245, 1.0]), 8);
      var routeRenderer = new SimpleRenderer(routeSymbol);

      function setStopIconsSizes(lvl) {
        var factor = lvl * lvl * 0.0037;
        stopSym.setWidth(baseSizes.stop.w * factor);
        stopSym.setHeight(baseSizes.stop.h * factor);
        schoolSym.setWidth(baseSizes.stop.w * factor);
        schoolSym.setHeight(baseSizes.stop.h * factor);
        _.forEach(busSVGs, function (busMarker) {
          busMarker.setHeight(baseSizes.bus.h * factor);
          busMarker.setWidth(baseSizes.bus.w * factor);
        });
        routeSymbol.setWidth(baseSizes.route.w * factor);
      }

      function init() {
        scp.stopsLayer = new GraphicsLayer();
        scp.map.addLayer(scp.stopsLayer);
        SharedService.getCredentials().then(function (credentials) {
          SharedService.getStudents(credentials.account).then(function (students) {
            scp.students = students;
            scp.setMapHeight();
            if (scp.students && scp.students.length > 0) {
              scp.listCols = scp.students.length > 4 ? 4 : scp.students.length;

              var st = scp.students[0];
              if ($stateParams.studentId) {
                st = _.find(scp.students, function (s) { return s.studentId == $stateParams.studentId });
                if (!st) {
                  (scp.mapToastWithText($filter('translate')('maps.messages.noStudent')));
                }
              }
              scp.selectStudent(st);
            }
          });
        });
        var zoomControls = $document[0].querySelector('.esriSimpleSliderTL');
        zoomControls.classList.add('expanded');

        initDeviceLocation();
      }

      // Custom base layer if provided by server
      scp.addBaseLayer = function () {
        scp.baseMapLayer = new esri.layers.ArcGISTiledMapServiceLayer(scp.orgUrls.mapsGuid);
        scp.map.addLayer(baseMapLayer);
      };

      scp.addRouteLayer = function () {
        var featureCollection = {
          layerDefinition: {
            geometryType: "esriGeometryPolyline",
            fields: [{
              name: "__OBJECTID",
              alias: "OBJECTID",
              type: "esriFieldTypeOID"
            }]
          },
          featureSet: scp.selectedBus.features
        };
        scp.routeLayer = new FeatureLayer(featureCollection);
        scp.routeLayer.setRenderer(routeRenderer);
        scp.map.addLayer(scp.routeLayer);

        if (scp.orgUrls.mapsGuid) {
          scp.addBaseLayer();
        }
      };

      scp.addBusLayer = function () {
        scp.orgUrls.streamDomain = scp.orgUrls.streamingUrl.replace('http://', '')
          .replace('https://', '').split('/')[0];
        esriConfig.defaults.io.corsEnabledServers.push(scp.orgUrls.streamDomain);

        var definitionField = 'trackId';
        scp.busLayer = new StreamLayer(scp.orgUrls.streamingUrl, {
          maximumTrackPoints: 1,
          definitionExpression: (definitionField + " = '" + scp.selectedBus.vehicle.uvid + "'"),
          purgeOptions: {
            displayCount: 1
          }
        });

        scp.busLayer.on('connect', function () {
          if (scp.orgUrls.ssoToken) {
            scp.busLayer.socket.send(JSON.stringify({
              type: 'Authorization',
              token: scp.orgUrls.ssoToken.split(' ')[1]
            }));
          }
        });

        scp.busLayer.on('load', function () {
          var renderer = new UniqueValueRenderer(busSVGs[0], "iconName");
          _.forEach(busSVGs, function (symbol, i) {
            renderer.addValue(angleArr[i], symbol);
          });
          scp.busLayer.setRenderer(renderer);
          scp.map.addLayer(scp.busLayer);
        });

        scp.busLayer.on('graphic-add', function (g) {
          var rotation = parseInt(g.graphic.attributes.heading);
          if (isNaN(rotation)) {
            rotation = rotationMap[g.graphic.attributes.heading] || 0;
          }
          g.graphic.attributes.iconName = angleArr[Math.round((rotation * angleArr.length) / 360) % angleArr.length];
          var time = moment(g.graphic.attributes.time);
          if (moment().diff(time) > 300000) {
            scp.busLayer.remove(g.graphic);
          }
        });
      };

      scp.changeBusDefinition = function () {
        var definitionField = 'trackId'
        scp.busLayer.setDefinitionExpression(definitionField + " = '" + scp.selectedBus.vehicle.uvid + "'");
      };

      scp.addStopsGraphics = function () {
        var stops = scp.selectedBus.stops || [];

        for (var i = 0; i < stops.length; i++) {
          var stop = stops[i];
          var symbol = stop.type.toLowerCase().indexOf('school') > -1 ? schoolSym : stopSym;

          var pt = new Point({
            x: stop.location.longitude,
            y: stop.location.latitude,
            spatialReference: {
              wkid: stop.location.spatialReference.wkid || 4326
            }
          });
          var graphic = new Graphic(pt, symbol);
          scp.stopsLayer.add(graphic);
        }
      };

      scp.reCenterPoints = function () {
        var points = [];
        var stopWKid = null;
        var defaultWKid = 4326;
        var plannedStops = scp.selectedBus.stops || [];

        for (var i = 0; i < plannedStops.length; i++) {
          points.push([plannedStops[i].location.longitude, plannedStops[i].location.latitude]);
          stopWKid = plannedStops[0].location.spatialReference.wkid;
        }

        if (points.length > 0) {
          scp.stopsMulti = new Multipoint({
            points: points,
            spatialReference: { wkid: stopWKid || defaultWKid }
          });

          if (scp.stopsMulti.points.length > 1) {
            scp.map.setExtent(scp.stopsMulti.getExtent(), true);
          } else {
            scp.map.centerAndZoom(scp.stopsMulti.getPoint(0), 13);
          }

          scp.map.reorderLayer(scp.stopsLayer, 1);
        }
      };

      //user location
      function initDeviceLocation() {
        if (navigator.geolocation) {
          scp.watchId = navigator.geolocation.watchPosition(showLocation, locationError);
        }
      }

      function locationError(error) {
        if (navigator.geolocation) {
          navigator.geolocation.clearWatch(scp.watchId);
        }
        switch (error.code) {
          case error.PERMISSION_DENIED:
            scp.mapToastWithText($filter('translate')('maps.messages.location.notProvided'));
            break;

          case error.POSITION_UNAVAILABLE:
            scp.mapToastWithText($filter('translate')('maps.messages.location.notAvailable'));
            break;

          default:
            break;
        }
      }

      function showLocation(location) {
        var pt = new Point(location.coords.longitude, location.coords.latitude);
        if (!scp.locationGraphic) {
          addGraphic(pt);
        }
        else {
          scp.locationGraphic.setGeometry(pt);
        }
      }

      function addGraphic(pt) {
        var symbol = new SimpleMarkerSymbol(SimpleMarkerSymbol.STYLE_CIRCLE, 10,
          new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color([66, 133, 244, 0.3]), 8),
          new Color([66, 133, 244, 0.95])
        );
        scp.locationGraphic = new Graphic(pt, symbol);
        scp.map.graphics.add(scp.locationGraphic);
      }
    });

  $scope.selectStudent = function (ele) {
    if (!ele || ele == scp.selectedStudent) {
      return;
    }

    scp.selectedStudent = ele;
    $scope.cleanStudent();
  }

  $scope.cleanStudent = function () {
    scp.recenterStop = true;
    scp.recenterPoly = false;

    if (scp.orgUrls) {
      for (var i = esriConfig.defaults.io.corsEnabledServers.length - 1; i >= 0; i--) {
        if (esriConfig.defaults.io.corsEnabledServers[i] === scp.orgUrls.streamDomain) {
          esriConfig.defaults.io.corsEnabledServers.splice(i, 1);
        }
      }
    }

    if (busRefreshInt) {
      $interval.cancel(busRefreshInt);
    }
    busRefreshInt = $interval($scope.updateStudent, 120000);
    $scope.updateStudent();
  };

  $scope.updateStudent = function () {
    if (scp.routeLayer) {
      scp.map.removeLayer(scp.routeLayer);
    }
    if (scp.fenceLayer) {
      scp.map.removeLayer(scp.fenceLayer);
    }
    if (scp.busLayer) {
      scp.map.removeLayer(scp.busLayer);
    }

    scp.stopsLayer.clear();
    scp.stopsMulti = undefined;

    if (scp.baseMapLayer) {
      scp.map.removeLayer(scp.baseMapLayer);
    }


    if (scp.recenterStop || scp.recenterPoly) {
      scp.$emit('showSpinner');
    }
    SharedService.getBus(scp.selectedStudent.studentId, scp.selectedStudent.schoolGuid).then(function (bus) {
      scp.selectedBus = bus;

      if (!bus) {
        scp.mapToastWithText($filter('translate')('maps.messages.noBus'));
        scp.$emit('hideSpinner');
        return;
      }

      if (!bus.organization) {
          console.error("invalid student organizationId", scp.selectedStudent.organizationId);
          scp.$emit('hideSpinner');
          return;
      }

      scp.orgUrls = bus.organization;
      var showVehicle = scp.selectedBus.vehicle.uvid != null
        && scp.orgUrls.streamingUrl != null;

      if (scp.selectedBus.features) {
          scp.addRouteLayer();
      }

      scp.$emit('hideSpinner');
      scp.addStopsGraphics();
      scp.reCenterPoints();

      if (showVehicle) {
        scp.addBusLayer();
      }
    });
  }


  //UI Related methods
  $scope.setMapHeight = function () {
    $timeout(function () {
      $scope.$apply();
      var stCount = $scope.students.length || 0;
      var listH = 50;
      if ($mdMedia('gt-sm')) {
        listH = Math.ceil(stCount / 4)
        listH = ((listH > 3) ? 3 : listH) * 58;
        listH = (listH > 154 ? 154 : listH);
      }

      var winH = $window.innerHeight;
      var topH = 64;
      $scope.mapH = { height: (winH - topH - listH) };
    })
  }

  angular.element($window).bind('resize', function () {
    $timeout(function () { $scope.$apply(); });
    $scope.setMapHeight();
    $location.hash('sbh-top-menu');
    $anchorScroll();
    $location.url($location.path());
  });

  $scope.mapToastWithText = function (text) {
    $mdToast.show(
      $mdToast.simple()
        .textContent(text)
        .capsule(true)
        .position('bottom right')
        .parent($document[0].querySelector('#mapDiv'))
    );
  }

  scp.$on('$destroy', function () {
    if (navigator.geolocation) {
      navigator.geolocation.clearWatch(scp.watchId);
    }
    if (busRefreshInt) {
      $interval.cancel(busRefreshInt);
    }
    scp.map.destroy();
  })
}

'use strict';

angular.module('schoolbushubApp')
    .factory('MainService', ["$http", function ($http) {
        return {
            updateContactInformation: function(email, phone, sendEmail){
                return $http.post('/api/updateContactInformation?&email='+ email + '&phone=' + phone
                + '&sendEmail=' + sendEmail);
            },
            deleteCurrentUser: function(password) {
                return $http.delete('/api/users/userDeletionValidated/?key=' + encodeURIComponent(password));
            },
            findStudents: function () {
                return $http.get('api/users/students').then(function (response) {
                    return response.data;
                });
            },
            verifyAndAddStudent: function (schoolCode, dateOfBirth, idStudent, organizationId, callback) {
                return $http.post('api/users/verifyaddstudent?schoolCode=' + schoolCode +
                    '&dateOfBirth=' + moment(dateOfBirth).format('YYYY-MM-DD') + '&idStudent=' + idStudent
                    +'&organizationId='+organizationId)
                    .then(function successCallback(data) {
                    	callback({status:data});
                    },function errorCallback(data) {
                        callback({status:'error'});
                    });
            },
            deleteStudent: function(studentId, callback) {
                return $http.post('api/users/deleteStudent?studentId=' + studentId)
                .then(function successCallback(){
                    callback({status:"Ok"});
                },function errorCallback(data){
                    callback({status:'error'});
                });
            },
            getBus: function (studentId, schoolId) {
                return $http({
                    url:'api/studentLocationDetails',
                    method: "GET",
                    params: {"studentId" : studentId, "schoolId" : schoolId}
                }).then(function (response) {
                    return response.data;
                }, function(err) {
                    return undefined;
                });
            }
        };
    }]);

'use strict';

angular.module('schoolbushubApp')
    .controller('AddStudentController', ["$scope", "$mdDialog", "Notifications", "MainService", "Principal", "$filter", function ($scope, $mdDialog, Notifications, MainService, Principal, $filter) {
        Principal.identity().then(function(account) {
            $scope.account = account;
        });
        $scope.verifying = false;
        $scope.schoolCode;
        $scope.idStudent;
    	$scope.dateOfBirth;
        var today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        $scope.cancel = function() {
            $("#dateOfBirth").datepicker("hide");
            $mdDialog.cancel();
        };

        /**
         * Verify Student Callback
         * @param response
         */
        var verifyStudentCallback = function(response) {
            if (response.status.data == 'added') {
                $mdDialog.hide();
            } else if (response.status.data == 'duplicate') {
                Notifications.showToastModals('error', $filter('translate')('main.addStudent.duplicateStudent'));
            } else {
            	Notifications.showToastModals('error', $filter('translate')('main.addStudent.studentNotFound'));
            }
            $scope.verifying = false;
        };

        /**
         * Verify Student
         */
        $scope.verifyStudent = function() {
            if($scope.validated()) {
                $scope.verifying = true;
                MainService.verifyAndAddStudent($scope.schoolCode, moment($scope.dateOfBirth, $filter('translate')('main.formatDateMoment')).toDate(), $scope.idStudent, $scope.account.login, verifyStudentCallback);
            }
        };

        /**
         * Validation of the fields
         * @returns {boolean}
         */
        $scope.validated = function(){
            var validated = true;
            if($scope.dateOfBirth) {
                var dateByParts = $scope.dateOfBirth.split('/');
                if(dateByParts.length == 3){
                    _.each(dateByParts, function (num) { if (isNaN(num) || isNaN(parseFloat(num))) {validated = false;}});
                }else{
                    validated = false;
                }
                if (validated) {
                    if (!moment($scope.dateOfBirth, [$filter('translate')('main.formatDateMoment')]).isValid()) {
                        validated = false;
                        Notifications.showToastModals('error', $filter('translate')('main.addStudent.dateBirthIsInvalid'));
                    } else {
                        var birth = moment($scope.dateOfBirth, $filter('translate')('main.formatDateMoment')).toDate();
                        if (moment(birth).diff(today, 'hours') > 0) {
                            validated = false;
                            Notifications.showToastModals('error', $filter('translate')('main.addStudent.dateBirthIsHigherCurrentDate'));
                        }
                    }
                } else {
                    Notifications.showToastModals('error', $filter('translate')('main.addStudent.dateBirthIsInvalid'));
                }
            }else{
                validated = false;
                Notifications.showToastModals('error', $filter('translate')('main.addStudent.birthDateRequired'));
            }
            return validated;
        }

    }]);

'use strict';

angular.module('schoolbushubApp')
    .service('Notifications', ["$mdToast", "$document", function ($mdToast, $document) {

        /**
         * Show Toast Account
         *
         * @param type
         * @param msg
         */
        var showToastAccount = function(type, msg) {
            $mdToast.show({
                template: '<md-toast class="'+ type +'">'
				+'<md-icon class="icon" aria-label=""></md-icon>'
                +'<span flex>'+msg+'</span>'
                +'<md-button ng-click="closeToast()">'
                +'x'
                +'</md-button>'
                +'</md-toast>',
                controller:'ToastNotificationCtrl',
                hideDelay: 6000,
                parent : $document[0].querySelector('#toastBounds'),
                position: 'top'
            });
        };

        /**
         * Show Toast Account
         *
         * @param type
         * @param msg
         */
        var showToastModals = function(type, msg) {
            $mdToast.show({
                template: '<md-toast class="md-toast ' + type +'">'
				+'<md-icon class="icon" aria-label=""></md-icon>'
                +'<span flex>'+msg+'</span>'
                +'<md-button ng-click="closeToast()">'
                +'x'
                +'</md-button>'
                +'</md-toast>',
                controller:'ToastNotificationCtrl',
                hideDelay: 6000,
                parent : $document[0].querySelector('#ModalContainer'),
                position: 'top'
            });
        };

        var showCustomToast = function(controller, templateUrl, delay) {
            $mdToast.show({
              controller: controller,
              templateUrl: templateUrl,
              hideDelay: (delay? delay : 6000),
              parent : $document[0].querySelector('#toastBounds'),
              position: 'top'
            });
        };

        /**
         * Show Toast Account
         *
         * @param type
         * @param msg
         */
        var showToastDashboard = function(type, msg) {
            $mdToast.show({
                template: '<md-toast class="md-toast ' + type +'">'
                +'<md-icon class="icon" aria-label=""></md-icon>'
                +'<span flex>'+msg+'</span>'
                +'<md-button ng-click="closeToast()">'
                +'x'
                +'</md-button>'
                +'</md-toast>',
                controller:'ToastNotificationCtrl',
                hideDelay: 6000,
                parent : $document[0].querySelector('#dashboardAlert'),
                position: 'top'
            });
        };

        return{
            showToastAccount:showToastAccount,
            showToastModals:showToastModals,
            showCustomToast:showCustomToast,
            showToastDashboard:showToastDashboard
        }
    }]);

'use strict';

angular.module('schoolbushubApp')
.controller('ToastNotificationCtrl', ["$scope", "$mdToast", function($scope, $mdToast) {
    $scope.closeToast = function() {
        $mdToast.hide();
    };
}]);

"use strict";

angular
    .module("schoolbushubApp")
    .controller(
        "MenuController",
        ["$scope", "$state", "Auth", "Principal", "$mdSidenav", "$rootScope", "$filter", "Language", "$translate", "tmhDynamicLocale", "$mdDialog", "MainService", "$mdToast", function (
            $scope,
            $state,
            Auth,
            Principal,
            $mdSidenav,
            $rootScope,
            $filter,
            Language,
            $translate,
            tmhDynamicLocale,
            $mdDialog,
            MainService,
            $mdToast
        ) {
            DialogController.$inject = ["$scope", "$mdDialog", "$filter", "account"];
            $scope.showAdministration = false;
            $scope.labelLanguage;
            $scope.isProcessing = true;

            $scope.toggleMenu = function (close) {
                close ? $mdSidenav("left").close() : $mdSidenav("left").toggle();
            };

            /**
             * Identification of authorizations and language
             */
            Principal.identity().then(function (account) {
                $scope.account = account;
                if ($scope.account) {
                    $rootScope.userName = $scope.account.firstName + " " + $scope.account.lastName;
                    var roles = _.filter($scope.account.authorities, function (rol) {
                        return rol === "ROLE_ADMIN" || rol === "ROLE_CUSTOMERADMIN" || rol === "ROLE_SEONADMIN";
                    });
                    if (roles.length > 0) {
                        $scope.showAdministration = true;
                    }
                    if (account.langKey === "en") {
                        $scope.labelLanguage = $filter("translate")("main.menu.spanish");
                    } else {
                        $scope.labelLanguage = $filter("translate")("main.menu.english");
                    }
                }
            });

            /**
             * Change Language
             *
             * @param languageKey
             */
            $scope.changeLanguage = function (languageKey) {
                if (languageKey) {
                    $translate.use(languageKey);
                    moment.locale(languageKey);
                    tmhDynamicLocale.set(languageKey);
                    Principal.identity().then(function (account) {
                        Language.updateUserLanguage(languageKey);
                        account.langKey = languageKey;
                    });
                } else {
                    Principal.identity().then(function (account) {
                        var languageKey;
                        if (account.langKey === "en") {
                            languageKey = "es";
                            $scope.labelLanguage = $filter("translate")("main.menu.english");
                        } else {
                            languageKey = "en";
                            $scope.labelLanguage = $filter("translate")("main.menu.spanish");
                        }
                        Language.updateUserLanguage(languageKey);
                        $translate.use(languageKey);
                        moment.locale(languageKey);
                        tmhDynamicLocale.set(languageKey);
                        account.langKey = languageKey;
                    });
                }
            };

            $scope.privacyPolicy = function () {
                window.open("https://www.safefleet.net/privacy-policy/");
            };

            /**
             * Edit profile
             */
            $scope.editProfile = function (e) {
                $mdDialog
                    .show({
                        controller: DialogController,
                        templateUrl: "edit-contact-information.html",
                        parent: angular.element(document.body),
                        targetEvent: e,
                        clickOutsideToClose: false,
                        fullscreen: true,
                        resolve: {
                            account: function () {
                                return $scope.account;
                            },
                        },
                    })
                    .then(function () {
                        var toast = $mdToast
                            .simple()
                            .textContent($filter("translate")("main.editProfile.saveSuccess"))
                            .position("bottom right")
                            .hideDelay(3000);
                        $mdToast.show(toast);
                    });
            };

            function DialogController($scope, $mdDialog, $filter, account) {
                $scope.isParent = account.authorities.length === 1 && account.authorities[0] === 'ROLE_PARENT';
                $scope.email = account.email;
                $scope.phone = account.mobileNumber;
                $scope.sendEmail = account.sendEmail;

                $scope.cancel = function () {
                    $mdDialog.cancel();
                };


                $scope.deleteAccountShown = false;

                $scope.toggleDeleteConfirm = function (show) {
                    $scope.deleteAccountShown = !!show;
                };

                $scope.confirmDelete = function (ev, pass) {
                    $scope.isProcessing = true;
                        var confirm = $mdDialog
                        .confirm()
                        .title($filter("translate")("main.menu.deleteConfirm"))
                        .textContent($filter("translate")("main.menu.deleteCantUndo"))
                        .ariaLabel("confirm delete")
                        .targetEvent(ev)
                        .multiple(true)
                        .ok($filter("translate")("main.addStudent.submit"))
                        .cancel($filter("translate")("main.addStudent.cancel"));

                    $mdDialog.show(confirm).then(
                      function () {
                        MainService.deleteCurrentUser(pass).then(
                            function () {
                                $mdDialog.hide();
                                Auth.logout();
                                $scope.isProcessing = false;
                                $state.go("login");
                            },
                            function (err) {
                                var msgKey =
                                    err.status === 404 ? "main.menu.invalidPassword" : "main.menu.deleteAccountError";
                                var errorAlert = $mdDialog
                                    .alert()
                                    .title($filter("translate")("main.menu.deleteAccountError"))
                                    .textContent($filter("translate")(msgKey))
                                    .multiple(true)
                                    .ok($filter("translate")("main.deleteStudent.ok"));

                                $scope.isProcessing = false;
                                $mdDialog.show(errorAlert);
                            }
                        );
                      },
                      function() {
                        $scope.isProcessing = false;
                      }
                  );
                };

                $scope.save = function (answer) {
                    MainService.updateContactInformation($scope.$$childTail.email, $scope.$$childTail.phone, $scope.$$childTail.sendEmail).then(
                        function () {
                            //update the current account object
                            account.email = $scope.$$childTail.email;
                            account.mobileNumber = $scope.$$childTail.phone;
                            account.sendEmail = $scope.$$childTail.sendEmail;
                            console.log("contact information updated!");
                            $mdDialog.hide(answer);
                        },
                        function (err) {
                            var msg = $filter("translate")("main.editProfile.saveError");
                            if (err.data.exception && err.data.exception.includes("DataIntegrityViolationException")) {
                                msg = $filter("translate")("main.editProfile.emailDuplicated");
                            }
                            var toast = $mdToast.simple().textContent(msg).position("bottom right").hideDelay(3000);
                            $mdToast.show(toast);
                            console.error(err);
                        }
                    );
                };
            }

            /**
             * Logout
             */
            $scope.logout = function () {
                Auth.logout();
                $state.go("login");
            };

            /**
             * Administration
             */
            $scope.administration = function () {
                $state.go("administration");
            };

            $scope.goToMain = function () {
                $state.go("home");
            };
            $scope.goToMaps = function () {
                $state.go("maps");
            };

            $scope.changeView = function () {
                if ($state.current.name === "home") $state.go("maps");

                if ($state.current.name === "maps") $state.go("home");
            };

            $scope.getCurrentStyle = function (str) {
                return $state.current.name === str ? "current-section" : "";
            };
        }]
    );

'use strict';

angular.module('schoolbushubApp')
  .config(["$stateProvider", function ($stateProvider) {
        $stateProvider.state('menu', {
            'abstract': true,
            data: {
                authorities: ['ROLE_PARENT', 'ROLE_ADMIN']
            },
            views: {
                'app-menu@': {
                    templateUrl: 'scripts/components/menu/menu.html',
                    controller: 'MenuController'
                },
                'left-menu@': {
                    templateUrl: 'scripts/components/menu/left-menu.html',
                    controller: 'MenuController'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ]
            }
        });
  }]);

'use strict';

angular.module('schoolbushubApp')
    .service('DataService', function(){

    var data = {};

    return { get:get, set:set };

    function get(key){
        return data[key];
    };

    function set(key, value){
        data[key] = value;
    };
})

angular.module('schoolbushubApp').service('SharedService', SharedService);
SharedService.$inject = ['$q', '$translate', 'tmhDynamicLocale', 'Principal', 'DataService', 'MainService'];

function SharedService($q, $translate, tmhDynamicLocale, Principal, DataService, MainService) {
	var svc = this;

	var bClasses = ['blue-block', 'aqua-block', 'sprint-green-block', 'green-block'];
	var bgIconClasses = ['purple', 'pink', 'blue', 'cyan'];

	svc.getCredentials = function(force) {
		var def = $q.defer();
		if (DataService.get('credentials')  && !force) {
			def.resolve(DataService.get('credentials'));
		}
		else {
			Principal.identity().then(function(resp) {
				DataService.set('credentials', { account: resp, isAuthenticated: Principal.isAuthenticated });
	            $translate.use(resp.langKey);
	            tmhDynamicLocale.set(resp.langKey);
	            def.resolve(DataService.get('credentials'));
	    	});
		}
		return def.promise;
	}

	svc.getStudents = function(force) {
		var def = $q.defer();
		if(DataService.get('students') && !force) {
			def.resolve(DataService.get('students'));
		}
		else {
			MainService.findStudents().then(function(students){
			    if(students && students.length > 0){
			        _.first(students).show = true;
			    }

			    _.each(students, function(st, idx) {
			    	st.blockClass   		= bClasses[idx%4];
			    	st.backgroundIconClass 	= bgIconClasses[idx%4];
			    });

			    DataService.set('students', students);
			    def.resolve(students);
			});
		}
		return def.promise;
	}

	svc.getBus = function(studentId, schoolId) {
		var def = $q.defer();
		MainService.getBus(studentId, schoolId).then(function(bus){
			def.resolve(bus);
		});
		return def.promise;
	}



}

angular.module('schoolbushubApp').directive('numbersOnly', function(){
   return {
     require: 'ngModel',
     restrict: 'A',
     link: function(scope, element, attrs, ctrl) {
       ctrl.$parsers.push(function (inputValue) {
           // this next if is necessary for when using ng-required on your input.
           // In such cases, when a letter is typed first, this parser will be called
           // again, and the 2nd time, the value will be undefined
           if (inputValue == undefined) return ''
           var transformedInput = inputValue.replace(/[^0-9]/g, '');
           if (transformedInput!=inputValue) {
              ctrl.$setViewValue(transformedInput);
              ctrl.$render();
           }

           return transformedInput;
       });
     }
   };
});

'use strict';

angular.module('schoolbushubApp')
  .service('Verify', ["$http", function ($http) {

    /**
     * Verify Student
     *
     * @param schoolCode
     * @param dateOfBirth
     * @param idStudent
     * @param callback
     */
    var verifyStudent = function(schoolCode, dateOfBirth, idStudent, organizationId, callback){
        var url = 'api/verifyStudent?schoolCode='+schoolCode+'&dateOfBirth='+moment(dateOfBirth).format('YYYY-MM-DD')
          +'&idStudent='+idStudent+'&organizationId='+organizationId;
        $http.post(url,[])
            .then(function(response) {
                callback({status:'success',verificationKey:response.data});
            }).catch(function() {
                callback({status:'error'});
            }
        );
    }

    return{
        verifyStudent:verifyStudent
    }

  }]);

(function() {
	angular.module("badge-counter", [])
	angular.module("badge-counter").directive('badgeCount', BadgeCount)

	// BadgeCount.$inject = [];

	function BadgeCount() {
		return {
			restrict: 'A',
			scope: {
				badgeCount: '=?'
			},
			// template: ''
			link: function(scope, element, attrs) {
				if (!scope.badgeCount) scope.badgeCount = 0;
				scope.badgeCount = Number(scope.badgeCount)
				if (!Number.isNaN(scope.badgeCount)) {
					var n = Math.abs(scope.badgeCount);
					if (n > 99) {
						n = '!';
					}
					else {
						n = 'a';
					}
					if (n) {
						element.attr('data-badge-count', n);
						element.toggleClass('badge-icon', true);

					}
				}
			}
		}
	}
}) ();
/* Inicialización en español para la extensión 'UI date picker' para jQuery. */
/* Traducido por Vester (xvester@gmail.com). */
(function( factory ) {
	if ( typeof define === "function" && define.amd ) {

		// AMD. Register as an anonymous module.
		define([ "../datepicker" ], factory );
	} else {

		// Browser globals
		factory( jQuery.datepicker );
	}
}(function( datepicker ) {

datepicker.regional['es'] = {
	closeText: 'Cerrar',
	prevText: '&#x3C;Ant',
	nextText: 'Sig&#x3E;',
	currentText: 'Hoy',
	monthNames: ['enero','febrero','marzo','abril','mayo','junio',
	'julio','agosto','septiembre','octubre','noviembre','diciembre'],
	monthNamesShort: ['ene','feb','mar','abr','may','jun',
	'jul','ago','sep','oct','nov','dic'],
	dayNames: ['domingo','lunes','martes','miércoles','jueves','viernes','sábado'],
	dayNamesShort: ['dom','lun','mar','mié','jue','vie','sáb'],
	dayNamesMin: ['D','L','M','X','J','V','S'],
	weekHeader: 'Sm',
	dateFormat: 'dd/mm/yy',
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: ''};
datepicker.setDefaults(datepicker.regional['es']);

return datepicker.regional['es'];

}));

/* French initialisation for the jQuery UI date picker plugin. */
/* Written by Keith Wood (kbwood{at}iinet.com.au),
			  Stéphane Nahmani (sholby@sholby.net),
			  Stéphane Raimbault <stephane.raimbault@gmail.com> */
(function( factory ) {
	if ( typeof define === "function" && define.amd ) {

		// AMD. Register as an anonymous module.
		define([ "../datepicker" ], factory );
	} else {

		// Browser globals
		factory( jQuery.datepicker );
	}
}(function( datepicker ) {

datepicker.regional['fr'] = {
	closeText: 'Fermer',
	prevText: 'Précédent',
	nextText: 'Suivant',
	currentText: 'Aujourd\'hui',
	monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin',
		'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
	monthNamesShort: ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin',
		'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
	dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
	dayNamesShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
	dayNamesMin: ['D','L','M','M','J','V','S'],
	weekHeader: 'Sem.',
	dateFormat: 'dd/mm/yy',
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: ''};
datepicker.setDefaults(datepicker.regional['fr']);

return datepicker.regional['fr'];

}));

    angular.module("schoolbushubApp")
    .directive('datePicker', ["$parse", "Language", function ($parse, Language) {

    var linker = function (scope, element, attrs) {
        if(attrs.inline == "true"){
            jQuery(element).datepicker(
                {
                    changeYear: true,
                    yearRange: '1950:2050',
                    inline: true,
                    altField: '#'+attrs.idInput
                });

            $('#'+attrs.idInput).change(function(){
                element.datepicker('setDate', $(this).val()).css('z-index', 2000);
            });
        }else{
            jQuery(element).datepicker(
                {
                    changeYear: true,
                    yearRange: '1950:2050'
                });
        }

        Language.getCurrent().then(function (language) {
             element.datepicker("option", $.datepicker.regional[language]).css('z-index', 2000);
             element.datepicker("option", "dateFormat", "mm/dd/yy");
        });
    };

    return {
        restrict: 'A',
        link: linker,
        scope: {
            content: '='
        }
    };
}]);

angular.module('seon.queryBuilder', []);

angular.module('seon.queryBuilder').config(["$translateProvider", function ($translateProvider) {
    //$translateProvider.useMissingTranslationHandler('undefHandler');
}]);

angular.module('seon.queryBuilder').filter('splitNames', ["$translate", function($translate) {
    return function (fieldName /*, uses*/) {
        return $translate.instant(
            fieldName.replace(/^(filters\.columns\.)/,"")
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/^./, function(str){ return str.toUpperCase(); })
                    .replace('.', ' ')
        );
    }
}])
.filter('cutLargeLabels', ["$mdMedia", function($mdMedia){
    return function(str){
        if(str.length > 18 && $mdMedia('xs')){
            return str.substring(0, 18) + '...'
        }

        return str
    }
}]);

angular.module('seon.queryBuilder').directive('queryBuilderContainer', QueryBuilderContainer);
QueryBuilderContainer.$inject = ['$translate', '$sce', '$uibModal', '$window', '$timeout'];

function QueryBuilderContainer($translate, $sce, $uibModal, $window, $timeout) {
    return {
        restrict: 'E',
        scope: {
            fields: '=',
            advancedQuery: '=',
            queryObject: '=',
            filterCallback: '='
        },
        templateUrl: 'scripts/components/filters/filtersContainer.html',
        link: function(scope, elem, attrs) {

            scope.group = {
                'operator' : 'and',
                'rules' : [{condition: '', field:'', data:''}]
            };

            scope.queryStr = '';
            scope.showBuilder = false;
            scope.showQueryStr = true;
            scope.advMode = scope.advancedQuery;

            scope.toggleGroups = function(show) {
                if (!show && _.find(scope.group.rules, function(rule){ return rule.hasOwnProperty('group')})) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        keyboard: true,
                        templateUrl: 'templates/ConfirmBasic.html',
                        controller: 'ConfirmBasicCtrl',
                        size: 'sm'
                    });

                    modalInstance.result.then(function(status) {
                        removeAllGroups();
                    }, function(reason) {
                        scope.advMode = true;
                    });
                }
            }

            function removeAllGroups() {
                var newGroup = {'operator' : scope.group.operator, 'rules' : []};
                angular.forEach(scope.group.rules, function(val, key) {
                    if (!val.hasOwnProperty('group')) {
                        newGroup.rules.push(val);
                    }
                });
                scope.group = newGroup;
            }

            scope.$watch('group', function(newVal, oldVal) {
                scope.queryObject = scope.cleanGroup(newVal);
                scope.queryStr = $sce.trustAsHtml(setQueryString(scope.queryObject).slice(1, -1));
            }, true);

            scope.cleanGroup = function(group) {
                var resp = {operator: group.operator, rules: []};
                angular.forEach(group.rules, function(e) {
                    if (e.hasOwnProperty('group')) {
                        var newGrp = {group: scope.cleanGroup(e.group)};
                        if (newGrp.group.rules.length > 0) {
                            resp.rules.push(newGrp);
                        }
                    }
                    else {
                        if (e.field && e.condition && (e.data || e.data === 0)) {
                            resp.rules.push({field: e.field.name, condition:e.condition, data:e.data});
                        }
                    }
                });
                return resp;
            }

            function setQueryString(group) {
                if (!group) return "";
                for (var str = "(", i = 0; i < group.rules.length; i++) {
                    i > 0 && (str += " " + ('<b>' + group.operator.toUpperCase() + '</b>') + " ");
                    str += group.rules[i].group ?
                    setQueryString(group.rules[i].group) :
                    group.rules[i].field + " " + ('<b>' + htmlEntities(group.rules[i].condition) + '</b>') + " " + group.rules[i].data;
                }
                return str + ")";
            }

            function htmlEntities(str) {
                return String(str).replace(/</g, '&lt;').replace(/>/g, '&gt;');
            }

            function cleanGroupAndQueryStr(group) {
                var grp = {operator: group.operator, rules: []};
                var str = "(";

                angular.forEach(group.rules, function(e, i) {
                    i > 0 && (str += " " + group.operator + " ");

                    if (e.hasOwnProperty('group')) {
                        var inner = cleanGroupAndQueryStr(e.group);
                        var newGrp = {group: inner.group};
                        if (newGrp.group.rules.length > 0) {
                            str += inner.string;
                            grp.rules.push(newGrp);
                        }
                    }
                    else {
                        if (e.field && e.condition && e.data) {
                            grp.rules.push({field: e.field.name, condition:e.condition, data:e.data});
                            str += e.field.name + " " + htmlEntities(e.condition) + " " + e.data;
                        }
                    }
                })

                return {group: grp, string: (str + ")")};
            }


            scope.clearFilters = function() {
                scope.group = {
                    'operator' : 'and',
                    'rules' : [{condition: '', field:'', data:''}]
                }
                $timeout(function() {
                    scope.filterCallback();
                });
            }
        }
    };
}

angular.module('seon.queryBuilder').controller('ConfirmBasicCtrl', ConfirmBasicCtrl);
ConfirmBasicCtrl.$inject = ['$scope', '$uibModalInstance'];

function ConfirmBasicCtrl($scope, $uibModalInstance) {
    $scope.ok = function() {$uibModalInstance.close('ok'); }
    $scope.cancel = function() {$uibModalInstance.dismiss('cancel'); };
}


angular.module('seon.queryBuilder').directive('queryBuilder', QueryBuilder);
QueryBuilder.$inject = ['$compile', '$translate', '$timeout'];

function QueryBuilder($compile, $translate, $timeout) {
    return {
        restrict: 'E',
        scope: {
            fields: '=fields',
            group: '=',
            advancedQuery: '='
        },
        templateUrl: 'scripts/components/filters/filters.html',
        compile: function (element, attrs) {
            var content, directive;
            content = element.contents().remove();
            return function (scope, element, attrs) {

                scope.highlightCondition = function(ev) {
                    angular.element(jQuery(ev.currentTarget).closest('div')).toggleClass('highlighted', true);
                }

                scope.unhighlightCondition = function(ev) {
                    angular.element(jQuery(ev.currentTarget).closest('div')).toggleClass('highlighted', false);
                }

                scope.collapseGroup = false;
                scope.toggleGroup = function() {
                    scope.collapseGroup = !scope.collapseGroup;
                    scope.collapseGroup && (scope.innerExpression = computed(scope.group));
                }

                function computed(group) {
                    if (!group) return "";
                    for (var str = "(", i = 0; i < group.rules.length; i++) {
                        var rule = group.rules[i];
                        if (!rule.group && (!rule.field.name || !rule.condition || !rule.data)) { continue;}
                        i > 0 && (str += " " + group.operator + " ");
                        str += group.rules[i].group ?
                        computed(group.rules[i].group) :
                        rule.field.name + " " + (rule.condition) + " " + rule.data;
                    }
                    return str + ")";
                }


                //calendar stuff
                scope.dateFormat = "MM/dd/yyyy";
                scope.datetimeFormat = "MM/dd/yyyy HH:mm:ss";
                // scope.datepickerOpts = { maxDate: moment() }
                // scope.altInputFormats = ["yyyy-MM-dd HH:mm:ss", "MM/dd/yyyy HH:mm:ss", 'yyyy-MM-dd', 'yyyy/MM/dd'];
                // scope.calendarOpened = false;
                // scope.format = scope.altInputFormats[1];
                // console.log("teh formt:", scope.format)


                scope.$watch('advancedQuery', function(newVal) {
                    scope.showRemGrp = scope.advancedQuery && scope.nestedLevel > 0;
                    scope.showAddGrp = scope.advancedQuery && scope.nestedLevel < 5;
                }, true);


                scope.validateString = function (rule) {
                    if (rule.data && rule.data.length > 100) {
                        rule.data = rule.data.substring(0, 100);
                    }
                }

                scope.checkNumbers = function(ev) {
                    var key = ev.keyCode || ev.charCode;
                    if(isNaN(String.fromCharCode(key))){ ev.preventDefault(); }
                }

                scope.validateNumber = function (rule) {
                    if (rule.data && rule.data.toString().length > 11) {
                        rule.data = Number(rule.data.toString().substring(0, 11));
                    }
                }

                scope.updateData = function(rule) {
                    var d = rule.pickerDate;
                    if (d && rule.field.type == 'date') {
                        rule.data = moment(d).format("YYYY-MM-DD");
                    }
                    else if (d && rule.field.type == 'dateTime') {
                        rule.data = moment.utc(d).format("YYYY-MM-DDTHH:mm:ss[Z]");
                    }
                    else {
                        rule.data = '';
                        rule.pickerDate = undefined;
                    }
                }


                //vars to show/hide elements
                scope.isChildFiltersDirective = ("isChildFiltersDirective" in scope.$parent);
                scope.nestedLevel = scope.isChildFiltersDirective?  (scope.$parent.nestedLevel + 1) : 0;
                scope.showRemGrp = scope.nestedLevel > 0;
                scope.showAddGrp = scope.advancedQuery && scope.nestedLevel < 5;
                scope.minRules = scope.nestedLevel == 0 ? 1 : 2;
                scope.maxRules = 15;

                scope.showRemRule = function() {
                    var r = scope.group.rules.filter(function(v) {
                            return !v.hasOwnProperty('group');
                    });
                    return (r.length > scope.minRules);
                }


                scope.operators = [
                    { name: 'All', value: 'and', locale: 'filters.all' },
                    { name: 'Any', value: 'or' , locale: 'filters.any' }
                ];

                //conditions vars
                var eq = {name: 'equals',       value: '=',    locale: 'filters.equal'      };
                var ne = {name: 'not equal',    value: '!=',   locale: 'filters.notEqual'   };
                var gt = {name: 'greater than', value: '>',    locale: 'filters.greaterThan'};
                var lt = {name: 'less than',    value: '<',    locale: 'filters.lessThan'   };
                var lk = {name: 'contains',     value: 'like', locale: 'filters.contains'   };
                scope.conditions = {
                    "text": [eq, ne, lk],
                    "boolean": [eq, ne],
                    "number": [eq, ne, gt, lt],
                    "date" : [eq, ne, gt, lt],
                    "dateTime" : [eq, ne, gt, lt]
                }

                scope.setConditions = function (r, old) {
                    (r.field.type != old.field.type) && (r.data = '');
                    (r.field.type == 'boolean') && (r.condition = '=');
                    (r.field.type == 'date') && (r.calendarOpened = false);
                }

                scope.addCondition = function () {
                    if (!scope.group.rules.length >= scope.maxRules) {
                        return;
                    }
                    scope.group.rules.push({
                        condition: '',
                        field: '',
                        data: ''
                    });
                };

                scope.removeCondition = function (index) {
                    if (!scope.showRemRule) {
                        return;
                    }
                    scope.group.rules.splice(index, 1);
                };

                scope.addGroup = function () {
                    if (!scope.group.rules.length >= scope.maxRules) {
                        return;
                    }
                    scope.group.rules.push({
                        group: {
                            operator: 'and',
                            rules: [{condition: '', field:'', data:''}, {condition: '', field:'', data:''}]
                        }
                    });
                };

                scope.removeGroup = function () {
                    "group" in scope.$parent && scope.$parent.group.rules.splice(scope.$parent.$index, 1);
                };

                directive || (directive = $compile(content));

                element.append(directive(scope, function ($compile) {
                    return $compile;
                }));
            }
        }
    }
}
