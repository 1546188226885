'use strict';

angular.module('schoolbushubApp')
	.controller('OrganizationDeleteController', function($scope, $uibModalInstance, entity, Organization, $filter, AlertService) {

        $scope.organization = entity;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Organization.removeOrganization(id, function(response, error){
                if(response.status == 'success'){
                    $uibModalInstance.close(true);
                    $scope.clear();
                }else{
                    $uibModalInstance.dismiss('cancel');
                    if(error.numberOfStudents){
                        AlertService.error($filter('translate')('schoolbushubApp.organization.organizationPartnerStudents', { number: error.numberOfStudents }));
                    }else{
                        if(error.numberOfSchools){
                            AlertService.error($filter('translate')('schoolbushubApp.organization.organizationPartnerSchools', { number: error.numberOfSchools }));
                        }else{
                            if(error.numberOfUsers){
                                AlertService.error($filter('translate')('schoolbushubApp.organization.organizationPartnerUsers', { number: error.numberOfUsers }));
                            }else{
                                AlertService.error($filter('translate')('schoolbushubApp.organization.errorDelete'));
                            }
                        }
                    }
                }
            });
        };
    });
