'use strict';

angular.module('schoolbushubApp')
    .controller('SchoolController', function ($scope,  $filter, AlertService, $state, $uibModal, School, ParseLinks) {

        $scope.schools = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.loadAll = function() {
            var pageable = {page: $scope.page, size: $scope.per_page, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            School.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.total = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.schools.push(result[i]);
                }
            }, function(err) {
                AlertService.error($filter('translate')('filters.resultMessages.resultsError'));
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.schools = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            if ($scope.links && page > $scope.links['last']) { return; };
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.fields = School.filterVars();
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.school = {
                name: null,
                code: null,
                id: null
            };
        };

        $scope.viewOrganizationDetail = function(org) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/entities/organization/organization-detail.html',
                controller: 'OrganizationDetailController',
                size: 'lg',
                resolve: {entity: function() { return org; } }
            });
        }

        $scope.viewSchoolDetail = function(schl) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/entities/school/school-detail.html',
                controller: 'SchoolDetailController',
                size: 'lg',
                resolve: {entity: function() { return schl; } }
            });
        }
    });
