'use strict';

angular.module('schoolbushubApp')
    .controller('SendActivationEmailController', function ($scope, $location, Notifications, $filter, $http, $mdToast, DataService, Language) {

    var langKey = '';
    Language.getCurrent().then(function (language) {
        langKey = language;
    })

    $scope.sendActivationEmail = function() {

        var username = DataService.get("username");

        var response = $http.get('/api/sendActivationEmail/' + username + '/' + langKey);

        response.then(function() {
            $location.path('/sendActivationEmailSuccess');

        });

        response.catch(function() {
            Notifications.showToastAccount('error', $filter('translate')('login.messages.error.errorSendActivationEmail'));
        });

    };

    $scope.closeToast = function() {
        $mdToast.hide();
    };

  });
