'use strict';

angular.module('schoolbushubApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('school', {
                parent: 'entity',
                url: '/schools',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN'],
                    pageTitle: 'schoolbushubApp.school.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/school/schools.html',
                        controller: 'SchoolController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('school');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('organization');
                        return $translate.refresh();
                    }]
                }
            })
            // .state('school.detail', {
            //     parent: 'entity',
            //     url: '/school/{id}',
            //     data: {
            //         authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN'],
            //         pageTitle: 'schoolbushubApp.school.detail.title'
            //     },
            //     views: {
            //         'content@': {
            //             templateUrl: 'scripts/app/entities/school/school-detail.html',
            //             controller: 'SchoolDetailController'
            //         }
            //     },
            //     resolve: {
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('school');
            //             return $translate.refresh();
            //         }],
            //         entity: ['$stateParams', 'School', function($stateParams, School) {
            //             return School.get({id : $stateParams.id});
            //         }]
            //     }
            // })
            .state('school.new', {
                parent: 'school',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/school/school-dialog.html',
                        controller: 'SchoolDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    code: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('school', null, { reload: true });
                    }, function() {
                        $state.go('school');
                    })
                }]
            })
            .state('school.edit', {
                parent: 'school',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/school/school-dialog.html',
                        controller: 'SchoolDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['School', function(School) {
                                return School.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('school', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('school.delete', {
                parent: 'school',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/school/school-delete-dialog.html',
                        controller: 'SchoolDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['School', function(School) {
                                return School.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('school', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    });
