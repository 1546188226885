'use strict';

angular.module('schoolbushubApp')
    .service('SchoolCode', function () {

        var schoolCode;

        var get = function(){
            var sc = schoolCode;
            schoolCode = undefined;
            return sc;
        }

        var set = function(sc){
            schoolCode = sc;
        }

        return{
            get:get,
            set:set
        }

    });

