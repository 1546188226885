'use strict';

angular.module('schoolbushubApp')
    .controller('SchoolDetailController', function ($scope, $rootScope, $stateParams, $uibModalInstance, entity, School, Organization) {
        $scope.school = entity;
        $scope.load = function (id) {
            School.get({id: id}, function(result) {
                $scope.school = result;
            });
        };

        var unsubscribe = $rootScope.$on('schoolbushubApp:schoolUpdate', function(event, result) {
            $scope.school = result;
        });

        $scope.close = function() {
            unsubscribe();
            $uibModalInstance.dismiss('ok');
        };

        $scope.$on('$destroy', unsubscribe);

    });
