(function () {
  "use strict";

  angular.module("schoolbushubApp").controller("OrganizationDialogController", OrganizationDialogController);

  OrganizationDialogController.$inject = [
    "$scope",
    "$stateParams",
    "$uibModalInstance",
    "$q",
    "$http",
    "entity",
    "Organization",
    "User",
    "School",
    "isNew"
  ];
  function OrganizationDialogController($scope, $stateParams, $uibModalInstance, $q, $http, entity, Organization, User, School, isNew) {
    if (entity.id === null) {
      entity.arrivalTimeWindow = 1200;
    }
    $scope.isNew = isNew;
    $scope.disableRouteNot = false;
    $scope.trueFalseOptions = [true, false];
    Organization.getEventSource(function(resp){
      $scope.eventSourceData=resp.data;
      $scope.nonNavigator = resp.data[1];
      $scope.organization.eventSource= entity.id === null ?  resp.data[0]:$scope.organization.eventSource;
    });
    $scope.organization = entity;

    $scope.users = User.query();
    $scope.schools = School.query();

    // LOCATION
    $scope.existingLocation = null;
    $scope.locationsAsync = [];

    var arcgisUrl = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/';
    var geocodeParams = '?f=json&maxSuggestions=10&category=City%2CSubregion&sourceCountry=CA%2CUSA';
    var candidateParams = '?f=json&outSR=%5Bobject+Object%5D&outFields=City%2CCounty%2CRegion%2CRegionAbbr%2CSubregion%2CCountry%2CMatch_addr&maxLocations=6';


    if ($scope.organization && $scope.organization.$promise) {

      $scope.organization.$promise.then(function () {
        var storedLocation = { text:  $scope.organization.locationName };
        $scope.locationsAsync = [storedLocation];
        $scope.location = storedLocation;
        $scope.orgCode = $scope.organization.code;
      });
    }


    // seon master data load
    $scope.loadingMaster = false;
    $scope.masterLoadError = false;

    var masterProperties = ["endpoint", "endpointUser", "endpointPassword", "timezone", "applicationCode", "streamingUrl"];

    $scope.load = function (id) {
      Organization.resource.get({ id: id }, function (result) {
        $scope.organization = result;
      });
    };

    $scope.timezones = [];

    _.forEach(moment.tz.names(), function (timezone) {
      $scope.timezones.push({
        name: timezone,
        zone: moment.tz(timezone).format("Z")
      });
    });

    $scope.getTimezoneOffset = function (timezone) {
      return moment.tz(timezone).format("Z");
    };

    var onSaveSuccess = function (result) {
      $scope.$emit("schoolbushubApp:organizationUpdate", result);
      $uibModalInstance.close(result);
      $scope.isSaving = false;
    };

    var onSaveError = function (result) {
      $scope.isSaving = false;
    };

    $scope.save = function () {
      $scope.isSaving = true;
      if ($scope.organization.id != null) {
        Organization.resource.update($scope.organization, onSaveSuccess, onSaveError);
      } else {
        Organization.resource.save($scope.organization, onSaveSuccess, onSaveError);
      }
    };

    $scope.loadOrg = function (code, alias, applicationCode) {
      $scope.loadingMaster = true;
      Organization.getOrganizationData(code, alias, applicationCode, function (resp) {
        $scope.loadingMaster = false;
        $scope.masterLoadError = !_.isUndefined(resp.error);
        if (resp.data) {
          $scope.orgCode = $scope.organization.code;
          _.each(masterProperties, function (prop) {
            $scope.organization[prop] = resp.data[prop];
          });
        }
      });
    };

    $scope.clear = function () {
      $uibModalInstance.dismiss("cancel");
    };


    $scope.getLocations = function (query) {
      var deferred = $q.defer();
      if (!query) {
        deferred.resolve();
        return deferred.promise;
      }

      var req = new XMLHttpRequest();
      req.open('GET', arcgisUrl + 'suggest' + geocodeParams + '&text=' + query, true);
      req.onreadystatechange = function () {

        if (req.readyState === 4) {
          if (req.status === 200) {
            $scope.locationsAsync = JSON.parse(req.responseText).suggestions;
          } else {
            $scope.locationsAsync = [];
          }
          $scope.$apply();
          deferred.resolve();
        }
      }
      req.send(null);

      return deferred.promise;
    };


    $scope.locationSelected = function (item) {
      if (!item) {
        $scope.organization.location = undefined;
        $scope.organization.locationName = undefined;
        return;
      }

      $scope.existingLocation = null;

      var req = new XMLHttpRequest();
      req.open('GET', arcgisUrl + 'findAddressCandidates' + candidateParams + '&magicKey=' + item.magicKey, true);
      req.onreadystatechange = function () {

        if (req.readyState === 4) {
          if (req.status === 200) {
            var result = JSON.parse(req.responseText);
            var match = result && result.candidates && result.candidates[0] || {};
            var sr = result && result.spatialReference;
            $scope.organization.state = match.attributes.Region;
            $scope.organization.city = match.attributes.City;
            $scope.organization.county = match.attributes.Subregion;
            $scope.organization.locationName = match.address;

            if (match.extent && match.location) {
              match.location.spatialReference = sr;
              match.extent.spatialReference = sr;
            }

            $scope.organization.extent = JSON.stringify(match.extent);
            $scope.organization.location = JSON.stringify(match.location);

          } else {
            $scope.organization.state = undefined;
            $scope.organization.city = undefined;
            $scope.organization.county = undefined;
            $scope.organization.locationName = undefined;
            $scope.organization.extent = undefined;
            $scope.organization.location = undefined;

            $scope.location = undefined;
          }
          $scope.$apply();
        }
      }
      req.send(null);
    };

    $scope.cleanOrganizationCode = function () {
      $scope.orgCode = undefined;
      $scope.organization.code= '';
    }
  }

})();
