'use strict';

angular.module('schoolbushubApp')
    .service('Notifications', function ($mdToast, $document) {

        /**
         * Show Toast Account
         *
         * @param type
         * @param msg
         */
        var showToastAccount = function(type, msg) {
            $mdToast.show({
                template: '<md-toast class="'+ type +'">'
				+'<md-icon class="icon" aria-label=""></md-icon>'
                +'<span flex>'+msg+'</span>'
                +'<md-button ng-click="closeToast()">'
                +'x'
                +'</md-button>'
                +'</md-toast>',
                controller:'ToastNotificationCtrl',
                hideDelay: 6000,
                parent : $document[0].querySelector('#toastBounds'),
                position: 'top'
            });
        };

        /**
         * Show Toast Account
         *
         * @param type
         * @param msg
         */
        var showToastModals = function(type, msg) {
            $mdToast.show({
                template: '<md-toast class="md-toast ' + type +'">'
				+'<md-icon class="icon" aria-label=""></md-icon>'
                +'<span flex>'+msg+'</span>'
                +'<md-button ng-click="closeToast()">'
                +'x'
                +'</md-button>'
                +'</md-toast>',
                controller:'ToastNotificationCtrl',
                hideDelay: 6000,
                parent : $document[0].querySelector('#ModalContainer'),
                position: 'top'
            });
        };

        var showCustomToast = function(controller, templateUrl, delay) {
            $mdToast.show({
              controller: controller,
              templateUrl: templateUrl,
              hideDelay: (delay? delay : 6000),
              parent : $document[0].querySelector('#toastBounds'),
              position: 'top'
            });
        };

        /**
         * Show Toast Account
         *
         * @param type
         * @param msg
         */
        var showToastDashboard = function(type, msg) {
            $mdToast.show({
                template: '<md-toast class="md-toast ' + type +'">'
                +'<md-icon class="icon" aria-label=""></md-icon>'
                +'<span flex>'+msg+'</span>'
                +'<md-button ng-click="closeToast()">'
                +'x'
                +'</md-button>'
                +'</md-toast>',
                controller:'ToastNotificationCtrl',
                hideDelay: 6000,
                parent : $document[0].querySelector('#dashboardAlert'),
                position: 'top'
            });
        };

        return{
            showToastAccount:showToastAccount,
            showToastModals:showToastModals,
            showCustomToast:showCustomToast,
            showToastDashboard:showToastDashboard
        }
    });
