'use strict';

angular.module('schoolbushubApp')
    .controller('StudentController', function ($scope,  $filter, AlertService, $state, $uibModal, Student, ParseLinks) {

        $scope.students = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.loadAll = function() {
            var pageable = {page: $scope.page, size: $scope.per_page, sort: [$scope.predicate + ','
            + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            Student.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.total = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    $scope.students.push(result[i]);
                }
            }, function(err) {
                AlertService.error($filter('translate')('filters.resultMessages.resultsError'));
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.students = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            if ($scope.links && page > $scope.links['last']) { return; };
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.fields = Student.filterVars();
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.student = {
                firstName: null,
                middleName: null,
                lastName: null,
                schoolName: null,
                studentId: null,
                avatar: null,
                schoolCode: null,
                dateOfBirth: null,
                id: null
            };
        };

        $scope.viewStudentDetail = function(stdt) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/entities/student/student-detail.html',
                controller: 'StudentDetailController',
                size: 'lg',
                resolve: {entity: function() { return stdt; } }
            });
        }

        $scope.formatDoB = function(d) {
            return moment.utc(d).format('MM/DD/YYYY');
        }
    });
