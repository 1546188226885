'use strict';

angular.module('schoolbushubApp')
    .controller('RequestResetController', function ($rootScope, $scope, $state, $timeout, Auth, Notifications, $filter, Language) {

        $scope.error = null;
        $scope.errorEmailNotExists = null;
        $scope.resetAccount = {};
        $timeout(function (){angular.element('[ng-model="resetAccount.email"]').focus();});
        Language.getCurrent().then(function (language) {
            $scope.langKey = language;
        });

        $scope.requestReset = function () {

            $scope.error = null;

            Auth.resetPasswordInit($scope.resetAccount.email, $scope.langKey).then(function () {
                Notifications.showToastAccount('success', $filter('translate')('reset.request.messages.success'));
            }).catch(function (response) {
                if (response.status === 400 && response.data === 'e-mail address not registered') {
                    Notifications.showToastAccount('error', $filter('translate')('reset.request.messages.notfound'));
                } else {
                    $scope.error = 'ERROR';
                }
            });
        }

    });
