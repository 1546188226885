(function () {
    'use strict';

    angular.module('schoolbushubApp').config(function ($stateProvider) {
        $stateProvider
            .state('routeModification', {
                parent: 'reports',
                url: '/routeModification',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/admin/routeModification/routeModification.html',
                        controller: 'RouteModificationController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        $translatePartialLoader.addPart('routeModification');
                        return $translate.refresh();
                    }]
                }
            })
            .state('routeModification.cancel', {
                parent: 'routeModification',
                url: '/{fleetId}/{id}/cancel',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/admin/routeModification/cancelRouteModal.html',
                        controller: 'CancelRouteController',
                        size: 'md',
                        resolve: {
                            entity: ['RouteModification', function (RouteModification) {
                                return RouteModification.get({ runId: $stateParams.id, fleetId: $stateParams.fleetId});
                            }],
                            cancelled: function() { return false }
                        }
                    }).result.then(function (result) {
                        $state.go('routeModification');
                    }, function (err) {
                        $state.go('^');
                    })
                }]
            })
            .state('routeModification.restore', {
                parent: 'routeModification',
                url: '/{fleetId}/{id}/restore',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/admin/routeModification/cancelRouteModal.html',
                        controller: 'CancelRouteController',
                        size: 'md',
                        resolve: {
                            entity: ['RouteModification', function (RouteModification) {
                                return RouteModification.get({ runId: $stateParams.id, fleetId: $stateParams.fleetId });
                            }],
                            cancelled: function() { return true }
                        }
                    }).result.then(function (result) {
                        $state.go('routeModification');
                    }, function (err) {
                        $state.go('^');
                    })
                }]
            })
            .state('routeModification.edit', {
                parent: 'routeModification',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/admin/routeModification/editRouteModal.html',
                        controller: 'EditRouteController',
                        size: 'md',
                        resolve: {
                            entity: ['RouteModification', function (RouteModification) {
                                return RouteModification.get({ id: $stateParams.id });
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('routeModification', null, { reload: true });
                    }, function (err) {
                        $state.go('^');
                    })
                }]
            });
    });

})();
