'use strict';

angular.module('schoolbushubApp')
	.controller('NotificationAuditDeleteController', function($scope, $uibModalInstance, entity, NotificationAudit) {

        $scope.notificationAudit = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            NotificationAudit.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    });
