'use strict';

angular.module('schoolbushubApp')
    .controller('NotificationAuditDetailController', function ($scope, $rootScope, $stateParams, entity, NotificationAudit, VehicleAudit) {
        $scope.notificationAudit = entity;
        $scope.load = function (id) {
            NotificationAudit.get({id: id}, function(result) {
                $scope.notificationAudit = result;
            });
        };
        var unsubscribe = $rootScope.$on('schoolbushubApp:notificationAuditUpdate', function(event, result) {
            $scope.notificationAudit = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
