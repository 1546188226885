'use strict';
angular.module('schoolbushubApp').factory('RestService', RestService);
RestService.$inject = ['$resource'];

function RestService ($resource) {
    return $resource('api/:url/:id', {url: '@url'}, {
        get: { method: 'GET', params: {id: '@id'}},
        getArr: { method: 'GET', isArray: true},
        post: { method: 'POST' },
        postArr: { method: 'POST', isArray: true},
        put: { method: 'PUT', params: {id: '@id'}},
        'delete': { method: 'DELETE', params: {id: '@id'}},
        'deleteArr': { method: 'DELETE', params: {id: '@id'}, isArray: true}
    });
}
