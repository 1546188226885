'use strict';

angular.module('schoolbushubApp')
    .controller('StudentDetailController', function ($scope, $rootScope, $stateParams, $uibModalInstance, entity, Student, User) {
        $scope.student = entity;
        $scope.load = function (id) {
            Student.get({id: id}, function(result) {
                $scope.student = result;
            });
        };

        var unsubscribe = $rootScope.$on('schoolbushubApp:studentUpdate', function(event, result) {
            $scope.student = result;
        });

        $scope.close = function() {
            unsubscribe();
            $uibModalInstance.dismiss('ok');
        };

        $scope.$on('$destroy', unsubscribe);

        $scope.formatDoB = function(d) {
            return moment.utc(d).format('MM/DD/YYYY');
        }

    });
