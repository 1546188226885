'use strict';

angular.module('schoolbushubApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('home', {
                parent: 'menu',
                url: '/dashboard',
                isHome: true,
                isApp: true,
                data: {
                    authorities: ['ROLE_PARENT', 'ROLE_ADMIN'],
                    pageTitle: 'School Bus Hub'
                },
                views: {
                    'app-home@': {
                        templateUrl: 'scripts/app/main/main.html',
                        controller: 'MainController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('main');
                        return $translate.refresh();
                    }]
                }
            });
    });
