'use strict';

angular.module('schoolbushubApp')
    .controller('MainController',
        function ($scope, $filter, $mdDialog, Principal, MainService, Notifications, $translate, $location, $mdMedia,
                  $timeout, $window, $rootScope, tmhDynamicLocale, $anchorScroll, SharedService, DataService, $http, $q) {

        $scope.verifyData;
        $scope.verifying = false;
        $scope.showOne = false;
        $scope.student1 = true;
        $scope.verify = false;
        $scope.idStudent;
        $scope.dateOfBirth;
        $scope.direction = 'left';
        $scope.currentIndex = 0;
        $scope.transition = true;
        var today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);


        var scp = $scope;
        scp.listFlexGtSm = 100;
        scp.listFlexSm = 100;
        scp.listColsGtSm = 4;
        scp.listColsSm = 4;

        function fetchStudents(force) {
            SharedService.getStudents(force).then(function(students) {
                scp.students = students;

                scp.listFlexGtSm = students.length * 25;
                scp.listFlexGtSm = (scp.listFlexGtSm > 100? 100 : scp.listFlexGtSm);
                scp.listFlexSm = students.length * 50;
                scp.listFlexSm = (scp.listFlexSm > 100? 100 : scp.listFlexSm);

                scp.listColsGtSm = students.length > 4? 4 : students.length;
                scp.listColsSm = students.length > 2? 2 : students.length;

                var currentIndex = scp.currentIndex +1;
                if(scp.students.length > 0 && currentIndex > scp.students.length){
                    scp.currentIndex = scp.students.length-1;
                }
            });
        }
        fetchStudents(true);

        scp.selectChild = function(student) {
             if (scp.selectedStudent == student) {
                scp.selectedStudent = undefined;
             }
             else {
                scp.selectedStudent = student;
                $location.hash('student' + student.id);
                setTimeout(function() {
                    $anchorScroll();
                    $location.url($location.path());
                }, 300);
             }
         }


        $scope.isCurrentSlideIndex = function (index) {
            return $scope.currentIndex === index;
        };

        $scope.prevSlide = function () {
            $scope.transition = true;
            $scope.direction = 'right';
            $scope.currentIndex = ($scope.currentIndex > 0) ? --$scope.currentIndex : $scope.currentIndex;
        };

        $scope.nextSlide = function () {
            $scope.transition = true;
            $scope.direction = 'left';
            $scope.currentIndex = ($scope.currentIndex < $scope.students.length - 1)
            ? ++$scope.currentIndex : $scope.currentIndex;
        };

        $scope.showAdvanced = function(ev) {
            $mdDialog.show({
                controller: 'AddStudentController',
                templateUrl: 'scripts/app/main/add-student.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader',
                    function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('main');
                        return $translate.refresh();
                    }]
                }
            }).then(function() {
                fetchStudents(true);
            }, function() {
              $scope.status = 'You cancelled the dialog.';
            });
        };

          // deleteStudent callback
          var deleteStudentCallback = function(response) {
            if (response.status == 'Ok') {
                fetchStudents(true);
            }
            else{
                var alert = $mdDialog.alert()
                    .title($filter('translate')('main.modalError.title'))
                    .content($filter('translate')('main.modalError.errorDeleteStudent'))
                    .ok($filter('translate')('main.modalError.close'));
                $mdDialog.show(alert);
            }
        };

        // Deletes and student (The relationship between student and tutor).
        $scope.deleteStudent = function(studentId) {
            var confirm = $mdDialog.confirm()
              .title($filter('translate')('main.deleteStudent.confirmTitle'))
              .content($filter('translate')('main.deleteStudent.confirmContent'))
              .ok($filter('translate')('main.deleteStudent.ok'))
              .cancel($filter('translate')('main.deleteStudent.cancel'));
            $mdDialog.show(confirm).then(function() {
                MainService.deleteStudent(studentId, deleteStudentCallback);
            });
        };

         /**
         * Verify Student Callback
         * @param response
         */
        var verifyStudentCallback = function(response) {
            if (response.status.data == 'added') {
                fetchStudents(true);
                $scope.verify=false;
                $scope.cleanVerify();
            } else if (response.status.data == 'duplicate') {
                Notifications.showToastDashboard('error', $filter('translate')('main.addStudent.duplicateStudent'));
            } else {
                Notifications.showToastDashboard('error', $filter('translate')('main.addStudent.studentNotFound'));
            }
            $scope.verifying = false;
        };

        /**
         * Verify Student
         */
        $scope.verifyStudent = function() {
            if($scope.validated()) {
                $scope.verifying = true;
                MainService.verifyAndAddStudent($scope.school.code, moment($scope.dateOfBirth,
                $filter('translate')('main.formatDateMoment')).toDate(), $scope.idStudent, $scope.organization.id,
                 verifyStudentCallback);
            }
        };

        /**
         * Validation of the fields
         * @returns {boolean}
         */
        $scope.validated = function(){
            var validated = true;
            var dateByParts = $scope.dateOfBirth.split('/');
            if(dateByParts.length == 3){
                _.each(dateByParts, function (num) { if (isNaN(num) || isNaN(parseFloat(num))) {validated = false;}});
            }else{
                validated = false;
            }
            if (validated) {
                if (!moment($scope.dateOfBirth, [$filter('translate')('main.formatDateMoment')]).isValid()) {
                    validated = false;
                    Notifications.showToastDashboard('error',
                    $filter('translate')('main.addStudent.dateBirthIsInvalid'));
                } else {
                    var birth = moment($scope.dateOfBirth, $filter('translate')('main.formatDateMoment')).toDate();
                    if (moment(birth).diff(today, 'hours') > 0) {
                        validated = false;
                        Notifications.showToastDashboard('error',
                        $filter('translate')('main.addStudent.dateBirthIsHigherCurrentDate'));
                    }
                }
            } else {
                Notifications.showToastDashboard('error', $filter('translate')('main.addStudent.dateBirthIsInvalid'));
            }
            return validated;
        }

        /**
         * Clean Verify
         */
        $scope.cleanVerify = function(){
            $scope.organization = undefined;
            $scope.school = undefined;
            $scope.idStudent = angular.copy($scope.verifyData);
            $scope.dateOfBirth = angular.copy($scope.verifyData);
            $scope.verifyStudentForm.$setPristine();
            $scope.verifyStudentForm.$setUntouched();
            $location.hash('ContainerModal');
            $anchorScroll();
            $location.url($location.path());
        }

        $scope.changeView = function(index){
            if(window.innerWidth < 1200) {
                $scope.transition = false;
                $scope.currentIndex = index;
                $scope.showOne = true;
            }
        }

        $scope.setGridMinH = function() {
        	$timeout(function(){
        		$scope.$apply();
        		var winH = $window.innerHeight;
        		var offset = 66;
        		if ($mdMedia('gt-sm'))
        		    offset = offset + 140;

        		$scope.gridMinH = {"min-height": (winH - offset)};
        	})
        }

        angular.element($window).bind('resize', function () {
        	$timeout(function(){ $scope.$apply();});
        	$scope.setGridMinH();
        	$location.hash('sbh-top-menu');
        	$anchorScroll();
        	$location.url($location.path());
        });

        $scope.setGridMinH();

        $scope.searchSchool = function (query) {
          var deferred = $q.defer();
          var params = {
            organizationId: $scope.organization.id,
            schoolName: query
          };
          $http.get('api/schools/codes', { params: params }).then(function (results) {
            for (var i = 0; i < results.data.length; i++) {
              results.data[i].displayName = results.data[i].code + ' - ' + results.data[i].name;
            }
            deferred.resolve(results.data);
          }, function (err) {
            deferred.resolve([]);
          });
          return deferred.promise;
        }

        $scope.searchOrganizations = function (query) {
          var deferred = $q.defer();
          if ($scope.school) {
            $scope.school = null;
          }
          $http.get('api/organizations/search?name=' + query).then(function (results) {
            deferred.resolve(results.data);
          }, function (err) {
            deferred.resolve([]);
          });
          return deferred.promise;
        }

    });
