'use strict';

angular.module('schoolbushubApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('maps', {
                parent: 'menu',
                url: '/maps/:studentId?',
                isHome: true,
                isApp: true,
                data: {
                    authorities: ['ROLE_PARENT', 'ROLE_ADMIN'],
                    pageTitle: 'School Bus Hub'
                },
                views: {
                    'app-home@': {
                        templateUrl: 'scripts/app/maps/mapsview.html',
                        controller: 'MapsController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('main');
                        $translatePartialLoader.addPart('maps');
                        return $translate.refresh();
                    }]
                }
            });
    });
