(function () {
    'use strict';

    angular.module('schoolbushubApp').controller('EditRouteController', EditRouteController);

    EditRouteController.$inject = [
        "$scope", "$state", "$uibModalInstance", "entity", "RouteModification", "DateUtils", "AlertService"
    ];

    function EditRouteController($scope, $state, $uibModalInstance, entity, RouteModification, DateUtils, AlertService) {

        $scope.route = entity;

        entity.$promise.then(function(a) {
            a.shiftTime = a.shiftTime || 0;
            $scope.shiftUpdated();
        });


        $scope.shiftUpdated = function() {
            $scope.newStartTime = $scope.route.startTime + $scope.route.shiftTime * 60000;
            $scope.newEndTime = $scope.route.endTime + $scope.route.shiftTime * 60000;
        }

        $scope.timeFormat = function (time) {
            return DateUtils.timeFormat(time);
        };

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmEdit = function (id) {
            delete $scope.route.selected;
            RouteModification.update($scope.route,
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
