angular.module('seon.queryBuilder', []);

angular.module('seon.queryBuilder').config(function ($translateProvider) {
    //$translateProvider.useMissingTranslationHandler('undefHandler');
});

angular.module('seon.queryBuilder').filter('splitNames', function($translate) {
    return function (fieldName /*, uses*/) {
        return $translate.instant(
            fieldName.replace(/^(filters\.columns\.)/,"")
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/^./, function(str){ return str.toUpperCase(); })
                    .replace('.', ' ')
        );
    }
})
.filter('cutLargeLabels', function($mdMedia){
    return function(str){
        if(str.length > 18 && $mdMedia('xs')){
            return str.substring(0, 18) + '...'
        }

        return str
    }
});

angular.module('seon.queryBuilder').directive('queryBuilderContainer', QueryBuilderContainer);
QueryBuilderContainer.$inject = ['$translate', '$sce', '$uibModal', '$window', '$timeout'];

function QueryBuilderContainer($translate, $sce, $uibModal, $window, $timeout) {
    return {
        restrict: 'E',
        scope: {
            fields: '=',
            advancedQuery: '=',
            queryObject: '=',
            filterCallback: '='
        },
        templateUrl: 'scripts/components/filters/filtersContainer.html',
        link: function(scope, elem, attrs) {

            scope.group = {
                'operator' : 'and',
                'rules' : [{condition: '', field:'', data:''}]
            };

            scope.queryStr = '';
            scope.showBuilder = false;
            scope.showQueryStr = true;
            scope.advMode = scope.advancedQuery;

            scope.toggleGroups = function(show) {
                if (!show && _.find(scope.group.rules, function(rule){ return rule.hasOwnProperty('group')})) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        keyboard: true,
                        templateUrl: 'templates/ConfirmBasic.html',
                        controller: 'ConfirmBasicCtrl',
                        size: 'sm'
                    });

                    modalInstance.result.then(function(status) {
                        removeAllGroups();
                    }, function(reason) {
                        scope.advMode = true;
                    });
                }
            }

            function removeAllGroups() {
                var newGroup = {'operator' : scope.group.operator, 'rules' : []};
                angular.forEach(scope.group.rules, function(val, key) {
                    if (!val.hasOwnProperty('group')) {
                        newGroup.rules.push(val);
                    }
                });
                scope.group = newGroup;
            }

            scope.$watch('group', function(newVal, oldVal) {
                scope.queryObject = scope.cleanGroup(newVal);
                scope.queryStr = $sce.trustAsHtml(setQueryString(scope.queryObject).slice(1, -1));
            }, true);

            scope.cleanGroup = function(group) {
                var resp = {operator: group.operator, rules: []};
                angular.forEach(group.rules, function(e) {
                    if (e.hasOwnProperty('group')) {
                        var newGrp = {group: scope.cleanGroup(e.group)};
                        if (newGrp.group.rules.length > 0) {
                            resp.rules.push(newGrp);
                        }
                    }
                    else {
                        if (e.field && e.condition && (e.data || e.data === 0)) {
                            resp.rules.push({field: e.field.name, condition:e.condition, data:e.data});
                        }
                    }
                });
                return resp;
            }

            function setQueryString(group) {
                if (!group) return "";
                for (var str = "(", i = 0; i < group.rules.length; i++) {
                    i > 0 && (str += " " + ('<b>' + group.operator.toUpperCase() + '</b>') + " ");
                    str += group.rules[i].group ?
                    setQueryString(group.rules[i].group) :
                    group.rules[i].field + " " + ('<b>' + htmlEntities(group.rules[i].condition) + '</b>') + " " + group.rules[i].data;
                }
                return str + ")";
            }

            function htmlEntities(str) {
                return String(str).replace(/</g, '&lt;').replace(/>/g, '&gt;');
            }

            function cleanGroupAndQueryStr(group) {
                var grp = {operator: group.operator, rules: []};
                var str = "(";

                angular.forEach(group.rules, function(e, i) {
                    i > 0 && (str += " " + group.operator + " ");

                    if (e.hasOwnProperty('group')) {
                        var inner = cleanGroupAndQueryStr(e.group);
                        var newGrp = {group: inner.group};
                        if (newGrp.group.rules.length > 0) {
                            str += inner.string;
                            grp.rules.push(newGrp);
                        }
                    }
                    else {
                        if (e.field && e.condition && e.data) {
                            grp.rules.push({field: e.field.name, condition:e.condition, data:e.data});
                            str += e.field.name + " " + htmlEntities(e.condition) + " " + e.data;
                        }
                    }
                })

                return {group: grp, string: (str + ")")};
            }


            scope.clearFilters = function() {
                scope.group = {
                    'operator' : 'and',
                    'rules' : [{condition: '', field:'', data:''}]
                }
                $timeout(function() {
                    scope.filterCallback();
                });
            }
        }
    };
}

angular.module('seon.queryBuilder').controller('ConfirmBasicCtrl', ConfirmBasicCtrl);
ConfirmBasicCtrl.$inject = ['$scope', '$uibModalInstance'];

function ConfirmBasicCtrl($scope, $uibModalInstance) {
    $scope.ok = function() {$uibModalInstance.close('ok'); }
    $scope.cancel = function() {$uibModalInstance.dismiss('cancel'); };
}


angular.module('seon.queryBuilder').directive('queryBuilder', QueryBuilder);
QueryBuilder.$inject = ['$compile', '$translate', '$timeout'];

function QueryBuilder($compile, $translate, $timeout) {
    return {
        restrict: 'E',
        scope: {
            fields: '=fields',
            group: '=',
            advancedQuery: '='
        },
        templateUrl: 'scripts/components/filters/filters.html',
        compile: function (element, attrs) {
            var content, directive;
            content = element.contents().remove();
            return function (scope, element, attrs) {

                scope.highlightCondition = function(ev) {
                    angular.element(jQuery(ev.currentTarget).closest('div')).toggleClass('highlighted', true);
                }

                scope.unhighlightCondition = function(ev) {
                    angular.element(jQuery(ev.currentTarget).closest('div')).toggleClass('highlighted', false);
                }

                scope.collapseGroup = false;
                scope.toggleGroup = function() {
                    scope.collapseGroup = !scope.collapseGroup;
                    scope.collapseGroup && (scope.innerExpression = computed(scope.group));
                }

                function computed(group) {
                    if (!group) return "";
                    for (var str = "(", i = 0; i < group.rules.length; i++) {
                        var rule = group.rules[i];
                        if (!rule.group && (!rule.field.name || !rule.condition || !rule.data)) { continue;}
                        i > 0 && (str += " " + group.operator + " ");
                        str += group.rules[i].group ?
                        computed(group.rules[i].group) :
                        rule.field.name + " " + (rule.condition) + " " + rule.data;
                    }
                    return str + ")";
                }


                //calendar stuff
                scope.dateFormat = "MM/dd/yyyy";
                scope.datetimeFormat = "MM/dd/yyyy HH:mm:ss";
                // scope.datepickerOpts = { maxDate: moment() }
                // scope.altInputFormats = ["yyyy-MM-dd HH:mm:ss", "MM/dd/yyyy HH:mm:ss", 'yyyy-MM-dd', 'yyyy/MM/dd'];
                // scope.calendarOpened = false;
                // scope.format = scope.altInputFormats[1];
                // console.log("teh formt:", scope.format)


                scope.$watch('advancedQuery', function(newVal) {
                    scope.showRemGrp = scope.advancedQuery && scope.nestedLevel > 0;
                    scope.showAddGrp = scope.advancedQuery && scope.nestedLevel < 5;
                }, true);


                scope.validateString = function (rule) {
                    if (rule.data && rule.data.length > 100) {
                        rule.data = rule.data.substring(0, 100);
                    }
                }

                scope.checkNumbers = function(ev) {
                    var key = ev.keyCode || ev.charCode;
                    if(isNaN(String.fromCharCode(key))){ ev.preventDefault(); }
                }

                scope.validateNumber = function (rule) {
                    if (rule.data && rule.data.toString().length > 11) {
                        rule.data = Number(rule.data.toString().substring(0, 11));
                    }
                }

                scope.updateData = function(rule) {
                    var d = rule.pickerDate;
                    if (d && rule.field.type == 'date') {
                        rule.data = moment(d).format("YYYY-MM-DD");
                    }
                    else if (d && rule.field.type == 'dateTime') {
                        rule.data = moment.utc(d).format("YYYY-MM-DDTHH:mm:ss[Z]");
                    }
                    else {
                        rule.data = '';
                        rule.pickerDate = undefined;
                    }
                }


                //vars to show/hide elements
                scope.isChildFiltersDirective = ("isChildFiltersDirective" in scope.$parent);
                scope.nestedLevel = scope.isChildFiltersDirective?  (scope.$parent.nestedLevel + 1) : 0;
                scope.showRemGrp = scope.nestedLevel > 0;
                scope.showAddGrp = scope.advancedQuery && scope.nestedLevel < 5;
                scope.minRules = scope.nestedLevel == 0 ? 1 : 2;
                scope.maxRules = 15;

                scope.showRemRule = function() {
                    var r = scope.group.rules.filter(function(v) {
                            return !v.hasOwnProperty('group');
                    });
                    return (r.length > scope.minRules);
                }


                scope.operators = [
                    { name: 'All', value: 'and', locale: 'filters.all' },
                    { name: 'Any', value: 'or' , locale: 'filters.any' }
                ];

                //conditions vars
                var eq = {name: 'equals',       value: '=',    locale: 'filters.equal'      };
                var ne = {name: 'not equal',    value: '!=',   locale: 'filters.notEqual'   };
                var gt = {name: 'greater than', value: '>',    locale: 'filters.greaterThan'};
                var lt = {name: 'less than',    value: '<',    locale: 'filters.lessThan'   };
                var lk = {name: 'contains',     value: 'like', locale: 'filters.contains'   };
                scope.conditions = {
                    "text": [eq, ne, lk],
                    "boolean": [eq, ne],
                    "number": [eq, ne, gt, lt],
                    "date" : [eq, ne, gt, lt],
                    "dateTime" : [eq, ne, gt, lt]
                }

                scope.setConditions = function (r, old) {
                    (r.field.type != old.field.type) && (r.data = '');
                    (r.field.type == 'boolean') && (r.condition = '=');
                    (r.field.type == 'date') && (r.calendarOpened = false);
                }

                scope.addCondition = function () {
                    if (!scope.group.rules.length >= scope.maxRules) {
                        return;
                    }
                    scope.group.rules.push({
                        condition: '',
                        field: '',
                        data: ''
                    });
                };

                scope.removeCondition = function (index) {
                    if (!scope.showRemRule) {
                        return;
                    }
                    scope.group.rules.splice(index, 1);
                };

                scope.addGroup = function () {
                    if (!scope.group.rules.length >= scope.maxRules) {
                        return;
                    }
                    scope.group.rules.push({
                        group: {
                            operator: 'and',
                            rules: [{condition: '', field:'', data:''}, {condition: '', field:'', data:''}]
                        }
                    });
                };

                scope.removeGroup = function () {
                    "group" in scope.$parent && scope.$parent.group.rules.splice(scope.$parent.$index, 1);
                };

                directive || (directive = $compile(content));

                element.append(directive(scope, function ($compile) {
                    return $compile;
                }));
            }
        }
    }
}
