'use strict';

angular.module('schoolbushubApp')
    .factory('HelpService', function ($http) {
        return {
            getHelpUrl: function (callback) {
                $http.get('api/helpUrl').then(function(response) {
                    callback({status:'success',helpUrl:response.data});
                }).catch(function(){
                    callback({status:'error'});
                });
            }
        };
    });

