'use strict';

angular.module('schoolbushubApp')
    .controller('LoginController', function ($rootScope, $scope, $state, $timeout, Auth, Principal, Notifications, $stateParams, $location, $filter, $anchorScroll, DataService) {

        var scroll = function(){
            if ($location.hash() !== 'toastBounds') {
                $location.hash('toastBounds');
            } else {
                $anchorScroll();
            }
        }

        if($stateParams.expiredSession){
            Notifications.showToastAccount('error', $filter('translate')('login.messages.error.theSessionHasExpired'));
        }

        $scope.user = {};
        $scope.errors = {};

        $scope.rememberMe = true;
        $timeout(function (){angular.element('[ng-model="username"]').focus();});
        $scope.login = function (event) {
            event.preventDefault();
            Auth.login({
                username: $scope.username,
                password: $scope.password,
                rememberMe: $scope.rememberMe
            }).then(function () {
                $scope.authenticationError = false;
                Principal.identity().then(function(account) {
                    if ($rootScope.previousStateName && $rootScope.previousStateName.parent != 'account') {
                        $state.go($rootScope.previousStateName.name, $rootScope.previousStateNameParams);
                    } else {
                        if(_.filter(account.authorities, function(rol){ return rol == 'ROLE_ADMIN' ||
                                    rol == 'ROLE_CUSTOMERADMIN' ||
                                    rol == 'ROLE_SEONADMIN'; }).length > 0){
                            $state.go('administration');
                        }else{
                            $state.go('home');
                        }
                    }
                });
            }).catch(function (err) {
                if(err.status == 401){
                    $scope.authenticationError = false;
                    $scope.emailNotVerified = true;
                    $scope.usernameToSendEmail = $scope.username;

                    DataService.set("username", $scope.username);
                    scroll();
                    Notifications.showCustomToast("SendActivationEmailController", "scripts/app/account/login/sendVerificationEmailTemplate.html");
                } else{
                    $scope.authenticationError = true;
                    $scope.emailNotVerified = false;
                    scroll();
                    Notifications.showToastAccount('error', $filter('translate')('login.messages.error.authentication'));
                }
            });
        };
    });
