'use strict';

angular.module('schoolbushubApp')
    .factory('Organization', function ($resource, $http, Principal, AlertService) {

        var synchronizeSchools = function (organization, callback) {
            var url = 'api/organizations/synchronizeSchools';
            $http.post(url, organization.id).then(function success(response) {
                callback({
                    data: response.status
                }, organization);
            }, function error(response) {
                callback({
                    data: response.status
                }, organization);
            });
        }

        var removeOrganization = function (organizationId, callback) {
            var url = 'api/organizations/remove';
            $http.post(url, organizationId)
                .then(function () {
                    callback({
                        status: 'success'
                    });
                }).catch(function (error) {
                    callback({
                        status: 'error'
                    }, error);
                });
        }

        var clearNotifications = function (organization, callback) {
            var url = 'api/organizations/clearNotifications';
            $http.post(url, organization)
                .then(function () {
                    callback({
                        status: 'success'
                    }, organization);
                }).catch(function () {
                    callback({
                        status: 'error'
                    }, organization);
                });
        }

        var getOrganizationData = function (code, alias, applicationCode, callback) {
            var url = 'api/organizations/verify/' + code;
            var params = {'alias':alias, 'applicationCode':applicationCode};
            $http.get(url, {params:params}).then(function success(response) {
                callback({ data: response.data });
            }, function error(response) {
                callback({ error: response.data });
            });
        }

        var getEventSource = function (callback) {
            var url = 'api/organizations/eventSource';
            $http.get(url).then(function success(response) {
                callback({ data: response.data });
            }, function error(response) {
                callback({ error: response.data });
            });
        }

        var resource = $resource('api/organizations/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'filterQuery': {
                method: 'POST',
                url: 'api/organizations/query',
                isArray: true
            },
            'filterVars': {
                method: 'GET',
                url: 'api/organizations/info',
                isArray: true
            },
            'update': {
                method: 'PUT'
            }
        });

        var getRoleOrganizations = function () {
            return new Promise(function (resolve) {
                Principal.identity().then(function (account) {
                    if (account) {
                        var roles = _.filter(account.authorities, function (rol) {
                            return rol == 'ROLE_CUSTOMERADMIN';
                        });
                        if (roles.length == 0) {
                            resource.filterQuery( {"pageable":{"page":0,"size":1000,"sort":["id,asc","id"]}},
                                function (result) {
                                    return resolve({
                                        isCustomerAdmin: false,
                                        organizations: result.length > 0 ? _.map(result, function (o) {
                                            return o;
                                        }) : []
                                    })
                                },
                                function (err) {
                                    AlertService.error('Error getting organizations');
                                }
                            );
                        } else {
                            return resolve({
                                isCustomerAdmin: true,
                                organization: account.organization,
                                organizations: []
                            });
                        }
                    }
                });
            });
        }

        return {
            resource: resource,
            synchronizeSchools: synchronizeSchools,
            removeOrganization: removeOrganization,
            clearNotifications: clearNotifications,
            getOrganizationData: getOrganizationData,
            getRoleOrganizations: getRoleOrganizations,
            getEventSource: getEventSource
        }
    });
