'use strict';

angular.module('schoolbushubApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('login', {
                parent: 'account',
                isApp: true,
                url: '/',
                data: {
                    authorities: [],
                    pageTitle: 'login.title'
                },
                views: {
                    'app-content@': {
                        templateUrl: 'scripts/app/account/login/login.html',
                        controller: 'LoginController'
                    }
                },
                params: {
                    'expiredSession': false
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sendActivationEmailSuccess',{
                            parent: 'account',
                            isApp: true,
                            url: '/sendActivationEmailSuccess',
                            data: {
                                roles: [],
                                pageTitle: 'login.title'
                            },
                            views: {
                                'app-content@': {
                                    templateUrl: 'scripts/app/account/login/sendActivationEmailSuccess.html',
                                    controller: 'LoginController'
                                }
                            },
                            resolve: {
                                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                    $translatePartialLoader.addPart('login');
                                    return $translate.refresh();
                                }]
                            }
            });
    });
