'use strict';

angular.module('schoolbushubApp')
    .controller('AccountAuditController', function ($scope, ReportsService, AlertService, $filter, Parameters, $uibModal, Principal, Organization) {

        $scope.accounts = [];
        $scope.formatDate = $filter('translate')('reports.formatDate');
        $scope.validationMobileNumber = Parameters.getRegularExpressionMobileNumber();
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.lastPage = true;
        $scope.accountAuditRequest = {
            schoolCodes:[],
            userLastName:"",
            userEmailAddress:"",
            userMobileNumber:"",
            emailAddressVerified:"",
            accountDeactivated:""
        };

        $scope.organizations = [];
        $scope.isCustomerAdmin = false;
        Principal.identity().then(function(account) {
            if(account){
               var roles = _.filter(account.authorities, function(rol){ return rol == 'ROLE_CUSTOMERADMIN'; });
                if(roles.length == 0){
                    Organization.resource.filterQuery( {"pageable":{"page":0,"size":1000,"sort":["id,asc","id"]}},
                        function(result, headers) {
                            for (var i = 0; i < result.length; i++) {
                                $scope.organizations.push(result[i]);
                                }
                        }, function(err) {
                            AlertService.error('Error getting organizations');
                        }
                    );
                } else {
                    $scope.isCustomerAdmin = true;
                    $scope.organization = account.organization;
                    $scope.getSchools($scope.organization);
                }
            }
        });

        $scope.getSchools = function(organization) {
            $scope.schools = [];
            ReportsService.getSchools(organization, function(response) {
                if (response.status == 'success') {
                    $scope.schools = response.schools;
                }
                else {
                    AlertService.error($filter('translate')('reports.accountAudit.errorGettingSchools'));
                }
            });
        }

         /**
         * Generate Report Callback
         *
         * @param response
         */
        var generateReportCallback = function(response){
            if(response.status == 'success'){
                if(response.accountAuditResponse.length > 0) {
                    $scope.accounts = response.accountAuditResponse;
                    if(response.accountAuditResponse.length < $scope.per_page){
                        $scope.lastPage = true;
                    }else{
                        $scope.lastPage = false;
                    }
                }else{
                    $scope.lastPage = true;
                }
                if($scope.accounts.length > 0){
                    AlertService.success($filter('translate')('reports.accountAudit.reportGeneratedSuccessfully'));
                }else{
                    AlertService.warning($filter('translate')('reports.accountAudit.noResultsWereFound'));
                }
            }else{
                AlertService.error($filter('translate')('reports.accountAudit.errorToGenerateTheReport'));
            }
        };

         /**
         * Generate Report Callback
         *
         * @param response
         */
        var loadPageCallback = function(response){
            if(response.status == 'success'){
                if(response.accountAuditResponse.length > 0) {
                    for (var i = 0; i < response.accountAuditResponse.length; i++) {
                        $scope.accounts.push(response.accountAuditResponse[i]);
                    }
                    if(response.accountAuditResponse.length < $scope.per_page){
                        $scope.lastPage = true;
                    }
                }else{
                    $scope.lastPage = true;
                }
            }else{
                AlertService.error($filter('translate')('reports.accountAudit.errorToGenerateTheReport'));
            }
        };

        /**
         * Generate Report
         */
        $scope.generateReport = function(){
            $scope.accounts = [];
            $scope.page = 1;
            $scope.per_page = $scope.infiniteScrollPageSize;
            ReportsService.getReport($scope.accountAuditRequest, $scope.organization, $scope.page, $scope.per_page, generateReportCallback);
        };

        /**
         * Generate Report
         */
        $scope.loadPage = function(page){
            // if ($scope.links && page > $scope.links['last']) { return; };
            $scope.page = page;
            if(!$scope.lastPage) {
                ReportsService.getReport($scope.accountAuditRequest, $scope.organization, $scope.page, $scope.per_page, loadPageCallback);
            }
        };

        /**
         * Clean  Account Audit Request
         */
        $scope.clean = function(){
            $scope.accounts = [];
            $scope.accountAuditRequest = {
                schoolCodes:[],
                userLastName:"",
                userEmailAddress:"",
                userMobileNumber:"",
                emailAddressVerified:"",
                accountDeactivated:""
            };
        };

        /**
         * convert To UTC
         *
         * @param dt
         * @returns {Date}
         */
        $scope.formatDateTime = function(dt) {
             if (!dt) return '--';
             return moment.utc(dt).format('MM/DD/YYYY HH:mm:ss');
        };

        /**
         * Open Modal Detail Students
         */
        $scope.openDetailStudents = function (account) {
            ReportsService.getDetailStudents(account.emailAddress, $scope.accountAuditRequest.schoolCodes, function(response){
                if(response.status == 'success'){
                    $scope.modalInstance = $uibModal.open({
                        templateUrl: 'scripts/app/reports/accountaudit/detail-students.html',
                        controller: 'DetailStudentsController',
                        backdrop: 'static',
                        resolve: {
                            students: function () {
                                return response.students;
                            },
                            userName: function() {
                                return account.firstName + ' ' + account.lastName;
                            },
                            mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                                $translatePartialLoader.addPart('reports');
                                return $translate.refresh();
                            }]
                        }
                    });
                }else{
                    AlertService.error($filter('translate')('reports.accountAudit.failedQueryDetailStudents'));
                }
            });
        };
    });
