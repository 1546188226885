/*
* created by wmontalvo on 09/24/2015
**/
'use strict';
/* Directives */
angular.module('schoolbushubApp').directive('passwordStrength', function  () {
       return {
          restrict: 'A',
          link: function(scope, element, attrs){
              scope.$watch(attrs.passwordStrength, function(value) {
                  console.log(value);
                  if(angular.isDefined(value)){
                      var validator = isSatisfied(value && value.length >= 8) +
                      isSatisfied(value && /[A-z]/.test(value)) +
                      isSatisfied(value && /(?=.*\W)/.test(value)) +
                      isSatisfied(value && /\d/.test(value));
                      if(validator){
                          if (value.length > 8) {
                              scope.strength = 'strong';
                          } else if (value.length > 3) {
                              scope.strength = 'medium';
                          } else {
                              scope.strength = 'weak';
                          }
                      }
                  }
                  function isSatisfied(criteria) {
                      return criteria ? 1 : 0;
                  }
              });
          }
      };
  }).directive('patternValidator',function (){
     return {
         require: 'ngModel',
         restrict: 'A',
         link: function(scope, elem, attrs, ctrl) {
           ctrl.$parsers.unshift(function(viewValue) {

             var patt = new RegExp(attrs.patternValidator);

             var isValid = patt.test(viewValue);

             ctrl.$setValidity('passwordPattern', isValid);

             // angular does this with all validators -> return isValid ? viewValue : undefined;
             // But it means that the ng-model will have a value of undefined
             // So just return viewValue!
             return viewValue;

           });
         }
       };
  }).directive('compareTo',function (){
     return {
         require: "ngModel",
         scope: {
            otherModelValue: "=compareTo"
         },
         link: function(scope, element, attributes, ngModel) {

            ngModel.$validators.compareTo = function(modelValue) {
                return modelValue == scope.otherModelValue;
            };

            scope.$watch("otherModelValue", function() {
                ngModel.$validate();
            });
         }
     };
});
