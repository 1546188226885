'use strict';

angular.module('schoolbushubApp')
.config(function ($stateProvider) {
    $stateProvider
    .state('notificationAudit', {
        parent:'reports',
        url: '/notificationAudits',
        data: {
            authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
        },
        views: {
            'content@': {
                templateUrl: 'scripts/app/reports/notificationAudit/notificationAudits.html',
                controller: 'NotificationAuditController'
            }
        },
        resolve: {
            mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                $translatePartialLoader.addPart('reports');
                return $translate.refresh();
            }]
        }
    })
});

