'use strict';

angular.module('schoolbushubApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('vehicleAudit', {
                parent: 'entity',
                url: '/vehicleAudits',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN'],
                    pageTitle: 'schoolbushubApp.vehicleAudit.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudits.html',
                        controller: 'VehicleAuditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('vehicleAudit');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            // .state('vehicleAudit.detail', {
            //     parent: 'entity',
            //     url: '/vehicleAudit/{id}',
            //     data: {
            //         authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN'],
            //         pageTitle: 'schoolbushubApp.vehicleAudit.detail.title'
            //     },
            //     views: {
            //         'content@': {
            //             templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudit-detail.html',
            //             controller: 'VehicleAuditDetailController'
            //         }
            //     },
            //     resolve: {
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('vehicleAudit');
            //             return $translate.refresh();
            //         }],
            //         entity: ['$stateParams', 'VehicleAudit', function($stateParams, VehicleAudit) {
            //             return VehicleAudit.get({id : $stateParams.id});
            //         }]
            //     }
            // })
            .state('vehicleAudit.new', {
                parent: 'vehicleAudit',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudit-dialog.html',
                        controller: 'VehicleAuditDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    zoneType: null,
                                    deviceId: null,
                                    tripOrderGuid: null,
                                    eventTimeStamp: null,
                                    plannedArrivalTime: null,
                                    latLong: null,
                                    rawMessage: null,
                                    isMsgOld: null,
                                    insideAllowedTime: null,
                                    allowedTimeStart: null,
                                    allowedTimeEnd: null,
                                    belongsToStop: null,
                                    stopHasStudents: null,
                                    processTime: null,
                                    runGuid: null,
                                    stopOrder: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('vehicleAudit', null, { reload: true });
                    }, function() {
                        $state.go('vehicleAudit');
                    })
                }]
            })
            .state('vehicleAudit.edit', {
                parent: 'vehicleAudit',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudit-dialog.html',
                        controller: 'VehicleAuditDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['VehicleAudit', function(VehicleAudit) {
                                return VehicleAudit.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('vehicleAudit', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('vehicleAudit.delete', {
                parent: 'vehicleAudit',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'scripts/app/entities/vehicleAudit/vehicleAudit-delete-dialog.html',
                        controller: 'VehicleAuditDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['VehicleAudit', function(VehicleAudit) {
                                return VehicleAudit.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('vehicleAudit', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    });
