'use strict';

angular.module('schoolbushubApp')
    .controller('StudentAuditController', function ($scope, ReportsService, AlertService, $filter) {

        /**
         * Generate Report Callback
         *
         * @param response
         */
        var generateReportCallback = function(response){
            if(response.status == 'success'){
                $scope.students = response.studentAuditResponse;
                if($scope.students.length > 0){
                    $scope.studentName = $scope.students[0].studentName;
                    $scope.studentDateOfBirth = $scope.students[0].studentDateOfBirth;
                    $scope.studentSchoolCodeId = $scope.students[0].studentSchoolCodeId;
                    AlertService.success($filter('translate')('reports.studentAudit.reportGeneratedSuccessfully'));
                }else{
                    AlertService.warning($filter('translate')('reports.studentAudit.noResultsWereFound'));
                }
            }else{
                AlertService.error($filter('translate')('reports.studentAudit.errorToGenerateTheReport'));
            }
        };

        $scope.students = [];
        $scope.studentId;
        $scope.studentName;
        $scope.studentDateOfBirth;
        $scope.studentSchoolCodeId;
        $scope.formatDate = $filter('translate')('reports.formatDate');

        /**
         * Generate Report
         */
        $scope.generateReport = function(){
            $scope.studentName = '';
            $scope.studentDateOfBirth = '';
            $scope.studentSchoolCodeId = '';
            ReportsService.getStudentReport($scope.studentId, generateReportCallback);
        };

        /**
         * Clean Report
         */
        $scope.clean = function(){
            $scope.students = [];
            $scope.studentId = '';
            $scope.studentName = '';
            $scope.studentDateOfBirth = '';
            $scope.studentSchoolCodeId = '';
        };

        /**
         * Convert DateTime To UTC
         *
         * @param dt
         * @returns {Date}
         */
        $scope.formatDateTime = function(dt) {
             if (!dt) return '--';
             return moment.utc(dt).format('MM/DD/YYYY HH:mm:ss');
        };

        /**
         * Convert Date To UTC
         *
         * @param dt
         * @returns {Date}
         */
        $scope.formatDate = function(dt) {
             if (!dt) return '--';
             return moment.utc(dt).format('MM/DD/YYYY');
        };

    });
