'use strict';

angular.module('schoolbushubApp')
    .controller('AddStudentController', function ($scope, $mdDialog, Notifications, MainService, Principal, $filter) {
        Principal.identity().then(function(account) {
            $scope.account = account;
        });
        $scope.verifying = false;
        $scope.schoolCode;
        $scope.idStudent;
    	$scope.dateOfBirth;
        var today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        $scope.cancel = function() {
            $("#dateOfBirth").datepicker("hide");
            $mdDialog.cancel();
        };

        /**
         * Verify Student Callback
         * @param response
         */
        var verifyStudentCallback = function(response) {
            if (response.status.data == 'added') {
                $mdDialog.hide();
            } else if (response.status.data == 'duplicate') {
                Notifications.showToastModals('error', $filter('translate')('main.addStudent.duplicateStudent'));
            } else {
            	Notifications.showToastModals('error', $filter('translate')('main.addStudent.studentNotFound'));
            }
            $scope.verifying = false;
        };

        /**
         * Verify Student
         */
        $scope.verifyStudent = function() {
            if($scope.validated()) {
                $scope.verifying = true;
                MainService.verifyAndAddStudent($scope.schoolCode, moment($scope.dateOfBirth, $filter('translate')('main.formatDateMoment')).toDate(), $scope.idStudent, $scope.account.login, verifyStudentCallback);
            }
        };

        /**
         * Validation of the fields
         * @returns {boolean}
         */
        $scope.validated = function(){
            var validated = true;
            if($scope.dateOfBirth) {
                var dateByParts = $scope.dateOfBirth.split('/');
                if(dateByParts.length == 3){
                    _.each(dateByParts, function (num) { if (isNaN(num) || isNaN(parseFloat(num))) {validated = false;}});
                }else{
                    validated = false;
                }
                if (validated) {
                    if (!moment($scope.dateOfBirth, [$filter('translate')('main.formatDateMoment')]).isValid()) {
                        validated = false;
                        Notifications.showToastModals('error', $filter('translate')('main.addStudent.dateBirthIsInvalid'));
                    } else {
                        var birth = moment($scope.dateOfBirth, $filter('translate')('main.formatDateMoment')).toDate();
                        if (moment(birth).diff(today, 'hours') > 0) {
                            validated = false;
                            Notifications.showToastModals('error', $filter('translate')('main.addStudent.dateBirthIsHigherCurrentDate'));
                        }
                    }
                } else {
                    Notifications.showToastModals('error', $filter('translate')('main.addStudent.dateBirthIsInvalid'));
                }
            }else{
                validated = false;
                Notifications.showToastModals('error', $filter('translate')('main.addStudent.birthDateRequired'));
            }
            return validated;
        }

    });
