'use strict';

angular.module('schoolbushubApp')
    .factory('MainService', function ($http) {
        return {
            updateContactInformation: function(email, phone, sendEmail){
                return $http.post('/api/updateContactInformation?&email='+ email + '&phone=' + phone
                + '&sendEmail=' + sendEmail);
            },
            deleteCurrentUser: function(password) {
                return $http.delete('/api/users/userDeletionValidated/?key=' + encodeURIComponent(password));
            },
            findStudents: function () {
                return $http.get('api/users/students').then(function (response) {
                    return response.data;
                });
            },
            verifyAndAddStudent: function (schoolCode, dateOfBirth, idStudent, organizationId, callback) {
                return $http.post('api/users/verifyaddstudent?schoolCode=' + schoolCode +
                    '&dateOfBirth=' + moment(dateOfBirth).format('YYYY-MM-DD') + '&idStudent=' + idStudent
                    +'&organizationId='+organizationId)
                    .then(function successCallback(data) {
                    	callback({status:data});
                    },function errorCallback(data) {
                        callback({status:'error'});
                    });
            },
            deleteStudent: function(studentId, callback) {
                return $http.post('api/users/deleteStudent?studentId=' + studentId)
                .then(function successCallback(){
                    callback({status:"Ok"});
                },function errorCallback(data){
                    callback({status:'error'});
                });
            },
            getBus: function (studentId, schoolId) {
                return $http({
                    url:'api/studentLocationDetails',
                    method: "GET",
                    params: {"studentId" : studentId, "schoolId" : schoolId}
                }).then(function (response) {
                    return response.data;
                }, function(err) {
                    return undefined;
                });
            }
        };
    });
