angular.module('schoolbushubApp')
    .directive('formattingMobileNumber', function() {
        var length = 0;
        var patt = new RegExp('(\\(([0-9]{3})\\) [0-9]{3} - [0-9]{4}){1,16}');
        return {
            scope: {
                onchange: '&watchChange'
            },
            require: 'ngModel',
            link: function(scope, element, attrs, model) {
                element.on('input', function() {
                    scope.$apply(function () {
                        if (element.context.value.length > length) {
                            switch (element.context.value.length) {
                                case 1:
                                    if(element.context.value != '(') {
                                        element.context.value = '(' + element.context.value;
                                    }
                                    break;
                                case 4:
                                    element.context.value = element.context.value + ') '
                                    break;
                                case 9:
                                    element.context.value = element.context.value + ' - ';
                                    break;
                                default:
                            }
                        }else{
                            switch (element.context.value.length) {
                                case 1:
                                    if(element.context.value != '(') {
                                        element.context.value = '(' + element.context.value;
                                    }
                                    break;
                                case 5:
                                    element.context.value = element.context.value.substring(0, 3);
                                    break;
                                case 11:
                                    element.context.value = element.context.value.substring(0, 8);
                                    break;
                                default:
                            }
                        }
                        var isValid = patt.test(element.context.value);
                        model.$setValidity('numberFormat', isValid);
                        length = element.context.value.length;
                    });
                });
                model.$parsers.push(function (value) {
                    var isValid = patt.test(value);
                    model.$setValidity('numberFormat', isValid);
                    return isValid ? value : undefined;
                });
            }
        };
    });
