(function () {
    'use strict';

    angular.module('schoolbushubApp').factory('FiltersRouteSvc',
    function ($http, $resource) {
    	return {
    		// filter queries
        getRoutesByName: function(organization, query, successCallback, errorCallback) {
            return $http.get('api/runs/match?organization=' + organization.code + '&name=' + query
            + '&active=true').then(function (results) {
                successCallback(results.data);
            }, function (err) {
                errorCallback(err.data);
            });
        },
        getVehiclesByName: function(organization, query, successCallback, errorCallback) {
            $http.get('api/vehicles/match?organization=' + organization.code + '&id=' + query
            + '&active=true').then(function (results) {
                successCallback(results.data);
            }, function (err) {
                errorCallback(err.data);
            });
        },
        getSchoolsByName: function(organization, query, successCallback, errorCallback) {
            $http.get('api/schools/match?organization=' + organization.id + '&schoolName=' + query)
            .then(function (results) {
                successCallback(results.data);
            }, function (err) {
                errorCallback(err.data);
            });
        }
    	}
    });
})();

