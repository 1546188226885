'use strict';

angular.module('schoolbushubApp')
	.controller('StudentDeleteController', function($scope, $uibModalInstance, $translate, entity, Student) {

       $scope.student = entity;

        entity.$promise.then(function(stu)  {
          var studentName = [stu.firstName, stu.middleName, stu.lastName].join(' ');
          $scope.dialogText = $translate.instant("schoolbushubApp.student.delete.question", { fullName: studentName });
        });
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Student.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    });
