'use strict';

angular.module('schoolbushubApp')
    .controller('OrganizationDetailController', function ($scope, $rootScope, $uibModalInstance, entity, Organization) {
        $scope.organization = entity;
        $scope.sourceApplication = $scope.organization.applicationCode === 'CMP' ? 'Compass' : 'Path';
        $scope.load = function (id) {
            Organization.resource.get({id: id}, function(result) {
                $scope.organization = result;
            });
        };

        var unsubscribe = $rootScope.$on('schoolbushubApp:organizationUpdate', function(event, result) {
            $scope.organization = result;
        });

        $scope.close = function() {
            unsubscribe();
            $uibModalInstance.dismiss('ok');
        };

        $scope.$on('$destroy', unsubscribe);

    });
