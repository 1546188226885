'use strict';

angular.module('schoolbushubApp')
    .controller('UserController', function($scope, $timeout, $uibModal, User, USER_ROLES, Principal, $translate, Auth, ParseLinks, Organization, Parameters) {
        var scp = $scope;

        $scope.users = [];
        $scope.currentPage = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.maxSize = 5;
        $scope.sorts = ["login,asc"];
        $scope.validationMobileNumber = Parameters.getRegularExpressionMobileNumber();
        $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();

        $scope.user = {
            login: null,
            email: null,
            authoritiesUser: []
        };

        Principal.identity().then(function(account) {
            $scope.account = account;
            if (jQuery.inArray("ROLE_CUSTOMERADMIN", $scope.account.roles) != -1 || $scope.account.authorities == "ROLE_CUSTOMERADMIN") {
                $scope.organizations = [$scope.account.organization];
            } else {
                $scope.organizations = Organization.resource.filterQuery( {"pageable":{"page":0,"size":1000,"sort":["id,asc","id"]}});
            }
        });


        /**
         * Set the roles for the user.
         * @author jmontoya
         */
        $scope.setRol = function(users) {
            users.forEach(function(user) {
                if (user.authorities) {
                    user.authorities.forEach(function(role) {
                        var roleName = $translate.instant('schoolbushubApp.user.appRoles.' + role.name);
                        user.roles = user.roles ? user.roles + ', ' + roleName : roleName;
                    });
                }
                $scope.users.push(user);
            });
        };

        $scope.loadPage = function() {
            if ($scope.currentPage < 0 || $scope.links && $scope.currentPage > $scope.links['last']) {
                return;
            };
            User.query({
                page: $scope.currentPage,
                size: $scope.per_page,
                sort: $scope.sorts
            }, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = result.totalElements;
                $scope.setRol(result.content);

                // The second page is forced to load to prevent the scroll from disappearing sometimes
                // when the notification message is displayed and the first page occupies the entire size
                // but when the message disappears it makes the scroll disappear. (SBH-1881)
                if($scope.currentPage - 1 === 0) {
                    $scope.$emit('list:users');
                }
            });
            $scope.currentPage++;
        };

        /**
         * show edit modal.
         * @author wmontalvo
         */
        $scope.update = function(login) {
            $scope.requiredClass = '';
            $scope.user = User.get({
                login: login
            }, function(user) {
                user.authoritiesUser = [];
                user.confirmEmail = user.email;

                var roleAdmin = {
                    has: _.where(user.authorities, {
                        name: USER_ROLES.admin
                    })[0] ? true : false,
                    name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.admin),
                    rol: USER_ROLES.admin,
                    disable: false
                }

                var roleCustomerAdmin = {
                    has: _.where(user.authorities, {
                        name: USER_ROLES.customeradmin
                    })[0] ? true : false,
                    name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.customeradmin),
                    rol: USER_ROLES.customeradmin,
                    disable: false
                }

                var roleParent = {
                    has: _.where(user.authorities, {
                        name: USER_ROLES.parent
                    })[0] ? true : false,
                    name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.parent),
                    rol: USER_ROLES.parent,
                    disable: false
                }

                var roleSeonAdmin = {
                    has: _.where(user.authorities, {
                        name: USER_ROLES.seonadmin
                    })[0] ? true : false,
                    name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.seonadmin),
                    rol: USER_ROLES.seonadmin
                }

                if (_.indexOf($scope.account.authorities, "ROLE_ADMIN") != -1){
                    user.authoritiesUser.push(roleAdmin);
                    user.authoritiesUser.push(roleCustomerAdmin);
                    user.authoritiesUser.push(roleParent);
                    user.authoritiesUser.push(roleSeonAdmin);
                }else

                if (_.indexOf($scope.account.authorities, "ROLE_SEONADMIN") != -1){
                    user.authoritiesUser.push(roleSeonAdmin);
                    user.authoritiesUser.push(roleCustomerAdmin);
                    user.authoritiesUser.push(roleParent);
                }else

                if (_.indexOf($scope.account.authorities, "ROLE_CUSTOMERADMIN") != -1){
                    user.authoritiesUser.push(roleParent);
                    user.authoritiesUser.push(roleCustomerAdmin);
                }
            });
            $scope.isEdit = true;
            $('#editUserModal').modal('show');
            $scope.showUser();
        };


        /**
         * create user.
         * @author jmontoya
         */
        $scope.createUser = function() {
            $scope.isEdit = false;
            $scope.requiredClass = 'bold';
            $scope.createRoleList();
            $('#strengthBar li').each(function() {
                $(this).css("background-color", "#DDD");
            });
            $scope.showUser();
        };

        /**
         * create roles list.
         * @author jmontoya
         */
        $scope.createRoleList = function() {
            $scope.user = {
                login: null,
                email: null,
                authoritiesUser: []
            };

            var roleAdmin = {
                has: false,
                name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.admin),
                rol: USER_ROLES.admin,
                disable: false
            }

            var roleCustomerAdmin = {
                has: false,
                name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.customeradmin),
                rol: USER_ROLES.customeradmin,
                disable: false
            }

            var roleParent = {
                has: false,
                name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.parent),
                rol: USER_ROLES.parent,
                disable: false
            }

            var roleSeonAdmin = {
                has: false,
                name: $translate.instant('schoolbushubApp.user.appRoles.' + USER_ROLES.seonadmin),
                rol: USER_ROLES.seonadmin,
                disable: false
            }

            if (_.indexOf($scope.account.authorities, "ROLE_ADMIN") != -1){
                $scope.user.authoritiesUser.push(roleAdmin);
                $scope.user.authoritiesUser.push(roleCustomerAdmin);
                $scope.user.authoritiesUser.push(roleParent);
                $scope.user.authoritiesUser.push(roleSeonAdmin);
            }else

            if (_.indexOf($scope.account.authorities, "ROLE_SEONADMIN") != -1){
                $scope.user.authoritiesUser.push(roleSeonAdmin);
                $scope.user.authoritiesUser.push(roleCustomerAdmin);
                $scope.user.authoritiesUser.push(roleParent);
            }else

            if (_.indexOf($scope.account.authorities, "ROLE_CUSTOMERADMIN") != -1){
                $scope.user.authoritiesUser.push(roleCustomerAdmin);
                $scope.user.authoritiesUser.push(roleParent);
            }
        };


        $scope.currentPageChanged = function(){
            $scope.currentPage = 0;
            $scope.loadPage();
        }

        $scope.loadPage();

        $scope.deleteModal = function(user) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/admin/user/_deleteUserModal.html',
                controller: 'DelUserModalCtrl',
                resolve: {
                    user: function () {
                        return user;
                    }
                }
            });

            modalInstance.result.then(function(status) {
                $scope.reloadPages();
            }, function(reason) {
                if (reason == 'error') {
                    bootbox.alert($translate.instant("global.messages.error.generalError"));
                }
            });
        };

        $scope.showUser = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/admin/user/_userModal.html',
                controller: 'UserModalCtrl',
                backdrop: 'static',
                resolve: {
                    info: function () {
                        return {
                            user:scp.user,
                            isEdit: scp.isEdit,
                            requiredClass:scp.requiredClass,
                            organizations:scp.organizations,
                            account:scp.account
                        };
                    }
                }
            });
            scp.user = {};

            modalInstance.result.then(function(status) {
                $scope.reloadPages();
            }, function (reason) {
                if (reason == 'error') {
                    bootbox.alert($translate.instant("global.messages.error.generalError"));
                }
            });
        };

        $scope.reloadPages = function () {
            $scope.users = [];
            $scope.currentPage = 0;
            $scope.loadPage();
        }
    });

angular.module('schoolbushubApp').controller('DelUserModalCtrl', function ($scope, $uibModalInstance, user, User, $translate) {
    $scope.user = user;

    $scope.delete = function(login) {
        User.delete({
            login: login
        }, function() {
           $uibModalInstance.close('ok');
        }, function(response) {
            bootbox.alert($translate.instant("global.messages.error.generalError"));
        });
    };

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };
});

angular.module('schoolbushubApp').controller('UserModalCtrl', function ($scope, $uibModalInstance, info, User, $translate, USER_ROLES, Parameters) {
    $scope.user = info.user;
    $scope.isEdit = info.isEdit;
    $scope.requiredClass = info.requiredClass;
    $scope.organizations = info.organizations;
    $scope.account = info.account;
    $scope.ROLE_ADM = USER_ROLES.admin
    $scope.validationMobileNumber = Parameters.getRegularExpressionMobileNumber();
    $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();
    $scope.customerAdminSelected = function(){
        var authUser = _.find($scope.user.authoritiesUser, function(aut){ return aut.rol == "ROLE_CUSTOMERADMIN";});
        if (authUser && authUser.has){
            return true;
        }
        return false;
    }


    $scope.save = function() {
        if ($scope.password !== $scope.confirmPassword && $scope.password !== '' && $scope.confirmPassword !== '') {
            $scope.doNotMatch = true;
        }
        else {
            $scope.doNotMatch = null;

            var method = $scope.isEdit? 'update' : 'save';
            if (!$scope.user.langKey) { $scope.user.langKey = 'en'; }
            $scope.user.authorities = [];
            _.each($scope.user.authoritiesUser, function(auth) {
                if (auth.has) {
                    var auth = {
                        name: auth.rol
                    };
                    $scope.user.authorities.push(auth);
                };
            });

            User[method]({
                    user: $scope.user,
                    password: $scope.confirmPassword
                },
                function() {
                    $uibModalInstance.close('ok');
                    window.location.reload();
                },
                function(response) {
                    if (response.status === 400 && response.data.error && response.data.error === 'e-mail address already in use') {
                        bootbox.alert($translate.instant("schoolbushubApp.user.messages.emailAlreadyUse"));
                    }
                    else if (response.status === 400 && response.data.error && response.data.error === 'username already in use') {
                        bootbox.alert($translate.instant("schoolbushubApp.user.messages.loginAlreadyUse"));
                    }
                    else {
                        bootbox.alert($translate.instant("global.messages.error.generalError"));
                    }
                }
            );
        }
    }

    $scope.checkCustomerAdmin = function() {
         if ($scope.hasAnyAdminRole($scope.account.authorities)) {
            if (!$scope.customerAdminSelected()) {
                $scope.user.organization = null;
            }
         }
         else if ($scope.account.authorities.includes("ROLE_CUSTOMERADMIN")) {
             if ($scope.customerAdminSelected()) {
                 $scope.user.organization = $scope.account.organization;
             }
             else {
                 $scope.user.organization = null;
             }
         }

        return !($scope.hasAnyAdminRole($scope.account.authorities) && $scope.customerAdminSelected());
    }

    $scope.hasAnyAdminRole = function(authorities) {
        return authorities.includes("ROLE_ADMIN") ||
            authorities.includes("ROLE_SEONADMIN");
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.restrictRoles = function (checkObj) {
        if (checkObj.rol === "ROLE_ADMIN") {
          _.each($scope.user.authoritiesUser, function(role) {
            if (role !== checkObj) {
              if (checkObj.has) {
                role.has = !checkObj.has;
              }
              role.disable = checkObj.has;
              }
          });
        }
    }


    try {
        $scope.user.$promise.then(function () {
          $scope.restrictRoles($scope.user.authoritiesUser[0]);
        });
    } catch (error) {

    }
});
