(function() {
    'use strict';
    'use strict';

    angular.module('schoolbushubApp').controller('CancelRouteController', CancelRouteController);

    CancelRouteController.$inject = [
        "$scope", "$uibModalInstance", "$stateParams", "entity", "cancelled", "RouteModification", "AlertService", "$filter"
    ];

    function CancelRouteController($scope, $uibModalInstance, $stateParams, entity, cancelled, RouteModification, AlertService, $filter) {
        $scope.route = entity;
        $scope.cancelled = cancelled;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.submitDisabled = true;

        entity.$promise.then(function() {
            $scope.submitDisabled = false;
        });

        $scope.confirmAction = function () {
            delete $scope.route.selected;
            var method, trans;

            if ($scope.route.cancelled) {
                method = RouteModification.restore;
                trans = 'routeRestoredOk'
            } else {
                method = RouteModification.cancel;
                trans = 'routeCancelledOk';
            }

            method({fleetId: $stateParams.fleetId, uvid: $scope.route.uvid, routeId: $scope.route.id},[$scope.route],
                function () {
                    AlertService.success($filter('translate')('routeModification.messages.' + trans, { id: $scope.route.name}));
                    $uibModalInstance.close(true);
                });
        };
    }
})();
