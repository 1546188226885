'use strict';

angular.module('schoolbushubApp')
    .controller('ResetFinishController', function ($scope, $stateParams, $timeout, Auth, Parameters, $filter, Notifications) {

        $scope.validationPassword = Parameters.getRegularExpressionValidationPassword();

        Auth.logout();

        $scope.keyMissing = $stateParams.key === undefined;

        if($scope.keyMissing){
            Notifications.showToastAccount('error', $filter('translate')('reset.finish.messages.keymissing'));
        }


        $scope.resetAccount = {};
        $timeout(function (){angular.element('[ng-model="resetAccount.password"]').focus();});

        $scope.finishReset = function() {
            if ($scope.resetAccount.password !== $scope.confirmPassword) {
                Notifications.showToastAccount('error', $filter('translate')('global.messages.error.dontmatch'));
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: $scope.resetAccount.password}).then(function () {
                    $scope.success = 'OK';
                }).catch(function (response) {
                    $scope.success = null;
                    Notifications.showToastAccount('error', $filter('translate')('reset.finish.messages.error'));
                });
            }

        };
    });
