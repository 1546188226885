'use strict';

angular.module('schoolbushubApp')
.config(function ($stateProvider) {
    $stateProvider
        .state('users', {
            parent: 'admin',
            url: '/users',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CUSTOMERADMIN', 'ROLE_SEONADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'scripts/app/admin/user/users.html',
                    controller: 'UserController'
                }
            },
            resolve: {
            	mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('user');
                    return $translate.refresh();
                }]
            }
        });
});
