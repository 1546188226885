'use strict';

angular.module('schoolbushubApp')
    .directive('confirmVerify', function() {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, model) {
                if (!attrs.confirmVerify) {
                    console.error('confirmVerify expects a model as an argument!');
                    return;
                }
                scope.$watch(attrs.confirmVerify, function (value) {
                    if (model.$viewValue !== undefined && model.$viewValue !== '') {
                        model.$setValidity('confirmVerify', value === model.$viewValue);
                    }
                });
                scope.$watch(attrs.ngModel, function (value) {
                    var isValid = value === scope.$eval(attrs.confirmVerify);
                    model.$setValidity('confirmVerify', isValid);
                    return isValid ? value : undefined;
                });
            }
        };
});
