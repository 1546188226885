'use strict';

angular.module('schoolbushubApp')
    .config(function ($stateProvider) {
            $stateProvider
                .state('activate', {
                    parent: 'account',
                    isApp: true,
                    url: '/activate?key',
                    data: {
                        roles: [],
                        pageTitle: 'activate.title'
                    },
                    views: {
                        'app-content@': {
                            templateUrl: 'scripts/app/account/activate/activate.html',
                            controller: 'ActivationController'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('activate');
                            return $translate.refresh();
                        }]
                    }
                });
    });
