'use strict';

angular.module('schoolbushubApp')
    .controller('DetailStudentsController', function ($scope, students, userName, $uibModalInstance, $filter) {
        $scope.students = students;
        $scope.userName = userName;
        $scope.formatDate = $filter('translate')('reports.formatDate');

        /**
         * Close modal
         */
        $scope.ok = function () {
            $uibModalInstance.dismiss('cancel');
        };

        /**
         * convert To UTC
         *
         * @param dt
         * @returns {Date}
         */
        $scope.convertToUTC = function(dt) {
            var localDate = new Date(dt);
            var localTime = localDate.getTime();
            var localOffset = localDate.getTimezoneOffset() * 60000;
            return new Date(localTime + localOffset);
        };
    });
