"use strict";

angular.module("schoolbushubApp").factory("MonitoringSvc", MonitoringSvc);
MonitoringSvc.$inject = ["$http", "$resource"];

function MonitoringSvc($http, $resource) {
  return {
    getRoutesInfo: function (deviceId, runGuid, organizationId, date, successCallback, errorCallback) {
      var url =
        "/api/monitoring?deviceId=" +
        deviceId +
        "&runGuid=" +
        runGuid +
        "&organizationId=" +
        organizationId +
        "&date=" +
        date;
      return $http.get(url).then(
        function (resp) {
          successCallback(resp.data);
        },
        function (error) {
          errorCallback(error.data);
        }
      );
    },
    searchRoutes: function (name, date, successCallback, errorCallback) {
      var url = "api/searchRoutes";
      return $http.get(url).then(
        function (resp) {
          successCallback(resp.data);
        },
        function (error) {
          errorCallback(error.data);
        }
      );
    },
    getStudentsInStop: function (tripOrderGuid, deviceId, organizationId, successCallback, errorCallback) {
      var url =
        "api/studentsInStop?tripOrderGuid=" +
        tripOrderGuid +
        "&deviceId=" +
        deviceId +
        "&organizationId=" +
        organizationId;
      return $http.get(url).then(
        function (resp) {
          successCallback(resp.data);
        },
        function (error) {
          console.log(error);
          errorCallback();
        }
      );
    },
    queryRuns: function (orgId, query, callback) {
      return $http.get("api/runs/match?organization=" + orgId + "&name=" + query + "&active=true").then(
        function (results) {
          callback(results.data);
        },
        function (err) {
          callback([]);
        }
      );
    },
    queryVehicles: function (orgId, query, callback) {
      return $http.get("api/vehicles/match?organization=" + orgId + "&id=" + query + "&active=true").then(
        function (results) {
          callback(results.data);
        },
        function (err) {
          callback([]);
        }
      );
    },
  };
}
