'use strict';

angular.module('schoolbushubApp')
    .controller('AuditsController', function ($scope, $filter, AuditsService, ParseLinks) {
        $scope.audits = [];
        $scope.page = 1;
        $scope.per_page = 30;
        $scope.lastPage = 1;
        $scope.maxSize = 5;
        $scope.busy = false;
        $scope.optionsPerPage = [5,10,20,50,100];
        $scope.user = '';
        $scope.state = '';
        $scope.fromDate = new Date();
        $scope.toDate = new Date();

        //calendar stuff
        $scope.altInputFormats = ['M!/d!/yyyy'];
        $scope.format = 'MM/dd/yyyy';
        $scope.fromDateCalendarOpened = false;
        $scope.toDateCalendarOpened = false;
        $scope.fromDatepickerOpts = {
            maxDate: new Date()
        };
        $scope.toDatepickerOpts = {
            maxDate: new Date()
        };

        $scope.toggleCalendar = function(cal) {
            if (cal === 'from') {
                $scope.fromDateCalendarOpened = !$scope.fromDateCalendarOpened;
            } else if (cal === 'to') {
                $scope.toDateCalendarOpened = !$scope.toDateCalendarOpened;
            }
        }
        $scope.calendarChanged = function() {
            $scope.fromDatepickerOpts.maxDate = $scope.toDate || new Date();
            $scope.toDatepickerOpts.minDate = $scope.fromDate;
        }

        $scope.loadResults = function () {
            $scope.fromDate.setHours(0,0,0,0);
            $scope.toDate.setHours(23,59,59,999);
            $scope.page = 1;
            $scope.lastPage = 1;
            $scope.audits = [];
            $scope.loadPage();
        };

        $scope.loadPage = function () {
            if ($scope.page < 1 || $scope.page > $scope.lastPage) {
                return;
            };
            
            $scope.busy = true;
            AuditsService.findByFilters($scope.page, $scope.per_page, $scope.fromDate, $scope.toDate, $scope.user, $scope.state, function(response) {
                $scope.totalItems = response.data.totalElements;
                $scope.lastPage = Math.ceil($scope.totalItems / $scope.per_page);
                $scope.audits = $scope.audits.concat(response.data.content);
                $scope.busy = false;
            });
            $scope.page++;
        };


        $scope.formatDateTime = function(dt) {
            if (!dt) return '--';
            return moment(dt).format('MM/DD/YYYY HH:mm:ss');
        };

        $scope.calendarChanged();

        $scope.loadResults();
    });
