(function () {
    'use strict';

    angular.module('schoolbushubApp').factory('RouteModification',
        function ($resource) {
            return $resource('api/editRuns/:fleetId', {uvid: '@uvid'}, {
                'query': { method: 'GET', isArray: true },
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data[0];
                    }
                },
                'cancel': {
                    method: 'PUT',
                    url: '/api/editRuns/canceled/:fleetId'
                },
                'restore': {
                    method: 'DELETE',
                    url: '/api/editRuns/canceled/:fleetId'
                }
            });
        });
})();
