'use strict';

angular.module('schoolbushubApp').controller('VehicleAuditDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'VehicleAudit', 'NotificationAudit',
        function($scope, $stateParams, $uibModalInstance, DataUtils, entity, VehicleAudit, NotificationAudit) {

        $scope.vehicleAudit = entity;
        $scope.notificationaudits = NotificationAudit.query();
        $scope.load = function(id) {
            VehicleAudit.get({id : id}, function(result) {
                $scope.vehicleAudit = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('schoolbushubApp:vehicleAuditUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.vehicleAudit.id != null) {
                VehicleAudit.update($scope.vehicleAudit, onSaveSuccess, onSaveError);
            } else {
                VehicleAudit.save($scope.vehicleAudit, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.abbreviate = DataUtils.abbreviate;

        $scope.byteSize = DataUtils.byteSize;
        $scope.datePickerForEventTimeStamp = {};

        $scope.datePickerForEventTimeStamp.status = {
            opened: false
        };

        $scope.datePickerForEventTimeStampOpen = function($event) {
            $scope.datePickerForEventTimeStamp.status.opened = true;
        };
        $scope.datePickerForPlannedArrivalTime = {};

        $scope.datePickerForPlannedArrivalTime.status = {
            opened: false
        };

        $scope.datePickerForPlannedArrivalTimeOpen = function($event) {
            $scope.datePickerForPlannedArrivalTime.status.opened = true;
        };
        $scope.datePickerForAllowedTimeStart = {};

        $scope.datePickerForAllowedTimeStart.status = {
            opened: false
        };

        $scope.datePickerForAllowedTimeStartOpen = function($event) {
            $scope.datePickerForAllowedTimeStart.status.opened = true;
        };
        $scope.datePickerForAllowedTimeEnd = {};

        $scope.datePickerForAllowedTimeEnd.status = {
            opened: false
        };

        $scope.datePickerForAllowedTimeEndOpen = function($event) {
            $scope.datePickerForAllowedTimeEnd.status.opened = true;
        };
        $scope.datePickerForProcessTime = {};

        $scope.datePickerForProcessTime.status = {
            opened: false
        };

        $scope.datePickerForProcessTimeOpen = function($event) {
            $scope.datePickerForProcessTime.status.opened = true;
        };
}]);
