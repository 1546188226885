'use strict';

angular.module('schoolbushubApp')
    .controller('NavbarController', function ($scope, $location, $state, Auth, Principal, ENV, HelpService, $window) {
        $scope.isAuthenticated = Principal.isAuthenticated;
        $scope.$state = $state;
        $scope.inProduction = ENV === 'prod';

        $scope.logout = function () {
            Auth.logout();
            $state.go('login');
        };

        $scope.help = function () {
            HelpService.getHelpUrl(function (response) {
                if (response.status == 'success'){
                    console.log(response.helpUrl);
                    $window.open(response.helpUrl);
                }
            });
        }
    });
