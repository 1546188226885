'use strict';

angular.module('schoolbushubApp')
    .controller('OrganizationController', function ($scope, $state, $uibModal, Organization, ParseLinks, $filter, AlertService) {

        const HTTP_STATUS = { "OK": 200, "UNAUTHORIZED":401, "NOT_FOUND": 404 };
        $scope.organizations = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.loadAll = function() {
            var pageable = {page: $scope.page, size: $scope.per_page, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']};
            Organization.resource.filterQuery({pageable:pageable, filters:$scope.queryObject}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.total = headers('x-total-count');
                for (var i = 0; i < result.length; i++) {
                    result[i].sourceApplication = result[i].applicationCode === 'CMP' ? 'Compass' : 'Path';
                    $scope.organizations.push(result[i]);
                }
            }, function(err) {
                AlertService.error($filter('translate')('filters.resultMessages.resultsError'));
            });
        };
        $scope.reset = function() {
            $scope.page = 0;
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.organizations = [];
            $scope.loadAll();
        };
        $scope.loadPage = function(page) {
            if ($scope.links && page > $scope.links['last']) { return; };
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.fields = Organization.resource.filterVars();
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.organization = {
                name: null,
                apiUser: null,
                apiPassword: null,
                timezone: null,
                organizationGuid: null,
                arrivalTimeWindow: null,
                id: null
            };
        };

        var syncSchoolsCallback = function(response, organization){
            if (response.data == HTTP_STATUS.OK) {
                AlertService.success($filter('translate')('schoolbushubApp.organization.synchronizedSchools', {
                    organization: organization.code
                }));
            } else if (response.data == HTTP_STATUS.NOT_FOUND) {
                AlertService.error($filter('translate')('schoolbushubApp.organization.organizationHasNoSchools', {
                    organization: organization.code
                }));
            } else if (response.data == HTTP_STATUS.UNAUTHORIZED) {
                AlertService.error($filter('translate')('schoolbushubApp.organization.organizationNotFound', {
                    organization: organization.code
                }));
            } else {
                AlertService.error($filter('translate')('schoolbushubApp.organization.errorSynchronizingSchools', {
                    organization: organization.code
                }));
            }
            $scope.disabledSyncSchools = false;
        }

        $scope.syncSchools = function(organization){
            $scope.disabledSyncSchools = true;
            Organization.synchronizeSchools(organization, syncSchoolsCallback);
        }

        $scope.clearNotifications = function(organization) {
            Organization.clearNotifications(organization, function(response, organization){
                if(response.status == 'success'){
                    AlertService.success($filter('translate')('schoolbushubApp.organization.clearedNotifications', { organization: organization.code }));
                }else{
                    AlertService.error($filter('translate')('schoolbushubApp.organization.errorClearNotifications', { organization: organization.code }));
                }
            });
        }

        $scope.viewOrganizationDetail = function(org) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/entities/organization/organization-detail.html',
                controller: 'OrganizationDetailController',
                size: 'lg',
                resolve: {entity: function() { return org; } }
            });
        }
    });
