'use strict';

angular.module('schoolbushubApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('studentAudit', {
                parent:'reports',
                url: '/studentAudit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/reports/studentaudit/student-audit.html',
                        controller: 'StudentAuditController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        return $translate.refresh();
                    }]
                }
            })
    });
