'use strict';

angular.module('schoolbushubApp').factory('AuditsService', function($http) {
    return {
        findAll: function() {
            return $http.get('api/audits/all').then(function(response) {
                return response.data;
            });
        },
        findByFilters: function(page, perPage, fromDate, toDate, user, state, callback) {
            var params = {
                page: page,
                per_page: perPage,
                fromDate: moment(fromDate).format('YYYY-MM-DD 00:00:00Z'),
                toDate: moment(toDate).format('YYYY-MM-DD 23:59:59Z'),
                user: user,
                state: state
            };
            $http.get('api/audits/byFilters', { params: params }).then(
                function(response) {
                    callback(response);
                },
                function(error) {
                    callback({ error: error });
                }
            );
        }
    };
});
