angular.module('schoolbushubApp').service('SharedService', SharedService);
SharedService.$inject = ['$q', '$translate', 'tmhDynamicLocale', 'Principal', 'DataService', 'MainService'];

function SharedService($q, $translate, tmhDynamicLocale, Principal, DataService, MainService) {
	var svc = this;

	var bClasses = ['blue-block', 'aqua-block', 'sprint-green-block', 'green-block'];
	var bgIconClasses = ['purple', 'pink', 'blue', 'cyan'];

	svc.getCredentials = function(force) {
		var def = $q.defer();
		if (DataService.get('credentials')  && !force) {
			def.resolve(DataService.get('credentials'));
		}
		else {
			Principal.identity().then(function(resp) {
				DataService.set('credentials', { account: resp, isAuthenticated: Principal.isAuthenticated });
	            $translate.use(resp.langKey);
	            tmhDynamicLocale.set(resp.langKey);
	            def.resolve(DataService.get('credentials'));
	    	});
		}
		return def.promise;
	}

	svc.getStudents = function(force) {
		var def = $q.defer();
		if(DataService.get('students') && !force) {
			def.resolve(DataService.get('students'));
		}
		else {
			MainService.findStudents().then(function(students){
			    if(students && students.length > 0){
			        _.first(students).show = true;
			    }

			    _.each(students, function(st, idx) {
			    	st.blockClass   		= bClasses[idx%4];
			    	st.backgroundIconClass 	= bgIconClasses[idx%4];
			    });

			    DataService.set('students', students);
			    def.resolve(students);
			});
		}
		return def.promise;
	}

	svc.getBus = function(studentId, schoolId) {
		var def = $q.defer();
		MainService.getBus(studentId, schoolId).then(function(bus){
			def.resolve(bus);
		});
		return def.promise;
	}



}
