(function () {
    'use strict';

    angular.module('schoolbushubApp').controller('RouteModificationController', RouteModificationController);

    RouteModificationController.$inject = [
        "$scope", "$filter", "AlertService", "RouteModification", "DateUtils", "Organization", "$http", "ParseLinks"
    ];


    function RouteModificationController($scope, $filter, AlertService, RouteModification, DateUtils, Organization, $http, ParseLinks) {
        $scope.routes = [];
        $scope.total = 0;

        // pagination
        $scope.page = 0;
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.per_page = $scope.infiniteScrollPageSize + 4;

        $scope.date = null;
        $scope.dateCtrlOpened = false;
        $scope.startDatepickerOpts = {
            minDate: new Date()
        };
        $scope.altInputFormats = ['M!/d!/yyyy'];
        $scope.format = 'MM/dd/yyyy';

        // filters options
        $scope.routesAsync = [];
        $scope.vehiclesAsync = [];
        $scope.schoolsAsync = [];


        $scope.searchParams = {
            route: undefined,
            vehicle: undefined,
            school: undefined,
            date: new Date()
        };

        $scope.allRoutesSelected = false;

        Organization.getRoleOrganizations().then(function (result) {
            $scope.organizations = result.organizations;
            $scope.isCustomerAdmin = result.isCustomerAdmin;
            $scope.organization = result.organization;
        });



        $scope.predicate = 'id';

        $scope.executeSearch = function (page) {
            if (!$scope.organization || (!$scope.searchParams.vehicle && !$scope.searchParams.route && !$scope.searchParams.school)) {
              return;
            }
            if ($scope.links && page > $scope.links['last']) {
              return;
            };

            if (page === 0) {
              $scope.routes.length = 0;
              $scope.per_page = $scope.infiniteScrollPageSize + 4;
            }
            $scope.page = page;

            var searchPars = {fleetId: $scope.organization.code};
            searchPars.active = true;
            if ($scope.searchParams.route) {
                searchPars.runId = $scope.searchParams.route.id;
            }
            if ($scope.searchParams.vehicle) {
                searchPars.vehicleId = $scope.searchParams.vehicle.id;
            }
            if ($scope.searchParams.school) {
                searchPars.schoolId = $scope.searchParams.school.guid;
            }
            if ($scope.searchParams.date) {
                searchPars.date = $scope.searchParams.date.valueOf();
            }

            searchPars.page = $scope.page;
            searchPars.size = $scope.per_page;


            RouteModification.query(searchPars, function (result, headers) {
                if (result.length < 1) {
                    $scope.total = 0;
                    AlertService.warning($filter('translate')('reports.notificationAudit.noResultsWereFound'));
                    return;
                }
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.total = headers('x-total-count');
                 for (var i = 0; i < result.length; i++) {
                    var r = result[i];
                    r.status = calculateRouteStatus(r);
                    r.cancellable = !r.cancelled && !r.inProgress && !r.completed && r.upcoming;
                    $scope.routes.push(r);
                }
                $scope.date = new Date();
                $scope.selectedOrg = $scope.organization;
            }, function (err) {
                AlertService.error($filter('translate')('filters.resultMessages.resultsError'));
            });
        };

        function calculateRouteStatus(route) {
            if (route.inProgress) return 'inProgress';
            if (route.completed) return 'completed';
            if (route.cancelled) return 'cancelled';
            return 'upcoming';
        }


        $scope.reset = function sortBy() {
            $scope.routes = [];
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.page = 0;
            $scope.total = 0;
        };

        $scope.timeFormat = function (time) {
            return DateUtils.timeFormatWithTz(time, $scope.selectedOrg.fullTimezone);
        };

        $scope.clean = function() {
            $scope.selectedRoute = undefined;
            $scope.vehicle = undefined;
            $scope.routes = [];
            $scope.total = 0;
            clearSearchParameters();
        };

        function clearSearchParameters(){
            $scope.searchParams = {
                route: undefined,
                vehicle: undefined,
                school: undefined,
                date: new Date()
            };
        }

         $scope.clearFilters = function() {
            clearSearchParameters();
            $scope.schoolsAsync = [];
            $scope.vehiclesAsync = [];
            $scope.routesAsync = [];
        };

        // filter queries
        $scope.getRoutesByName = function(query) {
            if (!$scope.organization || !query) {
                return undefined;
            }
            return $http.get('api/runs/match?organization=' + $scope.organization.code + '&name=' + query
            + '&active=true').then(function (results) {
                $scope.routesAsync = results.data;
            }, function (err) {
                $scope.routesAsync = [];
            });
        };
        $scope.getVehiclesByName = function(query) {
            if (!$scope.organization || !query) {
                return undefined;
            }
            $http.get('api/vehicles/match?organization=' + $scope.organization.code + '&id=' + query
            + '&active=true').then(function (results) {
                $scope.vehiclesAsync = [];
                for(var i = 0; i < results.data.length; i++) {
                    var r = results.data[i];
                    $scope.vehiclesAsync.push({ id: r.id, name: r.id });
                }
            }, function (err) {
                $scope.vehiclesAsync = [];
            });
        };
        $scope.getSchoolsByName = function(query) {
            if (!$scope.organization || !query) {
                return undefined;
            }
            $http.get('api/schools/match?organization=' + $scope.organization.id + '&schoolName=' + query)
            .then(function (results) {
                $scope.schoolsAsync = results.data;
            }, function (err) {
                $scope.schoolsAsync = [];
            });
        };
    }
})();
