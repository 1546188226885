'use strict';

angular.module('schoolbushubApp')
    .controller('VehicleAuditDetailController', function ($scope, $rootScope, $stateParams, $uibModalInstance, DataUtils, entity, VehicleAudit, NotificationAudit) {
        $scope.vehicleAudit = entity;
        $scope.load = function (id) {
            VehicleAudit.get({id: id}, function(result) {
                $scope.vehicleAudit = result;
            });
        };

        var unsubscribe = $rootScope.$on('schoolbushubApp:vehicleAuditUpdate', function(event, result) {
            $scope.vehicleAudit = result;
        });

        $scope.close = function() {
            unsubscribe();
            $uibModalInstance.dismiss('ok');
        };

        $scope.$on('$destroy', unsubscribe);

        $scope.byteSize = DataUtils.byteSize;

        $scope.formatDate = function(d) {
            if (!d) return '';
            return moment(d).format('MM/DD/YYYY HH:mm:ss');
        }
    });
