"use strict";

angular.module("schoolbushubApp").controller("MonitoringCtrl", MonitoringCtrl);
MonitoringCtrl.$inject = [
  "$scope",
  "$location",
  "$anchorScroll",
  "$http",
  "$filter",
  "RestService",
  "MonitoringSvc",
  "Organization",
  "EVENT_SOURCES",
];

function MonitoringCtrl(
  $scope,
  $location,
  $anchorScroll,
  $http,
  $filter,
  RestService,
  MonitoringSvc,
  Organization,
  EVENT_SOURCES
) {
  var scp = $scope;

  scp.disablePlanned = false;
  scp.disableHistoric = false;
  scp.organizations = [];
  scp.routesAsync = [];
  scp.vehiclesAsync = [];
  scp.runOrVehicle = {};
  var dateFormat = "YYYY-MM-DD";

  scp.isCustomerAdmin = false;
  Organization.getRoleOrganizations().then(function (result) {
    scp.organizations = result.organizations;
    scp.isCustomerAdmin = result.isCustomerAdmin;
    scp.organization = result.organization;
  });

  var baseSizes = {
    selStop: { h: 35, w: 65, o: 30 },
    stop: { h: 94.626, w: 50 },
    pulse: { h: 32, w: 32 },
    line: { w: 7 },
  };

  var distanceIcons = ["location_on", "arrow_upward", "arrow_downward", "arrow_downward"];

  var stopVarsToCopy = [
    "distanceInsideAllowedTime",
    "distanceMsgNotReceived",
    "distanceMsgOld",
    "timeInsideAllowedTime",
    "timeMsgNotReceived",
    "timeNotificationSent",
    "expectedEventTime",
    "realEventTime",
    "realArrivalTime",
    "stopDuration",
    "sbhTimeArrival",
    "sbhDistanceArrival"
  ];
  var studentVarsToCopy = ["registered", "distanceEmail", "timeEmail", "pickUp", "dropOff", "arrivalTime"];

  require([
    "esri/config",
    "esri/map",
    "esri/layers/FeatureLayer",
    "esri/geometry/Point",
    "esri/symbols/PictureMarkerSymbol",
    "esri/graphic",
    "esri/symbols/SimpleLineSymbol",
    "esri/Color",
    "esri/renderers/SimpleRenderer",
    "esri/renderers/UniqueValueRenderer",
    "esri/geometry/Extent",
    "esri/layers/GraphicsLayer",
    "esri/SpatialReference",
  ], function (
    esriConfig,
    Map,
    FeatureLayer,
    Point,
    PictureMarkerSymbol,
    Graphic,
    SimpleLineSymbol,
    Color,
    SimpleRenderer,
    UniqueValueRenderer,
    Extent,
    GraphicsLayer,
    SpatialReference
  ) {
    esriConfig.defaults.io.corsEnabledServers.push("arcgisstaging.vmaxlive.net");
    esriConfig.defaults.io.corsDetection = false;

    scp.map = new Map("monitoringMap", {
      center: [-107.4919934, 49.7952768],
      zoom: 4,
      basemap: "streets",
      force3DTransforms: true,
    });

    scp.map.on("load", function (ev) {
      setStopIconsSizes(ev.map.getZoom());
    });
    scp.map.on("zoom-end", function (ev) {
      setStopIconsSizes(ev.level);
    });

    var selectedStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-selected.svg", 20, 20);
    scp.selectedMarker = new Graphic(undefined, selectedStopSymbol);

    var historicSymbol = new PictureMarkerSymbol();
    historicSymbol.setUrl("../assets/images/arrow2.svg");

    var historicRenderer = new SimpleRenderer(historicSymbol);
    historicRenderer.setRotationInfo({
      field: function (gr) {
        return parseInt(gr.attributes.heading);
      },
    });

    var routeSymbol = new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, Color("#337AB7"), 4);
    var routeRenderer = new SimpleRenderer(routeSymbol);

    var blueStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-blue.svg", 20, 20);
    var grayStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-gray.svg", 20, 20);
    var greenStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-green.svg", 20, 20);
    var yellowStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-yellow.svg", 20, 20);
    var redStopSymbol = new PictureMarkerSymbol("../assets/images/monitoring/bus-stop-red.svg", 20, 20);
    var depotSymbol = new PictureMarkerSymbol("../assets/images/monitoring/depot-black.svg", 20, 20);
    var schoolSymbol = new PictureMarkerSymbol("../assets/images/monitoring/school-blue.svg", 20, 20);

    function setStopIconsSizes(lvl) {
      var factor = lvl * lvl * 0.004;
      historicSymbol.setHeight(baseSizes.pulse.h * factor);
      historicSymbol.setWidth(baseSizes.pulse.w * factor);
      routeSymbol.setWidth(baseSizes.line.w * factor);
      selectedStopSymbol.setWidth(baseSizes.selStop.w * factor);
      selectedStopSymbol.setHeight(baseSizes.selStop.h * factor);
      selectedStopSymbol.setOffset(0, baseSizes.selStop.o * factor);

      angular.forEach(
        [blueStopSymbol, grayStopSymbol, greenStopSymbol, yellowStopSymbol, redStopSymbol, depotSymbol, schoolSymbol],
        function (sym) {
          sym.setWidth(baseSizes.stop.w * factor);
          sym.setHeight(baseSizes.stop.h * factor);
        }
      );
    }

    var plannedRenderer = new UniqueValueRenderer(grayStopSymbol, function (stop) {
      if (stop.attributes.type.toLowerCase() === "stop") {
        if (
          stop.attributes.students.length === 0 ||
          stop.attributes.difference === undefined ||
          stop.attributes.noEvents
        )
          return "gray";
        if (Math.abs(stop.attributes.difference) <= 1) return "green";
        if (Math.abs(stop.attributes.difference) <= 5) return "yellow";
        return "red";
      } else if (stop.attributes.type.toLowerCase() === "school") {
        return "school";
      }
      return "depot";
    });
    plannedRenderer.addValue("gray", grayStopSymbol);
    plannedRenderer.addValue("green", greenStopSymbol);
    plannedRenderer.addValue("yellow", yellowStopSymbol);
    plannedRenderer.addValue("red", redStopSymbol);
    plannedRenderer.addValue("blue", blueStopSymbol);
    plannedRenderer.addValue("depot", depotSymbol);
    plannedRenderer.addValue("school", schoolSymbol);

    scp.clearLayers = function () {
      scp.map.graphics.remove(scp.selectedMarker);
      if (scp.plannedRouteLayer) {
        scp.map.removeLayer(scp.plannedRouteLayer);
        scp.plannedRouteLayer = undefined;
      }
      if (scp.historicRouteLayer) {
        scp.map.removeLayer(scp.historicRouteLayer);
        scp.historicRouteLayer = undefined;
      }
      if (scp.routeLayer) {
        scp.map.removeLayer(scp.routeLayer);
        scp.routeLayer = undefined;
      }
    };

    scp.addPlannedLayers = function () {
      if (!scp.routesData.route) {
        return;
      }

      //route polygon layer
      var routeLayer = _.find(scp.routesData.route.directions.layers, function (l) {
        return l.layerDefinition.name === "RouteInfo";
      });
      if (routeLayer) {
        scp.routeLayer = new FeatureLayer(routeLayer);
        scp.routeLayer.setRenderer(routeRenderer);
        scp.routeLayer.setVisibility(!scp.disablePlanned);
        scp.map.addLayer(scp.routeLayer);
        scp.map.reorderLayer(scp.routeLayer, 0);
      }

      // planned stops
      if (scp.routesData.route.routeInfo.stops.length > 0) {
        var stops = scp.routesData.route.routeInfo.stops;

        scp.plannedRouteLayer = new GraphicsLayer();
        scp.plannedRouteLayer.setRenderer(plannedRenderer);
        scp.plannedRouteLayer.setVisibility(!scp.disablePlanned);

        var min = { x: stops[0].geometry.x, y: stops[0].geometry.y };
        var max = { x: stops[0].geometry.x, y: stops[0].geometry.y };
        for (var i = 0; i < stops.length; i++) {
          stops[i].isSelected = false;
          var p = stops[i].geometry;
          if (p.x < min.x) {
            min.x = p.x;
          }
          if (p.x > max.x) {
            max.x = p.x;
          }
          if (p.y < min.y) {
            min.y = p.y;
          }
          if (p.y > max.y) {
            max.y = p.y;
          }

          var point = new Point(stops[i].geometry);
          scp.plannedRouteLayer.add(new Graphic(point, undefined, stops[i]));
        }
        // set initial extent
        var ext = new Extent(min.x, min.y, max.x, max.y, new SpatialReference(stops[0].geometry.spatialReference));
        scp.map.setExtent(ext, true);

        scp.map.addLayer(scp.plannedRouteLayer);
        scp.map.reorderLayer(scp.plannedRouteLayer, 1);
        scp.plannedRouteLayer.on("click", function (ev) {
          scp.highlightStop(ev.graphic.attributes, true);
        });
      }
    };
    scp.addHistoricLayer = function () {
      var info = scp.routesData.historicRoute;
      if (info) {
        scp.historicRouteLayer = new FeatureLayer(info, {
          mode: FeatureLayer.MODE_SNAPSHOT,
        });
        scp.historicRouteLayer.setRenderer(historicRenderer);
        scp.historicRouteLayer.setVisibility(!scp.disableHistoric);
        scp.map.addLayer(scp.historicRouteLayer);

        scp.historicRouteLayer.on("mouse-move", function (evt) {
          var content =
            "<b>" +
            $filter("translate")("monitoring.map.time") +
            ": </b>" +
            scp.formatLocalTimeSeconds(evt.graphic.attributes.eventTimeStamp) +
            "<br>" +
            "<b>" +
            $filter("translate")("monitoring.map.speed") +
            ": </b>" +
            evt.graphic.attributes.speed +
            " " +
            evt.graphic.attributes.distanceUnits +
            "h<br>";
          scp.map.infoWindow.setContent(content);
          scp.map.infoWindow.show(evt.screenPoint, scp.map.getInfoWindowAnchor(evt.screenPoint));
        });
        scp.historicRouteLayer.on("mouse-out", function (evt) {
          scp.map.infoWindow.hide();
        });
      }
    };
  });

  scp.searchResultsOpen = false;
  scp.searchParams = { url: "searchRoutes" };

  scp.datePickerOptions = {
    maxDate: moment(),
  };

  scp.botchTranslate = function (str) {
    return $filter("translate")(str);
  };

  scp.$watch("date", function (newVal, oldVal) {
    if (newVal) {
      scp.searchParams.date = moment.utc(newVal).format(dateFormat);
    } else {
      scp.searchParams.date = "";
    }
    scp.invalidDate = newVal && moment.utc(newVal) > moment.utc();
  });

  scp.toggleResults = function ($event) {
    $event.preventDefault();
    $event.stopPropagation();
    scp.searchResultsOpen = !scp.searchResultsOpen;
    scp.calendarOpened = false;
  };

  scp.searchKeyDown = function (evt) {
    if (evt.keyCode === 13) {
      scp.search(evt);
    }
  };

  scp.search = function ($event) {
    $event.preventDefault();
    $event.stopPropagation();
    scp.searchResults = undefined;
    scp.searchParams.organizationId = scp.organization.id;
    scp.searchParams.isNavigator = scp.organization.eventSource === 'Navigator';
    scp.searchParams.name = undefined;
    scp.searchParams.displayName = undefined;
    scp.searchParams.vehicleId = undefined;
    if (scp.runOrVehicle.name) {
      if (scp.runOrVehicle.name.type == "run") {
        var eventSource = (scp.organization.eventSource || '').toLowerCase();
        var runNameOrId = eventSource === 'navigator'
            ? scp.runOrVehicle.name.name : scp.runOrVehicle.name.id;
        scp.searchParams.name = runNameOrId;
        scp.searchParams.displayName = scp.runOrVehicle.name.text;
      }
      if (scp.runOrVehicle.name.type == "vehicle") {
        scp.searchParams.vehicleId = scp.runOrVehicle.name.id;
      }
    }
    RestService.getArr(
      scp.searchParams,
      function (data) {
        scp.searchResults = [];
        angular.forEach(data, function (r) {
          scp.searchResults.push(r);
        });
        scp.searchResultsOpen = true;
        scp.resultsParams = {
          name: scp.searchParams.displayName,
          date: scp.searchParams.date,
          organizationId: scp.organization.id,
          timezone: scp.organization.fullTimezone,
        };
      },
      function (err) {
        console.console.error(err);
      }
    );
  };

  function addOptions(type, values) {
    _.forEach(values, function (value) {
        value.text = type === "run" ? value.name : value.id;
        scp.optionsAsync.push(Object.assign(value, { type: type }));
    });
  }

  scp.vehicleAndRunGroups = function(item) {
    if (item.type === "run") {
      return $filter("translate")("monitoring.route");
    }

    if (item.type === "vehicle") {
      return $filter("translate")("monitoring.vehicle");
    }
  }

  scp.getOptionsByName = function (query) {
    if (!$scope.organization || !query) {
      return undefined;
    }
    scp.optionsAsync = [];
    Promise.all([
      MonitoringSvc.queryRuns(scp.organization.code, query, addOptions.bind(this, "run")),
      MonitoringSvc.queryVehicles(scp.organization.code, query, addOptions.bind(this, "vehicle")),
    ]);
  };

  scp.selectBus = function (bus) {
    scp.clearLayers();
    scp.bus = bus;
    scp.orgIsNavigator = scp.searchParams.isNavigator;
    scp.routesData = {};
    scp.slider = {};
    scp.searchResultsOpen = false;
    scp.loadingPlanned = true;
    scp.loadingHistoric = true;
    loadRoute(bus);
  };

  function loadRoute(bus) {
    var params = { url: "stopsFromThePlannedRoute", routeId: bus.runGuid, organizationId: bus.organization.code };

    RestService.get(
      params,
      function (data) {
        scp.loadingPlanned = false;
        if (data && data.routeInfo && data.routeInfo.stops) {
          data.routeInfo.stops = _.sortBy(data.routeInfo.stops, "order");
          var searchDate = moment.utc(bus.date);
          data.routeInfo.startTime = moment
            .tz(data.routeInfo.startTime, scp.resultsParams.timezone)
            .year(searchDate.year())
            .month(searchDate.month())
            .date(searchDate.date());
          data.routeInfo.endTime = moment
            .tz(data.routeInfo.endTime, scp.resultsParams.timezone)
            .year(searchDate.year())
            .month(searchDate.month())
            .date(searchDate.date());
          if (data.routeInfo.startTime.isAfter(data.routeInfo.endTime)) {
            data.routeInfo.endTime.add(1, "days");
          }
          setSliderValues(data);
          loadTelemetry(data.routeInfo.startTime, data.routeInfo.endTime, bus);
        } else {
          scp.loadingHistoric = false;
        }
        scp.routesData.route = data;
        loadRouteEvents(bus);
      },
      function (err) {
        scp.loadingPlanned = false;
      }
    );
  }

  function loadTelemetry(startTime, endTime, bus) {
    var params = {
      url: "historicRoute",
      startDate: moment.tz(startTime, scp.resultsParams.timezone).format("YYYY-MM-DD HH:mm"),
      endDate: moment.tz(endTime, scp.resultsParams.timezone).format("YYYY-MM-DD HH:mm"),
      organizationId: bus.organization.code,
      deviceId: bus.deviceId,
    };

    RestService.get(
      params,
      function (data) {
        scp.loadingHistoric = false;
        scp.routesData.historicRoute = data;
        scp.addHistoricLayer();
        scp.slider.options.disabled = false;
      },
      function (err) {
        scp.loadingHistoric = false;
        scp.slider.options.disabled = false;
      }
    );
  }

  function loadRouteEvents(bus) {
    var params = {
      url: "routeEvents",
      date: scp.searchParams.date,
      deviceId: bus.deviceId,
      routeId: bus.runGuid,
      organizationId: scp.resultsParams.organizationId,
    };
    if (!scp.resultsParams.date) {
      params.date = moment.utc(bus.date).format(dateFormat);
    }

    RestService.get(
      params,
      function (data) {
        if (scp.routesData.route.routeInfo) {
          mergeRouteInfoAndEvents(scp.routesData.route.routeInfo, data);
          setStopVariables(scp.routesData.route.routeInfo.stops);
          scp.addPlannedLayers();
        }
      },
      function (err) {
        scp.loadingHistoric = false;
      }
    );
  }

  function setStopVariables(stops) {
    angular.forEach(stops, function (stop) {
      var arrival = stop.actualArrivalTime || stop.realArrivalTime || undefined;
      if (arrival !== undefined && stop.arrivalTime !== undefined) {
        arrival = moment.tz(scp.resultsParams.timezone).startOf("day").format(dateFormat) + arrival.substr(10, 9);
        var newArr = moment.tz(arrival, scp.resultsParams.timezone).valueOf();
        stop.differenceMs = newArr - stop.arrivalTime;
        stop.difference = Math.floor(stop.differenceMs / 60000);
      }
      stop.failedDistance = 0;
      stop.failedTime = 0;
      stop.failedStudent = 0;
      angular.forEach(stop.students, function (student) {
        if (scp.orgIsNavigator) {
          !student.pickUp && !student.dropOff && stop.failedStudent++;
        } else {
          !student.distanceEmail && stop.failedDistance++;
        }
        !student.timeEmail && stop.failedTime++;
      });
      if (stop.students.length === 0) {
        stop.noEvents = true;
      }
    });
  }

  function mergeRouteInfoAndEvents(routeInfo, events) {
    if (!routeInfo || !routeInfo.stops || !events || !events.stops) {
      return;
    }
    for (var i = 0; i < routeInfo.stops.length; i++) {
      var stop = routeInfo.stops[i];
      var eventStop =
        _.find(events.stops, function (s) {
          return s.order === stop.order;
        }) || {};
      stop.noEvents = !eventStop.order;
      _.each(stopVarsToCopy, function (v) {
        stop[v] = eventStop[v];
      });
      _.each(stop.students, function (student) {
        var eventStudent =
          _.find(eventStop.students, function (evtSt) {
            return evtSt.id === student.id;
          }) || {};
        _.each(studentVarsToCopy, function (v) {
          student[v] = eventStudent[v];
        });
        if (scp.orgIsNavigator) {
          student.icon = distanceIcons[~~student.pickUp + (~~student.dropOff << 1)];
        } else {
          student.icon = distanceIcons[0];
        }
      });
    }
  }

  scp.timeDifferenceClass = function (stop) {
    if ((stop.noEvents && stop.students.length === 0) || stop.difference === undefined) return "unavailable";
    if (stop.difference >= -1 && stop.difference <= 1) return "success";
    if (stop.difference >= -5 && stop.difference <= 5) return "warning";
    return "danger";
  };

  scp.timeDifferenceTooltip = function (diff, noEvents) {
    if (noEvents || noEvents === undefined) return $filter("translate")("monitoring.stopsList.noEvents");
    if (diff === 0) return $filter("translate")("monitoring.stopsList.busOnTime");
    if (diff < 0)
      return $filter("translate")("monitoring.stopsList.busEarly", {
        num: -diff,
      });
    if (diff > 0)
      return $filter("translate")("monitoring.stopsList.busLate", {
        num: diff,
      });
    if (scp.orgIsNavigator)
      return $filter("translate")("monitoring.stopsList.noPickUpDropOff");

    return $filter("translate")("monitoring.stopsList.busUnavailable");
  };

  scp.notificationsClass = function (stop) {
    if (stop.students.length > 0) {
      var errors = stop.failedDistance + stop.failedTime + stop.failedStudent;
      return errors > 0 ? "danger" : "";
    }
    return "unavailable";
  };

  scp.notificationsTooltip = function (t, students) {
    if (students.length === 0) return $filter("translate")("monitoring.stopsList.stopWithoutStudents");
    if (t == 0) return $filter("translate")("monitoring.stopsList.okNotifications");
    return $filter("translate")("monitoring.stopsList.failedNotifications", {
      num: t,
    });
  };

  scp.studentTooltip = function (arrivalTime) {
    if (arrivalTime) {
      var time = scp.formatTime(arrivalTime);
      return $filter("translate")("monitoring.stopsList.studentTooltip", {
        time: time,
      });
    }
  };

  scp.togglePlanned = function () {
    if (scp.plannedRouteLayer) {
      scp.plannedRouteLayer.setVisibility(scp.disablePlanned);
    }
    if (scp.routeLayer) {
      scp.routeLayer.setVisibility(scp.disablePlanned);
    }
    if (scp.selectedMarker) {
      scp.disablePlanned ? scp.selectedMarker.show() : scp.selectedMarker.hide();
    }
    scp.disablePlanned = !scp.disablePlanned;
  };

  scp.toggleHistoric = function () {
    if (scp.historicRouteLayer) {
      scp.historicRouteLayer.setVisibility(scp.disableHistoric);
    }
    scp.disableHistoric = !scp.disableHistoric;
  };

  scp.expandStop = function (s, evt) {
    s.isExpanded = s.isExpanded !== true;
  };

  scp.selectStop = function (s, evt) {
    evt.stopPropagation();
    scp.highlightStop(s);
  };

  scp.highlightStop = function (stop, force) {
    if (scp.routesData) {
      angular.forEach(scp.routesData.route.routeInfo.stops, function (s) {
        s.isSelected = s === stop;
        if (s.isSelected) {
          s.isExpanded = true;
          if (force) {
            if ($location.hash() === "listStop" + s.order) {
              $anchorScroll();
            } else {
              $location.hash("listStop" + s.order);
            }
          }
        }
      });
    }

    if (scp.plannedRouteLayer) {
      var mapStop = _.find(scp.plannedRouteLayer.graphics, function (s) {
        return s.attributes === stop;
      });

      if (mapStop) {
        if (scp.map.getZoom() < 14) {
          scp.map.setZoom(14);
        }
        scp.map.centerAt(mapStop.geometry);
        scp.map.graphics.remove(scp.selectedMarker);
        scp.selectedMarker.setGeometry(mapStop.geometry);
        scp.map.graphics.add(scp.selectedMarker);
      }
    }
    if (force) {
      scp.$apply();
    }
  };

  scp.getRoutesByName = function (query) {
    if (!$scope.organization || !query) {
      return undefined;
    }
    return $http
      .get("api/runs/match?organization=" + $scope.organization.code + "&name=" + query + "&active=true")
      .then(
        function (results) {
          $scope.routesAsync = results.data;
        },
        function (err) {
          $scope.routesAsync = [];
        }
      );
  };
  scp.getVehiclesByName = function (query) {
    if (!$scope.organization || !query) {
      return undefined;
    }
    $http.get("api/vehicles/match?organization=" + $scope.organization.code + "&id=" + query + "&active=true").then(
      function (results) {
        $scope.vehiclesAsync = [];
        for (var i = 0; i < results.data.length; i++) {
          var r = results.data[i];
          $scope.vehiclesAsync.push({ id: r.id, name: r.id });
        }
      },
      function (err) {
        $scope.vehiclesAsync = [];
      }
    );
  };

  scp.formatArrivalDiff = function (diff) {
    if (isNaN(diff)) return "";
    var v = Math.abs(diff);
    if (v > 60) return "!!";
    return v;
  };

  scp.formatFailedCount = function (tCount, dCount) {
    var c = tCount + dCount;
    if (c > 99) c = "!!";
    return c;
  };

  scp.formatDateTime = function (d) {
    if (!d) return "--";
    return moment.utc(d).format("MM/DD/YYYY HH:mm:ss");
  };

  scp.formatTime = function (d) {
    if (!d) return "--";
    return moment.utc(d).format("HH:mm");
  };

  scp.formatLocalTime = function (d) {
    if (!d) return "--";
    return moment.tz(d, scp.resultsParams.timezone).format("HH:mm");
  };

  scp.formatTimeSeconds = function (d) {
    if (!d) return "--";
    return moment.utc(d).format("HH:mm:ss");
  };

  scp.formatLocalTimeSeconds = function (d) {
    if (!d) return "--";
    return moment.tz(d, scp.resultsParams.timezone).format("HH:mm:ss");
  };

  scp.formatDate = function (d) {
    if (!d) return "--";
    return moment.utc(d).format("MM/DD/YYYY");
  };

  scp.dateDiff = function (d1, d2) {
    if (!d1 || !d2) return "--";
    return Math.floor(moment(d1).diff(d2) / 60000);
  };

  scp.$on("$destroy", function () {
    scp.map.destroy();
  });

  scp.getStudentsInStop = function (stop) {
    if (!stop.students || stop.students.length === 0) {
      MonitoringSvc.getStudentsInStop(
        stop.tripOrderGuid.replace("-PU", "").replace("-DO", ""),
        scp.bus.deviceId,
        scp.bus.organization.code,
        function (data) {
          stop.students = data;
        },
        function () {
          stop.students = [];
        }
      );
    }
  };

  scp.onEndSlider = function () {
    scp.map.removeLayer(scp.historicRouteLayer);
    scp.historicRouteLayer = undefined;
    scp.slider.options.disabled = true;
    loadTelemetry(scp.slider.minValue, scp.slider.maxValue, scp.bus);
  };

  function setSliderValues(data) {
    var minValue = data.routeInfo.startTime.valueOf() - 900000;
    var maxValue = data.routeInfo.endTime.valueOf() + 900000;
    var oneHourInMilli = 3600000;
    scp.slider = {
      minValue: minValue,
      maxValue: maxValue,
      options: {
        floor: minValue - oneHourInMilli,
        ceil: maxValue + oneHourInMilli,
        translate: scp.formatLocalTime,
        onEnd: scp.onEndSlider,
        hideLimitLabels: true,
      },
    };
  }
}
