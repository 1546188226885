'use strict';

angular.module('schoolbushubApp')
    .controller('notificationsController', function ($scope, $filter, $http, Organization, RouteModification, FiltersRouteSvc, RestService, AlertService) {
        var scp = $scope;
        scp.organizations = [];
        scp.selectedRoutes = [];
        scp.isCustomerAdmin = false;
        scp.disableSendMessage = true;
        scp.notifyAll = "true";
        scp.searchParams = {
            url: 'findRoutes'
        };

        scp.botchTranslate = function(str) {
            return $filter('translate')(str);
        }

        Organization.getRoleOrganizations().then(function (result) {
            scp.organizations = result.organizations;
            scp.isCustomerAdmin = result.isCustomerAdmin;
            scp.organization = result.organization;
        })

        scp.search = function ($event) {
            scp.selectAll = false;
            scp.searchResults = undefined;
            scp.selectedRoutes = [];
            var searchPars = {fleetId: scp.organization.code};
            searchPars.active = true;
            if (scp.searchParams.route) {
                searchPars.runId = scp.searchParams.route.id;
            }
            if (scp.searchParams.vehicle) {
                searchPars.vehicleId = scp.searchParams.vehicle.id;
            }
            if (scp.searchParams.school) {
               searchPars.schoolId = scp.searchParams.school.guid;
            }

             searchPars.page = 0;
             searchPars.size = 5000;

            RouteModification.query(searchPars, function (data, headers) {
                scp.searchResults = [];
                angular.forEach(data, function (r) {
                    scp.searchResults.push(r);
                });
                $event.preventDefault();
                $event.stopPropagation();
                scp.searchResultsOpen = true;
                scp.resultsParams = {
                    name: scp.searchParams.name,
                    organizationId: scp.organization.id,
                };
                scp.verifySendMessage();
                if(scp.searchResults.length === 0){
                    AlertService.info($filter('translate')('notifications.filter.notfoundroutes'));
                }
            }, function (err) {
                AlertService.error($filter('translate')('notifications.filter.error'));
            });
        };

        scp.selectRoute = function(route){
            if(route.__selected) {
                scp.selectedRoutes.push(route);
            } else {
                scp.selectedRoutes.shift(route)
            }
            scp.verifySendMessage();
        }

        scp.selectAllRoutes = function(){
            if(scp.selectAll){
                scp.selectedRoutes = scp.searchResults;
            } else {
                scp.selectedRoutes = [];
            }
            scp.searchResults.forEach(function(r){
                r.__selected = scp.selectAll;
            });
            scp.verifySendMessage();
        }

        scp.updateMessage = function(){
            scp.customMessage = scp.messageType;
            scp.verifySendMessage();
        }

        scp.sendMessage = function(){
            var requestBody = {
                message: scp.customMessage,
                organizationId: scp.organization.id
            };
            if(scp.notifyAll === 'false') {
               requestBody.routes = scp.selectedRoutes
            }
            RestService.post({url: 'sendMassiveNotification'}, requestBody, function(){
                AlertService.info($filter('translate')('notifications.messageSended'));
            }, function(err){
            if(err.status === 404){
             AlertService.error($filter('translate')('notifications.studentsOrParentsNotFound'));
            } else {
             AlertService.error($filter('translate')('notifications.errorSendingMessage'));
            }
                console.log(err);
            });
        }

      scp.getRoutesByName = function(query) {
          if (!scp.organization || !query) {
            return undefined;
          }
          FiltersRouteSvc.getRoutesByName(scp.organization, query, function(data) {
            scp.routesAsync = data;
          }, function(err){
            scp.routesAsync = [];
          });
      };

      scp.getVehiclesByName = function(query) {
          if (!scp.organization || !query) {
              return undefined;
          }
          FiltersRouteSvc.getVehiclesByName(scp.organization, query, function(data) {
            scp.vehiclesAsync = [];
            for(var i = 0; i < data.length; i++) {
                var r = data[i];
                scp.vehiclesAsync.push({ id: r.id, name: r.id });
            }
          }, function(err){
            scp.vehiclesAsync = [];
          });
      };

      scp.getSchoolsByName = function(query) {
          if (!scp.organization || !query) {
              return undefined;
          }
          FiltersRouteSvc.getSchoolsByName(scp.organization, query, function(data) {
            scp.schoolsAsync = data;
          }, function(err){
            scp.schoolsAsync = [];
          });
      };

      scp.verifySendMessage = function() {
        scp.disableSendMessage =  !scp.customMessage || (scp.notifyAll === 'false' && scp.selectedRoutes.length === 0);
      }

     scp.cleanFilters = function () {
       $scope.searchParams.route = undefined;
       $scope.searchParams.vehicle = undefined;
       $scope.searchParams.school = undefined;
     }

    });
