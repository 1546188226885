'use strict';

angular.module('schoolbushubApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('administration', {
                parent: 'site',
                url: '/administration',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SEONADMIN', 'ROLE_CUSTOMERADMIN'],
                    pageTitle: 'School Bus Hub'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/administration/administration.html',
                        controller: 'AdministrationController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('administration');
                        return $translate.refresh();
                    }]
                }
            });
    });
