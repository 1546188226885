'use strict';

angular.module('schoolbushubApp').controller('MapsController', MapsController);
MapsController.$inject = ['$scope', '$timeout', '$location', '$stateParams', '$anchorScroll', '$window', '$document',
  '$interval', '$mdToast', '$mdMedia', '$state', '$filter', '$mdDialog', 'SharedService', 'DataService'];

function MapsController($scope, $timeout, $location, $stateParams, $anchorScroll, $window, $document, $interval,
  $mdToast, $mdMedia, $state, $filter, $mdDialog, SharedService, DataService) {
  var scp = $scope;
  scp.mapH = { height: '90vh' };

  var busRefreshInt;

  var baseSizes = {
    stop: { w: 50, h: 95 },
    bus: { w: 70.5, h: 60 },
    route: { w: 8 },
  };

  var rotationMap = {
    N: 0,
    NNE: 22.5,
    NE: 45,
    ENE: 67.5,
    E: 90,
    ESE: 112.5,
    SE: 135,
    SSE: 157.5,
    S: 180,
    SSW: 202.5,
    SW: 225,
    WSW: 247.5,
    W: 270,
    WNW: 292.5,
    NW: 315,
    NNW: 337.5
  };

  var angleArr = [
    "0", "15", "30", "45", "60", "75",
    "90", "105", "120", "135", "150", "165",
    "180", "195", "210", "225", "240", "255",
    "270", "285", "300", "315", "330", "345",
  ];

  require([
    "esri/map", "esri/geometry/Point", "esri/geometry/Extent", "esri/layers/StreamLayer", "esri/layers/FeatureLayer",
    "esri/layers/GraphicsLayer", "esri/Color", "esri/renderers/SimpleRenderer",'esri/renderers/UniqueValueRenderer',
    "esri/geometry/Multipoint", "esri/symbols/SimpleMarkerSymbol", "esri/symbols/PictureMarkerSymbol",
    "esri/symbols/SimpleLineSymbol", "esri/graphic", "esri/config", "esri/request", 'dojo/request/xhr', 'dojo/aspect'],
    function (Map, Point, Extent, StreamLayer, FeatureLayer, GraphicsLayer, Color, SimpleRenderer, UniqueValueRenderer, Multipoint,
      SimpleMarkerSymbol, PictureMarkerSymbol, SimpleLineSymbol, Graphic, esriConfig, esriRequest, XHR, Aspect) {

      esriConfig.defaults.io.corsDetection = false;

      esriRequest.setRequestPreCallback(function (ioArgs) {
        var hostName = scp.orgUrls && scp.orgUrls.streamDomain;
        if (hostName && ioArgs && ioArgs.url && ioArgs.url.indexOf(hostName) > -1) {
          ioArgs.headers = ioArgs.headers || {};
          ioArgs.headers.Authorization = scp.orgUrls.ssoToken;
        }
        return ioArgs;
      });

      Aspect.before(dojo, "xhr", function(httpMethod, args)  {
        if (scp.orgUrls && scp.orgUrls.ssoToken) {
          args.headers = args.headers || {};
          args.headers.Authorization = scp.orgUrls.ssoToken;
        }
      });

      scp.map = new Map("mapDiv", {
        center: [-107.4919934, 49.7952768],
        zoom: 4,
        basemap: "streets",
        force3DTransforms: true
      });
      scp.map.on("load", function (ev) {
        setStopIconsSizes(ev.map.getZoom());
        init();
      });
      scp.map.on('zoom-end', function (ev) {
        setStopIconsSizes(ev.level);
      });

      var stopSym = new PictureMarkerSymbol("../assets/images/maps/stop.svg");
      var schoolSym = new PictureMarkerSymbol("../assets/images/maps/school.svg");
      var busSVGs = _.map(angleArr, function (name) {
        return new PictureMarkerSymbol("../assets/images/maps/bus/" + name + ".svg");
      });
      var routeSymbol = new SimpleLineSymbol(esri.symbol.SimpleLineSymbol.STYLE_SOLID,
        new Color([118, 158, 245, 1.0]), 8);
      var routeRenderer = new SimpleRenderer(routeSymbol);

      function setStopIconsSizes(lvl) {
        var factor = lvl * lvl * 0.0037;
        stopSym.setWidth(baseSizes.stop.w * factor);
        stopSym.setHeight(baseSizes.stop.h * factor);
        schoolSym.setWidth(baseSizes.stop.w * factor);
        schoolSym.setHeight(baseSizes.stop.h * factor);
        _.forEach(busSVGs, function (busMarker) {
          busMarker.setHeight(baseSizes.bus.h * factor);
          busMarker.setWidth(baseSizes.bus.w * factor);
        });
        routeSymbol.setWidth(baseSizes.route.w * factor);
      }

      function init() {
        scp.stopsLayer = new GraphicsLayer();
        scp.map.addLayer(scp.stopsLayer);
        SharedService.getCredentials().then(function (credentials) {
          SharedService.getStudents(credentials.account).then(function (students) {
            scp.students = students;
            scp.setMapHeight();
            if (scp.students && scp.students.length > 0) {
              scp.listCols = scp.students.length > 4 ? 4 : scp.students.length;

              var st = scp.students[0];
              if ($stateParams.studentId) {
                st = _.find(scp.students, function (s) { return s.studentId == $stateParams.studentId });
                if (!st) {
                  (scp.mapToastWithText($filter('translate')('maps.messages.noStudent')));
                }
              }
              scp.selectStudent(st);
            }
          });
        });
        var zoomControls = $document[0].querySelector('.esriSimpleSliderTL');
        zoomControls.classList.add('expanded');

        initDeviceLocation();
      }

      // Custom base layer if provided by server
      scp.addBaseLayer = function () {
        scp.baseMapLayer = new esri.layers.ArcGISTiledMapServiceLayer(scp.orgUrls.mapsGuid);
        scp.map.addLayer(baseMapLayer);
      };

      scp.addRouteLayer = function () {
        var featureCollection = {
          layerDefinition: {
            geometryType: "esriGeometryPolyline",
            fields: [{
              name: "__OBJECTID",
              alias: "OBJECTID",
              type: "esriFieldTypeOID"
            }]
          },
          featureSet: scp.selectedBus.features
        };
        scp.routeLayer = new FeatureLayer(featureCollection);
        scp.routeLayer.setRenderer(routeRenderer);
        scp.map.addLayer(scp.routeLayer);

        if (scp.orgUrls.mapsGuid) {
          scp.addBaseLayer();
        }
      };

      scp.addBusLayer = function () {
        scp.orgUrls.streamDomain = scp.orgUrls.streamingUrl.replace('http://', '')
          .replace('https://', '').split('/')[0];
        esriConfig.defaults.io.corsEnabledServers.push(scp.orgUrls.streamDomain);

        var definitionField = 'trackId';
        scp.busLayer = new StreamLayer(scp.orgUrls.streamingUrl, {
          maximumTrackPoints: 1,
          definitionExpression: (definitionField + " = '" + scp.selectedBus.vehicle.uvid + "'"),
          purgeOptions: {
            displayCount: 1
          }
        });

        scp.busLayer.on('connect', function () {
          if (scp.orgUrls.ssoToken) {
            scp.busLayer.socket.send(JSON.stringify({
              type: 'Authorization',
              token: scp.orgUrls.ssoToken.split(' ')[1]
            }));
          }
        });

        scp.busLayer.on('load', function () {
          var renderer = new UniqueValueRenderer(busSVGs[0], "iconName");
          _.forEach(busSVGs, function (symbol, i) {
            renderer.addValue(angleArr[i], symbol);
          });
          scp.busLayer.setRenderer(renderer);
          scp.map.addLayer(scp.busLayer);
        });

        scp.busLayer.on('graphic-add', function (g) {
          var rotation = parseInt(g.graphic.attributes.heading);
          if (isNaN(rotation)) {
            rotation = rotationMap[g.graphic.attributes.heading] || 0;
          }
          g.graphic.attributes.iconName = angleArr[Math.round((rotation * angleArr.length) / 360) % angleArr.length];
          var time = moment(g.graphic.attributes.time);
          if (moment().diff(time) > 300000) {
            scp.busLayer.remove(g.graphic);
          }
        });
      };

      scp.changeBusDefinition = function () {
        var definitionField = 'trackId'
        scp.busLayer.setDefinitionExpression(definitionField + " = '" + scp.selectedBus.vehicle.uvid + "'");
      };

      scp.addStopsGraphics = function () {
        var stops = scp.selectedBus.stops || [];

        for (var i = 0; i < stops.length; i++) {
          var stop = stops[i];
          var symbol = stop.type.toLowerCase().indexOf('school') > -1 ? schoolSym : stopSym;

          var pt = new Point({
            x: stop.location.longitude,
            y: stop.location.latitude,
            spatialReference: {
              wkid: stop.location.spatialReference.wkid || 4326
            }
          });
          var graphic = new Graphic(pt, symbol);
          scp.stopsLayer.add(graphic);
        }
      };

      scp.reCenterPoints = function () {
        var points = [];
        var stopWKid = null;
        var defaultWKid = 4326;
        var plannedStops = scp.selectedBus.stops || [];

        for (var i = 0; i < plannedStops.length; i++) {
          points.push([plannedStops[i].location.longitude, plannedStops[i].location.latitude]);
          stopWKid = plannedStops[0].location.spatialReference.wkid;
        }

        if (points.length > 0) {
          scp.stopsMulti = new Multipoint({
            points: points,
            spatialReference: { wkid: stopWKid || defaultWKid }
          });

          if (scp.stopsMulti.points.length > 1) {
            scp.map.setExtent(scp.stopsMulti.getExtent(), true);
          } else {
            scp.map.centerAndZoom(scp.stopsMulti.getPoint(0), 13);
          }

          scp.map.reorderLayer(scp.stopsLayer, 1);
        }
      };

      //user location
      function initDeviceLocation() {
        if (navigator.geolocation) {
          scp.watchId = navigator.geolocation.watchPosition(showLocation, locationError);
        }
      }

      function locationError(error) {
        if (navigator.geolocation) {
          navigator.geolocation.clearWatch(scp.watchId);
        }
        switch (error.code) {
          case error.PERMISSION_DENIED:
            scp.mapToastWithText($filter('translate')('maps.messages.location.notProvided'));
            break;

          case error.POSITION_UNAVAILABLE:
            scp.mapToastWithText($filter('translate')('maps.messages.location.notAvailable'));
            break;

          default:
            break;
        }
      }

      function showLocation(location) {
        var pt = new Point(location.coords.longitude, location.coords.latitude);
        if (!scp.locationGraphic) {
          addGraphic(pt);
        }
        else {
          scp.locationGraphic.setGeometry(pt);
        }
      }

      function addGraphic(pt) {
        var symbol = new SimpleMarkerSymbol(SimpleMarkerSymbol.STYLE_CIRCLE, 10,
          new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color([66, 133, 244, 0.3]), 8),
          new Color([66, 133, 244, 0.95])
        );
        scp.locationGraphic = new Graphic(pt, symbol);
        scp.map.graphics.add(scp.locationGraphic);
      }
    });

  $scope.selectStudent = function (ele) {
    if (!ele || ele == scp.selectedStudent) {
      return;
    }

    scp.selectedStudent = ele;
    $scope.cleanStudent();
  }

  $scope.cleanStudent = function () {
    scp.recenterStop = true;
    scp.recenterPoly = false;

    if (scp.orgUrls) {
      for (var i = esriConfig.defaults.io.corsEnabledServers.length - 1; i >= 0; i--) {
        if (esriConfig.defaults.io.corsEnabledServers[i] === scp.orgUrls.streamDomain) {
          esriConfig.defaults.io.corsEnabledServers.splice(i, 1);
        }
      }
    }

    if (busRefreshInt) {
      $interval.cancel(busRefreshInt);
    }
    busRefreshInt = $interval($scope.updateStudent, 120000);
    $scope.updateStudent();
  };

  $scope.updateStudent = function () {
    if (scp.routeLayer) {
      scp.map.removeLayer(scp.routeLayer);
    }
    if (scp.fenceLayer) {
      scp.map.removeLayer(scp.fenceLayer);
    }
    if (scp.busLayer) {
      scp.map.removeLayer(scp.busLayer);
    }

    scp.stopsLayer.clear();
    scp.stopsMulti = undefined;

    if (scp.baseMapLayer) {
      scp.map.removeLayer(scp.baseMapLayer);
    }


    if (scp.recenterStop || scp.recenterPoly) {
      scp.$emit('showSpinner');
    }
    SharedService.getBus(scp.selectedStudent.studentId, scp.selectedStudent.schoolGuid).then(function (bus) {
      scp.selectedBus = bus;

      if (!bus) {
        scp.mapToastWithText($filter('translate')('maps.messages.noBus'));
        scp.$emit('hideSpinner');
        return;
      }

      if (!bus.organization) {
          console.error("invalid student organizationId", scp.selectedStudent.organizationId);
          scp.$emit('hideSpinner');
          return;
      }

      scp.orgUrls = bus.organization;
      var showVehicle = scp.selectedBus.vehicle.uvid != null
        && scp.orgUrls.streamingUrl != null;

      if (scp.selectedBus.features) {
          scp.addRouteLayer();
      }

      scp.$emit('hideSpinner');
      scp.addStopsGraphics();
      scp.reCenterPoints();

      if (showVehicle) {
        scp.addBusLayer();
      }
    });
  }


  //UI Related methods
  $scope.setMapHeight = function () {
    $timeout(function () {
      $scope.$apply();
      var stCount = $scope.students.length || 0;
      var listH = 50;
      if ($mdMedia('gt-sm')) {
        listH = Math.ceil(stCount / 4)
        listH = ((listH > 3) ? 3 : listH) * 58;
        listH = (listH > 154 ? 154 : listH);
      }

      var winH = $window.innerHeight;
      var topH = 64;
      $scope.mapH = { height: (winH - topH - listH) };
    })
  }

  angular.element($window).bind('resize', function () {
    $timeout(function () { $scope.$apply(); });
    $scope.setMapHeight();
    $location.hash('sbh-top-menu');
    $anchorScroll();
    $location.url($location.path());
  });

  $scope.mapToastWithText = function (text) {
    $mdToast.show(
      $mdToast.simple()
        .textContent(text)
        .capsule(true)
        .position('bottom right')
        .parent($document[0].querySelector('#mapDiv'))
    );
  }

  scp.$on('$destroy', function () {
    if (navigator.geolocation) {
      navigator.geolocation.clearWatch(scp.watchId);
    }
    if (busRefreshInt) {
      $interval.cancel(busRefreshInt);
    }
    scp.map.destroy();
  })
}
