'use strict';

angular.module('schoolbushubApp')
    .controller('NotificationAuditController', function ($scope, $state, $filter, $sce, $uibModal, $translate,
        AlertService, ReportsService, NotificationAudit, ParseLinks, Principal, Organization, DateUtils) {

        /**
        * Generate Report Callback
        *
        * @param response
        */
        var loadPageCallback = function (response) {
            if (response.status == 'success') {
                $scope.loadPage(response.auditResponse);
            } else {
                AlertService.error($filter('translate')('reports.notificationAudit.errorToGenerateTheReport'));
            }
            $scope.busy = false;
        };

        /**
         * Generate Report Callback
         *
         * @param response
         */
        var generateReportCallback = function (response) {
            if (response.status == 'success') {
                $scope.loadPage(response.auditResponse);

                if ($scope.notificationAudits.length > 0) {
                    AlertService.success($filter('translate')('reports.notificationAudit.reportGeneratedSuccessfully'));
                } else {
                    $scope.lastPage = true;
                    AlertService.warning($filter('translate')('reports.notificationAudit.noResultsWereFound'));
                }
            } else {
                AlertService.error($filter('translate')('reports.notificationAudit.errorToGenerateTheReport'));
            }
            $scope.busy = false;
        };

        $scope.formatDate = $filter('translate')('reports.formatDate');
        $scope.page = 0;
        $scope.per_page = $scope.infiniteScrollPageSize;
        $scope.lastPage = false;
        $scope.busy = false;

        $scope.organizations = [];

        $scope.messageTypes = [
            { value: 'SYSTEM', translateStr: 'reports.notificationAudit.system' },
            { value: 'DISTANCE', translateStr: 'reports.notificationAudit.stop' },
            { value: 'TIME', translateStr: 'reports.notificationAudit.time' },
            { value: 'BULK', translateStr: 'reports.notificationAudit.massive' },
            { value: 'PICK_UP', translateStr: 'reports.notificationAudit.pickUp' },
            { value: 'DROP_OFF', translateStr: 'reports.notificationAudit.dropOff' }
        ];

        $scope.notificationTypes = [
            { value: 'EMAIL', translateStr: 'reports.notificationAudit.email' },
            { value: 'PUSH', translateStr: 'reports.notificationAudit.push' }
        ];

        $scope.isCustomerAdmin = false;
        Organization.getRoleOrganizations().then(function (result) {
            $scope.organizations = result.organizations;
            $scope.isCustomerAdmin = result.isCustomerAdmin;
            $scope.organization = result.organization;
        });

        //calendar stuff
        $scope.altInputFormats = ['M!/d!/yyyy'];
        $scope.format = 'MM/dd/yyyy';
        $scope.startDateCalendarOpened = false;
        $scope.endDateCalendarOpened = false;
        $scope.startDatepickerOpts = {
            maxDate: new Date()
        };
        $scope.endDatepickerOpts = {
            maxDate: new Date()
        };

        $scope.toggleCalendar = function (cal) {
            if (cal === 'start') {
                $scope.startDateCalendarOpened = !$scope.startDateCalendarOpened;
            } else if (cal === 'end') {
                $scope.endDateCalendarOpened = !$scope.endDateCalendarOpened;
            }
        }
        $scope.calendarChanged = function () {
            $scope.startDatepickerOpts.maxDate = $scope.endDateCtrl || new Date();
            $scope.endDatepickerOpts.minDate = $scope.startDateCtrl;

            $scope.notificationAuditRequest.startDate = undefined;
            if ($scope.startDateCtrl) {
                $scope.notificationAuditRequest.startDate = moment($scope.startDateCtrl).format("YYYY-MM-DD[T00:00:00Z]");
            }

            $scope.notificationAuditRequest.endDate = undefined;
            if ($scope.endDateCtrl) {
                $scope.notificationAuditRequest.endDate = moment($scope.endDateCtrl).format("YYYY-MM-DD[T23:59:59Z]");
            }
        }

        //actions stuff
        $scope.formIsEmpty = function () {
            return (
                !$scope.notificationAuditRequest.username &&
                !$scope.notificationAuditRequest.recipient &&
                !$scope.notificationAuditRequest.studentId &&
                !$scope.notificationAuditRequest.startDate &&
                !$scope.notificationAuditRequest.endDate &&
                !$scope.notificationAuditRequest.messageTypes &&
                !$scope.notificationAuditRequest.types
            );
        }

        $scope.clean = function () {
            $scope.notificationAudits = [];
            $scope.startDateCtrl = undefined;
            $scope.endDateCtrl = undefined;
            $scope.notificationAuditRequest = {
                username: "",
                studentId: "",
                messageType: "",
                type: "",
                recipient: "",
                browserTimezone: moment.tz.guess()
            };
        }

        $scope.clean();


        /**
        * Generate Report
        */
        $scope.generateReport = function () {
            $scope.notificationAudits = [];
            $scope.page = 1;
            $scope.lastPage = false;
            $scope.per_page = $scope.infiniteScrollPageSize;
            $scope.busy = true;
            ReportsService.getNotificationsReport($scope.notificationAuditRequest, $scope.organization, $scope.page, $scope.per_page, generateReportCallback);
            $scope.page++;
        };

        /**
        * Generate Report
        */
        $scope.loadPageScroll = function () {
            if ($scope.page < 1 || $scope.lastPage) {
                return;
            };

            $scope.busy = true;
            ReportsService.getNotificationsReport($scope.notificationAuditRequest, $scope.organization, $scope.page, $scope.per_page, loadPageCallback);
            $scope.page++;
        };

        $scope.loadPage = function(page) {
            if (page && page.length > 0) {
                $scope.notificationAudits = $scope.notificationAudits.concat(page);
                if (page.length < $scope.per_page) {
                    $scope.lastPage = true;
                }
            } else {
                $scope.lastPage = true;
            }
        }

        /**
         * convert To UTC
         *
         * @param dt
         * @returns {Date}
         */
        $scope.convertToUTC = function (dt) {
            var localDate = new Date(dt);
            var localTime = localDate.getTime();
            var localOffset = localDate.getTimezoneOffset() * 60000;
            return new Date(localTime + localOffset);
        };

        $scope.showBody = function (audit, timezone) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/reports/notificationAudit/_mailBodyModal.html',
                controller: 'MailBodyModalCtrl',
                size: 'lg',
                resolve: {
                    audit: function () {
                        return audit;
                    },
                    timezone: function () {
                       return timezone;
                    }
                }
            });
        };

        $scope.showVehicleAudit = function (audit) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/reports/notificationAudit/_vehicleAuditModal.html',
                controller: 'VehicleAuditModalCtrl',
                size: 'lg',
                resolve: {
                    info: function () {
                        return audit;
                    }
                }
            });
        };

        $scope.showErrorDetails = function (audit) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'scripts/app/reports/notificationAudit/_errorsDetailModal.html',
                controller: 'ErrorsDetailModalCtrl',
                size: 'lg',
                resolve: {
                    audit: function () {
                        return audit;
                    }
                }
            });
        };

        $scope.formatTime = function (d) {
            return DateUtils.dateTimeFormat(d);
        }
    });

angular.module('schoolbushubApp').controller('MailBodyModalCtrl', function ($scope, $uibModalInstance, audit, timezone, $sce) {
    $scope.audit = audit;
    $scope.html = $sce.trustAsHtml(audit.messageBody);
    $scope.date = moment.utc($scope.audit.date).tz(timezone).format('LLLL');

    $scope.clear = function () {
        $uibModalInstance.dismiss('ok');
    };
});

angular.module('schoolbushubApp').controller('VehicleAuditModalCtrl', function ($scope, $uibModalInstance, info, $sce) {
    $scope.vehicleAudit = info;
    $scope.vehicleAudit.rawMessage = JSON.parse($scope.vehicleAudit.rawMessage);
    $scope.vehicleAudit.rawMessage = JSON.stringify($scope.vehicleAudit.rawMessage, undefined, 2);

    $scope.clear = function () {
        $uibModalInstance.dismiss('ok');
    };
});

angular.module('schoolbushubApp').controller('ErrorsDetailModalCtrl', function ($scope, $uibModalInstance, audit, $sce) {
    $scope.audit = audit;
    $scope.date = moment($scope.audit.date).format('LLLL');

    $scope.clear = function () {
        $uibModalInstance.dismiss('ok');
    };
});
