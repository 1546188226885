'use strict';

angular.module('schoolbushubApp')
    .service('ReportsService', function ($http) {
        /**
         * Get Schools list
         */
        var getSchools = function(organization, callback) {
            var headers = {'X-Requested-With':'XMLHttpRequest', 'Accept':'application/json, text/plain, */*'};
            $http.get('api/schools/list?organizationId='+organization.id, undefined, headers)
            .then(function(response) {
                callback({status:'success', schools:response.data});
            })
            .catch(function() {
                callback({status:'error'});
            });
        }

        /**
         * Get Report Account Audit
         *
         * @param accountAuditRequest
         * @param callback
         */
        var getReport = function(accountAuditRequest, organization, page,  per_page, callback){
            var url = 'api/accountsAudit?page='+page+'&per_page='+per_page+'&organizationId='+organization.id;
            var headers = {'X-Requested-With':'XMLHttpRequest', 'Accept':'application/json, text/plain, */*'};
            $http.post(url, accountAuditRequest, headers)
            .then(function(response) {
                callback({status:'success',accountAuditResponse:response.data});
            }).catch(function(){
                callback({status:'error'});
            });
        };

        /**
         * Get Report Student Audit
         *
         * @param id student
         * @param callback
         */
        var getStudentReport = function(studentId, callback){
            var url = 'api/studentAudit?studentId='+studentId;
            var headers = {'X-Requested-With':'XMLHttpRequest', 'Accept':'application/json, text/plain, */*'};
            $http.post(url, [], headers)
                .then(function(response) {
                    callback({status:'success',studentAuditResponse:response.data});
                }).catch(function(){
                    callback({status:'error'});
                });
        };

        /**
         * Get Report Student Audit
         *
         * @param id student
         * @param callback
         */
        var getDetailStudents = function(emailAddress, schoolCodes, callback){
            var url = 'api/detailStudents';
            var headers = {'X-Requested-With':'XMLHttpRequest', 'Accept':'application/json, text/plain, */*'};
            var params = {'schoolCodes[]':schoolCodes, 'emailAddress':emailAddress};
            $http.get(url, {headers:headers,params:params})
                .then(function(response) {
                    callback({status:'success',students:response.data});
                }).catch(function(){
                    callback({status:'error'});
                });
        };

        /**
         * Get Report Account Audit
         *
         * @param auditRequest
         * @param callback
         */
        var getNotificationsReport = function(auditRequest, organization, page, per_page, callback){
            var url = 'api/notificationAudits/report?organizationId='+((organization)? organization.id:'')+'&page='+page+'&per_page='+per_page;
            var headers = {'X-Requested-With':'XMLHttpRequest', 'Accept':'application/json, text/plain, */*'};
            $http.post(url, auditRequest, headers)
            .then(function(response) {
                callback({status:'success',auditResponse:response.data});
            }).catch(function(){
                callback({status:'error'});
            });
        };

        return {
            getSchools:getSchools,
            getReport:getReport,
            getStudentReport:getStudentReport,
            getDetailStudents:getDetailStudents,
            getNotificationsReport:getNotificationsReport
        };
    });
