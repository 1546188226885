'use strict';

angular.module('schoolbushubApp')
    .factory('VehicleAudit', function ($resource, DateUtils) {
        return $resource('api/vehicleAudits/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.eventTimeStamp = DateUtils.convertDateTimeFromServer(data.eventTimeStamp);
                    data.plannedArrivalTime = DateUtils.convertDateTimeFromServer(data.plannedArrivalTime);
                    data.allowedTimeStart = DateUtils.convertDateTimeFromServer(data.allowedTimeStart);
                    data.allowedTimeEnd = DateUtils.convertDateTimeFromServer(data.allowedTimeEnd);
                    data.processTime = DateUtils.convertDateTimeFromServer(data.processTime);
                    return data;
                }
            },
            'filterQuery': { method: 'POST', url: 'api/vehicleaudits/query', isArray:true },
            'filterVars': { method: 'GET', url: 'api/vehicleaudits/info', isArray:true },
            'update': { method:'PUT' }
        });
    });
