'use strict';

angular.module('schoolbushubApp')
  .service('Verify', function ($http) {

    /**
     * Verify Student
     *
     * @param schoolCode
     * @param dateOfBirth
     * @param idStudent
     * @param callback
     */
    var verifyStudent = function(schoolCode, dateOfBirth, idStudent, organizationId, callback){
        var url = 'api/verifyStudent?schoolCode='+schoolCode+'&dateOfBirth='+moment(dateOfBirth).format('YYYY-MM-DD')
          +'&idStudent='+idStudent+'&organizationId='+organizationId;
        $http.post(url,[])
            .then(function(response) {
                callback({status:'success',verificationKey:response.data});
            }).catch(function() {
                callback({status:'error'});
            }
        );
    }

    return{
        verifyStudent:verifyStudent
    }

  });
