    angular.module("schoolbushubApp")
    .directive('datePicker', function ($parse, Language) {

    var linker = function (scope, element, attrs) {
        if(attrs.inline == "true"){
            jQuery(element).datepicker(
                {
                    changeYear: true,
                    yearRange: '1950:2050',
                    inline: true,
                    altField: '#'+attrs.idInput
                });

            $('#'+attrs.idInput).change(function(){
                element.datepicker('setDate', $(this).val()).css('z-index', 2000);
            });
        }else{
            jQuery(element).datepicker(
                {
                    changeYear: true,
                    yearRange: '1950:2050'
                });
        }

        Language.getCurrent().then(function (language) {
             element.datepicker("option", $.datepicker.regional[language]).css('z-index', 2000);
             element.datepicker("option", "dateFormat", "mm/dd/yy");
        });
    };

    return {
        restrict: 'A',
        link: linker,
        scope: {
            content: '='
        }
    };
});
