'use strict';

angular.module('schoolbushubApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('reports', {
                abstract: true,
                parent: 'site'
            });
    });
