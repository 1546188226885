/* jshint quotmark: false */
"use strict";
// DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
angular.module("schoolbushubApp")

.constant("REMOTE_SERVER", "")

.constant("ENV", "dev")

.constant("VERSION", "3.12.1")

.constant("USER_ROLES", {
	"all": "*",
	"admin": "ROLE_ADMIN",
	"seonadmin": "ROLE_SEONADMIN",
	"customeradmin": "ROLE_CUSTOMERADMIN",
	"parent": "ROLE_PARENT"
})
.constant("EVENT_SOURCES", {
	'NAVIGATOR':'Navigator',
	'NON_NAVIGATOR':'Non Navigator'
})

;
